import { getGenericPostRequest, buildURLs, buildKeys, getGenericGetRequest } from "./utils";

const BASE_URLS = {
    // GET requests
    GET_PROFILE_DETAILS: "users/get_profile_details",
    VERIFY_USER: "users/verifyUser",
    GET_USERS: "users/get_users",
    GET_PROJECT: "user/projectDetail/:projectId",

    // POST requests
    PROJECT_LISTING: "public/projectListing",
    GET_USER_EXPRESSION: "user/projectExpressionListing",
    GET_ADMIN_EXPRESSION: "admin/projectExpressionListing",
    PROMOTE_PROJECT: "user/promote_project",
    UPDATE_USER_DETAILS: "users/update_profile_details",
    GET_PROJECT_UPLOAD_URL: "projects/get_upload_url",
    GET_UPLOAD_URL: "users/get_upload_url",
    LIKE_PROJECT: "users/like_project",
    UNLIKE_PROJECT: "users/unlike_project",
    ADD_PROJECT: "admin/addProject",
    ADD_COMMENT: "user/addProjectComment",
    GET_PROJECT_COMMENTS: "user/projectCommentListing",
    
};

export const URLS = buildURLs({
    urls: BASE_URLS,
    prefix: "/api/",
});

// Export all generic functions

// GET requests
export const getProfileDetailsAPI = getGenericGetRequest(URLS.GET_PROFILE_DETAILS);
export const verifyUserAPI = getGenericGetRequest(URLS.VERIFY_USER);
export const getUsersAPI = getGenericGetRequest(URLS.GET_USERS);
export const getProjectAPI = getGenericGetRequest(URLS.GET_PROJECT);

// POST requests
export const getProjectListingAPI = getGenericPostRequest(URLS.PROJECT_LISTING);
export const getUserExpressionListingAPI = getGenericPostRequest(URLS.GET_USER_EXPRESSION);
export const getAdminExpressionListingAPI = getGenericPostRequest(URLS.GET_ADMIN_EXPRESSION);
export const promoteProjectAPI = getGenericPostRequest(URLS.PROMOTE_PROJECT);
export const updateUserDetailsAPI = getGenericPostRequest(URLS.UPDATE_USER_DETAILS);
export const getProjectUploadUrlAPI = getGenericPostRequest(URLS.GET_PROJECT_UPLOAD_URL);
export const getUploadURLAPI = getGenericPostRequest(URLS.GET_UPLOAD_URL);
export const likeProjectAPI = getGenericPostRequest(URLS.LIKE_PROJECT);
export const unlikeProjectAPI = getGenericPostRequest(URLS.UNLIKE_PROJECT);
export const addProjectAPI = getGenericPostRequest(URLS.ADD_PROJECT);
export const addCommentAPI = getGenericPostRequest(URLS.ADD_COMMENT);
export const getProjectCommentsAPI = getGenericPostRequest(URLS.GET_PROJECT_COMMENTS);

// Export query keys
const KEYS = buildKeys(BASE_URLS);

export default KEYS;
