import React from "react";
import { components } from "react-select";

const DropDownOption = ({ children, ...props }) => {
    const isDisabled = props.getValue().some(({ isSelectAll }) => isSelectAll) ? true : false;

    return (
        <components.Option
            className={`!cursor-pointer !whitespace-nowrap  ${
                props.isSelected ? "!bg-gray-200 !text-black" : isDisabled ? "!bg-gray-100" : "!bg-white"
            } !p-0 hover:!bg-gray-100 ${props.innerProps.className}`}
            {...props}
        >
            <div
                className="z-1000 flex w-full flex-row items-center justify-between px-2 py-2"
                onClick={(e) => {
                    e.stopPropagation();
                    if (props.data.onClick) {
                        props.data.onClick();
                    }
                    if (!isDisabled || props.data.isSelectAll) {
                        props.selectOption(props.data);
                    }
                }}
            >
                {props?.isMulti && (
                    <input
                        type="checkbox"
                        disabled={isDisabled && !props.data.isSelectAll}
                        id="customCheck"
                        checked={props.isSelected}
                        className="w-4 h-4 mr-2"
                    />
                )}
                <div className={`flex flex-grow flex-row items-center ${props?.isMulti ? "ml-2" : ""}`}>
                    {props.data.showIcon && !props.data.showRightIcon && (
                        <>
                            {typeof props.data.icon === "string" ? (
                                <img src={props.data.icon} alt="#" className="mr-2 h-4 w-4 rounded-full" />
                            ) : (
                                props.data.icon
                            )}
                        </>
                    )}
                    <div className="flex flex-col">
                        <span>{props?.data?.label}</span>
                        {props?.data?.subLabel && <span>{props?.data?.subLabel}</span>}
                    </div>
                    {props.data.showIcon && props.data.showRightIcon && (
                        <>
                            {typeof props.data.icon === "string" ? (
                                <img src={props.data.icon} alt="#" className="mr-2 h-4 w-4 rounded-full" />
                            ) : (
                                props.data.icon
                            )}
                        </>
                    )}
                </div>
            </div>
        </components.Option>
    );
};

export default DropDownOption;
