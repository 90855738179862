import React from "react";
import { useForm } from "react-hook-form";
import { Button, Grid, InputLabel, TextField, Typography } from "@mui/material";
import "./ContactUs.styles.scss";

const ContactUs = () => {
    const { register, handleSubmit } = useForm();
    return (
        <div className="contact_us_page">
            <Grid container direction="column" justifyContent="center" alignItems="center" className="contact_us_header">
                <Typography>Home &gt; &gt; Contact Us</Typography>
                <Typography variant="h1">Contact Us</Typography>
                <Typography>
                    If you have business inquiries or other questions, please fill out the following form to contact us. Thank you.
                </Typography>
            </Grid>
            <Grid style={{ marginBottom: "5rem" }} container xs={12} justifyContent="center">
                <form onSubmit={handleSubmit((data) => console.log(data))} item className="contact_us_form">
                    <Grid item className="contact_us_form_items">
                        <InputLabel>Name</InputLabel>
                        <TextField name="name" {...register} type="text" fullWidth sx={{ marginTop: "1rem" }} />
                    </Grid>
                    <Grid item className="contact_us_form_items">
                        <InputLabel>Email</InputLabel>
                        <TextField name="email" {...register} type="text" fullWidth sx={{ marginTop: "1rem" }} />
                    </Grid>
                    <Grid item className="contact_us_form_items">
                        <InputLabel>Message</InputLabel>
                        <TextField name="message" {...register} rows={6} type="text" fullWidth multiline sx={{ marginTop: "1rem" }} />
                    </Grid>
                    <Button variant="contained" type="submit" className="submit_button" fullWidth>
                        Submit
                    </Button>
                </form>
            </Grid>
        </div>
    );
};

export default ContactUs;
