import { React, useState, useEffect } from "react";
import { Tabs, Tab, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useGetProjectsQuery } from "types/generated-types.ts";
import ApprovedProjects from "../ApprovedProjects/ApprovedProjects";
import PendingProjects from "../PendingProjects/PendingProjects";
import "./ViewAllProjects.scss";

const ViewAllProjects = (props) => {
    const [tab, setTab] = useState("pending");
    const [approvedProjects, setApprovedProjects] = useState([]);
    const [pendingProjects, setPendingProjects] = useState([]);
    const [searchValue, setSearchValue] = useState("");

    const {
        data: projectData,
        refetch,
        error,
    } = useGetProjectsQuery({
        variables: {
            limit: Infinity,
            offset: 0,
        },
    });

    console.log({ error, projectData });

    useEffect(() => {
        if (projectData && projectData.projects) {
            let approvedProjects = [],
                pendingProjects = [];
            projectData?.projects?.forEach((project) => {
                project.is_verified === true ? approvedProjects.push(project) : pendingProjects.push(project);
            });
            setApprovedProjects(approvedProjects);
            setPendingProjects(pendingProjects);
        }
    }, [projectData]);

    useEffect(() => {
        if (searchValue === "") {
            if (projectData && projectData.projects) {
                let approvedProjects = [],
                    pendingProjects = [];
                projectData?.projects?.forEach((project) => {
                    project.is_verified === true ? approvedProjects.push(project) : pendingProjects.push(project);
                });
                setApprovedProjects(approvedProjects);
                setPendingProjects(pendingProjects);
                return;
            }
        }
        if (tab === "pending") {
            const filteredProject = projectData?.projects.filter(
                (item) => item.title.toLowerCase().includes(searchValue) && item.is_verified === false
            );
            setPendingProjects(filteredProject);
        } else {
            const filteredProject = projectData?.projects.filter(
                (item) => item.title.toLowerCase().includes(searchValue) && item.is_verified === true
            );
            setApprovedProjects(filteredProject);
        }
    }, [searchValue]);

    const performedAction = (action, id) => {
        setPendingProjects((oldValue) => {
            return oldValue.filter((exp) => {
                return exp.id !== id;
            });
        });

        if (action === "approve") {
            setApprovedProjects((oldValue) => {
                return oldValue.filter((exp) => {
                    return exp.id !== id;
                });
            });
        }
    };

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
        setSearchValue("");
    };

    return (
        <div className="all-projects__container">
            <Typography className="title">All Projects</Typography>
            <br />
            <Tabs scrollButtons={false} value={tab} className="status__tabs tabs__container" onChange={handleTabChange}>
                <Tab style={{ textTransform: "capitalize" }} disableRipple className="single__tab" label={"Approved"} value="approved" wrapped />
                <Tab style={{ textTransform: "capitalize" }} disableRipple className="single__tab" label={"Pending"} value="pending" />
            </Tabs>
            <TextField
                sx={{ my: 2, width: "50%" }}
                placeholder="Search by project name"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
            />
            <br />
            <div className="expTable">
                {tab === "pending" ? (
                    <PendingProjects refetch={refetch} projectDetails={pendingProjects} action={performedAction} />
                ) : tab === "approved" ? (
                    <ApprovedProjects refetch={refetch} projectDetails={approvedProjects} action={performedAction} />
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

export default ViewAllProjects;
