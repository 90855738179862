import { useEffect } from "react";
import CustomFooter from "components/CustomFooter/CustomFooter";
// components
import MainNavbar from "components/MainNavbar";
import PrivacyContent from "components/PrivacyContent/PrivacyContent";
import "./PrivacyPolicy.styles.scss";

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <section className="PrivacyPolicy">
            <MainNavbar bgColor="#fff" />
            <PrivacyContent />
            <CustomFooter />
        </section>
    );
};

export default PrivacyPolicy;
