import { useNavigate } from "react-router-dom";
import React, { createContext, useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useRegisterMutation } from "types/generated-types.ts";
import ROUTES from "constants/routes";

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
    var specificUserTypeFields = "";
    const navigate = useNavigate();
    const location = window.location.href;

    const [state, setState] = useState({
        email: "",
        phoneNumber: "",
        password: "",
        reEnterPassword: "",
        firstName: "",
        lastName: "",
        // userType: "",
        // userTypeFieldsDetails: {},
        individualState: {
            individualCity: "",
            individualGender: "",
            individualOccupation: "",
        },
        // csrFoundationState: {
        //     csrFoundationName: "",
        //     csrContributionCategories: [],
        //     csrPointOfContact: "",
        //     csrAddress: "",
        //     csrRepresentativeRole: "",
        // },
        // mlaState: {
        //     mlaName: "",
        //     mlaConstituency: "",
        //     mlaContactDetails: "",
        //     mlaPartyName: "",
        //     mlaTenureDuration: "",
        //     mlaGender: "",
        // },
        // mpState: {
        //     mpName: "",
        //     mpConstituency: "",
        //     mpContactDetails: "",
        //     mpPartyName: "",
        //     mpTenureDuration: "",
        //     mpGender: "",
        // },
        // npoState: {
        //     npoName: "",
        //     npoContributionCategories: [],
        //     npoPointOfContact: "",
        //     npoAddress: "",
        //     npoRepresentativeRole: "",
        // },
        // otherState: {
        //     otherName: "",
        //     otherPhone: "",
        //     otherAddress: "",
        // },
        // proBonoState: {
        //     proBonoName: "",
        //     proBonoProfession: "",
        //     proBonoPointOfContact: "",
        //     proBonoContributionCategories: [],
        //     proBonoSkills: "",
        //     proBonoGender: "",
        // },
        // psbState: {
        //     psbBankName: "",
        //     psbContributionCategories: [],
        //     psbPointOfContact: "",
        //     psbAddress: "",
        //     psbRepresentativeRole: "",
        // },
        // smeState: {
        //     smeName: "",
        //     smeExpertise: "",
        //     smePreviousCompany: "",
        //     smeRole: "",
        //     smePointOfContact: "",
        //     smeGender: "",
        // },
        // studentState: {
        //     studentName: "",
        //     studentCourseName: "",
        //     studentInstitutionName: "",
        //     studentInstitutionAddress: "",
        //     studentPersonalContact: "",
        //     studentGender: "",
        // },
    });

    const {
        email,
        phoneNumber,
        password,
        firstName,
        lastName,
        // userType,
        individualState,
        // csrFoundationState,
        // mpState,
        // studentState,
        // otherState,
        // smeState,
        // psbState,
        // proBonoState,
        // npoState,
        // mlaState,
    } = state;

    const [register] = useRegisterMutation({
        onCompleted: (data) => {
            console.log(data);
            if (data && data.register) {
                toast.dismiss();
                toast.success("User Registered Successfully");
                navigate(ROUTES.VERIFY_OTP);
            } else {
                toast.dismiss();
                toast.error("Something went wrong, please try again.");
            }
        },
    });
    const registerUser = () => {
        specificUserTypeFields = {
            name: firstName + lastName,
            gender: individualState.individualGender,
            city: individualState.individualCity,
            occupation: individualState.individualOccupation,
        };
        // if (userType === "Individual") {
        //     specificUserTypeFields = {
        //         name: individualState.individualName,
        //         phone: individualState.individualPhone,
        //         gender: individualState.individualGender,
        //         address: individualState.individualAddress,
        //         occupation: individualState.individualOccupation,
        //     };
        // }
        // if (userType === "CSR foundation") {
        //     specificUserTypeFields = {
        //         name: csrFoundationState.csrFoundationName,
        //         contributioCategories: csrFoundationState.csrContributionCategories,
        //         phone: csrFoundationState.csrPointOfContact,
        //         address: csrFoundationState.csrAddress,
        //         role: csrFoundationState.csrRepresentativeRole,
        //     };
        // }
        // if (userType === "Non-Profit Organization") {
        //     specificUserTypeFields = {
        //         name: npoState.npoName,
        //         contributionCategories: npoState.npoContributionCategories,
        //         phone: npoState.npoPointOfContact,
        //         address: npoState.npoAddress,
        //         cinNumber: npoState.npoCinNumber,
        //     };
        // }
        // if (userType === "Subject Matter Expert") {
        //     specificUserTypeFields = {
        //         name: smeState.smeName,
        //         expertise: smeState.smeExpertise,
        //         previousCompany: smeState.smePreviousCompany,
        //         role: smeState.smeRole,
        //         phone: smeState.smePointOfContact,
        //         gender: smeState.smeGender,
        //     };
        // }
        // if (userType === "Pro-Bono") {
        //     specificUserTypeFields = {
        //         name: proBonoState.proBonoName,
        //         profession: proBonoState.proBonoProfession,
        //         contributionCategories: proBonoState.proBonoContributionCategories,
        //         skills: proBonoState.proBonoSkills,
        //     };
        // }
        // if (userType === "Public Sector Bank") {
        //     specificUserTypeFields = {
        //         name: psbState.psbBankName,
        //         contributionCategories: psbState.psbContributionCategories,
        //         phone: psbState.psbPointOfContact,
        //         address: psbState.psbAddress,
        //         cinNumber: psbState.psbCinNumber,
        //     };
        // }
        // if (userType === "Member of Parliament") {
        //     specificUserTypeFields = {
        //         name: mpState.mpName,
        //         constituency: mpState.mpName,
        //         phone: mpState.mpName,
        //         partyName: mpState.mpName,
        //         tenureDuration: mpState.mpName,
        //         gender: mpState.mpName,
        //     };
        // }
        // if (userType === "Member of Legislative Assembly") {
        //     specificUserTypeFields = {
        //         name: mlaState.mlaName,
        //         constituency: mlaState.mlaConstituency,
        //         phone: mlaState.mlaContactDetails,
        //         partyName: mlaState.mlaPartyName,
        //         tenureDuration: mlaState.mlaTenureDuration,
        //         gender: mlaState.mlaGender,
        //     };
        // }
        // if (userType === "Students") {
        //     specificUserTypeFields = {
        //         name: studentState.studentName,
        //         courseName: studentState.studentCourseName,
        //         institutionName: studentState.studentInstitutionName,
        //         institutionAddress: studentState.studentInstitutionAddress,
        //         phone: studentState.studentPersonalContact,
        //         gender: studentState.studentGender,
        //     };
        // }
        // if (userType === "Others") {
        //     specificUserTypeFields = {
        //         name: otherState.otherName,
        //         phone: otherState.otherPhone,
        //         address: otherState.otherAddress,
        //     };
        // }
        // console.log("checking", userType, specificUserTypeFields);
        console.log({ phoneNumber });

        register({
            variables: {
                email,
                phone_number: phoneNumber,
                password,
                firstName: state.firstName,
                lastName: state.lastName,
                userName: state.firstName + " " + state.lastName,

                userTypeFieldsDetails: JSON.stringify(specificUserTypeFields),
            },
        });
    };

    useEffect(() => {
        if (!state.email && location.startsWith("/signup/")) {
            navigate("/login");
        }
    }, [state.email, navigate]);

    return <UserContext.Provider value={{ state, setState, registerUser }}>{children}</UserContext.Provider>;
};
