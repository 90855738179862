import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../components/ui/select";
import { useUser } from "contexts/user-type/user-type-context.js";
const ProBono = () => {
    const navigate = useNavigate();
    const { state, setState, registerUser } = useUser();
    const { userType, proBonoState } = state;
    let { proBonoName, proBonoGender, proBonoFocusOfInterest, proBonoProfession, proBonoContact, proBonoSkills } = proBonoState;

    const all_projects = [
        "Agriculture",
        "Community",
        "Education",
        "Environment",
        "HealthCare",
        "Housing",
        "InfraStructurePower",
        "Roads and Bridges",
        "Social Justice",
        "Technology",
        "Waste Management",
        "Water & Sanitization",
        "Others",
    ];

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            proBonoState: {
                ...prevState.proBonoState,
                [name]: value,
            },
        }));
    };

    const handleSelectChange = (value) => {
        setState((prevState) => ({
            ...prevState,
            proBonoState: {
                ...prevState.proBonoState,
                proBonoGender: value,
            },
        }));
    };

    const handleSelectChangeFocusOfInterest = (value) => {
        setState((prevState) => ({
            ...prevState,
            proBonoState: {
                ...prevState.proBonoState,
                proBonoFocusOfInterest: value,
            },
        }));
    };

    const handleForwardClick = () => {
        if (!proBonoName || !proBonoGender || !proBonoFocusOfInterest || !proBonoProfession || !proBonoContact || !proBonoSkills) {
            return alert("please fill all Pro Bono userType details");
        }
        // var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
        // if (!re.test(proBonoContact)) {
        //     return alert("Enter valid phone number");
        // }
        registerUser();
    };

    return (
        <div className="flex h-full w-full justify-center items-center my-auto flex-col bg-[#F9F9F9] md:px-0 px-6">
            <div className="flex justify-center flex-col mb-10 md:mb-4">
                <p className="text-[#0D0140] font-bold text-2xl md:text-3xl text-center">Create Account as {userType}</p>
                <p className="text-[#0D0140] text-center font-thin text-base mt-2">Please fill the below options to continue</p>
            </div>
            <form className="w-full md:w-1/3">
                {/* <div className="mb-4 ">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2" for="username">
                        Email
                    </label>
                    <input
                        className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
                        type="email"
                        placeholder="Brandonelouis@gmail.com"
                        disabled
                    />
                </div> */}
                <div className="mb-4 ">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2" for="username">
                        Full Name
                    </label>
                    <input
                        className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
                        id="username"
                        type="text"
                        name="proBonoName"
                        value={proBonoName}
                        onChange={handleInputChange}
                        placeholder="Brandone Louis"
                    />
                </div>
                <div className="mb-4 ">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2" for="username">
                        Gender
                    </label>
                    <Select className="modal-select-input" value={proBonoGender || ""} onValueChange={handleSelectChange}>
                        <SelectTrigger aria-label="User Type" className="border-0">
                            <SelectValue placeholder="Select Gender" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="female">Female</SelectItem>
                            <SelectItem value="male">Male</SelectItem>
                            <SelectItem value="other">Other</SelectItem>
                        </SelectContent>
                    </Select>
                </div>
                <div className="mb-4 ">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2" for="username">
                        Focus of Interest
                    </label>
                    <Select className="modal-select-input" value={proBonoFocusOfInterest} onValueChange={handleSelectChangeFocusOfInterest}>
                        <SelectTrigger aria-label="Focus of Interest" className="border-0">
                            <SelectValue placeholder="Select Focus of Interest" />
                        </SelectTrigger>
                        <SelectContent>
                            {all_projects.map((project) => (
                                <SelectItem key={project} value={project}>
                                    {project}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
                <div className="mb-4 ">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2" for="username">
                        Profession
                    </label>
                    <input
                        className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
                        type="text"
                        name="proBonoProfession"
                        value={proBonoProfession}
                        onChange={handleInputChange}
                        placeholder="Profession"
                    />
                </div>
                <div className="mb-4 ">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2">Contact</label>
                    <input
                        className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
                        type="text"
                        name="proBonoContact"
                        value={proBonoContact}
                        onChange={handleInputChange}
                        placeholder="Contact"
                    />
                </div>
                <div className="mb-4 ">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2">Skills</label>
                    <input
                        className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
                        type="text"
                        name="proBonoSkills"
                        value={proBonoSkills}
                        onChange={handleInputChange}
                        placeholder="Skills"
                    />
                </div>

                <div className="flex flex-col items-center w-full px-8 ">
                    <button
                        className="bg-[#130160] w-full text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="button"
                        onClick={handleForwardClick}
                    >
                        CONTINUE
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ProBono;
