import { Button, Grid } from "@mui/material";
import "./header.styles.scss";

const RegisterHeader = (props) => {
    return (
        <Grid item container className="header" direction="row" justifyContent="flex-end" alignItems="center">
            <Button className="nav__btn" onClick={() => props.setOpen("login")}>
                Login
            </Button>
            <Button className="nav__btn active">SignUp</Button>
        </Grid>
    );
};

export default RegisterHeader;
