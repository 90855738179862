import { React, useState, useEffect } from "react";
import { Tabs, Tab, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useGetKycStatusQuery } from "types/generated-types.ts";
import ApprovedKyc from "./components/ApprovedKyc";
import PendingKyc from "./components/PendingKyc";
import "./UsersKycDashboard.styles.scss";

const UsersKycDashboard = (props) => {
    const [tab, setTab] = useState("pending");

    const [approvedKyc, setApprovedKyc] = useState([]);

    const [pendingKyc, setPendingKyc] = useState([]);

    const [searchValue, setSearchValue] = useState("");

    const { data: kycData, refetch } = useGetKycStatusQuery();

    useEffect(() => {
        console.log(kycData);
        if (kycData && kycData.users) {
            let approved_kyc = [],
                pending_kyc = [];
            kycData?.users?.forEach((user) => {
                user.kyc_status === "accepted" ? approved_kyc.push(user) : pending_kyc.push(user);
            });
            setApprovedKyc(approved_kyc);
            setPendingKyc(pending_kyc);
        }
    }, [kycData]);

    useEffect(() => {
        if (searchValue === "") {
            if (kycData && kycData.users) {
                let approved_kyc = [],
                    pending_kyc = [];
                kycData?.users?.forEach((user) => {
                    user.kyc_status === "accepted" ? approved_kyc.push(user) : pending_kyc.push(user);
                });
                setApprovedKyc(approved_kyc);
                setPendingKyc(pending_kyc);
                return;
            }
        }
        if (tab === "pending") {
            const filteredProject = kycData?.users.filter(
                (item) => item.user_name.toLowerCase().includes(searchValue) && item.kyc_status === "pending"
            );
            setPendingKyc(filteredProject);
        } else {
            const filteredProject = kycData?.users.filter(
                (item) => item.user_name.toLowerCase().includes(searchValue) && item.kyc_status === "accepted"
            );
            setApprovedKyc(filteredProject);
        }
    }, [searchValue]);

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
        setSearchValue("");
    };

    return (
        <div className="users-kyc__container">
            <Typography className="title">User Kyc</Typography>
            <br />
            <Tabs scrollButtons={false} value={tab} className="status__tabs tabs__container" onChange={handleTabChange}>
                <Tab style={{ textTransform: "capitalize" }} disableRipple className="single__tab" label={"Approved"} value="approved" wrapped />
                <Tab style={{ textTransform: "capitalize" }} disableRipple className="single__tab" label={"Pending"} value="pending" />
            </Tabs>
            <TextField
                sx={{ my: 2, width: "50%" }}
                placeholder="Search by user name"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
            />
            <br />
            <div className="expTable">
                {tab === "pending" ? (
                    <PendingKyc refetch={refetch} kycDetails={pendingKyc} />
                ) : tab === "approved" ? (
                    <ApprovedKyc refetch={refetch} kycDetails={approvedKyc} />
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

export default UsersKycDashboard;
