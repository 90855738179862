// components
import { Link } from "react-router-dom";
import "./TermsContent.styles.scss";

const TermsContent = () => (
    <section className="Terms w-full">
        <div className="container">
            <div className="panel panel-default">
                <div className="text-title">Terms & Conditions</div>
                <div className="panel-body">
                    <p>
                        This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as applicable and the
                        amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000.
                        This electronic record is generated by a computer system and does not require any physical or digital signatures.
                    </p>
                    <p>
                        This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries
                        guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and Terms of Use for access or usage
                        of the website www.equippp.com.{" "}
                    </p>
                    <p>
                        www.equippp.com (hereinafter referred to as "Website") is the exclusive property of EquiPPP Social Impact Technologies Limited
                        with its registered office at # 8th Floor, Western Pearl Building, Near Google Building, Hitech City Road, Hyderabad – 500081,
                        Telangana, India{" "}
                    </p>
                    <p>
                        Your use of the Website is governed by the following terms and conditions (“Terms of Use”) as applicable to the Website
                        including the applicable policies which are incorporated herein by way of reference. If you browse, access, secure access to
                        the Website, You shall be subject to all the policies and terms mentioned herein. By merely accessing or securing access,
                        browsing the Website, You shall be contracting with www.equippp.com, EquiPPP Social Impact Technologies Limited and these
                        terms and conditions including the policies constitute your binding obligations, with www.equippp.com, EquiPPP Social Impact
                        Technologies Limited{" "}
                    </p>
                    <p>
                        For the purpose of these Terms of Use, wherever the context so requires ‘You’ or “User” shall mean any natural or legal person
                        browsing, accessing or who has registered on the Website including Individuals, CSR users, Projects under MP/ MLA LADS,
                        Projects under Swatch Bharat, Projects by Students, Projects by Public Sector Banks, Projects for Social Impact Bonds and
                        others by providing email id/ phone no, passwords while signing up and registering on the Website as Registered User using
                        Computer, Mobile phones, Tablets.{" "}
                    </p>
                    <p>
                        {" "}
                        For this purpose, the term “Third Party / Third Parties” shall mean and include any party or parties other than
                        www.equippp.com, EquiPPP Social Impact Technologies Limited For this purpose, the term “Admin” shall mean and include any
                        administrator of the online platform (www.equippp.com) EquiPPP Social Impact Technologies Limited. However, in some scenarios,
                        the online platform (www.equippp.com) or EquiPPP Social Impact Technologies Limited shall select any user, third party who is
                        a Domain Expert as an Administrator. Further, such users, third party shall be independent professionals. In addition, the
                        Admin herein reserves the right to monitor the projects and any information including content, images, videos etc. uploaded by
                        any user, third party on the online platform (www.equippp.com). You herein agree that any content including project related
                        information, images, videos etc. uploaded thereof shall be subject to the approval of the Admin and the decision of the Admin
                        shall be final and binding on all users, third party.{" "}
                    </p>
                    <p>
                        {" "}
                        For this purpose, the term “Super-Admin” shall mean and include any user appointed by an Entity, Organisation, Company etc. as
                        Super-Admin to create users and monitor their project and related activities uploaded by them on the online platform
                        (www.equippp.com){" "}
                    </p>
                    <p>
                        {" "}
                        www.equippp.com allows users to surf, browse the Website without registering on the Website. The term “we”, “us”, “our” shall
                        EquiPPP Social Impact Technologies Limited We reserve the right, at our sole discretion, to change, modify, add or remove
                        portions of these Terms of Use, at any time without any prior written notice to you. It is your responsibility to review these
                        Terms of Use periodically for updates / changes. Your continued use of the Website, following the posting of changes shall
                        indicate your consent and acceptance to such revisions As long as you comply with these Terms of Use, We grant you a personal,
                        non-exclusive, non-transferable, limited privilege to enter, access, use the Website. ACCESSING, BROWSING, REGISTERING ON THE
                        WEBSITE INDICATE YOUR AGREEMENT TO ALL THE TERMS AND CONDITIONS, SO PLEASE READ THE TERMS AND CONDITIONS CAREFULLY BEFORE
                        PROCEEDING. By impliedly or expressly accepting these Terms of Use, You also accept and agree to be bound by the Privacy
                        Policy and legal policies mentioned on www.equippp.com
                    </p>

                    <b>ONLINE PLATFORM</b>
                    <p>
                        {" "}
                        EquiPPP Social Impact Technologies Limited is an intermediary and offers an online platform through the Website
                        (www.equippp.com). The online platform is a collaborative platform that vitalizes crowd participation in Public- Private
                        Projects. The online platform connects Corporates, Citizens, Domain Experts, NGOs and Philanthropists with the Government
                        enabling them to initiate and participate together in socially relevant projects. The online features various third parties
                        including but not limited to individuals, NGO’s, Organisations, CSR users, Entities, Public Sector Banks etc. Further, the
                        online platform facilitates the users and third parties to create projects (private & public) , upload project details
                        including but not limited to objective & description of project, conditions, scope, project related images, videos (YouTube
                        videos) & documents, estimated project budget, type of participation (through cash or kind), share such projects through
                        social media, enable users to participate in the project, interact with the Admin through communique, create Super-Admin,
                        enable Super-Admin to assign projects to SME Admin, add co-owners to the project, enable the Super-Admin to create SME, CSR,
                        Individual and other types of users, share feedback to Admin etc. You understand, acknowledge and accept that the online
                        platform i.e. www.equippp.com is only a mere facilitator in this regard.
                    </p>
                    <br />
                    <b>TERMS OF ONLINE PLATFORM</b>
                    <p>
                        {" "}
                        You understand, agree and acknowledge that the online platform will feature various third parties. The Website shall however,
                        take no responsibility in any manner for the content, descriptions and any information uploaded by such third parties on
                        www.equippp.com You herein understand and agree that the Third Parties and users are independent professionals, and neither
                        EQUIPPP SOCIAL IMPACT TECHNOLOGIES LIMITED( the company) nor the Website (www.equippp.com) shall hold any responsibility,
                        liability thereof in whatsoever manner{" "}
                    </p>
                    <ul>
                        <li type="1">
                            The online platform (<Link to={"/"}>www.equippp.com</Link>) is only a mere facilitator of information, content,
                            descriptions uploaded and provided by Third Parties
                        </li>
                        <li type="1">
                            All such content, project details, images, videos, description uploaded by Third Parties are only displayed on the online
                            platform (<Link to={"/"}>www.equippp.com</Link>)
                        </li>
                        <li type="1">
                            The online platform (<Link to={"/"}>www.equippp.com</Link>) shall not endorse or advise or involve in whatsoever manner
                            with regard to such content, project details, images, videos, description provided by Third Parties including but not
                            limited to any warranties
                        </li>
                        <li type="1">
                            The online platform (<Link to={"/"}>www.equippp.com</Link>) shall not in whatsoever manner resolve or mediate any dispute
                            or disagreement between the Third Parties
                        </li>
                        <li type="1">
                            The online platform (<Link to={"/"}>www.equippp.com</Link>) shall not make any representation with regard to the content,
                            project details, images, videos, descriptions uploaded and provided by any Third Party
                        </li>
                        <li type="1">
                            You understand, acknowledge and accept that the online platform provided through the Website (
                            <Link to={"/"}>www.equippp.com</Link>) shall not held liable in whatsoever manner for any but not limited to acts,
                            actions, activities, commissions, omissions, misconduct, fraud or negligence of any user and Third Party
                        </li>
                    </ul>
                    <br />
                    <b>TERMS OF USE</b>
                    <p>
                        {" "}
                        This Website (www.equippp.com) shall be used only by persons who can form legally binding contracts under The Indian Contract
                        Act, 1872. Persons who are "incompetent to contract" within the meaning of the Indian Contract Act, 1872 including minors,
                        un-discharged insolvents etc. are not eligible to use this Website (www.equippp.com). If you are a minor i.e. under the age of
                        18 years, you shall not register as a member of the Website (www.equippp.com) and shall not use Website (www.equippp.com). As
                        a minor, if you wish to use or transact on the Website (www.equippp.com), such use may be made by your legal guardian or
                        parents on the Website (www.equippp.com). The Website (www.equippp.com) reserves the right to terminate your Registration and
                        / or refuse to provide you with access to the Website (www.equippp.com) if it is brought to the Website‘s notice or if it is
                        discovered that you are under the age of 18 years{" "}
                    </p>
                    <br />
                    <b>REGISTRATION & NO CHARGES</b>
                    <p>
                        {" "}
                        The website does not charge any fee for browsing, creating an account on the Website (www.equippp.com). In particular,
                        www.equippp.com may at its sole discretion introduce new services and modify some or all of the existing services offered on
                        the Website (www.equippp.com). In such an event, the website reserves the right to introduce fees for the new services offered
                        or amend/introduce fees for existing services, as the case may be. Changes to the Fee Policy shall be posted on the Website
                        (www.equippp.com) and such changes shall automatically become effective immediately after they are posted on the Website
                        (www.equippp.com). Unless otherwise stated, all fees shall be quoted in Indian Rupees. You shall be solely responsible for
                        compliance of all applicable laws including those in India for making payments to www.equippp.com{" "}
                    </p>
                    <br />
                    <b>
                        WEBSITE (<Link to={"/"}>www.equippp.com</Link>)’s TERMS OF USE
                    </b>
                    <p>
                        You agree, undertake and confirm that your use of Website (<Link to={"/"}>www.equippp.com</Link>) shall be strictly governed
                        by the following binding principles. You hereby agree not to use the Website (<Link to={"/"}>www.equippp.com</Link>) to:-
                    </p>
                    <ul>
                        <li type="a">harass or advocate harassment of another person;</li>
                        <li type="a">involve the transmission of "junk mail”, "chain letters”, or unsolicited mass mailing or "spamming";</li>
                        <li type="a">promote illegal activities or conduct that is abusive, threatening, obscene, defamatory or libelous;</li>
                        <li type="a">
                            infringe upon or violates any third party's rights [including, but not limited to, intellectual property rights, rights of
                            privacy (including without limitation unauthorized disclosure of a person's name, email address, physical address or phone
                            number) or rights of publicity];
                        </li>
                        <li type="a">
                            promote an illegal or unauthorized copy of another person's copyrighted work such as providing pirated computer programs
                            or links to them, providing information to circumvent manufacture-installed copy-protect devices
                        </li>
                        <li type="a">
                            provide material that exploits people in a sexual, violent or otherwise inappropriate manner or solicits personal
                            information from anyone;
                        </li>
                        <li type="a">
                            provide instructional information about illegal activities such as making or buying illegal weapons, violating someone's
                            privacy, or providing or creating computer viruses;
                        </li>
                        <li type="a">upload, share, transmit video, photographs, or images of another person (with a minor or an adult).</li>
                        <li type="a">
                            to gain or attempt to gain unauthorized access or exceeds the scope of authorized access to the Website (
                            <Link to={"/"}>www.equippp.com</Link>) or to account information or other areas of the Website (
                            <Link to={"/"}>www.equippp.com</Link>) or solicits passwords or personal identifying information for commercial or
                            unlawful purposes from other users;
                        </li>
                        <li type="a">
                            engage in commercial activities and/or sales without our prior written consent of the Website such as contests,
                            sweepstakes, barter, advertising and pyramid schemes etc. related to the Website (<Link to={"/"}>www.equippp.com</Link>).
                            Throughout this Terms of Use, <Link to={"/"}>www.equippp.com</Link> ‘s prior written consent means a communication coming
                            from <Link to={"/"}>www.equippp.com</Link>‘s Legal Department, categorically in response to your request, and
                            categorically addressing the activity or conduct for which you seek authorization;
                        </li>
                        <li type="a">
                            solicit gambling or engage in any gambling activity which We, in Our sole discretion, believe is or could be construed as
                            being illegal;
                        </li>
                        <li type="a">
                            interfere with another user's use and enjoyment of the Website (<Link to={"/"}>www.equippp.com</Link>) or any other
                            individual's user and enjoyment of similar services;
                        </li>
                        <li type="a">harm minors in any way;</li>
                        <li type="a">
                            infringe any patent, trademark, copyright or other proprietary rights or third party’s trade secrets or rights of
                            publicity or privacy or shall not be fraudulent or involve the sale of counterfeit or stolen items including images,
                            pictures, information, data;
                        </li>
                        <li type="a">violate any law for the time being in force;</li>
                        <li type="a">
                            spread, transmit, upload software viruses or any other computer code, files or programs designed to interrupt, destroy or
                            limit the functionality of any computer resource; or contains any trojan horses, worms, time bombs, cancelbots, easter
                            eggs or other computer programming routines that may damage, detrimentally interfere with, diminish value of,
                            surreptitiously intercept or expropriate any system, data or personal information;
                        </li>
                        <li type="a">
                            threaten the unity, integrity, defense, security or sovereignty of India, friendly relations with foreign states, or
                            public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or
                            is insulting any other nation.
                        </li>
                        <li type="a">
                            to offer, attempt to offer, trade or attempt to trade in any item, the dealing of which is prohibited or restricted in any
                            manner under the provisions of any applicable law, rule, regulation or guideline for the time being in force.
                        </li>
                        <li type="a">
                            You shall not create liability for us or cause us to lose (in whole or in part) the services of our ISPs or any third
                            parties including individuals, companies, entities, organisations etc.
                        </li>
                    </ul>
                    <b>
                        PROHIBITED USE OF WEBSITE (<Link to={"/"}>www.equippp.com</Link>)
                    </b>
                    <ul>
                        <li type="1">
                            You shall not use any “deep-link”, “page-scrape”, “robot”, “spider” or other automatic device, program, algorithm or
                            methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Website (
                            <Link to={"/"}>www.equippp.com</Link>) or any Content, or in any way reproduce or circumvent the navigational structure or
                            presentation of the Website (<Link to={"/"}>www.equippp.com</Link>) or any Content, to obtain or attempt to obtain any
                            materials, documents or information through any means not purposely made available through the Website (
                            <Link to={"/"}>www.equippp.com</Link>). We reserve our right to bar any such activity.
                        </li>
                        <li type="1">
                            You shall not attempt to gain unauthorized access to any portion or feature of the Website (
                            <Link to={"/"}>www.equippp.com</Link>), or any other systems or networks connected to the Website (
                            <Link to={"/"}>www.equippp.com</Link>) or to any server, computer, network, or to any of the services offered on or
                            through the Website (<Link to={"/"}>www.equippp.com</Link>), by hacking, password “mining” or any other illegitimate
                            means.
                        </li>
                        <li type="1">
                            You shall not probe, scan or test the vulnerability of the Website (<Link to={"/"}>www.equippp.com</Link>) or any network
                            connected to the Website (<Link to={"/"}>www.equippp.com</Link>) nor breach the security or authentication measures on the
                            Website (<Link to={"/"}>www.equippp.com</Link>) or any network connected to the Website (
                            <Link to={"/"}>www.equippp.com</Link>). You may not reverse look-up, trace or seek to trace any information on any other
                            user of Website (<Link to={"/"}>www.equippp.com</Link>), or any other customer, including any account on the Website (
                            <Link to={"/"}>www.equippp.com</Link>) not owned by You, to its source, or exploit the Website (
                            <Link to={"/"}>www.equippp.com</Link>) or any service or information made available or offered by or through the Website (
                            <Link to={"/"}>www.equippp.com</Link>), in any way where the purpose is to reveal any information, including but not
                            limited to personal identification or information, other than your own information, as provided for by the Website (
                            <Link to={"/"}>www.equippp.com</Link>).
                        </li>
                        <li type="1">
                            You shall not make any negative, denigrating or defamatory statement(s) or comment(s) about us or the brand name used by
                            us including the terms EquiPPP Social Impact Technologies Limited, Website (www.equippp.com) or otherwise engage in any
                            conduct or action that might tarnish the image or reputation, of the company, “EquiPPP Social Impact Technologies
                            Limited”, Website (www.equippp.com) or otherwise tarnish or dilute any trade or service marks, trade name and/or goodwill
                            associated with the trade or service marks, trade name as may be owned or used by us. You agree that You will not take any
                            action that imposes an unreasonable or disproportionately large load on the infrastructure of the Website
                            (www.equippp.com) or www.equippp.com‘s systems or networks, or any systems or networks connected to the website
                        </li>
                        <li type="1">
                            You agree not to use any device, software or routine to interfere or attempt to interfere with the proper working of the
                            Website (<Link to={"/"}>www.equippp.com</Link>) or any transaction being conducted on the Website (
                            <Link to={"/"}>www.equippp.com</Link>), or with any other person’s use of the Website (
                            <Link to={"/"}>www.equippp.com</Link>).
                        </li>
                        <li type="1">
                            You may not forge headers or otherwise manipulate identifiers in order to disguise the origin of any message or
                            transmittal, you send to us on or through the Website (<Link to={"/"}>www.equippp.com</Link>) or any service offered on or
                            through the Website (<Link to={"/"}>www.equippp.com</Link>). You may not pretend that you are, or that you represent,
                            someone else, or impersonate any other individual or entity.
                        </li>
                        <li type="1">
                            {" "}
                            You may not use the Website (www.equippp.com) or any content for any purpose that is unlawful or prohibited by these Terms
                            of Use, or to solicit the performance of any illegal activity or other activity which infringes the rights of EquiPPP
                            Social Impact Technologies Limited, Website (www.equippp.com) and / or others.{" "}
                        </li>
                        <li type="1">
                            You shall at all times ensure full compliance with the applicable provisions of the Information Technology Act, 2000 and
                            rules there under as applicable and as amended from time to time and also all applicable laws, rules and regulations.
                        </li>
                        <li type="1">
                            You may not transmit any chain letters or unsolicited commercial or junk email to other users via the Website (
                            <Link to={"/"}>www.equippp.com</Link>).
                        </li>
                        <li type="1">
                            It shall be a violation of these Terms of Use to use any information obtained from the Website (
                            <Link to={"/"}>www.equippp.com</Link>) in order to harass, abuse, or harm another person, or solicit, or sell to another
                            person including but not limited to individuals, entities, companies, organisations any such information.
                        </li>
                        <li type="1">
                            You understand that we have the right at all times to disclose any information (including the identity of the persons
                            providing information or materials on the Website (<Link to={"/"}>www.equippp.com</Link>) as necessary to satisfy any law,
                            regulation or valid governmental request. This may include, without limitation, disclosure of the information in
                            connection with investigation of alleged illegal activity or solicitation of illegal activity or in response to a lawful
                            court order or subpoena. In addition, we shall (You hereby expressly authorize us to) disclose any information about You
                            to law enforcement or other government officials, as we, in our sole discretion, believe necessary or appropriate in
                            connection with the investigation and/or resolution of possible crimes, especially those that may involve personal injury.
                        </li>
                        <li type="1">
                            {" "}
                            We reserve the right, but has no obligation, to monitor the materials posted on the Website (www.equippp.com). The Website
                            (www.equippp.com) shall have the right to remove or edit any content, product, image that in its sole discretion violates,
                            or is alleged to violate, any applicable law or either the spirit or letter of these Terms of Use. In no event shall
                            EquiPPP Social Impact Technologies Limited Website (www.equippp.com) assume or have any responsibility or liability for
                            any Content, information posted, uploaded, provided by any Third Party. Further, neither the Website (www.equippp.com),
                            EquiPPP Social Impact Technologies Limited shall hold any responsibility, liability for any claims, damages or losses
                            resulting from such content, project details, images, videos, descriptions, actions of any Third Party{" "}
                        </li>
                        <li type="1">
                            It is possible that other Users (including unauthorized users or “hackers”) may post or transmit offensive or obscene
                            materials on the Website (<Link to={"/"}>www.equippp.com</Link>) and that You may be involuntarily exposed to such
                            offensive and obscene materials. It also is possible for others to obtain personal information about you due to your use
                            of the Website (<Link to={"/"}>www.equippp.com</Link>), and that the recipient may use such information to harass or
                            injure you. We does not approve of such unauthorized uses, but by using the Website (<Link to={"/"}>www.equippp.com</Link>
                            ) You acknowledge and agree that we are not responsible for the use of any personal information that You publicly disclose
                            or share with others on the Website (<Link to={"/"}>www.equippp.com</Link>).
                        </li>
                        <li type="1">
                            The Website (<Link to={"/"}>www.equippp.com</Link>) shall have all the rights to take necessary action and claim damages
                            that may occur due to your involvement/participation in any way on your own or through group/s of people, intentionally or
                            unintentionally in DoS/DDoS (Distributed Denial of Services).
                        </li>
                    </ul>

                    <b>PROJECTS</b>
                    <p>
                        The online platform (<Link to={"/"}>www.equippp.com</Link>) shall enable you to create the following kinds of projects
                    </p>
                    <ul>
                        <li>
                            Private Projects <br />
                            <br />
                            <p>
                                This kind of project is available for all types of users and are not displayed in the maps page. Further, not every
                                user, third party shall be able to access this kind of project. However, any user, third party intending to access the
                                same shall be required to raise a request to the Admin
                            </p>
                        </li>
                        <li>
                            Public Projects <br />
                            <br />
                            <p>
                                This kind of project shall be visible and accessible to any third party, user of the online platform (
                                <Link to={"/"}>www.equippp.com</Link>)
                            </p>
                        </li>
                    </ul>
                    <b>CONTENTS POSTED ON THE WEBSITE</b>
                    <p>
                        {" "}
                        The Platform description, Content, text, user interfaces, visual interfaces, photographs, images, pictures, trademarks, logos,
                        and artwork relating to the Website (www.equippp.com) shall be the sole property of EquiPPP Social Impact Technologies Limited
                        and we have worldwide, perpetual and transferable rights in regard to such Content. We shall be entitled to, consistent with
                        our Privacy Policy as adopted in accordance with applicable law, use the Content or any of its elements for any type of use
                        forever, including but not limited to promotional and advertising purposes and in any media whether now known or hereafter
                        devised, including the creation of derivative works. The content, project related information, images, videos and any
                        information uploaded and posted by any user or Third Party while signing up and using any feature made available on the
                        Website (www.equippp.com) shall herein after be referred to as “Third-Party Content”. You herein agree that the online
                        platform (www.equippp.com) shall merely display such “Third-Party Content” and shall be a mere intermediary for this purpose.
                        All the intellectual property, proprietary rights arising out of such “Third Party Content” shall be the exclusive property of
                        the respective intellectual property owners. We have no intention to acquire the intellectual property arising out of Third
                        Party Content. In no event, shall EquiPPP Social Impact Technologies Limited Website (www.equippp.com) hold any
                        responsibility, liability for such Third-Party Content. The Website (www.equippp.com) may also contain free images, content
                        from the internet, images legally outsourced from various sources, royalty free images and in such case, the source of the
                        images, content may also be mentioned, and credit shall be given to the respective intellectual property owner. Nothing
                        contained herein shall be copied, reproduced, republished, uploaded, posted, publicly displayed, encoded, translated,
                        transmitted or distributed in any way (including “mirroring”) to any other computer, server, Website (www.equippp.com) or
                        other medium for publication or distribution or for any commercial enterprise, without the express prior written consent of
                        EquiPPP Social Impact Technologies Limited, Website (www.equippp.com).
                    </p>
                    <br />
                    <b>LEGAL ACTION AGAINST THE THIRD-PARTY / THIRD PARTIES</b>
                    <p>
                        {" "}
                        In the event of any criminal act committed by any user, Third Party / Third Parties or its staff or affiliates, you may take
                        legal action against such Third Party / Third Parties or its staff or affiliates and in such cases the Website
                        (www.equippp.com), EquiPPP Social Impact Technologies Limited shall also be informed about the same. In the occurrence of the
                        any such event mentioned above, you shall not make the Website (www.equippp.com EquiPPP Social Impact Technologies Limited, a
                        party to such offence, as the Third Party or its staff or affiliates are individually and severally liable for any criminal
                        act committed by them
                    </p>
                    <br />
                    <b>LIABILITY DUE TO INTERNET CONNECTIVITY & NETWORK ISSUES</b>
                    <p>
                        {" "}
                        The Website (www.equippp.com EquiPPP Social Impact Technologies Limited shall hold no responsibility, liability for any
                        actions, acts including but not limited to errors, transmission, activities and issues arising out of internet connectivity
                        and any other network related issues.{" "}
                    </p>
                    <br />
                    <b>FEEDBACK POLICY</b>
                    <p>
                        {" "}
                        While we encourage you to share your feedback on the Website (www.equippp.com), we expect that this will be done in a
                        respectful manner. This policy is applicable to feedback given by you on and/or using Website (www.equippp.com) The Website
                        (www.equippp.com) shall however not agree with or endorse every comment that individuals post on the Website
                        (www.equippp.com). When making moderation decisions, we will take these guidelines, as well as the context in which comments
                        are made, into account We share this information in an effort to be transparent about our moderation process and why certain
                        comments may be removed. Kindly read these terms/ guidelines while providing any feedback on the Website (www.equippp.com){" "}
                    </p>

                    <p>You specifically agree not to post or transmit any comments, reviews, posts, content or engage in any activity that:-</p>
                    <ul>
                        <li type="1">Violate our Terms and Policies;</li>
                        <li type="1">
                            Is harmful, threatening, abusive, harassing, tortious, indecent, defamatory, discriminatory, vulgar, profane, obscene,
                            libelous, hateful or otherwise objectionable
                        </li>
                        <li type="1">
                            Violate any right, including, but not limited to, right of privacy, right of publicity, copyright, trademark, patent,
                            trade secret, or any other intellectual property or proprietary rights;
                        </li>
                        <li type="1">
                            Disguising or attempting to disguise the origin of your comment, review, posts, content including but not limited to by
                            submitting your comments, posts, reviews, content under a fake or false name
                        </li>
                        <li type="1">Constitutes a knowingly erroneous or unethical review</li>
                        <li type="1">Causes, or is a result of, a conflict of interest;</li>
                        <li type="1">
                            Is commercial in nature, including but not limited to posts, comments, reviews submitted in exchange for payment,
                        </li>
                        <li type="1">Contains material that is not in English</li>
                        <li type="1">Accesses or uses the account of another user without permission;</li>
                        <li type="1">
                            We reserve the right to remove any comment, review, posts, content that might be found extremely offensive or threatening.
                        </li>
                        <li type="1">Please don't exaggerate, falsely state, misrepresent your experience and watch your language.</li>
                        <li type="1">
                            This also includes but not is limited to derogatory comments or indication of a personal vendetta against Website
                            (www.equippp.com), EquiPPP Social Impact Technologies Limited and its affiliates.
                        </li>
                    </ul>
                    <p>
                        All links posted as comments, reviews on the Website will be reviewed and may be deleted if found violating these guidelines.
                        Repeated violations of this policy may cause the author to be blocked from our Website (<Link to={"/"}>www.equippp.com</Link>
                        ).
                    </p>
                    <p>
                        The Website (<Link to={"/"}>www.equippp.com</Link>) shall have the right to immediately block your access and further it shall
                        also have the right to remove any non-compliant comment, post, review and content forthwith, uploaded on the Website. The
                        Website reserves have the right to take appropriate recourse to such remedies as would be available to it under various laws,
                        rules, regulations.
                    </p>
                    <br />
                    <b>USER OBLIGATIONS</b>
                    <ul>
                        <li type="1">You shall be obliged to provide accurate information and other details to us</li>
                        <li type="1">You shall also adhere to the minimum age requirement i.e. 18 years as defined by the law of the land</li>
                        <li type="1">
                            You shall read all the policies, terms mentioned in the Website (<Link to={"/"}>www.equippp.com</Link>)
                        </li>
                        <li type="1">
                            You shall not copy or use the information contained in the Website (<Link to={"/"}>www.equippp.com</Link>)
                        </li>
                        <li type="1">You shall ensure compliance to the User Review policy contained herein</li>
                        <li type="1">
                            You shall be responsible for all and acts, actions, activities, commissions, omissions, fraud, negligence,
                            misrepresentation etc. arising out of your account
                        </li>
                        <li type="1">
                            You shall be responsible for ensuring reasonable security measures regarding your login credentials and in event shall the
                            Website, EquiPPP Social Impact Technologies Limited be responsible or liable in this regard
                        </li>
                        <li type="1">
                            You shall be responsible in full and complete for any and information including but not limited to descriptions, images,
                            videos, project related information etc. posted and uploaded by you on the Website (www.equippp.com). You herein agree
                            that neither Website (www.equippp.com) nor EquiPPP Social Impact Technologies Limited hold any responsibility, liability
                            in this regard
                        </li>
                        <li type="1">
                            You shall be responsible for any providing accurate, complete, true information while registering and posting on the
                            Website (<Link to={"/"}>www.equippp.com</Link>)
                        </li>
                        <li type="1">
                            You shall be able to the following on signing up on the Website (<Link to={"/"}>www.equippp.com</Link>)
                            <ul>
                                <li>Create Project(s)</li>
                                <li>Share Project(s) through social media</li>
                                <li>Participate in Project(s)</li>
                                <li>Interact with Admin through communique</li>
                                <li>Add co-owner to a Project(s)</li>
                                <li>Provide feedback to the Admin</li>
                            </ul>
                        </li>
                    </ul>

                    <b>PRIVACY</b>
                    <p>
                        We view protection of Your privacy as a very important principle. We understand clearly that You and Your Sensitive Personal
                        Information is one of our most important assets. We store and process Your Sensitive Personal Information including any
                        sensitive financial information collected (as defined under the Information Technology Act, 2000), if any, on computers that
                        may be protected by physical as well as reasonable technological security measures and procedures in accordance with
                        Information Technology Act 2000 and Rules there under. Our Privacy Policy is available for more details.
                    </p>

                    <b>LEGAL DISCLAIMER</b>
                    <p>
                        This Website (<Link to={"/"}>www.equippp.com</Link>), all the services, included on or otherwise made available to You through
                        this Website (<Link to={"/"}>www.equippp.com</Link>) are provided on “as is” and “as available” basis without any
                        representation or warranties, express or implied except otherwise specified in writing. Without prejudice to the forgoing
                        paragraph, the Website does not warrant that:-
                    </p>
                    <ul>
                        <li type="i">
                            This Website (<Link to={"/"}>www.equippp.com</Link>) will be constantly available, or available at all; or the information
                            on this Website (<Link to={"/"}>www.equippp.com</Link>) is complete, true, accurate or non-misleading.
                        </li>
                        <li type="i">
                            The Website (<Link to={"/"}>www.equippp.com</Link>) does not warrant that this site; information, Content, Descriptions
                            (including software) or services included on or otherwise made available to You through the Website (
                            <Link to={"/"}>www.equippp.com</Link>); their servers; or electronic communication sent from us are free of viruses or
                            other harmful components.
                        </li>
                    </ul>
                    <p>
                        Nothing on Website (<Link to={"/"}>www.equippp.com</Link>) constitutes, or is meant to constitute, advice of any kind.
                    </p>
                    <p>
                        We disclaim any damages, liabilities, loss, claims including ANY SPECIAL, INCIDENTAL, INDIRECT or CONSEQUENTIAL DAMAGES OF ANY
                        KIND IN CONNECTION OR ARISING OUT OF YOUR USE OF THE WEBSITE.
                    </p>
                    <p>
                        You will be required to enter a valid name, phone number and email id while availing registering on the Website (
                        <Link to={"/"}>www.equippp.com</Link>). By registering your phone number, email with us, you consent to be contacted by us via
                        phone calls and / or SMS notifications and/ or email, in case of any updates.
                    </p>

                    <b>INDEMNITY</b>
                    <p>
                        You shall indemnify and hold harmless the Website (<Link to={"/"}>www.equippp.com</Link>), NAME OF THE COMPANY , its Director,
                        affiliates, subsidiaries, group companies (as applicable) and their respective officers, directors, agents, and employees,
                        from any claim or demand, or actions, damage claims made by any third party including third party vendor, user or penalty
                        imposed due to or arising out of Your breach of this Terms of Use, privacy Policy and other Policies, or Your violation of any
                        law, rules or regulations or the rights (including infringement of intellectual property rights) of a third party.
                    </p>

                    <b>APPLICABLE LAW / JURISDICTION</b>
                    <p>
                        Terms of Use shall be governed by and interpreted and construed in accordance with the laws of India. The place of
                        jurisdiction shall be exclusively in Hyderabad, Telangana, India.
                    </p>

                    <b>DESCRIPTION</b>
                    <p>
                        The Website (<Link to={"/"}>www.equippp.com</Link>) does not ensure that the content including description, data and
                        information thereof is accurate, complete, reliable, current, or error-free and assumes no liability in this regard.
                    </p>
                    <p>
                        The Website (<Link to={"/"}>www.equippp.com</Link>) may include inaccuracies and typographical errors. Changes and
                        improvements are periodically made to the site and the information therein. We do not warrant or assume any legal liability or
                        responsibility for the completeness, or usefulness of any information, content, blogs etc. Information received via this
                        Website (<Link to={"/"}>www.equippp.com</Link>) should not be relied upon for personal, legal or financial decisions.
                    </p>

                    <b>LIABILITY</b>
                    <p>
                        <b>OUR LIABILITY</b>
                    </p>
                    <p>
                        We shall be liable for any technical and relative issue with regard to Website (<Link to={"/"}>www.equippp.com</Link>).
                        However, we shall hold no liability for unforeseen circumstances, force majeure events and situations beyond our control
                    </p>

                    <b>THIRD PARTY LIABILITY</b>
                    <p>
                        The Third Party shall be solely and exclusively liable in full and complete for any content, project related details, images,
                        videos, accuracy of information and any other information posted and uploaded by such Third Party while registering and
                        posting on the Website (<Link to={"/"}>www.equippp.com</Link>)
                    </p>

                    <p>
                        <b>GRIEVANCE REDRESSAL</b>
                    </p>
                    <p>
                        In accordance with Information Technology Act 2000 and rules made there under, the name and contact details of the Grievance
                        Officer are provided below:
                    </p>
                    <p>Grievance Officer:-Mr. Saikiran</p>

                    <p>
                        Email: <a href="mailto: Saikiran@equippp.com">Saikiran@equippp.com</a>
                    </p>
                    <p>Time: Mon – Fri (10:00AM to 5:00PM)</p>
                    <p>
                        If you are the rightful owner of the content or an affected person and have the reason to believe that any content that has
                        been uploaded/contributed through this Service contravenes your rights in violation of Rule 3 of the Information Technology
                        (Intermediary Guidelines) Rules, 2011 then you may intimate the same to <Link to={"/"}>www.equippp.com</Link> in the manner
                        explained below:-
                    </p>
                    <ul>
                        <li type="a">
                            Sending a request in writing or through email signed with electronic signature identifying the content alleged to be in
                            infringement of your rights as the rightful owner or affecting you prejudicially;
                        </li>
                        <li type="a">
                            Providing exact identification and description of the content by giving its URL that is to be disabled, which in your view
                            contravenes Rule 3(2) of aforesaid Rules.
                        </li>
                        <li type="a">
                            Giving a declaration cum undertaking along with necessary documents establishing you
                            <ul>
                                <li type="i">as the rightful owner of the content to be disabled, or</li>
                                <li type="i">
                                    as an affected person, and stating that the information submitted is true, complete & accurate and no material
                                    fact has been hidden. Further, stating to <Link to={"/"}>www.equippp.com</Link> including its Management,
                                    Directors, Employees and Grievance Officer shall not be liable in whatsoever manner for any loss or damage or
                                    claim for relying upon the said request.
                                </li>
                            </ul>
                        </li>
                        <li type="a">Your contact information like address, telephone number, email address, etc. where you can be contacted.</li>
                    </ul>
                    <p>
                        <b>INTELLECTUAL PROPERTY POLICY (IP POLICY)</b>
                    </p>
                    <p>
                        All existing and future Intellectual Property in the nature of unregistered or registered rights to any and all patents, copy
                        rights, trademarks of “EquiPPP”, www.equippp.com and all Intellectual Property relating thereof shall be the sole property of
                        M/s.EquiPPP Social Impact Technologies Limited
                    </p>
                    <p>
                        All material on the website <Link to={"/"}>www.equippp.com</Link> including content, information, images, pictures are
                        protected by copyrights, trademarks, and other intellectual property rights. Material on the website is solely for your
                        personal, non-commercial use.
                    </p>
                    <p>
                        The website www.equippp.com may feature the content including but not limited to Project Title, Project Start and End Date,
                        Project Location, Project Category, Name of Unit (in case of CSR Project), Organisation Name (Corporate Name), Contact
                        Information, Objective of the Project, Need of the Project, Project Description, Conditions, Scope of Projects, Project
                        Images, Documents and YouTube Videos (if any), Project Estimated Budget, Approved Value by Corporate (in case of CSR
                        Projects), User’s Interest in Project (Cash or Kind) etc. and any content uploaded by a user, third party which shall be
                        referred to as “Third-Party Content” and shall belong to the respective owners. The website www.equippp.com merely displays
                        such Third-Party Content and shall have no intention in whatsoever manner to acquire any intellectual property rights of such
                        Third-Party Content. Further, the website (www.equippp.com )EquiPPP Social Impact Technologies Limited herein acknowledges and
                        agree that the respective third party, user shall be the sole and exclusive owner of any Intellectual Property arising out of
                        such Third-Party Content.
                    </p>
                    <p>
                        You acknowledge and agree not to use, reuse, and challenge any application, registration of any marks, anywhere in the world.
                        You shall not copy, reproduce, republish, upload, post, transmit or distribute such logos, images, marks in any way, including
                        by e-mail or other electronic means and whether directly or indirectly and You must not assist any other person to do so.
                    </p>
                    <p>
                        Without the prior written consent of the owner, modification of the materials, use of the materials on any other web site or
                        mobile or any communication device or networked computer environment or use of the materials for any purpose other than
                        personal, non-commercial use is a violation of the copyrights, trademarks, intellectual property and other proprietary rights,
                        and is prohibited. Any use for which you receive any remuneration, whether in money or otherwise, is a commercial use for the
                        purposes of this clause.
                    </p>

                    <b>INTELLECTUAL PROPERTY RELATED ISSUES</b>
                    <p>
                        The website <Link to={"/"}>www.equippp.com</Link> respects the intellectual property of others. In case, You feel that Your
                        Intellectual Property Right has been infringed, You can send an email to{" "}
                        <a href="mailto: admin@equippp.com">admin@equippp.com</a>
                    </p>

                    <p>
                        <b>
                            <u>GUIDELINES FOR WRITING POSTS, COMMENTS, REVIEWS ON EquiPPP’s SOCIAL MEDIA PAGES (Facebook, Twitter etc.)</u>
                        </b>
                    </p>
                    <p>
                        This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as applicable and the
                        amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000.
                        This electronic record is generated by a computer system and does not require any physical or digital signatures.
                    </p>
                    <p>Here are some basic guidelines for writing posts, comments, reviews, on EquiPPP’s SOCIAL MEDIA PAGES:</p>
                    <p>
                        While we encourage you to share your views and opinions on the BEING RELIGIOUS ’s SOCIAL MEDIA PAGES, we expect that this will
                        be done in a respectful manner. <Link to={"/"}>www.equippp.com</Link> does not agree with or endorse every comment that
                        individuals post on our SOCIAL MEDIA PAGES. When making moderation decisions, we will take these guidelines, as well as the
                        context in which comments are made, into account.
                    </p>
                    <p>
                        We share this information in an effort to be transparent about our moderation process and why certain comments may be removed.
                        Kindly read these terms/ guidelines while making any posts, comments, reviews on EquiPPP ’s SOCIAL MEDIA PAGES.
                    </p>
                    <p>
                        You may also engage with our content on or through third party social networking sites including Facebook, Twitter and related
                        plug-ins and applications. You herein acknowledge and agree that by accessing, browsing our content on such third party social
                        networking sites, you allow us to access certain information from your social media profile (including name, e-mail id, social
                        media profile URL, phone no)
                    </p>
                    <p>
                        ACCESSING, BROWSING OR OTHERWISE USING EquiPPP ’s SOCIAL MEDIA PAGES INDICATE YOUR AGREEMENT TO THESE TERMS AND CONDITIONS, SO
                        PLEASE READ THE TERMS OF USE CAREFULLY BEFORE PROCEEDING. By impliedly or expressly accepting these Terms, You also accept and
                        agree to be bound by these Terms.
                    </p>
                    <b>TERMS/ GUIDELINES- COMMENT POLICY</b>
                    <p>You specifically agree not to post or transmit any comments, reviews, posts, content or engage in any activity that:-</p>
                    <ul>
                        <li type="1">Violates our Guidelines and Policies;</li>
                        <li type="1">
                            Is harmful, threatening, abusive, harassing, tortious, indecent, defamatory, discriminatory, vulgar, profane, obscene,
                            libellous, hateful or otherwise objectionable
                        </li>
                        <li type="1">
                            Violates any right, including, but not limited to, right of privacy, right of publicity, copyright, trademark, patent,
                            trade secret, or any other intellectual property or proprietary rights;
                        </li>
                        <li type="1">
                            Disguising or attempting to disguise the origin of your comment, review, posts, content including but not limited to by
                            submitting your comments, posts, reviews, content under a fake or false name
                        </li>
                        <li type="1">Constitutes a knowingly erroneous or unethical review</li>
                        <li type="1">Causes, or is a result of, a conflict of interest;</li>
                        <li type="1">
                            Is commercial in nature, including but not limited to posts, comments, reviews submitted in exchange for payment,
                        </li>
                        <li type="1">Contains material that is not in English</li>
                        <li type="1">Accesses or uses the account of another user without permission;</li>
                        <li type="1">
                            We reserve the right to remove any comment, review, posts, content that might be found extremely offensive or threatening.{" "}
                        </li>
                        <li type="1">Please don't exaggerate, falsely state, misrepresent your experience and watch your language. </li>
                        <li type="1">
                            This also includes but not is limited to derogatory comments or indication of a personal vendetta against{" "}
                            <Link to={"/"}>www.equippp.com</Link> and its affiliates.
                        </li>
                    </ul>

                    <p>
                        All links posted as comments on EquiPPP ’s Social Media Pages will be reviewed and may be deleted if found violating these
                        guidelines. Repeated violations of this policy may cause the author to be blocked from EquiPPP ’s SOCIAL MEDIA PAGES.
                    </p>
                    <p>
                        <b>
                            Any Comment, Review, Posts, Content uploaded by you on any social media, shall be subject to relevant laws of India and
                            may be disabled, or and may be subject to investigation under all applicable laws.
                        </b>
                    </p>
                    <p>
                        <b>
                            Further, if you are found to be in non-compliance with the laws and regulations, these terms, terms & conditions, privacy
                            policy on mentioned in <Link to={"/"}>www.equippp.com</Link> , we reserve the right to immediately block your access,
                            usage to the Social Media Pages of EquiPPP and <Link to={"/"}>www.equippp.com</Link> shall have the right to remove any
                            non-compliant comment, post, review, content forthwith, uploaded and shall further have the right to take appropriate
                            recourse to such remedies as would be available to it under various statutes.
                        </b>
                    </p>

                    <p>
                        <b>
                            <u>IT LEGAL COMPLIANCE</u>
                        </b>
                    </p>
                    <p>
                        This user agreement is published and drafted in accordance & compliance with Section 43 A of IT Act 2008 and IT Rules 2011
                        (Intermediary guidelines) that require intermediaries to have a user agreement regarding sensitive personal data or
                        information (SPDI).
                    </p>
                    <p>
                        By accessing or securing access, browsing, using <Link to={"/"}>www.equippp.com</Link> , you agree to the terms of the user
                        agreement regarding your sensitive personal data or information (SPDI).
                    </p>
                    <p>
                        <b>
                            <u>TERMS</u>
                        </b>
                    </p>
                    <ul>
                        <li type="1">I understand and have the knowledge that my sensitive personal data or information is being collected.</li>
                        <li type="1">
                            I understand and have the knowledge of the purpose for which my sensitive personal data or information is collected.
                        </li>
                        <li type="1">I understand and have the knowledge of the indented recipients of the information.</li>
                        <li type="1">
                            I understand and have the knowledge of the name and address of the agency that is collecting, retaining and using the
                            information.{" "}
                        </li>
                        <li type="1">
                            I understand that I have the option not to provide the data or information sought to be collected by{" "}
                            <Link to={"/"}>www.equippp.com</Link>
                        </li>
                        <li type="1">
                            I understand that I also have an option (while registering and using <Link to={"/"}>www.equippp.com</Link>) to withdraw my
                            consent given earlier to <Link to={"/"}>www.equippp.com</Link> and understand and accept that such withdrawal of the
                            consent shall be sent in writing to <Link to={"/"}>www.equippp.com</Link> and in such case,{" "}
                            <Link to={"/"}>www.equippp.com</Link> shall have the option not to provide the services for which the said information was
                            sought.
                        </li>
                        <li type="1">
                            I read and have understood the Privacy Policy mentioned in <Link to={"/"}>www.equippp.com</Link>
                        </li>
                        <li type="1">
                            I read and have understood the Terms and Conditions of mentioned in <Link to={"/"}>www.equippp.com</Link>
                        </li>
                    </ul>
                    <p>
                        I read and have understood the IP Policy and all legal policies mentioned in <Link to={"/"}>www.equippp.com</Link>
                    </p>
                </div>
            </div>
        </div>
    </section>
);

export default TermsContent;
