import React from "react";
import * as ProgressPrimitive from "@radix-ui/react-progress";
import { cn } from "lib/utils";

const Progress = React.forwardRef(({ className, isForm, value, ...props }, ref) => {
    return (
        <ProgressPrimitive.Root ref={ref} className={cn("relative h-2 w-full overflow-hidden rounded-full bg-primary/20", className)} {...props}>
            <ProgressPrimitive.Indicator
                className={`h-full w-full flex-1 ${isForm ? "bg-[#4a3aff]" : "bg-primary"} transition-all`}
                style={{ transform: `translateX(-${100 - (value || 0)}%)` }}
            />
        </ProgressPrimitive.Root>
    );
});
Progress.displayName = ProgressPrimitive.Root.displayName;

export { Progress };
