import { useState, useEffect, useCallback } from "react";
import { useJwt } from "react-jwt";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import AuthUserContext from ".";
import { useMeLazyQuery } from "types/generated-types.ts";

export default (Component) => (props) => {
    const [authUser, setAuthUser] = useState(null);
    const { decodedToken } = useJwt(localStorage.getItem("authToken") || "");
    const [authUserInitiated, setAuthUserInitiated] = useState(false);
    const navigate = useNavigate();
    const [getMe, { data, error }] = useMeLazyQuery();
    useEffect(() => {
        if (data && data.users_by_pk && data.users_by_pk.id) {
            const user = data.users_by_pk;
            setAuthUser(user);
            setAuthUserInitiated(true);
            localStorage.setItem("authUser", JSON.stringify(user));
            // if (!user) {
            //     navigate("/profile");
            // }
        } else if (error) {
            setAuthUserInitiated(true);
        }
    }, [data]);

    const refetchProfile = useCallback(() => {
        if (decodedToken && decodedToken.id) {
            getMe({
                variables: {
                    id: decodedToken.id,
                },
            });
        } else {
            setAuthUserInitiated(true);
        }
    }, [decodedToken?.id]);

    useEffect(() => {
        refetchProfile();
    }, [decodedToken]);

    useEffect(() => {
        if (localStorage.getItem("authUser")) {
            const localStorageUser = localStorage.getItem("authUser");
            if (localStorageUser) {
                const user = JSON.parse(localStorageUser);
                if (user?.id) {
                    setAuthUser(user);
                }
                // if(!user?.name)
                //     navigate("/profile");
            }
        }
    }, []);

    console.log({
        authUser,
    });

    return (
        <AuthUserContext.Provider value={{ authUser, authUserInitiated, setAuthUser, refetchProfile, getMe }}>
            {authUserInitiated ? <Component {...props} /> : <CircularProgress className="loader" />}
        </AuthUserContext.Provider>
    );
};
