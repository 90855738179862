import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "contexts/user-type/user-type-context.js";

const Others = () => {
    const navigate = useNavigate();
    const { state, setState, registerUser } = useUser();
    const { userType, otherState } = state;

    let { otherName, otherPhone, otherAddress } = otherState;

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            otherState: {
                ...prevState.otherState,
                [name]: value,
            },
        }));
    };

    const handleForwardClick = () => {
        if (!otherName || !otherPhone || !otherAddress) {
            return alert("please fill all other userType details");
        }
        registerUser();
    };

    return (
        <div className="flex h-full w-full justify-center items-center my-auto flex-col bg-[#F9F9F9] md:px-0 px-6">
            <div className="flex justify-center flex-col mb-10 md:mb-4">
                <p className="text-[#0D0140] font-bold text-2xl md:text-3xl text-center">Create Account as {userType}</p>
                <p className="text-[#0D0140] text-center font-thin text-base mt-2">Please fill the below options to continue</p>
            </div>
            <form className="w-full md:w-1/3">
                <div className="mb-4 ">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2" for="username">
                        Full Name
                    </label>
                    <input
                        className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
                        id="username"
                        name="otherName"
                        value={otherName}
                        onChange={handleInputChange}
                        type="text"
                        placeholder="Brandone Louis"
                    />
                </div>

                <div className="mb-4 ">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2" for="username">
                        Address
                    </label>
                    <input
                        className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
                        type="text"
                        name="otherAddress"
                        value={otherAddress}
                        onChange={handleInputChange}
                        placeholder="Enter full address"
                    />
                </div>
                <div className="mb-4 ">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2" for="username">
                        Contact
                    </label>
                    <input
                        className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
                        type="text"
                        name="otherPhone"
                        value={otherPhone}
                        onChange={handleInputChange}
                        placeholder="contact number"
                    />
                </div>

                <div className="flex flex-col items-center w-full px-8 ">
                    <button
                        className="bg-[#130160] w-full text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="button"
                        onClick={handleForwardClick}
                    >
                        CONTINUE
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Others;
