import React from "react";

function playVideo() {
    var cover = document.getElementById("cover");
    var video = document.getElementById("video");
    cover.style.display = "none"; // Hide the cover image
    video.style.display = "block"; // Show the video
    if (video.src.indexOf("autoplay=1") < 0) {
        // Ensure autoplay is in the URL
        video.src += "&autoplay=1";
    }
    // video.play(); // Attempt to play the video, note this might not work without user interaction
}

const LandingPage2 = () => {
    return (
        <div className="w-full relative bg-white h-auto overflow-hidden text-left text-base text-colors-gray666 font-poppins py-6 px-14">
            <div className="flex w-full">
                <div className="flex flex-col w-[60%]">
                    <p className="text-lg text-[#1D2130] mb-4 tracking-wide font-bold">About Duddila Sridhar Babu</p>
                    <p className="text-base text-[#525560] mb-4 leading-7 ">
                        Duddilla Sridhar Babu is the son of Late Duddilla Sripada Rao, Speaker of the Andhra Pradesh Assembly (1990-1994).Born in
                        1969, and Sridhar Babu is an alumnus of the prestigious Delhi University where he completed his bachelors in Law. He also
                        holds a Masters in Political Sciences from the Hyderabad Central University. Sridhar Babu won as a MLA in the 1999 elections
                        from Manthani, at the young age of 29 years and went on to win the Manthani seat for a record 3 terms. He served in various
                        government positions first as a member of the TTD board, as a Government Whip in the Andhra Pradesh Assembly and finally as a
                        Minister from 2009 till 2014.
                    </p>
                    <p className="text-base text-[#525560]  leading-7">
                        Sridhar Babu held the portfolios of Higher Education & NRI Affairs( 2009-2010) and then Civil Supplies, Consumer Affairs and
                        Legal Meteorology (2010-2014). He was also the Legislative Affairs Minister( 2010-2014) and introduced the AP Reorganization
                        Bill in the Andhra Pradesh Assembly, which paved the way for the creation of the new state of Telangana. Currently he is the
                        Telangana Pradesh Congress Committee ( TPCC) Vice President and Secretary of the Coordination Committee.
                    </p>
                </div>
                <div className="flex-grow flex justify-end ml-4">
                    <img alt="Sridhar" src="/assets/images/sridhar.png" className="object-contain" />
                </div>
            </div>

            <div className="flex flex-col mt-4">
                <p className="text-base text-[#525560] leading-7 mb-4 ">
                    Sridhar Babu directed his energy and focus to ensure the overall development of all strata of people and worked tirelessly for
                    their welfare. He devised and implemented various schemes of the government as a MLA and as a Minister in the state cabinet across
                    basic amenities like housing schemes, essential commodities, cooking gas, electricity, water and drainage systems among others, to
                    the entire Manthani region.
                </p>
                <p className="text-base text-[#525560] leading-7 mb-4">
                    To his credit- he was also instrumental in the setting up of the JNTU in Manthani and a degree college as well, which led to job
                    creation and easy access to quality higher education to the people of erstwhile Karimnagar district and neighboring areas as well.
                    Sridhar Babu's particular interest in agriculture and farming community ensured that irrigation water supply, crop loans and land
                    to landless farmers was provided- thereby ensuring overall development of the region Sridhar Babu spends most of his time in the
                    interior areas of his constituency understanding the problems faced by the common man and trying to address those problems.
                    Sridhar Babu believes in youth empowerment and women empowerment and strongly believes that their participation is key to the
                    building of a strong society and encourages their participation in public life to shoulder larger responsibilities in politics.
                </p>
                <p className="text-base text-[#525560] leading-7 mb-4">
                    Sridhar Babu traveled extensively across the world and visited the US, Nigeria and many European Countries as part of official
                    delegations and study tours. Sridhar Babu is married to Shailaja Ramaiyer, an IAS officer of the Telangana Cadre and the couple
                    have two children, a son and a daughter. In his free time, Sridhar Babu loves to spend time with his kids, at home. A keen
                    cricketer as a student — he represented Nizam College and the University of Hyderabad
                </p>
            </div>

            <div className="relative w-full h-[448px] mt-16">
                <img
                    src="/assets/images/manthani-video.png"
                    alt="play"
                    id="cover"
                    className="absolute inset-0 w-full h-full  z-50 cursor-pointer"
                    onClick={playVideo}
                />
                <iframe
                    id="video"
                    className="absolute inset-0 w-full h-full object-fill rounded-2xl z-50"
                    src="https://www.youtube.com/embed/h493R4i5MXE?autoplay=1&mute=1"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ display: "none" }} // Correctly formatted style attribute for JSX
                ></iframe>
            </div>

            <div className="w-full absolute bottom-0 z-0 left-0 right-0 bg-[#FCEDC6] h-[353px]"></div>
        </div>
    );
};

export default LandingPage2;
