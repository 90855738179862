import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import { RegisterModalFooter } from "..";

function MLA({ mlaState, setMlaState, email, setOpen, registerUser }) {
    let { mlaName, mlaConstituency, mlaContactDetails, mlaPartyName, mlaTenureDuration, mlaGender } = mlaState;
    const handleMlaChange = (e) => {
        setMlaState((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };

    const handleBackClick = () => {
        setOpen("usertype");
    };

    const handleForwardClick = () => {
        if (!mlaName || !mlaConstituency || !mlaContactDetails || !mlaPartyName || !mlaTenureDuration || !mlaGender) {
            return alert("please fill all  member of legislative assembly  details");
        }
        var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
        if (!re.test(mlaContactDetails)) {
            return alert("enter valid mla phone number");
        }
        registerUser();
    };
    return (
        <Grid item xs={12} className="common-modal__container" container direction="column" justifyContent="flex-start" alignItems="flex-start">
            <h3>
                Sign up as <span className="span-text">MLA.</span>
            </h3>

            <Grid item xs={12} className="field__grid" container justifyContent="space-between" alignItems="center" mb="15px">
                <TextField
                    variant="standard"
                    label="Name"
                    className="input__field"
                    fullWidth
                    type="text"
                    name="mlaName"
                    value={mlaName}
                    onChange={handleMlaChange}
                />
                <TextField variant="standard" label="Email" className="input__field" fullWidth type="text" name="mlaEmail" disabled value={email} />
            </Grid>

            <Grid item xs={12} className="field__grid" container justifyContent="space-between" alignItems="center" mb="15px">
                <TextField
                    variant="standard"
                    label="Constituency"
                    className="input__field"
                    fullWidth
                    type="text"
                    name="mlaConstituency"
                    value={mlaConstituency}
                    onChange={handleMlaChange}
                />
                <TextField
                    variant="standard"
                    label="Contact"
                    className="input__field"
                    fullWidth
                    type="text"
                    onKeyPress={(event) => {
                        // ignore "-", "+" and "e"
                        if (event?.key === "-" || event?.key === "+" || event?.key === "e") {
                            event.preventDefault();
                        }
                        // if character is not number then ignore except decimal point and
                        if (event?.key !== "." && event?.key && isNaN(+event.key)) {
                            event.preventDefault();
                        }
                        if (event.target.value.length >= 10) {
                            event.preventDefault();
                        }
                    }}
                    InputProps={{
                        maxLength: 10,
                    }}
                    name="mlaContactDetails"
                    value={mlaContactDetails}
                    onChange={handleMlaChange}
                />
            </Grid>

            <Grid item xs={12} className="field__grid" container justifyContent="space-between" alignItems="center" mb="15px">
                <TextField
                    variant="standard"
                    label="Party Name"
                    className="input__field"
                    fullWidth
                    type="text"
                    name="mlaPartyName"
                    value={mlaPartyName}
                    onChange={handleMlaChange}
                />
                <TextField
                    variant="standard"
                    label="Tenure/Duration"
                    className="input__field"
                    fullWidth
                    type="text"
                    name="mlaTenureDuration"
                    value={mlaTenureDuration}
                    onChange={handleMlaChange}
                />
            </Grid>
            <Grid item xs={12} className="field__grid" container justifyContent="flex-start" alignItems="center" mb="15px">
                <FormControl className="radio__container">
                    <FormLabel id="radio-btns-label">Gender</FormLabel>
                    <RadioGroup row name="mlaGender" value={mlaGender} onChange={handleMlaChange}>
                        <FormControlLabel value="female" control={<Radio size="small" />} label="Female" />
                        <FormControlLabel value="male" control={<Radio size="small" />} label="Male" />
                        <FormControlLabel value="other" control={<Radio size="small" />} label="Other" />
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12} container direction="row" justifyContent="space-between" alignItems="center" className="button__container">
                <Button className="back__btn" type="button" onClick={handleBackClick}>
                    Back
                </Button>
                <Button className="continue__btn" type="button" onClick={handleForwardClick}>
                    Save and continue
                </Button>
            </Grid>
            <RegisterModalFooter />
        </Grid>
    );
}

export default MLA;
