import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../components/ui/select";
import { useUser } from "contexts/user-type/user-type-context.js";
const SubjectMatterExpert = () => {
    const navigate = useNavigate();
    const { state, setState, registerUser } = useUser();
    const { userType, smeState } = state;
    let { smeName, smeGender, smeAreaOfExpertise, smePreviousCompany, smeContact, smeRole } = smeState;

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            smeState: {
                ...prevState.smeState,
                [name]: value,
            },
        }));
    };

    const handleSelectChange = (value) => {
        setState((prevState) => ({
            ...prevState,
            smeState: {
                ...prevState.smeState,
                smeGender: value,
            },
        }));
    };

    const handleForwardClick = () => {
        if (!smeName || !smeGender || !smeAreaOfExpertise || !smePreviousCompany || !smeContact || !smeRole) {
            return alert("please fill all SME userType details");
        }
        // var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
        // if (!re.test(smeContact)) {
        //     return alert("Enter valid phone number");
        // }
        registerUser();
    };

    return (
        <div className="flex h-full w-full justify-center items-center my-auto flex-col bg-[#F9F9F9] md:px-0 px-6">
            <div className="flex justify-center flex-col mb-10 md:mb-4">
                <p className="text-[#0D0140] font-bold text-2xl md:text-3xl text-center">Create Account as {userType}</p>
                <p className="text-[#0D0140] text-center font-thin text-base mt-2">Please fill the below options to continue</p>
            </div>
            <form className="w-full md:w-1/3">
                {/* <div className="mb-4 ">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2" for="username">
                        Email
                    </label>
                    <input
                        className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
                        type="email"
                        placeholder="Brandonelouis@gmail.com"
                        disabled
                    />
                </div> */}
                <div className="mb-4 ">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2" for="username">
                        Full Name
                    </label>
                    <input
                        className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
                        id="username"
                        type="text"
                        name="smeName"
                        value={smeName}
                        onChange={handleInputChange}
                        placeholder="Brandone Louis"
                    />
                </div>
                <div className="mb-4 ">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2" for="username">
                        Gender
                    </label>
                    <Select className="modal-select-input" value={smeGender || ""} onValueChange={handleSelectChange}>
                        <SelectTrigger aria-label="User Type" className="border-0">
                            <SelectValue placeholder="Select Gender" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="female">Female</SelectItem>
                            <SelectItem value="male">Male</SelectItem>
                            <SelectItem value="other">Other</SelectItem>
                        </SelectContent>
                    </Select>
                </div>
                <div className="mb-4 ">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2" for="username">
                        Area of Expertise
                    </label>
                    <input
                        className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
                        type="text"
                        name="smeAreaOfExpertise"
                        value={smeAreaOfExpertise}
                        onChange={handleInputChange}
                        placeholder="Area of Expertise"
                    />
                </div>
                <div className="mb-4 ">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2" for="username">
                        Previous Company
                    </label>
                    <input
                        className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
                        type="text"
                        name="smePreviousCompany"
                        value={smePreviousCompany}
                        onChange={handleInputChange}
                        placeholder="Prevoius Company"
                    />
                </div>
                <div className="mb-4 ">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2">Contact</label>
                    <input
                        className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
                        type="text"
                        name="smeContact"
                        value={smeContact}
                        onChange={handleInputChange}
                        placeholder="Contact"
                    />
                </div>
                <div className="mb-4 ">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2">Role</label>
                    <input
                        className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
                        type="text"
                        name="smeRole"
                        value={smeRole}
                        onChange={handleInputChange}
                        placeholder="Contact"
                    />
                </div>

                <div className="flex flex-col items-center w-full px-8 ">
                    <button
                        className="bg-[#130160] w-full text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="button"
                        onClick={handleForwardClick}
                    >
                        CONTINUE
                    </button>
                </div>
            </form>
        </div>
    );
};

export default SubjectMatterExpert;
