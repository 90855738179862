import { Fragment, useState } from "react";
import Form from "./Form";
import { useNavigate, useParams } from "react-router-dom";
import { useGetDistrictsQuery } from "types/generated-types.ts";
import { useGetConstituencyQuery } from "types/generated-types.ts";

const footerData = [
    {
        title: "About us",
        items: [
            { name: "Talk to us", link: "/" },
            { name: "Mission", link: "/" },
            { name: "Vision", link: "/" },
            { name: "Terms & Conditions", link: "/" },
        ],
    },
    {
        title: "Users",
        items: [
            { name: "Individual", link: "/" },
            { name: "Company", link: "/" },
            { name: "Trust", link: "/" },
            { name: "Other", link: "/" },
        ],
    },
    {
        title: "Social Links",
        items: [
            { name: "LinkedIn", link: "/" },
            { name: "Facebook", link: "/" },
            { name: "Instagram", link: "/" },
            { name: "Twitter (X)", link: "/" },
        ],
    },
];

const District = () => {
    const [location, setLocation] = useState("");

    const { district } = useParams();
    const { state } = useParams();
    const { data: districts } = useGetDistrictsQuery({
        variables: {
            state: state,
        },
    });
    const { data: constituencies } = useGetConstituencyQuery({
        variables: {
            district: district,
        },
    });

    const navigate = useNavigate();

    const data = [
        {
            value: districts?.districts.length,
            label: "Total District",
        },
        {
            value: districts?.districts[0]?.district,
            label: "Top District",
        },
        {
            value: constituencies?.districts.length,
            label: "Total Sectors",
        },
        {
            value: 185,
            label: "Total Suggestions",
        },
    ];
    const handleChange = (e) => {
        setLocation(e.target.value);
    };

    console.log({ location });
    return (
        <Fragment>
            <div className="px-8 py-4">
                <span
                    className="text-black/50 cursor-pointer text-sm"
                    onClick={() => {
                        navigate("/");
                    }}
                >
                    Home
                </span>
                <span className="text-black/50 cursor-pointer text-sm ml-2 mr-2">{" > "}</span>
                <span className="text-black text-sm"> Map</span>
                <span className="text-black/50 cursor-pointer text-sm ml-2 mr-2">{" > "}</span>
                <span className="text-black text-sm">{district}</span>
                {location && (
                    <>
                        <span className="text-black/50 cursor-pointer text-sm ml-2 mr-2">{" > "}</span>
                        <span className="text-black text-sm">{location}</span>
                    </>
                )}
            </div>
            <div className="mb-10 mt-4 flex items-center justify-around gap-16 center">
                <img src="/assets/images/hyderabad.svg" alt="map" className="w-[500px]" />

                <div className="bg-gray-200 mr-10 rounded-full p-2 px-2.5 flex items-center justify-center gap-2">
                    {" "}
                    <img src="/assets/images/Near Me.svg" alt="map" className="" />
                    <select
                        value={location}
                        onChange={handleChange}
                        placeholder="Select Constituency"
                        className="text-sm outline-none bg-gray-200 w-72"
                    >
                        <option value="" disabled>
                            Select Constituency
                        </option>

                        {constituencies?.districts.map((constituency) => (
                            <option key={constituency.id} value={constituency.constituency}>
                                {constituency.constituency}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="flex mb-10 justify-between text-[#170F49] gap-10 items-stretch px-20">
                {data.map((item, index) => (
                    <div className="basis-1/4 text-center bg-[#e0ecfb] rounded-md p-3" key={index}>
                        <p className="font-medium text-2xl mb-1">{item.value}</p>
                        <p className="">{item.label}</p>
                    </div>
                ))}
            </div>

            <Form location={location} />

            <div className="flex justify-between items-center">
                <img src="/assets/images/footer-logo.svg" alt="" className="w-1/4" />
                <div className="flex basis-2/3">
                    {footerData.map((data, ind) => (
                        <div className="basis-1/3 flex flex-col" key={ind}>
                            <p className="text-xl font-semibold mb-2">{data.title}</p>
                            {data.items?.map((item, index) => (
                                <a className="mb-2" href={item.link} key={index}>
                                    {item.name}
                                </a>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
            <p className="text-center py-3 border-t text-sm border-gray-200">@ 2024 Powered by EQUIPPP</p>
        </Fragment>
    );
};

export default District;
