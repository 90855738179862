import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import SwiperCore, { Autoplay, EffectCoverflow } from "swiper";
import { useNavigate } from "react-router-dom";

import PemmasaniCarousel from "./pemmasani-carousel";

SwiperCore.use([Autoplay, EffectCoverflow]);

const OPTIONS = { loop: true };
const SLIDE_COUNT = 5;
const SLIDES = Array.from(Array(SLIDE_COUNT).keys());

const Carousel = () => {
    return <PemmasaniCarousel options={OPTIONS} />;
};

const PemmasaniLandingPage = () => {
    const navigate = useNavigate();
    return (
        <div className="w-full relative bg-white h-auto overflow-hidden text-left text-base text-colors-gray666 font-poppins py-4 px-14">
            <main className="flex justify-between items-center  bg-yellow-400 bg-opacity-100 w-full rounded-2xl px-12 py-16">
                {/* Left Column (Text) */}
                <div className="w-1/2">
                    {" "}
                    {/* Reduced right margin for larger screens */}
                    <div className="text-justify md:text-left ">
                        {" "}
                        {/* Adjusted text alignment and reduced right padding */}
                        <h1 className="text-4xl text-left font-bold mb-4 text-[#1C2B52]">Dr. Chandra Sekhar Pemmasani</h1>
                        <p className="text-lg text-black1 opacity-60 leading-7 ">
                            Dr. Chandra Sekhar Pemmasani is a graduate of Osmania Medical College in Hyderabad, India. He ranked 27th out of 60,000
                            students who took the EAMCET medical school entrance examination, one of the most competitive examinations in the country.
                            He completed his residency in internal medicine at Geisinger Medical Center in Danville, Pennsylvania, and obtained the
                            highest percentile scores in most board examinations. During his postgraduate training, he represented the state of
                            Pennsylvania in a national medical knowledge competition for 2 years. He then worked at Johns Hopkins University – Sinai
                            Hospital for about 5 years as an attending physician, teaching residents and medical students.
                        </p>
                        <div className="mt-4 flex">
                            <button
                                className="py-2 px-4 cursor-pointer bg-white rounded flex transition-transform hover:scale-105 flex-row items-center justify-center"
                                onClick={() => navigate("/about-chandra-sekhar-pemmasani")}
                            >
                                <p className="text-base font-medium text-[#1D2130]">Read more</p>
                            </button>
                            <button
                                className="py-2 px-4 ml-4 cursor-pointer transition-transform hover:scale-105 hover:bg-white bg-white/40 text-[#1D2130] hover:text-yellow-500 rounded flex flex-row items-center justify-center"
                                onClick={() => navigate("/projects")}
                            >
                                <p className="text-base font-medium ">View Project</p>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="w-[430px] h-[510px]">
                    <img alt="" src="/assets/images/pemmasani.jpg" className="w-full h-full rounded-lg object-fill" />
                </div>
            </main>
            <div className="w-full mt-40 mb-40">
                <div className="flex items-center mb-4">
                    <p className="text-4xl font-medium text-[#1D2130]  mr-4 mb-2">Overview</p>
                    <hr className="border w-full border-gray-500" />
                </div>

                <div className="flex justify-between w-full items-center">
                    <p className="text-[#666666] text-xl leading-7 w-1/2">
                        Dr. Pemmasani has worked in a free medical clinic providing care to uninsured/underinsured patients in the Indian community of
                        Dallas. In India, he helped provide free and clean water to residents of the Palnadu area for over 10 years, in the Guntur
                        district, and donated land for a community hall in his father’s village. He has also provided educational funding to a number
                        of children and adults and has made charitable donations to local community organizations. In addition, through the CSR and
                        foundation activities, he continues the philanthropic activities focusing on education, global crisis, and service basic
                        needs.
                    </p>
                    <div className="w-1/2 flex justify-end ">
                        <img className="w-[500px] object-cover h-[500px]" alt="map" src="/assets/images/guntur-map.png" />
                    </div>
                </div>
            </div>

            <Carousel></Carousel>

            <div className="flex text-l font-bold text-primary-text flex items-center md:pt-16 xl:pt-20 md:pl-4 xl:pl-8 md:pb-0 pb-4 md:pt-0 pt-4 mt-40">
                <hr className="ml-4 mr-2 h-[1px] w-[120px] bg-primary-text " />
                <span> VIDEO</span>
            </div>
            <div className="flex justify-center items-center bg-yellow-light w-full md:h-[400px] xl:h-[800px] md:pt-8 xl:pt-12 md:pb-4 xl:pb-8 md:pl-12 xl:pl-8 md:pr-4 xl:pr-8 md:pl-0 pl-4 md:pr-0 pr-4 md:pb-0 pb-4">
                <iframe
                    className="w-full h-full"
                    src="https://www.youtube.com/embed/ReQ4ObDGggQ"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                ></iframe>
            </div>
        </div>
    );
};

export default PemmasaniLandingPage;
