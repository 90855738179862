import React from "react";

import "swiper/swiper-bundle.css";
import { useNavigate } from "react-router-dom";

import ManthaniCarousel from "./manthani-carousel";

const OPTIONS = { loop: true };
const SLIDE_COUNT = 5;
const SLIDES = Array.from(Array(SLIDE_COUNT).keys());

const Carousel = () => {
    return <ManthaniCarousel options={OPTIONS} />;
};

// Install Swiper modules

// // import "./LandingPage.scss";
// import SwiperCore, { EffectCoverflow } from "swiper/core";

// SwiperCore.use([EffectCoverflow]); // Install Swiper modules

function playVideo() {
    var cover = document.getElementById("cover");
    var video = document.getElementById("video");
    cover.style.display = "none"; // Hide the cover image
    video.style.display = "block"; // Show the video
    if (video.src.indexOf("autoplay=1") < 0) {
        // Ensure autoplay is in the URL
        video.src += "&autoplay=1";
    }
    // video.play(); // Attempt to play the video, note this might not work without user interaction
}

const LandingPage = () => {
    const navigate = useNavigate();

    return (
        <div className="w-full relative bg-white h-auto overflow-hidden text-left text-base text-colors-gray666 font-poppins py-4 px-14">
            <main className="flex justify-between items-center  bg-yellow-400 bg-opacity-100 w-full rounded-2xl px-12 py-16">
                <div className="w-1/2">
                    <div className="text-justify md:text-left ">
                        <h1 className="text-5xl text-left font-bold mb-4 text-[#1C2B52]">Duddila Sridhar Babu</h1>
                        <p className="text-base text-black1 opacity-60 leading-7 ">
                            Duddilla Sridhar Babu is the son of Late Duddilla Sripada Rao, Speaker of the Andhra Pradesh Assembly (1990-1994). Born in
                            1969, and Sridhar Babu is an alumnus of the prestigious Delhi University where he completed his bachelors in Law. He also
                            holds a Masters in Political Sciences from the Hyderabad Central University. Sridhar Babu won as a MLA in the 1999
                            elections from Manthani, at the young age of 29 years and went on to win the Manthani seat for a record 3 terms. He served
                            in various government positions first as a member of the TTD board, as a Government Whip in the Andhra Pradesh Assembly
                            and finally as a Minister from 2009 till 2014.
                        </p>

                        <div className="mt-4 ">
                            <button
                                className="py-3 px-4 cursor-pointer bg-white rounded flex flex-row items-center justify-center"
                                onClick={() => navigate("/about-duddila-sridhar-babu")}
                            >
                                <div className="text-base font-medium text-[#1D2130]">Read more</div>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="w-[612px] h-[448px]">
                    <img alt="Sridhar" src="/assets/images/sridhar.png" className="w-full h-full" />
                </div>
            </main>

            <div className="w-full mt-20 mb-20">
                <div className="flex items-center mb-4">
                    <p className="text-4xl font-medium text-[#1D2130]  mr-4 mb-2">Overview</p>
                    <hr className="border w-full border-gray-500" />
                </div>

                <div className="flex justify-between w-full items-center">
                    <p className="text-[#666666] text-base leading-7 w-1/2">
                        Manthani is an assembly constituency in the state of Telangana, India. It is located in the Peddapalli district. Manthani is
                        known for its political significance, as it has been a stronghold of the Telangana Rashtra Samithi (TRS) party. The
                        constituency comprises a mix of rural and semi-urban areas, with agriculture being a primary occupation for many residents. It
                        has seen significant development in recent years, with infrastructure projects and initiatives aimed at improving the
                        livelihoods of its residents.Some notable projects include Mission Bhagiratha, Road Infrastructure Development ,Rural
                        Development Programs,Education and Healthcare Facilities.
                    </p>
                    <div className="w-1/2 flex justify-end ">
                        <img className="w-[419px] h-[342px]" alt="map" src="/assets/images/map22.png" />
                    </div>
                </div>
            </div>

            <Carousel></Carousel>
            <div className="mt-20 ">
                <div className="flex items-center  mb-4">
                    <img src="/assets/images/line.png" alt="video" className="w-20 mr-4" />
                    <p className="text-sm font-medium text-[#1D2130]  mr-4 ">VIDEO</p>
                </div>
                <div className="relative w-full h-[448px]">
                    <img
                        src="/assets/images/manthani-video.png"
                        alt="play"
                        id="cover"
                        className="absolute inset-0 w-full h-full  z-50 cursor-pointer"
                        onClick={playVideo}
                    />
                    <iframe
                        id="video"
                        className="absolute inset-0 w-full h-full object-fill rounded-2xl z-50"
                        src="https://www.youtube.com/embed/h493R4i5MXE?autoplay=1&mute=1"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        style={{ display: "none" }} // Correctly formatted style attribute for JSX
                    ></iframe>
                </div>
            </div>
            <div className="w-full absolute bottom-0 z-0 left-0 bg-[#FCEDC6] h-[353px]"></div>
        </div>
    );
};

export default LandingPage;
