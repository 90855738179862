import { useState, useEffect, useRef, useContext, useCallback, Fragment } from "react";
import { Progress } from "../../components/ui/progress";
import Upload from "./Upload";
import { create } from "zustand";
import axios from "axios";
import { LoaderCircle } from "lucide-react";
import { Camera, Plus, CircleX } from "lucide-react";

import { v4 as uuid } from "uuid";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import CustomSelect from "components/CustomSelect";
import clsx from "clsx";
import { useGetUploadUrlMutation } from "types/generated-types.ts";
import { get, useForm, Controller } from "react-hook-form";
import { useAddSuggestProjectMutation } from "types/generated-types.ts";

const categories = [
    { value: "Community", label: "Community" },
    { value: "Education", label: "Education" },
    { value: "Environment", label: "Environment" },
    { value: "Healthcare", label: "Healthcare" },
    { value: "Housing", label: "Housing" },
    { value: "Infrastructure", label: "InfrastructurePower" },
    { value: "Roads & Bridges", label: "Roads & Bridges" },
    { value: "Social Justice", label: "Social Justice" },
    { value: "Technology", label: "Technology" },
    { value: "Waste Management", label: "Waste Management" },
    { value: "Water & Sanitization", label: "Water & Sanitization" },
    { value: "Other", label: "Other" },
];

const types = [
    { value: "CSR", label: "CSR" },
    { value: "MP/MLA", label: "MP/MLA Lads" },
    { value: "Probono", label: "Probono" },
    { value: "Individual", label: "Individual" },
    { value: "Corporate", label: "Corporate" },
    { value: "NPO", label: "NPO" },
];

const useImagesStore = create((set) => ({
    images: [],
    actions: {
        reset: () => set({ images: [] }),
        addImage: ({ file }) =>
            set((state) => ({
                images: [
                    ...state.images,
                    {
                        id: uuid(),
                        imageUrl: "",
                        state: "idle",
                        file,
                    },
                ],
            })),
        removeImage: (id) =>
            set((state) => ({
                images: state.images.filter((image) => image.id !== id),
            })),
        markImageAsUploading: ({ id }) =>
            set((state) => ({
                images: state.images.map((image) => {
                    if (image.id === id) {
                        return {
                            ...image,
                            state: "uploading",
                        };
                    }
                    return image;
                }),
            })),

        markImageAsUploaded: ({ id, imageUrl }) =>
            set((state) => ({
                images: state.images.map((image) => {
                    if (image.id === id) {
                        return {
                            ...image,
                            imageUrl,
                            state: "uploaded",
                        };
                    }
                    return image;
                }),
            })),
        markImageaAsErrored: ({ id }) =>
            set((state) => ({
                images: state.images.map((image) => {
                    if (image.id === id) {
                        return {
                            ...image,
                            state: "errored",
                        };
                    }
                    return image;
                }),
            })),
    },
}));

const Image = ({ id }) => {
    const { images, actions } = useImagesStore();

    const currentImage = images.find((image) => image.id === id);

    const [getUploadURL] = useGetUploadUrlMutation({
        onCompleted: async (data) => {
            if (data?.getUploadURL?.success) {
                try {
                    let { downloadUrl, uploadParams } = data.getUploadURL;

                    uploadParams = JSON.parse(uploadParams);
                    let formData = new FormData();
                    Object.keys(uploadParams.fields).forEach((key) => {
                        formData.append(key, uploadParams.fields[key]);
                    });
                    formData.append("file", currentImage.file);
                    await axios.post(uploadParams.url, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    });
                    toast.dismiss();
                    actions.markImageAsUploaded({ id, imageUrl: downloadUrl });
                } catch (e) {
                    console.log(e);
                } finally {
                }
            }
        },
        onError: (error) => {
            toast.error(`Failed to upload: ${error.message ?? "Something went wrong please contact support"}`);
            actions.markImageaAsErrored({ id });
        },
    });

    useEffect(() => {
        if (currentImage.state === "idle") {
            getUploadURL({
                variables: {
                    fileName: currentImage.file.name,
                    fileSize: currentImage.file.size,
                    path: `\${type}/\${userId}/${currentImage.file.name}`,
                    uploadType: "images",
                },
            });

            actions.markImageAsUploading({
                id: currentImage.id,
            });
        }
    }, []);

    return (
        <div className="relative w-full h-32 rounded flex items-center justify-center" onClick={(e) => e.stopPropagation()}>
            <img src={URL.createObjectURL(currentImage.file)} alt="Preview" className="w-full  object-cover h-32 rounded-md" />
            {currentImage.state === "uploading" && (
                <div className="flex justify-center items-center absolute top-0 left-0 w-full h-full bg-black/30 text-white">
                    <LoaderCircle className="animate-spin" />
                </div>
            )}

            <CircleX
                fill="black"
                className="absolute top-0 right-0 translate-x-1/2 cursor-pointer text-white -translate-y-1/2"
                onClick={() => {
                    actions.removeImage(id);
                }}
            />

            {currentImage.state === "errored" && (
                <div className="flex justify-center items-center absolute top-0 left-0 w-full h-full bg-red-500/30 text-white">
                    <p>Failed to upload</p>
                </div>
            )}
        </div>
    );
};

const Form = ({ location }) => {
    const [step, setStep] = useState(1);
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control,
        watch,
        reset,
        getValues,
    } = useForm();

    const [suggestProject] = useAddSuggestProjectMutation();
    const { images, actions } = useImagesStore();
    const imageInputRef = useRef(null);
    const [fileUploadType, setFileUploadType] = useState("");

    const onDrop = useCallback(
        (acceptedFiles) => {
            // Check if the total number of images after adding new ones exceeds 5
            if (images.length + acceptedFiles.length > 5) {
                toast.error("You can only upload up to 5 images.");
                return;
            }

            // Process each accepted file
            acceptedFiles.forEach((file) => {
                const reader = new FileReader();
                reader.onabort = () => console.log("file reading was aborted");
                reader.onerror = () => console.log("file reading has failed");
                reader.onload = () => {
                    // Assuming a method to add images to your state
                    actions.addImage({ file, imageUrl: reader.result });
                };
                reader.readAsDataURL(file);
            });
        },
        [images.length, actions]
    );

    const onClickImageUpload = () => {
        imageInputRef.current?.click();
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: "image/*",
        maxFiles: 5 - images.length,
        maxSize: 5242880, // 5MB limit per file
    });

    const handleMoreImageChange = (event) => {
        const newFiles = Array.from(event.target.files);
        const totalFiles = images.length + newFiles.length;

        if (totalFiles > 5) {
            toast.error("You can only upload up to 5 images.");
            return;
        }

        newFiles.slice(0, 5 - images.length).forEach((file) => {
            actions.addImage({ file });
        });
    };

    const nextStep = () => {
        if (!location) {
            toast.error("Please select your constituency");
            document.getElementById("root").scrollTo({ top: 0, behavior: "smooth" });
            return;
        }
        const values = getValues();
        if (values.name === "" || values.emailId === "" || values.contactNumber === "" || values.address === "") {
            toast.error("Please fill all the fields");
            return;
        }
        setStep(step + 1);
    };

    const onSubmit = async (data) => {
        if (images.some((image) => image.state === "uploading")) {
            toast.error("Please wait for all images to finish uploading");
            return;
        }

        // check if any of the images are not uploaded
        if (images.some((image) => image.state !== "uploaded")) {
            toast.error("Some of the images are not uploaded, please remove them and retry");
            return;
        }

        let category = data.category.map((item) => item.value);

        let type = data.type.map((item) => item.value);

        const projectData = {
            name: data.name,
            email_id: data.emailId,
            contact_number: data.contactNumber,
            address: data.address,
            project_title: data.projectTitle,
            project_category: JSON.stringify(category),
            project_type: JSON.stringify(type),
            constituency: location,
            project_budget: data.estimatedBudget,
            project_address: data.projectAddress,
            project_location: data.projectLocation,
            project_description: data.projectDescription,
            project_requirements: data.projectRequirements,
            images: {
                data: images.map(({ imageUrl }) => ({
                    image_url: imageUrl,
                })),
            },
        };

        try {
            await suggestProject({
                variables: {
                    object: projectData,
                },
            });

            toast.success("Project suggested successfully");
            reset();
            actions.reset();
        } catch (error) {
            console.error("Error submitting:", error);
            toast.error("Failed to suggest project");
        }
    };

    return (
        <div className="container bg-[#f7f7fb] mx-auto p-10">
            <h2 className="text-2xl text-center font-semibold mb-4">Fill the details</h2>
            <p className="text-lg text-center text-gray-500 mb-4">
                Please fill the form below to receive a expression for your
                <br /> project. Feel free to add as much detail as needed.
            </p>

            <form className="bg-white shadow-md rounded-lg p-8 " onSubmit={handleSubmit(onSubmit)}>
                <div className="flex items-center mb-3 pb-4 border-b-none outline-none border-b border-gray-300 justify-center gap-4">
                    <span className={`rounded-full text-white px-2.5 py-[2px] bg-[#4a3aff]`}>1</span>
                    <Progress isForm={true} value={step === 1 ? 50 : 100} className="w-28 h-[6px] bg-[#eff0f6]" />
                    <span className={`${step === 2 ? "bg-[#4a3aff] text-white " : "bg-[#eff0f6]"} rounded-full px-2.5 py-[2px]`}>2</span>
                </div>
                {step === 1 && (
                    <div>
                        <p className="text-xl font-semibold">Fill the deatils</p>
                        <p className="text-lg mb-3 text-gray-500">Please fill your information so we can get in touch with you.</p>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                                <label className="mb-1 text-sm font-medium text-gray-700">Name</label>
                                <input
                                    {...register("name", { required: "Name is required" })}
                                    type="text"
                                    className="mt-1 p-2 border-none outline-none bg-[#f7f7f7] rounded-md w-full"
                                />
                                {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name.message}</p>}
                            </div>
                            <div>
                                <label className="mb-1 text-sm font-medium text-gray-700">Email ID</label>
                                <input
                                    {...register("emailId", { required: "Email ID is required" })}
                                    type="email"
                                    className="mt-1 p-2 border-none outline-none bg-[#f7f7f7] rounded-md w-full"
                                />
                                {errors.emailId && <p className="text-red-500 text-xs mt-1">{errors.emailId.message}</p>}
                            </div>
                            <div>
                                <label className="mb-1 text-sm font-medium text-gray-700">Contact Number</label>
                                <input
                                    {...register("contactNumber", { required: "Contact Number is required" })}
                                    type="text"
                                    className="mt-1 p-2 border-none outline-none bg-[#f7f7f7] rounded-md w-full"
                                />
                                {errors.contactNumber && <p className="text-red-500 text-xs mt-1">{errors.contactNumber.message}</p>}
                            </div>
                            <div>
                                <label className="mb-1 text-sm font-medium text-gray-700">Address</label>
                                <input
                                    {...register("address", { required: "address is required" })}
                                    type="text"
                                    className="mt-1 p-2 border-none outline-none bg-[#f7f7f7] rounded-md w-full"
                                />
                                {errors.address && <p className="text-red-500 text-xs mt-1">{errors.address.message}</p>}
                            </div>
                        </div>
                        <div className="flex justify-end">
                            <button onClick={nextStep} className="mt-4 px-4 py-2 bg-[#4a3aff] text-white rounded-full">
                                Next Step
                            </button>
                        </div>
                    </div>
                )}

                {step === 2 && (
                    <div>
                        <p className="text-xl font-semibold">Project Information</p>
                        <p className="text-lg mb-3 text-gray-500">Please fill your information so we can get in touch with you.</p>
                        <div className="mb-3">
                            <label className="mb-1 text-sm font-medium text-gray-700">Project Title*</label>
                            <input type="text" className="mt-1 p-2 border-none outline-none bg-[#f7f7f7] rounded-md w-full" />
                        </div>
                        <div className="grid grid-cols-1 mb-3 md:grid-cols-3 gap-4">
                            <div>
                                <label className="mb-1 text-sm font-medium text-gray-700">Project Category</label>
                                <Controller
                                    name={"category"}
                                    control={control}
                                    className="mt-1 p-2 border-none outline-none bg-[#f7f7f7] rounded-md w-full"
                                    render={({ field: { onChange, onBlur, value, name, ref } }) => {
                                        return (
                                            <CustomSelect
                                                placeholder="Select"
                                                multiple={true}
                                                className="mt-1 p-2 border-none outline-none bg-[#f7f7f7] rounded-md w-full"
                                                isSearchable={true}
                                                options={categories}
                                                reactHookFormRegister={{
                                                    onChange: async (e) => {
                                                        onChange(e);
                                                    },
                                                    onBlur: async () => {
                                                        onBlur();
                                                    },
                                                    value: value || null,
                                                    name,
                                                    ref,
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div>
                                <label className="mb-1 text-sm font-medium text-gray-700">Project Type</label>
                                <Controller
                                    name={"type"}
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, name, ref } }) => {
                                        return (
                                            <CustomSelect
                                                placeholder="Select"
                                                multiple={true}
                                                isSearchable={true}
                                                className="mt-1 p-2 border-none outline-none !bg-[#f7f7f7] rounded-md w-full"
                                                options={types}
                                                reactHookFormRegister={{
                                                    onChange: async (e) => {
                                                        onChange(e);
                                                    },
                                                    onBlur: async () => {
                                                        onBlur();
                                                    },
                                                    value: value || null,
                                                    name,
                                                    ref,
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div>
                                <label className="mb-1 text-sm font-medium text-gray-700">Proposed Estimated Budget*</label>
                                <input
                                    step="0.01"
                                    type="number"
                                    className="mt-1 p-2 border-none outline-none bg-[#f7f7f7] rounded-md w-full"
                                    {...register("estimatedBudget", { required: "This field is required" })}
                                />
                                {errors.estimatedBudget && <p className="text-xs text-red-500 mt-2 error">{errors.estimatedBudget.message}</p>}
                            </div>
                        </div>
                        <div className="flex mb-3 items-start gap-4">
                            <div className="w-3/4">
                                <label className="mb-1 text-sm font-medium text-gray-700">Project Address*</label>
                                <input
                                    {...register("projectAddress", { required: "This field is required" })}
                                    type="text"
                                    className="mt-1 p-2 border-none outline-none bg-[#f7f7f7] rounded-md w-full"
                                />
                                {errors.projectAddress && <p className="text-xs text-red-500 mt-2 error">{errors.projectAddress.message}</p>}
                            </div>
                            <div className="w-3/4">
                                <label className="mb-1 text-sm font-medium text-gray-700">Project Location*</label>
                                <input
                                    {...register("projectLocation", { required: "This field is required" })}
                                    type="text"
                                    className="mt-1 p-2 border-none outline-none bg-[#f7f7f7] rounded-md w-full"
                                />
                                {errors.projectLocation && <p className="text-xs text-red-500 mt-2 error">{errors.projectLocation.message}</p>}
                            </div>
                        </div>
                        <div className="w-full mb-3">
                            <label className="mb-1 text-sm font-medium text-gray-700">Project Description*</label>
                            <textarea
                                {...register("projectDescription", { required: "This field is required" })}
                                rows={3}
                                type="text"
                                className="mt-1 p-2 border-none outline-none bg-[#f7f7f7] rounded-md w-full"
                            />
                            {errors.projectDescription && <p className="text-xs text-red-500 mt-2 error">{errors.projectDescription.message}</p>}
                        </div>
                        <div className="w-full mb-3">
                            <label className="mb-1 text-sm font-medium text-gray-700">Project Requirements*</label>
                            <textarea
                                {...register("projectRequirements", { required: "This field is required" })}
                                rows={3}
                                type="text"
                                className="mt-1 p-2 border-none outline-none bg-[#f7f7f7] rounded-md w-full"
                            />
                            {errors.projectRequirements && <p className="text-xs text-red-500 mt-2 error">{errors.projectRequirements.message}</p>}
                        </div>
                        <div className="flex  md:w-1/2">
                            <div className="w-full ">
                                <div className="flex items-center mb-2">
                                    <lable className="text-sm mr-2">Add Project Photos</lable>
                                </div>
                                <input
                                    type="file"
                                    ref={imageInputRef}
                                    style={{ display: "none" }}
                                    accept="image/*"
                                    multiple
                                    onChange={handleMoreImageChange}
                                />

                                <div {...getRootProps()} className="dropzone">
                                    <input {...getInputProps()} />
                                    {isDragActive ? (
                                        <div className="w-full flex-col border-dashed border-2 h-[147px] border-gray-300 bg-gray-100 rounded-md flex justify-center items-center">
                                            <img src="/assets/images/gallery.png" className="w-6 h-6 text-gray-500" />
                                            <p className="text-gray-500">Drop files here...</p>
                                        </div>
                                    ) : (
                                        <aside>
                                            <div
                                                onClick={onClickImageUpload}
                                                className={clsx(
                                                    "w-full flex-col p-3 cursor-pointer border-dashed border-2 border-gray-200 bg-gray-100 rounded-md flex justify-center items-center",
                                                    images.length === 0 ? "h-[147px]" : ""
                                                )}
                                            >
                                                {images.length === 0 ? (
                                                    <Fragment>
                                                        <img src="/assets/images/gallery.png" className="w-6 h-6 text-gray-500" />
                                                        <p className="text-gray-500">Add Project Photos</p>
                                                    </Fragment>
                                                ) : (
                                                    <div className="grid grid-cols-5 items-center justify-center rounded-md gap-3">
                                                        {images.map(({ id }) => (
                                                            <Image key={id} id={id} />
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </aside>
                                    )}
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md">
                            Submit
                        </button>
                    </div>
                )}
            </form>
        </div>
    );
};

export default Form;
