import { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useLoginMutation, useResendVerificationEmailMutation } from "types/generated-types.ts";
import { validateLoginEmail, validateLoginPassword } from "utils";
import { LoginHeader } from "../Header";
import "./LoginModalContent.styles.scss";

const LoginModalContent = (props) => {
    // login form error messages
    const [loginErrorMsg, setloginErrorMsg] = useState(() => "");
    const [emailError, setEmailError] = useState(() => "");
    const [passwordError, setPasswordError] = useState(() => "");
    const [showPassword, setShowPassword] = useState(false);
    const [isSendVerifyLink, setIsSendVerifyLink] = useState(false);

    const [{ email, password }, setState] = useState({
        email: "",
        password: "",
    });

    const handleChange = (e) => {
        setState((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };

    const [login, { loading, error }] = useLoginMutation({
        onCompleted: (data) => {
            if (data && data?.login) {
                localStorage.setItem("authToken", data.login);
                props.setOpen(props.nextModal ? props.nextModal : "login-success");
                window.location.reload();
            }
        },
    });

    useMemo(() => {
        if (error) {
            const { graphQLErrors } = error;
            if (graphQLErrors) {
                setloginErrorMsg(graphQLErrors?.[0]?.message);
                if (graphQLErrors[0].message === "Email not verified, please verify and continue") {
                    setIsSendVerifyLink(true);
                } else {
                    setIsSendVerifyLink(false);
                }
            }
        }
    }, [error]);

    const [resendVerifyEmail] = useResendVerificationEmailMutation({
        onCompleted: (data) => {
            if (data && data.resendVerificationEmail) {
                toast.dismiss();
                toast.success("Mail Sent Successfully");
                props.setOpen(false);
            }
        },
    });

    const handleResendVerificationEmail = () => {
        toast.loading("Sending Email, please wait...");
        resendVerifyEmail({
            variables: {
                email: email,
            },
        });
    };

    const loginUser = (e) => {
        e.preventDefault();
        setloginErrorMsg("");
        setEmailError("");
        setPasswordError("");

        const checkEmail = validateLoginEmail(e.target["email"].value);
        const checkPassword = validateLoginPassword(e.target["password"].value);

        if (!checkEmail.status) setEmailError(checkEmail.message);
        if (!checkPassword.status) setPasswordError(checkPassword.message);

        if (checkEmail.status && checkPassword.status) {
            login({
                variables: {
                    email,
                    password,
                },
            });
        }
    };

    /**
     * Check if user is logged in.
     * If so, set isLoggedIn state to `true`, else `false`.
     */
    // const [isLoggedIn, setIsLoggedIn] = useState(false);
    // useEffect(() => {
    //   const accessToken = window.localStorage.getItem("access_token");
    //   setIsLoggedIn(!!accessToken);
    // }, []);

    return (
        <Grid container className="login-modal-content__container ">
            <LoginHeader {...props} />
            <Grid className="user-login-form" container>
                <form onSubmit={loginUser}>
                    <Grid item xs={12} className="field__grid" container direction="column" justifyContent="flex-start" alignItems="flex-start">
                        <InputLabel required className="input__label" htmlFor="title" error={""}>
                            Login with E-mail
                        </InputLabel>
                        <TextField
                            style={{ fontSize: "5rem" }}
                            className="input__field"
                            fullWidth
                            type="text"
                            name="email"
                            placeholder="example@gmail.com"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton edge="end">
                                            <img src="https://equippp-assets.s3.ap-south-1.amazonaws.com/images/email_icon.svg" alt="" />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            helperText={emailError && <small className="text-danger">{emailError}</small>}
                            value={email}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} className="field__grid" container direction="column" justifyContent="flex-start" alignItems="flex-start">
                        <InputLabel required className="input__label" htmlFor="title" error={""}>
                            Password
                        </InputLabel>
                        <TextField
                            fullWidth
                            name="password"
                            type={showPassword ? "text" : "password"}
                            className="input__field"
                            placeholder="******************"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton edge="end">
                                            <img src="https://equippp-assets.s3.ap-south-1.amazonaws.com/images/lock_password.svg" alt="" />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                            {!showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            helperText={passwordError && <small className="text-danger">{passwordError}</small>}
                            value={password}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
                        <Grid item xs={"auto"}>
                            <Button type="button" className="forgot-paswd__btn" onClick={() => props.setOpen("forgot")}>
                                Forgot Password?
                            </Button>
                        </Grid>
                    </Grid>
                    {loginErrorMsg && (
                        <Grid container direction="row" alignItems="center" justifyContent="center" className="error-msg__container">
                            <Typography className="error-para text-danger">{loginErrorMsg}</Typography>&nbsp;
                            {isSendVerifyLink && (
                                <Typography onClick={handleResendVerificationEmail} className="link__text">
                                    Send Link Again ?
                                </Typography>
                            )}
                        </Grid>
                    )}
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Button className="login__btn" type="submit" disabled={loading}>
                            {!loading ? "Log in" : <CircularProgress disableShrink />}
                        </Button>
                    </Grid>
                </form>
                <Typography className="terms__text">
                    By creating this account, you agree to our <Link to="/">Privacy Policy & Cookie Policy</Link>.
                </Typography>
            </Grid>
        </Grid>
    );
};

export default LoginModalContent;
