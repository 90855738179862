import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const ResetPassword = () => {
    
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        document.title = `EquiPPP - Reset Password`;
        handleClickOpen();
    }, []);

    return (
        <Dialog open={open} onClose={()=>{}}>
            <DialogTitle>RESET PASSWORD</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Typography sx={{ fontSize: ".9rem" }}>PASSWORD HAS BEEN SENT TO YOU MAIL PLEASE RESET YOUR PASSWORD</Typography>
                </DialogContentText><br/>
                <Grid container direction="column" justifyCOntent="flex-start" spacing={2}>
                    <Grid item xs={true}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Enter Password sent to your email / mobile"
                            type="email"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={true}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Change Password"
                            type="password"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={true}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Re-enter Password"
                            type="password"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                </Grid><br/>
                <Grid container direction="row" justifyContent="flex-start">
                    <Button variant="contained" onClick={()=>{}}>Submit</Button>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default ResetPassword;
