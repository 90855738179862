import React from "react";
import { AvatarGroup, Box, Typography, Grid, Avatar } from "@mui/material";
import "./SocialPartnerCard.styles.scss";

const SocialPartnerCard = () => {
    return (
        <Box variant="div" className="card_wrapper">
            <img src="https://equippp-assets.s3.ap-south-1.amazonaws.com/images/flag.png" alt="" className="card_flag_image" />
            <Typography className="partner_name">Name Here</Typography>
            <Typography className="partner_desc">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Totam nemo ex asperiores expedita quisquam saepe repudiandae obcaecati
                tenetur nostrum accusamus!
            </Typography>
            <Grid container item xs={12} justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography sx={{ m: 1 }}>Total Members</Typography>
                    <AvatarGroup max={3}>
                        <Avatar alt="avatar" src="/assets/images/profile.png" />
                        <Avatar alt="avatar" src="/assets/images/profile.png" />
                        <Avatar alt="avatar" src="/assets/images/profile.png" />
                    </AvatarGroup>
                </Grid>
                <Typography className="total_members_count">6</Typography>
            </Grid>
        </Box>
    );
};

export default SocialPartnerCard;
