import React, { useState, Fragment, useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import config from "config";
import { useLoginMutation } from "types/generated-types.ts";
import AuthUserContext from "contexts/session";
import ROUTES from "constants/routes";
import { useGetRegionAdminQuery, useGetCurrentUserLazyQuery } from "types/generated-types.ts";
import "./LoginPage.scss";

const LoginPage = () => {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [loginErr, setLoginErr] = useState(false);
    const [action, setAction] = useState("login");
    const { authUser } = useContext(AuthUserContext);
    const [tab, setTab] = useState("dashboard");
    const history = useNavigate();

    const { data: regionAdminData } = useGetRegionAdminQuery();

    const [login] = useLoginMutation({
        onCompleted: (data) => {
            if (data && data.login) {
                window.localStorage.setItem("authToken", data.login);
                history(`/profile-details?id=${regionAdminData?.regions[0]?.users[0]?.id}`);
            } else {
                setLoginErr(true);
            }
        },
    });

    const loginUser = () => {
        if (email && password) {
            login({
                variables: {
                    email,
                    password,
                },
            });
        }
    };

    async function forgetPass() {
        const reqBody = {
            emailId: email,
        };

        await fetch(`${config.apiUrl}/api/admin/forgotPassword`, {
            method: "POST",
            body: JSON.stringify(reqBody),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.status === 1) {
                    alert("Please check your registered mail Account");
                } else if (data.status === 0) {
                    setLoginErr(true);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    const toggleAction = () => {
        setAction((oldValue) => {
            return oldValue === "login" ? "forget" : "login";
        });
    };

    const submitForm = (e) => {
        e.preventDefault();
        action === "login" ? loginUser() : forgetPass();
    };

    return (
        <Grid container justifyContent="center" alignItems="center" className="login-page__container">
            {authUser && authUser.role === "admin" ? (
                <>
                    <Navigate to="/admin/backend/admin-dashboard" replace />
                </>
            ) : (
                <Fragment>
                    <Grid item className="login-panel">
                        <h3 className="heading">{action === "login" ? "AUTHORIZATION" : "FORGOT PASSWORD?"}</h3>
                        <form onSubmit={submitForm}>
                            <label className="input__label">Email Address</label>
                            <OutlinedInput
                                type="email"
                                id="email"
                                name="email"
                                onChange={(e) => setEmail(e.target.value)}
                                onClick={() => setLoginErr(false)}
                                placeholder="example@gmail.com"
                                className="input"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <img src="assets/images/email-icon.svg" alt="" />
                                    </InputAdornment>
                                }
                            />

                            {action === "login" ? (
                                <>
                                    <label className="input__label">Password</label>
                                    <OutlinedInput
                                        type="password"
                                        id="password"
                                        name="password"
                                        onChange={(e) => setPassword(e.target.value)}
                                        onClick={() => setLoginErr(false)}
                                        placeholder="enter password"
                                        className="input"
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <img src="assets/images/password-icon.svg" alt="" />
                                            </InputAdornment>
                                        }
                                    />
                                </>
                            ) : (
                                <Fragment />
                            )}
                            <Grid justifyContent="space-between" container mb="35px">
                                <label className="err__label" htmlFor="err">
                                    {loginErr ? (action === "login" ? "Incorrect Username or Password" : "Enter registered EMAIL ID") : null}
                                </label>

                                <label htmlFor="forgetPass" className="forget-password__label" onClick={toggleAction}>
                                    {action === "login" ? "Forgot Password?" : "Back to Login"}
                                </label>
                            </Grid>
                            <button type="submit" className="login-btn">
                                {action === "login" ? "Login" : action === "forget" ? "Submit" : ""}
                            </button>
                        </form>
                    </Grid>
                    <img src="assets/images/admin-login.png" className="bottom-img" alt="" />
                </Fragment>
            )}
        </Grid>
    );
};

export default LoginPage;
