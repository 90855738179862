import React, { useState, Fragment } from "react";
import { GoogleMap, MarkerF, useJsApiLoader, Autocomplete, Marker, InfoWindowF } from "@react-google-maps/api";
import PropTypes from "prop-types";
import { Switch } from "components/ui/switch";
import { googleMapInitializer } from "constants";

/*
    You have to enable the following APIs in the Google Cloud Console:
    1. Google Maps JavaScript API
    2. Google Maps Places API
*/
const MyActivityMap = ({ currentPosition }) => {
    const mapStyles = {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: "5px",
    };
    const center = {
        lat: currentPosition?.coordinates[1] || 28.7041,
        lng: currentPosition?.coordinates[0] || 77.1025,
    };

    const [showInfoWindow, setShowInfoWindow] = useState(true);

    const { isLoaded, loadError } = useJsApiLoader(googleMapInitializer);

    if (loadError) {
        return <div>Map cannot be loaded right now, sorry.</div>;
    }

    const toggleInfoWindow = () => {
        setShowInfoWindow(!showInfoWindow); // Toggle InfoWindow display
    };

    return (
        <Fragment>
            {/* <div className="flex  mb-2 items-center">
                <Switch onClick={() => setShowMarkers(!showMarkers)} />
                <p className="ml-2 text-md font-semibold">{showMarkers ? "Hide Projects" : "Show Projects"}</p>
            </div> */}

            {isLoaded ? (
                <GoogleMap mapContainerStyle={mapStyles} zoom={13} center={center}>
                    {currentPosition?.coordinates.map((position, index) => {
                        if (!position.lat || !position.lng) return null;

                        return <MarkerF key={index} position={position} />;
                    })}
                </GoogleMap>
            ) : (
                <h4>Loading...</h4>
            )}
        </Fragment>
    );
};

export default MyActivityMap;
