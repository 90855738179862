import React from "react";
import { Fragment, useState, useContext, useRef, useMemo, useEffect } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Avatar from "@mui/material/Avatar";
import TopBadge from "@mui/material/Badge";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { set } from "date-fns";
import { Button } from "components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "components/ui/dialog";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { InputOTP, InputOTPGroup, InputOTPSeparator, InputOTPSlot } from "components/ui/input-otp";
import { useForm } from "react-hook-form";
import { useSendChangeEmailOtpMutation, useVerifyChangeEmailOtpMutation } from "types/generated-types.ts";
import { toast } from "react-toastify";
import { create } from "zustand";
import AuthUserContext from "contexts/session";

const useConfirmOtpDialogStore = create((set) => ({
    isOpen: false,
    email: "",
    actions: {
        open: ({ email }) => set({ isOpen: true, email }),
        close: () => set({ isOpen: false }),
    },
}));

const ConfirmOtpDialog = ({ refetchProfile }) => {
    const { watch, setValue, getValues, reset } = useForm({
        defaultValues: {
            otp: "",
        },
    });

    const { authUser } = useContext(AuthUserContext);

    const { isOpen, email, actions } = useConfirmOtpDialogStore((state) => state);

    const [verifyChangeEmailOtp, { loading }] = useVerifyChangeEmailOtpMutation({
        onError: (error) => {
            toast.error(error.message);
        },
        onCompleted: (data) => {
            if (data?.verifyChangeEmailOtp) {
                toast.success("Email changed successfully");

                actions.close();

                reset();

                console.log({
                    authUser,
                });

                refetchProfile({
                    variables: {
                        id: authUser.id,
                    },
                });
            } else {
                toast.error("Failed to change email");
            }
        },
    });

    return (
        <Dialog
            open={isOpen}
            onOpenChange={(open) => {
                if (open) {
                    actions.open();
                } else {
                    actions.close();
                }
            }}
        >
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Confirm OTP</DialogTitle>
                    <DialogDescription>Enter the OTP sent to your email.</DialogDescription>
                </DialogHeader>
                <div className="grid gap-4 py-4">
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="otp" className="text-right">
                            OTP
                        </Label>
                        <InputOTP
                            maxLength={6}
                            value={watch("otp")}
                            onChange={(value) => {
                                setValue("otp", value);
                            }}
                        >
                            <InputOTPGroup>
                                <InputOTPSlot index={0} />
                                <InputOTPSlot index={1} />
                                <InputOTPSlot index={2} />
                                <InputOTPSlot index={3} />
                            </InputOTPGroup>
                        </InputOTP>
                    </div>
                </div>
                <DialogFooter>
                    <Button
                        type="submit"
                        disabled={loading}
                        onClick={() => {
                            verifyChangeEmailOtp({ variables: { otp: getValues().otp, email } });
                        }}
                    >
                        {loading ? <CircularProgress size={24} /> : "Confirm"}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

const useEditEmailDialogStore = create((set) => ({
    isOpen: false,
    actions: {
        open: () => set({ isOpen: true }),
        close: () => set({ isOpen: false }),
    },
}));

const EditEmailDialog = () => {
    const { register, getValues, reset } = useForm({
        defaultValues: {
            email: "",
        },
    });

    const { isOpen, actions } = useEditEmailDialogStore((state) => state);

    const [sendChangeEmailOtp, { loading }] = useSendChangeEmailOtpMutation({
        onError: (error) => {
            toast.error(error.message);
        },
        onCompleted: (data) => {
            if (data?.sendChangeEmailOtp) {
                toast.success("OTP sent successfully");

                useEditEmailDialogStore.getState().actions.close();

                useConfirmOtpDialogStore.getState().actions.open({
                    email: getValues().email,
                });

                reset();
            } else {
                toast.error("Failed to send OTP");
            }
        },
    });

    return (
        <Dialog
            open={isOpen}
            onOpenChange={(open) => {
                if (open) {
                    actions.open();
                } else {
                    actions.close();
                }
            }}
        >
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Edit email</DialogTitle>
                    <DialogDescription>A OTP will be sent to your email for verification.</DialogDescription>
                </DialogHeader>
                <div className="grid gap-4 py-4">
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="name" className="text-right">
                            New Email
                        </Label>
                        <Input {...register("email")} id="email" className="col-span-3" />
                    </div>
                </div>
                <DialogFooter>
                    <Button
                        type="submit"
                        disabled={loading}
                        onClick={() => {
                            sendChangeEmailOtp({ variables: { email: getValues().email } });
                        }}
                    >
                        {loading ? <CircularProgress size={24} /> : "Send OTP"}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

export const UserTypeDetails = ({
    profile_picture,
    email,
    kyc_status,
    user_type,
    user_type_details,
    description,
    twitter_link,
    facebook_link,
    linkedin_link,
    other_link,
    location_title,
    user_name,
    uploadProfiePictureLoading,
    handleFileChange,
    handleChange,
    handleUserTypeDetailsChange,
    setMapModal,
    isMapModalOpen,
    refetchProfile,
}) => {
    const all_projects = [
        "Agriculture",
        "Community",
        "Education",
        "Environment",
        "HealthCare",
        "Housing",
        "InfraStructurePower",
        "Roads and Bridges",
        "Social Justice",
        "Technology",
        "Waste Management",
        "Water & Sanitization",
        "Others",
    ];
    const genders = [
        {
            label: "Male",
            value: "male",
        },
        {
            label: "Female",
            value: "female",
        },
        {
            label: "Others",
            value: "others",
        },
    ];
    const [selected, setSelected] = useState(false);
    const [isSelectedAll, setIsSelectedAll] = useState(false);
    const ImageRef = useRef(null);
    const username_pattern = /^\w+$/;
    const handleClick = (e) => {
        if (user_type_details.contributionCategories?.length === all_projects?.length) {
            setSelected(false);

            e.stopPropagation();
        } else {
            setIsSelectedAll(!isSelectedAll);
            setSelected(true);
        }
    };
    return (
        <Fragment>
            <Grid style={{ marginBottom: "1rem" }} container item xs={12} justifyContent="center" alignItems="center">
                <Grid item xs="auto">
                    <div className="avatar">
                        {uploadProfiePictureLoading ? (
                            <CircularProgress />
                        ) : (
                            <TopBadge
                                overlap="rectangular"
                                className="camera__badge"
                                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                                badgeContent={
                                    <span className="badge__section">
                                        <CameraAltIcon
                                            onClick={() => {
                                                ImageRef.current.click();
                                            }}
                                            className="camera__icon"
                                        />
                                    </span>
                                }
                            >
                                <Avatar
                                    alt="image"
                                    className={`profile-picture`}
                                    src={profile_picture} // || "/assets/images/Avatar.png"
                                    onClick={() => {
                                        ImageRef.current.click();
                                    }}
                                />
                            </TopBadge>
                        )}
                    </div>

                    <input
                        ref={ImageRef}
                        className="input"
                        name="profile"
                        type="file"
                        accept="image/*"
                        hidden
                        onChange={handleFileChange}
                        id="profile-image"
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} container justifyContent="space-between" alignItems="center" className="input-container">
                <Grid item xs={12} md={6} className="field">
                    <TextField
                        type="text"
                        fullWidth
                        autoComplete="off"
                        variant="standard"
                        label="User Name"
                        value={user_name}
                        onChange={handleChange}
                        name="user_name"
                        className="input-field"
                    />
                    {kyc_status === "accepted" && (
                        <Tooltip title="Verified" arrow placement="top">
                            <img src="assets/images/user-verified.png" alt="" width={"20px"} height={"20px"} className="verified" />
                        </Tooltip>
                    )}
                </Grid>
                {/* <Grid item xs={12} md={6} className="field" style={{ position: "relative" }}>
                    <TextField
                        type="text"
                        fullWidth
                        autoComplete="off"
                        variant="standard"
                        label={user_type === "Non-Profit Organization" || user_type === "CSR foundation" ? "Company Name" : "Name"}
                        value={user_type_details.name}
                        onChange={handleUserTypeDetailsChange}
                        name="name"
                        className="input-field"
                    />
                </Grid> */}
                <Grid item xs={12} md={6} className="field">
                    <TextField
                        type="text"
                        fullWidth
                        autoComplete="off"
                        variant="standard"
                        label={"Profession"}
                        value={user_type_details.profession}
                        onChange={handleUserTypeDetailsChange}
                        name="profession"
                        className="input-field "
                    />
                </Grid>
                {user_type === "Student" && (
                    <Fragment>
                        {/* <Grid item xs={12} md={6} className="field">
                            <TextField
                                type="text"
                                fullWidth
                                autoComplete="off"
                                variant="standard"
                                label="Institution"
                                value={user_type_details.name}
                                onChange={handleUserTypeDetailsChange}
                                name="name"
                                className="input-field"
                            />
                        </Grid> */}
                        <Grid item xs={12} md={6} className="field">
                            <TextField
                                type="text"
                                fullWidth
                                autoComplete="off"
                                variant="standard"
                                label="Course"
                                value={user_type_details.courseName}
                                onChange={handleUserTypeDetailsChange}
                                name="courseName"
                                className="input-field"
                            />
                        </Grid>
                    </Fragment>
                )}
                <Grid item xs={12} md={6} className="field">
                    <div className="flex justify-center items-center gap-4">
                        <TextField
                            disabled
                            type="text"
                            fullWidth
                            autoComplete="off"
                            variant="standard"
                            label={user_type === "Non-Profit Organization" || user_type === "CSR foundation" ? "Company Email" : "Email"}
                            value={email}
                            name="email"
                            className="input-field"
                        />
                        <Button
                            className="translate-y-1.5"
                            variant="outline"
                            onClick={() => {
                                useEditEmailDialogStore.getState().actions.open();
                            }}
                        >
                            Edit Email
                        </Button>
                        <EditEmailDialog />
                        <ConfirmOtpDialog refetchProfile={refetchProfile} />
                    </div>
                </Grid>
                <Grid item xs={12} md={6} className="field">
                    <TextField
                        type="text"
                        fullWidth
                        autoComplete="off"
                        variant="standard"
                        label="Description"
                        value={description}
                        onChange={handleChange}
                        name="description"
                        className="input-field"
                    />
                </Grid>
                {(user_type === "Pro-Bono" || user_type === "Individual" || user_type === "Subject Matter Expert") && (
                    <Grid item xs={12} md={6} className="field">
                        <TextField
                            type="text"
                            fullWidth
                            autoComplete="off"
                            variant="standard"
                            label="Profession"
                            value={user_type_details.profession || ""}
                            name="profession"
                            onChange={handleUserTypeDetailsChange}
                            className="input-field"
                        />
                    </Grid>
                )}
                <Grid item xs={12} md={6} className="field">
                    <TextField
                        type="text"
                        fullWidth
                        autoComplete="off"
                        variant="standard"
                        label="Location"
                        value={location_title}
                        onClick={() => setMapModal(!isMapModalOpen)}
                        onChange={handleChange}
                        name="location_title"
                        className="input-field"
                    />
                </Grid>
                {user_type === "Subject Matter Expert" && (
                    <Grid item xs={12} md={6} className="field">
                        <TextField
                            type="text"
                            fullWidth
                            autoComplete="off"
                            variant="standard"
                            label="Area of expertise"
                            value={user_type_details.expertise}
                            onChange={handleUserTypeDetailsChange}
                            name="expertise"
                            className="input-field"
                        />
                    </Grid>
                )}
                {(user_type === "Pro-Bono" || user_type === "Student") && (
                    <Grid item xs={12} md={6} className="field">
                        <TextField
                            type="text"
                            fullWidth
                            autoComplete="off"
                            variant="standard"
                            label="Skills"
                            value={user_type_details.skills || ""}
                            onChange={handleUserTypeDetailsChange}
                            name="skills"
                            className="input-field"
                        />
                    </Grid>
                )}
                {(user_type === "Member of Parliament" || user_type === "Member of Legislative Assembly") && (
                    <Fragment>
                        <Grid item xs={12} md={6} className="field">
                            <TextField
                                type="text"
                                fullWidth
                                autoComplete="off"
                                variant="standard"
                                label="Constituency"
                                value={user_type_details.constituency || ""}
                                onChange={handleUserTypeDetailsChange}
                                name="constituency"
                                className="input-field"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} className="field">
                            <TextField
                                type="text"
                                fullWidth
                                autoComplete="off"
                                variant="standard"
                                label="Tenure left"
                                value={user_type_details.tenureDuration || ""}
                                onChange={handleUserTypeDetailsChange}
                                name="tenureDuration"
                                className="input-field"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} className="field">
                            <TextField
                                type="text"
                                fullWidth
                                autoComplete="off"
                                variant="standard"
                                label="Party Name"
                                value={user_type_details.partyName || ""}
                                onChange={handleUserTypeDetailsChange}
                                name="partyName"
                                className="input-field"
                            />
                        </Grid>
                    </Fragment>
                )}
                {/* <Grid item xs={12} md={6} className="field">
                                        <Typography className="label" component="p">
                                            Account Privacy
                                        </Typography>
                                        <TextField
                                            type="text"
                                            fullWidth
                                            select
                                            variant="outlined"
                                            value={is_private}
                                            onChange={handleChange}
                                            name="is_private"
                                            SelectProps={{ MenuProps: { disableScrollLock: true } }}
                                            className="input-field"
                                        >
                                            <MenuItem value={true}>Private</MenuItem>
                                            <MenuItem value={false}>Public</MenuItem>
                                        </TextField>individuals,students,Probono,MP,MLA,SME.
                    </Grid> */}
                {(user_type === "Individuals" ||
                    user_type === "Students" ||
                    user_type === "Pro-Bono" ||
                    user_type === "Member of Parliament" ||
                    user_type === "Member of Legislative Assembly" ||
                    user_type === "Subject Matter Expert") && (
                    <Grid item xs={12} md={6} className="field">
                        <TextField
                            type="text"
                            fullWidth
                            select
                            variant="standard"
                            label="Gender"
                            value={user_type_details.gender}
                            onChange={handleUserTypeDetailsChange}
                            name="gender"
                            SelectProps={{ MenuProps: { disableScrollLock: true } }}
                            className="input-field"
                        >
                            {genders.map((gender, index) => (
                                <MenuItem key={index} value={gender.value}>
                                    {gender.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                )}
                {(user_type === "Individuals" ||
                    user_type === "Students" ||
                    user_type === "Pro-Bono" ||
                    user_type === "Member of Parliament" ||
                    user_type === "Member of Legislative Assembly" ||
                    user_type === "Subject Matter Expert") && (
                    <Grid item xs={12} md={6} className="field">
                        <Typography className="label" component="p">
                            Date of birth
                        </Typography>
                        <TextField
                            type="date"
                            fullWidth
                            autoComplete="off"
                            variant="standard"
                            value={user_type_details.date_of_birth}
                            onChange={handleUserTypeDetailsChange}
                            name="date_of_birth"
                            className="input-field"
                        />
                    </Grid>
                )}
                {user_type === "Subject Matter Expert" && (
                    <Grid item xs={12} md={6} className="field">
                        <TextField
                            type="text"
                            fullWidth
                            autoComplete="off"
                            variant="standard"
                            label="Previous/Current company"
                            value={user_type_details.previousCompany}
                            name="previousCompany"
                            onChange={handleUserTypeDetailsChange}
                            className="input-field"
                        />
                    </Grid>
                )}
                <Grid item xs={12} md={6} className="field">
                    <TextField
                        type="text"
                        fullWidth
                        label={
                            user_type === "Non-Profit Organization" || user_type === "CSR foundation" || user_type === "Public Sector Bank"
                                ? "Company Contact"
                                : "Phone"
                        }
                        autoComplete="off"
                        variant="standard"
                        value={user_type_details.phone}
                        name="phone"
                        onChange={handleUserTypeDetailsChange}
                        className="input-field"
                    />
                </Grid>
                <Grid item xs={12} md={6} className="field">
                    <FormControl variant="standard" style={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-standard-label">Focus of Interest</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-multiple-name"
                            className="input-field"
                            multiple
                            value={user_type_details.contributionCategories || []}
                            name="contributionCategories"
                            onChange={handleUserTypeDetailsChange}
                        >
                            <MenuItem
                                value={"All Categories"}
                                onClick={handleClick}
                                style={selected ? { backgroundColor: "rgba(25, 118, 210, 0.08)" } : {}}
                            >
                                All Categories
                            </MenuItem>
                            {all_projects.map((item, index) => (
                                <MenuItem key={index} value={item} disabled={isSelectedAll}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {/* <TextField
                        type="text"
                        fullWidth
                        multi
                        select
                        label="Focus of interest"
                        autoComplete="off"
                        variant="standard"
                        SelectProps={{ MenuProps: { disableScrollLock: true } }}
                        value={user_type_details.contributionCategories || ""}
                        name="contributionCategories"
                        onChange={handleUserTypeDetailsChange}
                        className="input-field"
                    >
                        {all_projects.map((item, index) => (
                            <MenuItem key={index} value={item}>
                                {item}
                            </MenuItem>
                        ))}
                    </TextField> */}
                </Grid>
                {(user_type === "CSR foundation" || user_type === "Non-Profit Organization" || user_type === "Public Sector Bank") && (
                    <Fragment>
                        <Grid item xs={12} md={6} className="field">
                            <TextField
                                type="text"
                                fullWidth
                                label="Representative Role"
                                autoComplete="off"
                                variant="standard"
                                SelectProps={{ MenuProps: { disableScrollLock: true } }}
                                value={user_type_details.role || ""}
                                name="role"
                                onChange={handleUserTypeDetailsChange}
                                className="input-field"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} className="field">
                            <TextField
                                type="text"
                                fullWidth
                                label="Representative Name"
                                autoComplete="off"
                                variant="standard"
                                SelectProps={{ MenuProps: { disableScrollLock: true } }}
                                value={user_type_details.name}
                                name="name"
                                onChange={handleUserTypeDetailsChange}
                                className="input-field"
                            />
                        </Grid>
                    </Fragment>
                )}
            </Grid>
            <p className="heading">Social Links</p>
            <Grid item xs={12} container justifyContent="space-between" alignItems="center" className="input-container">
                {/* Twitter link, facebook link, linkedin link */}
                <Grid item xs={12} md={6} className="field">
                    <TextField
                        type="text"
                        fullWidth
                        autoComplete="off"
                        variant="standard"
                        label="Twitter"
                        value={twitter_link}
                        onChange={handleChange}
                        name="twitter_link"
                        className="input-field"
                    />
                </Grid>
                <Grid item xs={12} md={6} className="field">
                    <TextField
                        type="text"
                        fullWidth
                        autoComplete="off"
                        variant="standard"
                        label="Facebook"
                        value={facebook_link}
                        onChange={handleChange}
                        name="facebook_link"
                        className="input-field"
                    />
                </Grid>
                <Grid item xs={12} md={6} className="field">
                    <TextField
                        type="text"
                        fullWidth
                        autoComplete="off"
                        variant="standard"
                        label="LinkedIn"
                        value={linkedin_link}
                        onChange={handleChange}
                        name="linkedin_link"
                        className="input-field"
                    />
                </Grid>
                <Grid item xs={12} md={6} className="field">
                    <TextField
                        type="text"
                        fullWidth
                        autoComplete="off"
                        variant="standard"
                        label="Others/Your website"
                        value={other_link}
                        onChange={handleChange}
                        name="other_link"
                        className="input-field"
                    />
                </Grid>
            </Grid>
            {user_name === "Student" && (
                <Fragment>
                    {" "}
                    <Grid item xs={12} container justifyContent="space-between" alignItems="center" pl="20px" mb="15px">
                        <Grid item xs={12} md={6} className="field">
                            <p style={{ margin: "0" }}>Project as part of academics ?</p>
                        </Grid>
                        <Grid item xs={12} md={6} className="field">
                            <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                                <FormControlLabel value="female" control={<Radio />} label="yes" />
                                <FormControlLabel value="male" control={<Radio />} label="no" />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container justifyContent="space-between" alignItems="center" pl="20px">
                        <Grid item xs={12} className="field" direction="column">
                            <p>Why you want to take part in this project ?</p>
                            <textarea placeholder="write some description" className="textarea"></textarea>
                        </Grid>
                    </Grid>
                </Fragment>
            )}
        </Fragment>
    );
};
