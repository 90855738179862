import { useState, Fragment } from "react";
import Divider from "@mui/material/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import "./Comments.styles.scss";

const Comments = ({ pendingComments, approvedComments, rejectedComments }) => {
    const [view, setView] = useState(0);
    return (
        <Grid item xs={12} container alignItems="flex-start" className="project-comments__container">
            <Grid item xs={12} container justify="space-between" alignItems="center" className="project-comments__header">
                <Grid item xs={4} container justify="flex-start" alignItems="center">
                    <Typography variant="h6" className="project-comments__title">
                        Comments
                    </Typography>
                </Grid>
                <Grid item xs={8} container justify="space-around" alignItems="center">
                    <Typography variant="h6" className={`project-comments__btn ${view === 0 ? "active" : ""} orange`} onClick={() => setView(0)}>
                        Pending
                    </Typography>
                    <Typography variant="h6" className={`project-comments__btn ${view === 1 ? "active" : ""} green`} onClick={() => setView(1)}>
                        Approved
                    </Typography>
                    <Typography variant="h6" className={`project-comments__btn ${view === 2 ? "active" : ""} pink`} onClick={() => setView(2)}>
                        Rejected
                    </Typography>
                </Grid>
            </Grid>
            <Divider className="divider" />
            {view === 0 ? (
                pendingComments?.length > 0 ? (
                    pendingComments.map((comment, index) => {
                        return (
                            <Grid item xs={12} key={index} container className="project-comments__item">
                                <Typography className="comment">{comment?.comment}</Typography>
                            </Grid>
                        );
                    })
                ) : (
                    <Grid item xs={12} container className="project-comments__item">
                        <Typography className="comment">No Pending Comments</Typography>
                    </Grid>
                )
            ) : (
                <Fragment />
            )}
            {view === 1 ? (
                approvedComments?.length > 0 ? (
                    approvedComments.map((comment, index) => {
                        return (
                            <Grid item xs={12} key={index} container className="project-comments__item">
                                <Typography className="comment">{comment?.comment}</Typography>
                            </Grid>
                        );
                    })
                ) : (
                    <Grid item xs={12} container className="project-comments__item">
                        <Typography className="comment">No Approved Comments</Typography>
                    </Grid>
                )
            ) : (
                <Fragment />
            )}
            {view === 2 ? (
                rejectedComments?.length > 0 ? (
                    rejectedComments.map((comment, index) => {
                        return (
                            <Grid item xs={12} key={index} container className="project-comments__item">
                                <Typography className="comment">{comment?.comment}</Typography>
                            </Grid>
                        );
                    })
                ) : (
                    <Grid item xs={12} container className="project-comments__item">
                        <Typography className="comment">No Rejected Comments</Typography>
                    </Grid>
                )
            ) : (
                <Fragment />
            )}
        </Grid>
    );
};

export default Comments;
