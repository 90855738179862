import React, { useState, Fragment } from "react";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import CodeIcon from "@mui/icons-material/Code";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useShareProjectMutation } from "types/generated-types.ts";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "55%",
    minWidth: "450px",
    bgcolor: "background.paper",
    border: "1px solid gray",
    boxShadow: 24,
};

function ShareModal({ project_id, setShareOpen, shareOpen, props }) {
    const [email, setEmail] = useState("");
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const [shareProject] = useShareProjectMutation({
        onCompleted: (data) => {
            if (data) {
                toast.success("Project Shared Successfully");
                props?.refetch();
                setShareOpen(false);
            }
        },
        onError: (error) => {
            toast.error("Something went wrong");
        },
    });
    return (
        <Fragment>
            <Modal open={shareOpen} onClose={() => setShareOpen(false)}>
                <Box sx={style}>
                    <Grid container className="share__modal">
                        <Grid item container xs={12} pb="10px" className="header" justifyContent={"space-between"} alignItems="center">
                            <Typography>
                                <span>Invite</span>
                                <span>Publish</span>
                            </Typography>
                            <CloseIcon onClick={() => setShareOpen(false)} />
                        </Grid>
                        <Divider />
                        <Grid item xs={12} container justifyContent="space-between" className="mid-section">
                            <TextField
                                placeholder="Email, comma separated"
                                value={email}
                                onChange={handleEmailChange}
                                className="share-input-field"
                            />
                            <Button
                                variant="standard"
                                className="btn close"
                                onClick={() => {
                                    shareProject({
                                        variables: {
                                            email: email,
                                            project_id: project_id,
                                        },
                                    });
                                }}
                            >
                                Send invite
                            </Button>
                            <Grid container item xs={12}></Grid>
                        </Grid>
                        <Divider />
                        <Grid container item xs={12} className="footer">
                            <Grid
                                item
                                xs={2}
                                mr="10px"
                                alignItems={"center"}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    navigator.clipboard.writeText(`https://www.equippp.global/projects/${project_id}`);
                                }}
                            >
                                <InsertLinkIcon className="link-icon" />
                                <span>Copy link</span>
                            </Grid>
                            <Grid item xs={4} alignItems={"center"}>
                                <CodeIcon />
                                <span>Get embed code</span>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </Fragment>
    );
}

export default ShareModal;
