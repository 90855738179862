import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const BACKEND_URIS = {
    "madurai.equippp.global": "https://madurai-api.equippp.global/graphql",
    "manthani.equippp.global": "https://manthani-api.equippp.global/graphql",
    "zaheerabad.equippp.global": "https://zaheerabad-api.equippp.global/graphql",
    "pemmasani.equippp.global": "https://guntur-api.equippp.global/graphql",
};

const uri = (typeof window !== "undefined" && BACKEND_URIS[window.location.hostname]) || process.env.REACT_APP_API_URL;

const getUserID = () => {
    return localStorage.getItem("authUser");
};

export const getLink = (userID) => {
    // Create an http link:
    const httpLink = createHttpLink({
        uri,
        headers: {
            "keep-alive": "true",
            Authorization: `Bearer `,
            "X-Hasura-User-Id": userID ? userID : getUserID()?.id,
        },
    });

    // Create auth link
    const authLink = setContext((_, { headers }) => {
        const token = localStorage.getItem("authToken");

        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : "",
                "X-Hasura-User-Id": headers ? headers.user_id : "",
                "X-Hasura-Admin-Secret": "Equippp@123",
            },
        };
    });

    // Concat auth link and http link
    const link = authLink.concat(httpLink);

    return link;
};

const client = new ApolloClient({
    link: getLink(),
    cache: new InMemoryCache(),
    defaultOptions: {
        query: {
            fetchPolicy: "network-only",
            errorPolicy: "all",
        },
        mutate: {
            fetchPolicy: "network-only",
            errorPolicy: "all",
        },
        watchQuery: {
            fetchPolicy: "cache-and-network",
            errorPolicy: "all",
        },
    },
});

export default client;
