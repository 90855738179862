import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ROUTES from "constants/routes";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../components/ui/accordion";
import "./SideNavBar.scss";

const SideNavBar = ({ tab, changeNav }) => {
    const [userType, setUserType] = useState("");

    useEffect(() => {
        setUserType(window.localStorage.getItem("user_type"));
    }, []);

    const location = useLocation();
    const [expanded, setExpanded] = useState(false);

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className="flex flex-col justify-start items-start w-full h-full">
            <div className="flex justify-start items-center w-full  py-2.5 border-b pl-4 ">
                <Link to={ROUTES.ADMIN_DASHBOARD} className="w-full pl-4 font-semibold ">
                    Dashboard
                </Link>
            </div>
            <div className="flex justify-start items-center w-full py-2.5 pl-4 border-b">
                <Link to={ROUTES.ADMIN_ANALYTICS} className="w-full pl-4 font-semibold ">
                    Analytics
                </Link>
            </div>

            <Accordion type="single" collapsible className="w-full ">
                <AccordionItem value="item-1">
                    <AccordionTrigger>
                        <p className="pl-8 font-semibold">Projects</p>
                    </AccordionTrigger>
                    <AccordionContent>
                        <div className="flex items-start justify-start w-full flex-col pl-8">
                            <Link to={ROUTES.ADMIN_CREATE_PROJECT} className=" mb-3 text-gray-500">
                                Add Project
                            </Link>
                            <Link to={ROUTES.ADMIN_EXPRESSIONS} className=" mb-3 text-gray-500">
                                Expressions
                            </Link>
                            <Link to={ROUTES.ADMIN_PROJECT_COMMENTS} className=" mb-3 text-gray-500">
                                Project Comments
                            </Link>
                            <Link to={ROUTES.ADMIN_ALL_PROJECTS} className=" mb-2 text-gray-500">
                                All Projects
                            </Link>
                        </div>
                    </AccordionContent>
                </AccordionItem>
            </Accordion>

            <Accordion type="single" collapsible className="w-full">
                <AccordionItem value="item-2">
                    <AccordionTrigger>
                        <p className="pl-8 font-semibold">User</p>
                    </AccordionTrigger>
                    <AccordionContent>
                        <div className="flex flex-col items-start justify-start pl-8">
                            <Link to={ROUTES.ADMIN_CREATE_USER} className=" mb-2 text-gray-500">
                                Create User
                            </Link>
                            {/* {userType === "Super Admin" && (
                                <Link to={ROUTES.ADMIN_CREATE_ADMIN_USER} className=" mb-2 text-gray-500">
                                    Create Admin User
                                </Link>
                            )} */}

                            <Link to={ROUTES.ADMIN_CREATE_ADMIN_USER} className=" mb-2 text-gray-500">
                                Create Admin User
                            </Link>

                            <Link to={ROUTES.ADMIN_CHANGE_USER_ROLE} className=" mb-2 text-gray-500">
                                Change User's Role
                            </Link>
                            {/* <Link to={ROUTES.ADMIN_USERS_KYC} className={location.pathname === "/admin/backend/users-kyc" && "active"}>
                            Users Kyc
                        </Link> */}
                            {/* <Link to={ROUTES.ADMIN_INVITE_USERS} className=" mb-2 text-gray-500">
                                Invite Users
                            </Link> */}
                        </div>
                    </AccordionContent>
                </AccordionItem>
            </Accordion>
        </div>
    );
};

export default SideNavBar;
