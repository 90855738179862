import { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const SplashScreen = () => {
    const navigate = useNavigate();

    return (
        <Fragment>
            <div className="">
                <div className="mb-20 mt-10 flex justify-end w-full">
                    <p className="font-bold mx-4">Jobspot</p>
                </div>
                <div className="h-full w-full flex flex-col justify-center items-center">
                    <img src="assets/images/splash.svg" alt="logo" className="logo" />
                    <div>
                        <img src="assets/images/find.svg" alt="logo" className="my-10 text-start" />
                        <p>
                            Explore all the most exciting projects based
                            <br />
                            on your interest and major.
                        </p>
                    </div>
                    <div className="flex justify-end w-full">
                        <img
                            src="assets/images/next.svg"
                            alt="logo"
                            className="my-10 mx-4 flex justify-end "
                            onClick={() => {
                                localStorage.setItem("onboardingVisited", "true");

                                navigate("/login");
                            }}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default SplashScreen;
