import { React, useState, useEffect } from "react";
import Linkify from "react-linkify";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useUpdateCommentStatusMutation, useDeleteCommentMutation } from "types/generated-types.ts";
import MentionToLinks from "views/Project/ProjectComments/components/MentionToLinks";
import "./ApprovedComments.scss";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "fit-content",
    bgcolor: "background.paper",
    border: "1px solid gray",
    boxShadow: 24,
    p: 3,
};

const ApprovedComments = (props) => {
    const [accessToken, setAccessToken] = useState(false);
    const [tableRows, setTableRows] = useState([]);
    const [open, setOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [commentId, setCommentId] = useState("");
    const [existingComment, setExistingComment] = useState({ id: "", comment: "" });
    const [page, setPage] = useState(0);
    const [userType, setUserType] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [isValidComment, setIsValidComment] = useState(false);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleChange = (e) => {
        setUserType(e.target.value);
    };

    useEffect(() => {
        const token = window.localStorage.getItem("authToken");

        setAccessToken(token);
        const rows = props.commentDetails?.map((el) => {
            let commentText = el.comment;
            if (el.comment.includes("@[")) {
                commentText = <MentionToLinks key={el.id} text={el.comment} />;
            } else if (el.comment.includes("http")) {
                commentText = <Linkify key={el.id}>{el.comment}</Linkify>;
            }
            return {
                id: el.project_id,
                comment_id: el.id,
                projectName: el.project.title,
                comment: commentText,
                commentedBy: el.user?.user_name || el.user?.email_id,
                commentedOn: el.created_at.substring(0, 10),
                optionsBtns: (
                    <Grid item xs={"auto"} container direction="row" alignItems="center" justifyContent="center" className="action__btns">
                        <Button
                            className="outlined-btn"
                            variant="outlined"
                            onClick={() => {
                                setCommentId(el.id);
                                setEditOpen(true);
                                setExistingComment({
                                    ...existingComment,
                                    id: el.id,
                                    comment: el.comment,
                                });
                            }}
                        >
                            <EditIcon className="edit-icon" />
                            Edit
                        </Button>
                        <Button
                            className="outlined-btn"
                            variant="outlined"
                            onClick={() => {
                                setCommentId(el.id);
                                setOpen(true);
                            }}
                        >
                            <DeleteIcon className="edit-icon" />
                            Delete
                        </Button>
                    </Grid>
                ),
            };
        });
        if (userType === "") {
            setTableRows(rows);
        } else {
            let filteredItems = rows.filter((item) => {
                return item.userRole === userType;
            });
            setTableRows(filteredItems);
        }
    }, [props.commentDetails, userType]);

    const [deleteComment] = useDeleteCommentMutation({
        onCompleted: (data) => {
            toast.success("Comment Deleted Successfully");
            setTableRows(
                tableRows.filter((comment) => {
                    return comment.comment_id !== data?.delete_comments?.returning[0]?.id;
                })
            );
            setOpen(false);
            setCommentId("");
        },
    });

    const [updateComment] = useUpdateCommentStatusMutation({
        onCompleted: (data) => {
            if (data && data.response?.id) {
                toast.success("Comment Updated Successfully");
                setEditOpen(false);
                setCommentId("");
                setExistingComment(...existingComment, { id: "", comment: "" });
            }
        },
    });

    return (
        <Grid item xs={12} className="appproved-comments__container">
            {!!accessToken ? (
                <Grid item>
                    <TableContainer>
                        <Table>
                            <TableHead className="table-header-font-style">
                                <TableRow>
                                    <TableCell>Project Name</TableCell>
                                    <TableCell>Comments</TableCell>
                                    <TableCell>
                                        <Grid container alignItems="center" justifyContent="space-betweenr" className="commented-by-column">
                                            <span>Commented By</span>
                                            <Select id="demo-simple-select" value={userType} onChange={handleChange}>
                                                <MenuItem value={"user"}>user</MenuItem>
                                                <MenuItem value={"sme"}>sme</MenuItem>
                                                <MenuItem value={"expert"}>expert</MenuItem>
                                                <MenuItem value={"marquee"}>marquee</MenuItem>
                                            </Select>
                                        </Grid>
                                    </TableCell>
                                    <TableCell>Commented On</TableCell>
                                    <TableCell align="center">Options</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableRows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                    <TableRow key={row.projectName}>
                                        <TableCell component="td" scope="row" align="left">
                                            <Link to={`/projects/${row?.id}`}>{row.projectName}</Link>
                                        </TableCell>
                                        <TableCell>{row.comment}</TableCell>
                                        <TableCell>
                                            <Link to={`/profile-details?id=${row?.id}`}>{row.commentedBy}</Link>
                                        </TableCell>
                                        <TableCell>{row.commentedOn}</TableCell>
                                        <TableCell>{row.optionsBtns}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {tableRows && tableRows.length > 0 && (
                        <TablePagination
                            component="div"
                            count={tableRows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            rowsPerPageOptions={[5, 10]}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    )}
                </Grid>
            ) : (
                <Typography>Not logged in</Typography>
            )}

            <Modal open={open} onClose={() => setOpen(false)}>
                <Box sx={style}>
                    <Grid container className="edit-delete-timeline__modal">
                        <Grid item xs={12} container justifyContent="flex-start" alignItems="center">
                            <Typography className="delete-title">Are you sure you want to delete this comment?</Typography>
                        </Grid>

                        <Grid item xs={12} container justifyContent="flex-end">
                            <Button
                                variant="standard"
                                className="btn close"
                                onClick={() => {
                                    deleteComment({
                                        variables: {
                                            id: commentId,
                                        },
                                    });
                                }}
                            >
                                Yes
                            </Button>
                            <Button variant="standard" className="btn save" onClick={() => setOpen(false)}>
                                No
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <Modal className="edit__modal" open={editOpen} onClose={() => setEditOpen(false)}>
                <Box sx={style}>
                    <Grid container className="edit-delete-timeline__modal">
                        <Grid item xs={12} container justifyContent="center" alignItems="center">
                            <Typography className="delete-title">Update Comment</Typography>
                        </Grid>
                        <Grid item xs={12} container direction="column" justifyContent="flex-start" alignItems="flex-start">
                            <Typography className="delete-title">Comment : </Typography>
                            <TextField
                                type="text"
                                variant="standard"
                                name="comment"
                                multiline
                                maxRows={4}
                                value={existingComment?.comment}
                                onChange={(e) => {
                                    setExistingComment({
                                        ...existingComment,
                                        comment: e.target.value,
                                    });
                                    setIsValidComment(e.target.value !== "" ? true : false);
                                }}
                                placeholder="Add a comment"
                                InputProps={{ disableUnderline: true }}
                                className="comment-input"
                            />
                        </Grid>
                        <Grid item xs={12} container justifyContent="flex-end">
                            <Button
                                variant="standard"
                                className="btn close"
                                disabled={!isValidComment}
                                onClick={() => {
                                    updateComment({
                                        variables: {
                                            _set: {
                                                comment: existingComment?.comment,
                                            },
                                            id: commentId,
                                        },
                                    });
                                }}
                            >
                                Update
                            </Button>
                            <Button variant="standard" className="btn save" onClick={() => setEditOpen(false)}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </Grid>
    );
};

export default ApprovedComments;
