import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { FormHelperText } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { useCreateUserMutation, useGetEncyptedPasswordMutation } from "types/generated-types.ts";
import { useGetUsersQuery } from "../../../types/generated-types.ts";
import "./CreateUser.scss";

const CreateUser = () => {
    const [{ email_id, first_name, last_name, user_name, mobile, password, user_type }, setForm] = useState({
        email_id: "",
        first_name: "",
        last_name: "",
        user_name: "",
        mobile: "",
        password: "",
        user_type: "",
    });
    const [passSuccess, setPassSuccess] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [users, setUsers] = useState();
    const [otherUserType, setOtherUserType] = useState("");
    const username_pattern = /^\w+$/;
    const email_pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const password_pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*[&%$]).{8,}$/;

    const { data: usersData } = useGetUsersQuery();
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (e.target.name === "Others") {
            setOtherUserType(e.target.value);
            setForm((oldValue) => ({
                ...oldValue,
                user_type: otherUserType,
            }));
        } else {
            setForm((oldValue) => {
                return {
                    ...oldValue,
                    [name]: value,
                };
            });
        }
    };
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    function validateMobileNumber(txtMobId) {
        var mob = /^[1-9]{1}[0-9]{9}$/;
        if (mob.test(txtMobId) === false) {
            return false;
        }
        return true;
    }
    const [createUserMutation, { loading }] = useCreateUserMutation({
        onCompleted: (data) => {
            console.log("here too");
            toast.dismiss();
            toast.success("User Created Successfully");
            setForm({
                email_id: "",
                first_name: "",
                last_name: "",
                user_name: "",
                mobile: "",
                password: "",
                user_type: "",
            });
        },
    });
    useEffect(() => {
        if (usersData) {
            setUsers(usersData.users);
            console.log(usersData.users);
        }
    }, [usersData]);

    const [generatePassword] = useGetEncyptedPasswordMutation({
        onCompleted: (data) => {
            setForm((oldValue) => {
                return {
                    ...oldValue,
                    ["password"]: data?.generatePassword,
                };
            });
            setPassSuccess(true);
        },
    });
    useEffect(() => {
        if (passSuccess) {
            createUserMutation({
                variables: {
                    email: email_id,
                    first_name: first_name,
                    last_name: last_name,
                    user_name: user_name,
                    mobile: mobile,
                    password: password,
                    user_type: user_type,
                },
            });
        }
    }, [passSuccess]);

    const createUser = () => {
        if (!user_name.match(username_pattern)) {
            return;
        }
        if (users.filter((item) => item.user_name === user_name).length > 0) {
            return;
        }
        if (!email_pattern.test(email_id)) {
            return;
        }
        if (!password_pattern.test(password)) {
            return;
        }
        if (email_id === "") {
            alert("Please enter E-Mail Address");
            return;
        }
        if (first_name === "") {
            alert("Please enter First Name");
            return;
        }
        if (last_name === "") {
            alert("Please enter Last Name");
            return;
        }
        if (user_name === "") {
            alert("Please enter User Name");
            return;
        }
        if (mobile === "") {
            alert("Please enter 10 digits Mobile Number");
            return;
        }
        if (!validateMobileNumber(mobile)) {
            alert("Please enter valid Mobile Number");
            return;
        }
        if (password === "") {
            alert("Please Enter a Password");
            return;
        }
        if (user_type === "") {
            alert("Please Select User Type");
            return;
        }

        generatePassword({
            variables: {
                password: password,
            },
        });
    };

    return (
        <section className="create-user__container w-full">
            <Typography className="title">Create User</Typography>
            <br />
            <div className="create-user">
                <Grid container justifyContent="space-between" mb="25px">
                    <div className="input-container">
                        <label for="email">Email Adddress*</label>
                        <OutlinedInput
                            type="email"
                            id="email"
                            name="email_id"
                            value={email_id}
                            onChange={handleChange}
                            placeholder="Enter Email"
                            className="input"
                        />
                        <FormHelperText error id="accountId-error">
                            {users && users.filter((item) => email_id !== "" && item.email_id === email_id).length > 0 && (
                                <small className="text-danger">User with this email already exist.</small>
                            )}
                            {email_id && !email_pattern.test(email_id) && <small className="text-danger">Please enter valid E-Mail Address</small>}
                        </FormHelperText>
                    </div>
                    <div className="input-container">
                        <label for="userName">First Name *</label>
                        <OutlinedInput
                            type="text"
                            id="firstName"
                            name="first_name"
                            value={first_name}
                            onChange={handleChange}
                            placeholder="Enter First Name"
                            className="input"
                        />
                    </div>
                </Grid>

                <Grid container justifyContent="space-between" mb="25px">
                    <div className="input-container">
                        <label for="userName">Last Name *</label>
                        <OutlinedInput
                            type="text"
                            id="lastName"
                            name="last_name"
                            value={last_name}
                            onChange={handleChange}
                            placeholder="Enter Last Name"
                            className="input"
                        />
                    </div>
                    <div className="input-container">
                        <label for="userName">User Name *</label>
                        <OutlinedInput
                            type="text"
                            id="userName"
                            name="user_name"
                            value={user_name}
                            onChange={handleChange}
                            placeholder="Enter User Name"
                            className="input"
                        />
                        <FormHelperText error id="accountId-error">
                            {user_name && !user_name.match(username_pattern) && (
                                <small className="text-danger">Username is invalid. Only Numbers, Letters and Underscore are allowed</small>
                            )}
                            {users && users.filter((item) => user_name !== "" && item.user_name === user_name).length > 0 && (
                                <small className="text-danger">Username already exist.</small>
                            )}
                        </FormHelperText>
                    </div>
                </Grid>

                <Grid container justifyContent="space-between" mb="25px">
                    <div className="input-container">
                        <label for="userName">Mobile No. *</label>
                        <OutlinedInput
                            type="number"
                            id="mobileNo"
                            name="mobile"
                            value={mobile}
                            onChange={handleChange}
                            placeholder="Enter 10 digits Mobile Number"
                            className="input"
                        />
                    </div>
                    <div className="input-container">
                        <label for="userName">Password. *</label>
                        <OutlinedInput
                            type={showPassword ? "text" : "password"}
                            id="password"
                            name="password"
                            value={password}
                            onChange={handleChange}
                            placeholder="Enter Password"
                            className="input"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <FormHelperText error id="accountId-error">
                            {password && !password_pattern.test(password) && (
                                <small className="text-danger">
                                    Password must be at least 8 characters long, contain at least one number and at least one special character
                                </small>
                            )}
                        </FormHelperText>
                    </div>
                </Grid>

                <Grid container justifyContent="space-between" mb="50px">
                    <div className="input-container">
                        <label for="userName">User Type *</label>
                        <FormControl sx={{ width: "100%" }} className="usertype__container">
                            <Select
                                value={user_type}
                                name="user_type"
                                onChange={handleChange}
                                className="modal-select-input"
                                placeholder="select user type"
                            >
                                <MenuItem value={"Individual"}>Individual</MenuItem>
                                <MenuItem value={"CSR foundation"}>CSR foundation</MenuItem>
                                <MenuItem value={"Non-Profit Organization"}>Non-Profit Organization</MenuItem>
                                <MenuItem value={"Subject Matter Expert"}>Subject Matter Expert</MenuItem>
                                <MenuItem value={"Pro-Bono"}>Pro-Bono</MenuItem>
                                <MenuItem value={"Public Sector Bank"}>Public Sector Bank</MenuItem>
                                <MenuItem value={"Member of Parliament"}>Member of Parliament</MenuItem>
                                <MenuItem value={"Member of Legislative Assembly"}>Member of Legislative Assembly</MenuItem>
                                <MenuItem value={"Students"}>Student</MenuItem>
                                <MenuItem value={"Others"}>Others</MenuItem>
                            </Select>
                            {user_type === "Others" && (
                                <p className="others-textfield__container">
                                    <span> Others</span>
                                    <input type="text" name="Others" value={otherUserType} placeholder="mention here" onChange={handleChange} />
                                </p>
                            )}
                        </FormControl>
                    </div>
                </Grid>

                <button type="submit" className="create-btn" onClick={createUser}>
                    {loading ? "Loading" : "Create"}
                </button>
            </div>
        </section>
    );
};

export default CreateUser;
