import React from "react";
import { Grid, Typography } from "@mui/material";
import SocialPartnerCard from "./components/SocialPartnerCard";
import "./SocialPartners.styles.scss";

const SocialPartners = () => {
    const cardsCount = new Array(10).fill(0);
    return (
        <div className="social_partners_container">
            <Grid container item xs={12} direction="column" justifyContent="center" alignItems="center" className="social_partners_header">
                <Typography>Home &gt;&gt; Social Partners</Typography>
                <Typography variant="h1"> Social Partners </Typography>
                <Typography>Social value Partners are Companies / Organizations / People who are supporting the Social Projects </Typography>
            </Grid>
            <Grid container item xs={12} className="social_partners_cards_wrapper" justifyContent="center" spacing={5}>
                {cardsCount.map((index) => (
                    <Grid key={index} item className="social_partners_card_item">
                        <SocialPartnerCard />
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export default SocialPartners;
