// validation and imports
import { useEffect, useState, Fragment } from "react";
import { Spinner } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { useResetPasswordMutation } from "types/generated-types.ts";
import { validatePassword } from "utils";
import { useSearchParams } from "react-router-dom";
import "./ResetPasswordVerification.styles.scss";

const ResetPasswordVerification = ({ isAdmin }) => {
    const [searchParams] = useSearchParams();
    const phoneNumber = searchParams.get("phone_number");
    const otp = searchParams.get("otp");
    console.log({ phoneNumber, otp });
    const [passwordError, setPasswordError] = useState("");
    const [apiStatus, setApiStatus] = useState(true);
    const navigate = useNavigate();

    // let { token } = useParams();
    const [{ password, cpassword }, setValue] = useState({
        password: "",
        cpassword: "",
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValue((prev) => ({ ...prev, [name]: value }));
    };
    const [resetPassword] = useResetPasswordMutation({
        onCompleted: (data) => {
            setApiStatus(true);
            toast.dismiss();
            toast.success("Password reset Successfully. Login to continue");
            navigate("/login");
        },
        onError: (error) => {
            setApiStatus(true);
            toast.dismiss();
            toast.error(error.message);
        },
    });
    const [token, setToken] = useState(null);

    console.log({ phoneNumber, otp, token });
    const reset = (e) => {
        setApiStatus(false);
        e.preventDefault();
        setPasswordError("");
        const passwordCheck = validatePassword(password, cpassword);

        if (!passwordCheck.status) {
            setPasswordError(passwordCheck.message);
            setApiStatus(true);
            return;
        }
        resetPassword({
            variables: {
                password,
                otp: otp,
                phone_number: phoneNumber,
            },
        });
    };

    // initial setup and checks
    const search = useLocation().search;
    useEffect(() => {
        document.title = `EquiPPP - Reset Password`;
        setToken(() => new URLSearchParams(search).get("token"));
    }, []);

    return (
        <section className="ResetPasswordVerification w-full">
            <main className="reset-card">
                <header className="header">
                    <h1>RESET PASSWORD</h1>
                </header>

                <form onSubmit={reset} className="body">
                    <Fragment>
                        <section className="body-row">
                            <label>
                                <div className="label">New Password</div>
                                <input
                                    type="password"
                                    name="password"
                                    id="password"
                                    value={password}
                                    onChange={handleChange}
                                    autoComplete="off"
                                    className="border rounded mt-2"
                                />
                            </label>
                        </section>
                        <section className="body-row">
                            <label>
                                <div className="label">Confirm Password</div>
                                <input
                                    type="password"
                                    name="cpassword"
                                    id="cpassword"
                                    value={cpassword}
                                    onChange={handleChange}
                                    autoComplete="off"
                                    className="border rounded mt-2"
                                />
                            </label>
                            <small className="text-danger">{passwordError || <Fragment>&nbsp;</Fragment>}</small>
                        </section>
                        <hr />
                        <section className="submit-section">
                            <button type="submit" disabled={!apiStatus}>
                                {apiStatus ? "Submit" : <Spinner animation="border" variant="light" size="sm" />}
                            </button>
                        </section>
                    </Fragment>
                </form>
            </main>
        </section>
    );
};

export default ResetPasswordVerification;

ResetPasswordVerification.propTypes = {
    isAdmin: PropTypes.bool,
};
