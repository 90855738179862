import React from "react";
import { components } from "react-select";

const DropDownSingleValue = ({ children, ...props }) => {
    return (
        <components.SingleValue {...props}>
            <div className="z-1000 flex flex-grow flex-row items-center">
                {typeof props?.data?.icon === "string" ? (
                    <img src={props.data.icon} alt="#" className="mr-2 h-4 w-4 rounded-full" />
                ) : (
                    props?.data?.icon
                )}
                {children}
            </div>
        </components.SingleValue>
    );
};

export default DropDownSingleValue;
