import React, { useEffect, useState, Fragment, useContext } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate, useLocation, Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonIcon from "@mui/icons-material/Person";
import TextsmsIcon from "@mui/icons-material/Textsms";
import { Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grid from "@mui/material/Grid";
import Popper from "@mui/material/Popper";
import { useGetCurrentUserLazyQuery } from "types/generated-types.ts";
import { PopupModals } from "components/PopupModals/PopupModals";
import ROUTES from "constants/routes";
import AuthUserContext from "../../contexts/session";
import "./MainNavbar.styles.scss";
import { Navigate } from "react-router-dom";
import { useGetRegionAdminQuery } from "types/generated-types.ts";

const MainNavbar = (props) => {
    const { authUser } = useContext(AuthUserContext);
    const userName = authUser && authUser.name;
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const location = useLocation();
    const [getUsers, { data: userData, loading: isLoading, error: dataEr }] = useGetCurrentUserLazyQuery();
    useEffect(() => {
        if (authUser && authUser.id) {
            getUsers({
                variables: {
                    id: authUser.id,
                },
            });
        }
    }, [authUser, getUsers]);
    const isHome = location.pathname === ROUTES.HOME;

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popper" : undefined;
    const [isScrolled, setScrolled] = useState(() => false);

    const { data: regionAdminData } = useGetRegionAdminQuery({});

    useEffect(() => {
        /**
         * Function which returns false if the page is scrolled to top.
         * Returns false if the page is scrolled down.
         * @param {object} e Event object to catch scroll position of the document
         */
        const onScroll = (e) => {
            setScrolled(!!e.target.documentElement.scrollTop);
        };
        window.addEventListener("scroll", onScroll);

        // remove the scroll event listener if the page is destroyed
        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    const [isModalOpen, setModal] = useState(() => false);
    /**
     * Watch for change in state of Authentication modal.
     * The value of `isModalOpen` changes to desired modal name whenever the modal changes its state.
     * The modal is hidden if no parameter value is given.
     * @param {string} value The name of the modal to be triggered
     * @author DK
     */
    const toggleAuthModal = (value) => {
        if (value) setModal(value);
        else setModal(false);
    };
    function capitalizeFirstLetter(string) {
        return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
    }

    /**
     * Check if user is logged in.
     * If so, set isLoggedIn state to `true`, else `false`.
     */
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const adminAccessToken = window.localStorage.getItem("admin_access_token");
        const authToken = window.localStorage.getItem("authToken");
        setIsLoggedIn(!!adminAccessToken || !!authToken);
        if (typeof props.loginStatus === "function") props.loginStatus(!!adminAccessToken || !!authToken);
    }, []);

    const logout = () => {
        const authToken = window.localStorage.getItem("authToken");
        if (!authToken) return setIsLoggedIn(false);

        localStorage.clear();
        navigate("/login");
        setIsLoggedIn(false);
    };

    const isProfilePage = location.pathname.includes("/profile-details");

    // if (!authUser) return <Navigate to="/login" replace />;

    const handleMyActivityClick = () => {
        navigate(`/my-activity?id=${authUser?.id}`);
    };

    const userTypeName = userData?.users_by_pk?.user_type_details ? JSON.parse(userData?.users_by_pk?.user_type_details) : {};
    const name = userTypeName ? userTypeName.name : "No name";
    const nameWithSpace = capitalizeFirstLetter(userData?.users_by_pk?.first_name) + " " + capitalizeFirstLetter(userData?.users_by_pk?.last_name);

    const handleNavigate = () => {
        if (userData?.users_by_pk?.role === "admin") {
            navigate(`/profile-details?id=${authUser.id}`);
        } else {
            navigate(`/profile-details?id=${regionAdminData?.users[0].id}`);
        }
    };

    return (
        <AppBar position="static" elevation={0} className="app-bar__container " style={{ backgroundColor: "transparent" }}>
            <Grid
                container
                justifyContent="space-between"
                alignItems="stretch"
                className={`navbar-container `}
                style={{
                    "--bg-color": props.bgColor || "transparent",
                    "--link-color": props.bgColor === "#fff" ? "#333" : "#fff",
                }}
            >
                <Grid item xs={true} container alignItems="center">
                    <NavLink to={`/profile-details?id=${regionAdminData?.users[0].id}`}>
                        <img src="/assets/images/equippp-3.png" alt="Equipp" className="mr-6 w-12 h-12" />
                    </NavLink>
                    {authUser?.role === "admin" && (
                        <React.Fragment>
                            {/* <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                onClick={handleNavigate}
                                className={`nav-item-wrapper ${location.pathname.includes("/") ? "active" : "none"}`}
                            >
                                <Typography className="nav-item">Home</Typography>
                            </Grid> */}
                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                className={`nav-item-wrapper ${location.pathname.includes("/admin-dashboard") ? "active" : "none"}`}
                            >
                                <NavLink to="/admin">
                                    <Typography className="nav-item">Dashboard</Typography>
                                </NavLink>
                            </Grid>
                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                className={`nav-item-wrapper ${location.pathname.includes("/projects") ? "active" : "none"}`}
                            >
                                <NavLink to="/projects">
                                    <Typography className="nav-item">Projects</Typography>
                                </NavLink>
                            </Grid>
                            {/* <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                className={`nav-item-wrapper ${location.pathname.includes("/feed") ? "active" : "none"}`}
                            >
                                <NavLink to="/feed">
                                    <Typography className="nav-item">Feed</Typography>
                                </NavLink>
                            </Grid> */}
                        </React.Fragment>
                    )}

                    {/* <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        className={`nav-item-wrapper ${location.pathname.includes("/socialpartners") ? "active" : "none"}`}
                    >
                        <NavLink to="#">
                            <Typography className="nav-item">Social Partners</Typography>
                        </NavLink>
                    </Grid> */}
                    {/* <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        className={`nav-item-wrapper ${location.pathname.includes("/feedback") ? "active" : "none"}`}
                    >
                        <NavLink to="#">
                            <Typography className="nav-item">Feedback</Typography>
                        </NavLink>
                    </Grid> */}
                    {/* <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        className={`nav-item-wrapper ${location.pathname.includes("/faqs") ? "active" : "none"}`}
                    >
                        <NavLink to="/faqs">
                            <Typography className="nav-item">FAQs</Typography>
                        </NavLink>
                    </Grid> */}
                    {/* <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        className={`nav-item-wrapper ${location.pathname.includes("/media") ? "active" : "none"}`}
                    >
                        <NavLink to="#">
                            <Typography className="nav-item">Media</Typography>
                        </NavLink>
                    </Grid> */}

                    {/* <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        className={`nav-item-wrapper ${location.pathname.includes("/about") ? "active" : "none"}`}
                    >
                        <Link to="/#about">
                            <Typography className="nav-item">About</Typography>
                        </Link>
                    </Grid> */}
                </Grid>
                <div className="flex justify-end items-center">
                    {isLoggedIn ? (
                        <Fragment>
                            {!isHome && (
                                <Fragment>
                                    {!isProfilePage && (
                                        <Fragment>
                                            <p className="text-sm text-[#434343] font-semibold mr-4 cursor-pointer" onClick={handleNavigate}>
                                                <span className="flex items-center">
                                                    <img src="/assets/images/home-icon.png" alt="home-icon" className="w-6 h-6 mr-1" />
                                                </span>
                                            </p>
                                        </Fragment>
                                    )}
                                    <p
                                        className="text-sm text-[#434343] font-semibold mr-4 cursor-pointer"
                                        onClick={() => {
                                            navigate("/suggest-project");
                                        }}
                                    >
                                        Suggest Project
                                    </p>

                                    {authUser?.role !== "admin" && (
                                        <p className="text-sm text-[#434343] font-semibold mr-4 cursor-pointer" onClick={handleMyActivityClick}>
                                            My Activity
                                        </p>
                                    )}
                                    <Typography className="username cursor-pointer" onClick={handleNavigate}>
                                        {capitalizeFirstLetter(userData?.users_by_pk?.user_name || userData?.users_by_pk?.name || userName || name)}
                                    </Typography>
                                </Fragment>
                            )}

                            <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                                <div>
                                    <Grid container alignItems="center">
                                        <Avatar
                                            variant="rounded"
                                            src={userData?.users_by_pk?.profile_picture}
                                            onClick={handleClick}
                                            alt="Profile Picture"
                                        />
                                        <KeyboardArrowDown className="down-arrow" onClick={handleClick} />
                                    </Grid>
                                    <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
                                        <Box sx={{ bgcolor: "background.paper" }} className="options-popper">
                                            <Grid
                                                item
                                                xs={12}
                                                container
                                                alignItems="center"
                                                className="popper-item"
                                                onClick={() => navigate("/profile")}
                                            >
                                                <EditIcon className="popper-icon" />
                                                <Typography>Edit Profile</Typography>
                                            </Grid>
                                            {userData?.users_by_pk?.role !== "admin" && (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    container
                                                    alignItems="center"
                                                    className="popper-item"
                                                    onClick={() => navigate(`/profile-details?id=${regionAdminData?.users[0].id}`)}
                                                >
                                                    <PersonIcon className="popper-icon" />
                                                    <Typography>View Admins Profile</Typography>
                                                </Grid>
                                            )}

                                            {/* {userData?.users_by_pk?.role === "admin" && (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    container
                                                    alignItems="center"
                                                    className="popper-item"
                                                    onClick={() => navigate(`/profile-details?id=${authUser.id}`)}
                                                >
                                                    <PersonIcon className="popper-icon" />
                                                    <Typography>My Profile</Typography>
                                                </Grid>
                                            )} */}

                                            <Grid item xs={12} container alignItems="center" className="popper-item" onClick={logout}>
                                                <LogoutIcon className="popper-icon" />
                                                <Typography>Logout</Typography>
                                            </Grid>
                                        </Box>
                                    </Popper>
                                </div>
                            </ClickAwayListener>
                            {/* <NotificationsIcon className="nav-icon notification-icon" />
                            <TextsmsIcon className="nav-icon" /> */}
                        </Fragment>
                    ) : (
                        <Grid container onClick={() => navigate("/login")} style={{ cursor: "pointer" }}>
                            <PersonIcon className="nav-icon" />
                            <Typography fontSize={"15px"} className="text-black">
                                Login/Signup
                            </Typography>
                        </Grid>
                    )}
                </div>
            </Grid>
            <PopupModals isModalOpen={isModalOpen} setModal={setModal} isAdmin={props.isAdmin} toggle={props.toggle} />
        </AppBar>
    );
};

export default MainNavbar;
