import { useState, useEffect, Fragment } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import Grid from "@mui/material/Grid";
import SideNavBar from "./SideNavBar";
import "./HomeAdmin.scss";

const HomeAdmin = () => {
    const [tab, setTab] = useState("dashboard");
    const history = useNavigate();

    const changeNav = (tabName) => {
        setTab(tabName);
    };

    useEffect(() => {
        const accessToken = window.localStorage.getItem("authToken");
        if (!accessToken) {
            history("/admin");
        }
    }, []);

    return (
        <Fragment>
            <div container className="flex h-full ">
                <div className="w-[20%] h-full hidden sm:block pt-2.5">
                    <SideNavBar />
                </div>
                <div className="main_container bg-[#f9fafc] w-full h-full sm:min-w-0 px-4 py-4">
                    <Outlet />
                </div>
            </div>
        </Fragment>
    );
};

export default HomeAdmin;
