import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../components/ui/select";
import { useUser } from "contexts/user-type/user-type-context.js";
const Students = () => {
    const navigate = useNavigate();
    const { state, setState, registerUser } = useUser();
    const { userType, studentState } = state;

    let { studentName, studentGender, studentCourse, studentInstitutionName, studentInstitutionAddress, studentContact } = studentState;

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            studentState: {
                ...prevState.studentState,
                [name]: value,
            },
        }));
    };

    const handleSelectChange = (value) => {
        setState((prevState) => ({
            ...prevState,
            studentState: {
                ...prevState.studentState,
                studentGender: value,
            },
        }));
    };

    const handleForwardClick = () => {
        if (!studentName || !studentGender || !studentCourse || !studentInstitutionName || !studentInstitutionAddress || !studentContact) {
            return alert("please fill all Student userType details");
        }
        registerUser();
    };
    return (
        <div className="flex h-full w-full justify-center items-center my-auto flex-col bg-[#F9F9F9] md:px-0 px-6">
            <div className="flex justify-center flex-col mb-10 md:mb-4">
                <p className="text-[#0D0140] font-bold text-2xl md:text-3xl text-center">Create Account as {userType}</p>
                <p className="text-[#0D0140] text-center font-thin text-base mt-2">Please fill the below options to continue</p>
            </div>
            <form className="w-full md:w-1/3">
                {/* <div className="mb-4 ">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2" for="username">
                        Email
                    </label>
                    <input
                        className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
                        type="email"
                        placeholder="Brandonelouis@gmail.com"
                        disabled
                    />
                </div> */}
                <div className="mb-4 ">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2" for="username">
                        Full Name
                    </label>
                    <input
                        className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
                        id="username"
                        name="studentName"
                        value={studentName}
                        onChange={handleInputChange}
                        type="text"
                        placeholder="Brandone Louis"
                    />
                </div>
                <div className="mb-4 ">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2" for="username">
                        Gender
                    </label>
                    <Select className="modal-select-input" value={studentGender || ""} onValueChange={handleSelectChange}>
                        <SelectTrigger aria-label="User Type" className="border-0">
                            <SelectValue placeholder="Select Gender" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="female">Female</SelectItem>
                            <SelectItem value="male">Male</SelectItem>
                            <SelectItem value="other">Other</SelectItem>
                        </SelectContent>
                    </Select>
                </div>
                <div className="mb-4 ">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2" for="username">
                        Course
                    </label>
                    <input
                        className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
                        type="text"
                        name="studentCourse"
                        value={studentCourse}
                        onChange={handleInputChange}
                        placeholder="Course"
                    />
                </div>
                <div className="mb-4 ">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2" for="username">
                        Institution Name
                    </label>
                    <input
                        className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
                        type="text"
                        name="studentInstitutionName"
                        value={studentInstitutionName}
                        onChange={handleInputChange}
                        placeholder="Institution Name"
                    />
                </div>
                <div className="mb-4 ">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2">Institution Address</label>
                    <input
                        className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
                        type="text"
                        name="studentInstitutionAddress"
                        value={studentInstitutionAddress}
                        onChange={handleInputChange}
                        placeholder="Institution Address"
                    />
                </div>
                <div className="mb-4 ">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2">Contact</label>
                    <input
                        className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
                        type="text"
                        name="studentContact"
                        value={studentContact}
                        onChange={handleInputChange}
                        placeholder="Contact"
                    />
                </div>

                <div className="flex flex-col items-center w-full px-8 ">
                    <button
                        className="bg-[#130160] w-full text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="button"
                        onClick={handleForwardClick}
                    >
                        CONTINUE
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Students;
