import { useState, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useUser } from "contexts/user-type/user-type-context.js";
import { Eye, EyeOff } from "lucide-react";
import { toast } from "react-toastify";
import { useLoginMutation, useResendVerificationEmailMutation, useResendOtpMutation } from "types/generated-types.ts";
import { validateLoginEmail, validateLoginPassword, validateLoginPhoneNumber } from "utils";

import { useGetRegionAdminQuery } from "types/generated-types.ts";
import { WindowSharp } from "@mui/icons-material";

const MobileLogin = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loginErrorMsg, setloginErrorMsg] = useState(() => "");
    const [emailError, setEmailError] = useState(() => "");
    const [phoneNumberError, setPhoneNumberError] = useState(() => "");
    const [passwordError, setPasswordError] = useState(() => "");
    const [showPassword, setShowPassword] = useState(false);
    const [isSendVerifyLink, setIsSendVerifyLink] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const { state, setState, registerUser } = useUser();

    const { phoneNumber: otpPhoneNumber } = state;

    const [resendOtp] = useResendOtpMutation({
        onCompleted: (data) => {
            if (data && data.resendOtp) {
                toast.dismiss();
                toast.success("Otp Sent Successfully");
            }
        },
    });

    const handleChangeEmail = (e) => setEmail(e.target.value);
    const handleChangePassword = (e) => setPassword(e.target.value);

    const { data: regionAdminData } = useGetRegionAdminQuery();

    console.log(window.location.hostname, "window.location.hostname");

    console.log(regionAdminData, "regionData");

    const [login, { loading, error }] = useLoginMutation({
        onCompleted: (data) => {
            if (data && data?.login) {
                localStorage.setItem("authToken", data.login);
                // props.setOpen(props.nextModal ? props.nextModal : "login-success");
                // navigate("/");

                const redirectLink = localStorage.getItem("redirectLink");

                console.log({ redirectLink });

                if (redirectLink) {
                    navigate(redirectLink);
                    console.log("hello");
                    localStorage.removeItem("redirectLink");
                } else {
                    if (
                        window.location.hostname !== "localhost" &&
                        window.location.hostname !== "13.234.93.221" &&
                        window.location.hostname !== "www.equippp.global"
                    ) {
                        if (regionAdminData && regionAdminData.users.length > 0) {
                            const userId = regionAdminData.users[0].id;
                            navigate(`/profile-details?id=${userId}`);
                        } else {
                            const userId = regionAdminData.users[0].id;
                            navigate(`/profile-details?id=${userId}`);
                        }
                    } else {
                        const userId = regionAdminData.users[0].id;
                        navigate(`/profile-details?id=${userId}`);
                    }
                }
            }
        },
    });

    useMemo(() => {
        if (error) {
            const { graphQLErrors } = error;
            console.log("graphQLErrors", graphQLErrors);
            if (graphQLErrors) {
                setloginErrorMsg(graphQLErrors?.[0]?.message);
                if (graphQLErrors[0].message === "Email not verified, please verify and continue") {
                    setIsSendVerifyLink(true);
                } else if (graphQLErrors[0].message === "Phone number not verified, please verify and continue.") {
                    setIsSendVerifyLink(true);
                } else {
                    setIsSendVerifyLink(false);
                }
            }
        }
    }, [error]);

    const [resendVerifyEmail] = useResendVerificationEmailMutation({
        onCompleted: (data) => {
            if (data && data.resendVerificationEmail) {
                toast.dismiss();
                toast.success("Mail Sent Successfully");
                // props.setOpen(false);
            }
        },
    });

    const handleResendVerificationEmail = () => {
        toast.loading("Sending Email, please wait...");
        resendVerifyEmail({
            variables: {
                email: email,
            },
        });
    };

    const handleResendOtp = () => {
        toast.loading("Sending Email, please wait...");
        resendOtp({
            variables: {
                phone_number: phoneNumber,
            },
        });
        navigate(`/verify-otp?phone_number=${phoneNumber}`);
    };

    const loginUser = (e) => {
        e.preventDefault();
        setloginErrorMsg("");
        setEmailError("");
        setPasswordError("");
        setPhoneNumberError("");

        // const checkEmail = validateLoginEmail(e.target["email"].value);
        const checkPassword = validateLoginPassword(e.target["password"].value);
        const checkPhoneNumber = validateLoginPhoneNumber(phoneNumber);

        // if (!checkEmail.status) setEmailError(checkEmail.message);
        if (!checkPassword.status) setPasswordError(checkPassword.message);
        if (!checkPhoneNumber.status) setPhoneNumberError(checkPhoneNumber.message);

        console.log({ phoneNumber, checkPhoneNumber });

        if (checkPhoneNumber && checkPassword.status) {
            login({
                variables: {
                    phone_number: phoneNumber,
                    password,
                },
            });
        }
    };

    console.log("loginErrorMsg", loginErrorMsg);

    return (
        <div className="flex h-full w-full justify-center items-center my-auto flex-col bg-[#F9F9F9] md:px-0 px-6">
            <div className="flex justify-center mb-10">
                <p className="text-[#0D0140] font-bold text-3xl">Welcome Back</p>
            </div>
            <form onSubmit={loginUser} className="w-full md:w-1/3">
                {/* <div className="mb-4">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2" htmlFor="email">
                        Email
                    </label>
                    <input
                        className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
                        id="email"
                        type="text"
                        value={email}
                        onChange={handleChangeEmail}
                        placeholder="Enter your email"
                    />
                    {emailError && <p className="text-red-500 text-xs italic">{emailError}</p>}
                </div> */}

                <div className="mb-4">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2" htmlFor="email">
                        Enter your mobile number
                    </label>
                    <input
                        className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
                        id="phone_number"
                        type="text"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        placeholder="Enter your mobile number"
                    />
                    {phoneNumberError && <p className="text-red-500 text-xs italic">{phoneNumberError}</p>}
                </div>

                <div className="mb-6 relative">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2" htmlFor="password">
                        Password
                    </label>
                    <input
                        className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
                        id="password"
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={handleChangePassword}
                        placeholder="Enter your password"
                    />
                    <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                        className="absolute   flex items-center text-sm right-3 top-[55%] "
                    >
                        {showPassword ? <EyeOff className="h-6 w-6 text-[#60778C]" /> : <Eye className="h-6 w-6 text-[#60778C]" />}
                    </button>
                    {passwordError && <p className="text-red-500 text-xs italic">{passwordError}</p>}
                </div>
                <div class="flex items-center justify-between mb-4">
                    <div className="items-center flex">
                        <input id="default-checkbox" type="checkbox" value="" class="w-4 h-4 bg-[#E6E1FF] border-0-0" />
                        <label for="default-checkbox" className="ml-2 text-sm  text-[#AAA6B9] ">
                            Remember me
                        </label>
                    </div>
                    <div className="flex items-center">
                        <p
                            className="inline-block align-baseline cursor-pointer font-semibold text-sm text-[#0D0140] hover:text-[#E6E1FF]"
                            onClick={() => navigate("/forgot-password")}
                        >
                            Forgot Password?
                        </p>
                    </div>
                </div>

                {loginErrorMsg && (
                    <div className="flex justify-center mb-4 text-red-500">
                        <p>{loginErrorMsg}</p>
                        {isSendVerifyLink && (
                            <p onClick={handleResendOtp} className="underline underline-offset-4 ml-1 cursor-pointer text-blue-500">
                                Send otp Again ?
                            </p>
                        )}
                    </div>
                )}

                <div className="flex flex-col items-center w-full px-8 ">
                    <button
                        className="bg-[#130160] w-full text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="submit"
                        disabled={loading}
                    >
                        {loading ? "Logging in..." : "LOGIN"}
                    </button>
                </div>
            </form>

            <p className="text-center text-gray-500 text-xs mt-6">
                You don't have an account yet?{"  "}
                <span className="underline underline-offset-2 text-[#FF9228] cursor-pointer" onClick={() => navigate("/signup")}>
                    Sign up
                </span>
            </p>
        </div>
    );
};

export default MobileLogin;
