import { React, useEffect, useState, Fragment } from "react";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import verified from "../../../../../assets/user-verified.png";
import "./ApprovedKyc.styles.scss";

const ApprovedKyc = ({ refetch, kycDetails }) => {
    const [accessToken, setAccessToken] = useState(false);
    const [tableRows, setTableRows] = useState([]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (event, newPage) => {
        console.log(newPage);
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        const token = window.localStorage.getItem("authToken");
        setAccessToken(token);
        const rows = kycDetails
            ? kycDetails.map((el) => {
                  return {
                      user_name: el.user_name,
                      email_id: el.email_id,
                      kyc_status: el.kyc_status,
                      kyc_submission_date: el.kyc_submission_date,
                      file: (
                          <Button
                              className="kyc__file-name"
                              onClick={() => {
                                  window.open(el.kyc_url, "_blank", "noopener,noreferrer");
                              }}
                          >
                              View File
                          </Button>
                      ),
                      status: el.kyc_status,
                  };
              })
            : [];
        setTableRows(rows);
    }, [kycDetails]);

    return (
        <Fragment>
            <Typography className="title">Users KYC</Typography>
            <br />
            {!!accessToken ? (
                <div className="exp-table">
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>User Name</TableCell>
                                    <TableCell>User Email</TableCell>
                                    <TableCell>Kyc Status</TableCell>
                                    <TableCell>Submission Date</TableCell>
                                    <TableCell>File</TableCell>
                                    <TableCell>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableRows &&
                                    tableRows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                        <TableRow key={row.user_name}>
                                            <TableCell>
                                                {row.user_name}
                                                {row.kyc_status === "accepted" && (
                                                    <Tooltip title="Verified" arrow placement="top">
                                                        <img src={verified} alt="" width={"20px"} height={"20px"} style={{ marginLeft: "2px" }} />
                                                    </Tooltip>
                                                )}
                                            </TableCell>
                                            <TableCell>{row.email_id}</TableCell>
                                            <TableCell>{row.kyc_status}</TableCell>
                                            <TableCell>{row.kyc_submission_date?.substr(0, 10).split("-").reverse().join("-") || "NA"}</TableCell>
                                            <TableCell>{row.file}</TableCell>
                                            <TableCell>{row.status}</TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {tableRows && tableRows.length > 0 && (
                        <TablePagination
                            component="div"
                            count={tableRows?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            rowsPerPageOptions={[5, 10]}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    )}
                </div>
            ) : (
                <Typography>Not logged in</Typography>
            )}
        </Fragment>
    );
};

export default ApprovedKyc;
