import { toast } from "react-toastify";
import axios from "axios";

const uploadFile = async ({ uploadParams, file }) => {
    const { url, fields } = JSON.parse(uploadParams);

    let formData = new FormData();

    Object.keys(fields).forEach((key) => {
        formData.append(key, fields[key]);
    });

    formData.append("file", file);

    const result = await axios.post(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
    toast.dismiss();
    if (result) {
        toast.success("File uploaded successfully, please click on save to update");
    }
};

export default uploadFile;
