import mergeArraysInPagination from "./mergeArraysInPagination";
import mergePaginationWithSingleKeyInApollo from "./mergePaginationWithSingleKeyInApollo";
import uploadFile from "./uploadFile";

export { uploadFile, mergePaginationWithSingleKeyInApollo, mergeArraysInPagination };

/**
 * Validates a string of email if it can be sent to the server
 * @param {string} email Email ID of the user
 * @returns {{status: boolean, message: string}} Status of validation
 * @author DK
 */
export const validateEmail = (email = "") => {
    if (email === "")
        return {
            status: false,
            message: "Email cannot be empty",
        };

    const emailPattern = /^([a-zA-Z0-9.-]+)[@]([a-zA-Z0-9]+([.][a-zA-Z]{2,})+)$/;
    if (!emailPattern.test(email))
        return {
            status: false,
            message: "Email should be in the correct format",
        };

    return {
        status: true,
        message: "",
    };
};

export const validatePhoneNumber = (phoneNumber) => {
    if (phoneNumber === "") {
        return { status: false, message: "Phone number cannot be empty" };
    }
    const pattern = /^\d{10}$/;
    if (!phoneNumber.match(pattern)) {
        return { status: false, message: "Invalid phone number format." };
    }
    return { status: true, message: "" };
};

/**
 * Checks if the password fields from the user are valid
 * @param {string} password Value of password text field
 * @param {string} password2 Value of re-enter password text field
 * @returns {{status: boolean, field: string, message: string}}
 * @author DK
 */
export const validatePassword = (password = "", password2 = "") => {
    if (password === "")
        return {
            status: false,
            field: "password",
            message: "Password cannot be empty",
        };

    if (password2 === "")
        return {
            status: false,
            field: "confirm-password",
            message: "Confirm Password cannot be empty",
        };

    if (password !== password2)
        return {
            status: false,
            field: "confirm-password",
            message: "Passwords do not match",
        };

    const passwordPattern = /^(?=.*[a-z])(?=.*\d)(?=.*[!@#%^&*()_+{}\[\]:;,.?])(?!.*[&%$]).{8,}$/;
    if (!passwordPattern.test(password))
        return {
            status: false,
            field: "password",
            message: "Password must be at least 8 characters long, contain at least one number and at least one special character",
        };

    return {
        status: true,
        field: "",
        message: "",
    };
};

/**
 * Checks if the Email fields from the user is empty or not
 * @param {string} email Value of password text field
 * @returns {{status: boolean, field: string, message: string}}
 * @author Pawan
 */
export const validateLoginEmail = (email = "") => {
    if (email === "")
        return {
            status: false,
            field: "email",
            message: "Email cannot be empty",
        };
    return {
        status: true,
        field: "",
        message: "",
    };
};

/**
 * Checks if the Password fields from the user is empty or not
 * @param {string} password Value of password text field
 * @returns {{status: boolean, field: string, message: string}}
 * @author Pawan
 */
export const validateLoginPassword = (password = "") => {
    if (password === "")
        return {
            status: false,
            field: "password",
            message: "Password cannot be empty",
        };
    return {
        status: true,
        field: "",
        message: "",
    };
};

export const validateLoginPhoneNumber = (phoneNumber) => {
    if (phoneNumber === "") {
        return { status: false, message: "Phone number cannot be empty" };
    }
    const pattern = /^\d{10}$/;
    if (!phoneNumber.match(pattern)) {
        return { status: false, message: "Invalid phone number format." };
    }
    return { status: true, message: "" };
};
