import { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { FormControl, InputLabel, OutlinedInput } from "@mui/material";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useGetUsersQuery, useEditProfileMutation } from "../../../types/generated-types.ts";
import "./ChangeUserRole.styles.scss";
import {
    Dialog,
    DialogPortal,
    DialogOverlay,
    DialogTrigger,
    DialogClose,
    DialogContent,
    DialogHeader,
    DialogFooter,
    DialogTitle,
    DialogDescription,
} from "../../../components/ui/dialog";

const ChangeUserRole = () => {
    const [accessToken, setAccessToken] = useState(false);
    const [tableRows, setTableRows] = useState([]);
    const [usersArray, setUsersArray] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [filterValue, setFilterValue] = useState("");

    const { data: usersData } = useGetUsersQuery();

    useEffect(() => {
        if (usersData) {
            setUsersArray(usersData.users);
            console.log(usersData.users);
        }
    }, [usersData]);

    useEffect(() => {
        console.log(filterValue);
        setUsersArray([]);
        if (searchValue === "" && filterValue === "") {
            if (usersData) {
                setUsersArray(usersData.users);
            }
        } else {
            let filteredUsers = [];
            if (filterValue === "") {
                filteredUsers = usersData.users;
            } else {
                filteredUsers = usersData.users.filter((item) => item.role === filterValue);
            }
            let searchedUsers = filteredUsers.filter(
                (item) =>
                    item.user_name?.toLowerCase().includes(searchValue) ||
                    item.email_id?.toLowerCase().includes(searchValue) ||
                    item.name?.toLowerCase().includes(searchValue)
            );
            setUsersArray(searchedUsers);
        }
    }, [searchValue, filterValue]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [changeRoleMutation] = useEditProfileMutation({
        onCompleted: (data) => {
            toast.success("Role Updated Successfully");
        },
    });
    const changeRole = (userId, role) => {
        changeRoleMutation({
            variables: {
                _set: {
                    role: role,
                },
                id: userId,
            },
        });
    };

    useEffect(() => {
        const token = window.localStorage.getItem("authToken");
        setAccessToken(token);
        const rows =
            usersArray &&
            usersArray.length &&
            usersArray.map((el) => {
                return {
                    id: el.id,
                    userName: el?.user_name || "not found",
                    email: el?.email_id || "not found",
                    role: el.role,
                    inviteLinks: el?.invite_links || [],
                    changeToUser: (
                        <Grid container>
                            <Grid item xs={true}>
                                <Select className="select-field" value={el.role} onChange={(e) => changeRole(el.id, e.target.value)} fullWidth>
                                    <MenuItem value="sme">SME</MenuItem>
                                    <MenuItem value="marquee">Marquee</MenuItem>
                                    <MenuItem value="admin">Admin</MenuItem>
                                    <MenuItem value="user">User</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={1}></Grid>
                        </Grid>
                    ),
                };
            });
        setTableRows(rows);
    }, [usersArray]);

    console.log({ usersArray });

    return (
        <Fragment>
            <Typography className="title">Change User’s Role</Typography>
            <br />
            <Grid container style={{ margin: "1rem 0" }} alignItems="center">
                <Grid item xs={4} style={{ padding: "0 1rem" }}>
                    <OutlinedInput
                        placeholder="Search User by email, name, username"
                        fullWidth
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                    />
                </Grid>
                <Grid item xs={4} style={{ padding: "0 1rem" }}>
                    <FormControl className="filter-select-wrapper" fullWidth>
                        <InputLabel id="filter-label">Select Role</InputLabel>
                        <Select id="filter-select" labelId="filter-label" value={filterValue} onChange={(e) => setFilterValue(e.target.value)}>
                            <MenuItem value={""}>No Filter</MenuItem>
                            <MenuItem value={"admin"}>Admin</MenuItem>
                            {/* <MenuItem value={"sme"}>SME</MenuItem>
                            <MenuItem value={"marquee"}>Marquee</MenuItem> */}
                            <MenuItem value={"user"}>User</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            {!!accessToken ? (
                <Fragment>
                    {usersArray.length <= 0 ? (
                        <h5> No Users Found</h5>
                    ) : (
                        <div className="expTable">
                            <TableContainer className="change-user-role__container">
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>User Name</TableCell>
                                            <TableCell>Email Id</TableCell>
                                            <TableCell>Role</TableCell>
                                            <TableCell>Change User Role</TableCell>
                                            <TableCell>Number of invited users</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tableRows &&
                                            tableRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                                <TableRow key={row.userName}>
                                                    <TableCell>
                                                        <Link to={`/profile-details?id=${row?.id}`}>{row.userName}</Link>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Link to={`/profile-details?id=${row?.id}`}>{row.email}</Link>
                                                    </TableCell>
                                                    <TableCell>{row.role}</TableCell>
                                                    <TableCell>{row.changeToUser}</TableCell>
                                                    <TableCell>
                                                        <Dialog>
                                                            <DialogTrigger>
                                                                <button className="text-white px-4 py-2 flex items-center rounded bg-blue-500 ml-2">
                                                                    <p className="mr-2">{row.inviteLinks?.length}</p>
                                                                    <p>show</p>
                                                                </button>
                                                            </DialogTrigger>
                                                            <DialogContent className="w-90">
                                                                <DialogHeader>
                                                                    <DialogTitle className="mb-4">List Of Users Invited</DialogTitle>
                                                                    <DialogDescription>
                                                                        <div className="flex flex-col  border px-4 py-2 rounded">
                                                                            {row.inviteLinks?.map(({ invited_user }) => {
                                                                                if (invited_user) {
                                                                                    return (
                                                                                        <div className="flex items-center justify-between">
                                                                                            <p>{invited_user.user_name}</p>
                                                                                            <Link to={`/profile-details?id=${invited_user.id}`}>
                                                                                                <button className="text-white px-4 py-2 rounded bg-blue-500 ml-2">
                                                                                                    view profile
                                                                                                </button>
                                                                                            </Link>
                                                                                        </div>
                                                                                    );
                                                                                }
                                                                                return null;
                                                                            })}
                                                                        </div>
                                                                    </DialogDescription>
                                                                </DialogHeader>
                                                            </DialogContent>
                                                        </Dialog>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                                {tableRows && tableRows.length > 0 && (
                                    <TablePagination
                                        component="div"
                                        count={tableRows?.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        rowsPerPageOptions={[5, 10]}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                )}
                            </TableContainer>
                        </div>
                    )}
                </Fragment>
            ) : (
                <Typography>Not logged in</Typography>
            )}
        </Fragment>
    );
};

export default ChangeUserRole;
