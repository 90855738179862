import React from "react";
import { components } from "react-select";

const DropDownControl = ({ children, ...props }) => {
    return (
        <components.Control
            className="!hover:border-gray-200 !cursor-pointer !rounded-md !border !border-gray-200 !text-sm !shadow-none"
            {...props}
            innerProps={{
                ...props.innerProps,
                onMouseDown: (e) => {
                    props.innerProps?.onMouseDown?.(e);
                    props.selectProps?.setMenuIsOpen?.((prev) => {
                        return !prev;
                    });
                },
            }}
        >
            {children}
        </components.Control>
    );
};

export default DropDownControl;
