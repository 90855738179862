import React from "react";
import Grid from "@mui/material/Grid";
import { useGetDashboardValuesQuery } from "types/generated-types.ts";
import Comments from "./components/Comments";
import InfoItem from "./components/InfoItem";
import ProjectStats from "./components/ProjectStats";
import ProjectsCalender from "./components/ProjectsCalender/ProjectsCalender";
import RecentActivity from "./components/RecentActivity";
import { motion as m } from "framer-motion";
import "./Dashboard.scss";

const Dashboard = () => {
    const { data } = useGetDashboardValuesQuery();
    return (
        <m.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.75, ease: "easeInOut" }}
            container
            alignItems="center"
            justifyContent="center"
            rowSpacing={2}
            className="w-full gap-3 flex-col md:flex-row flex"
        >
            <div className="gap-3 grid grid-cols-1 flex-grow">
                <div className="flex gap-3 w-full">
                    {/* <Grid item xs={4} container alignItems="center">
                        <InfoItem
                            title="Pending Users"
                            icon="user-circle"
                            theme="blue"
                            value={data?.pending_users_count?.aggregate?.count}
                            showInfo={true}
                        />
                    </Grid> */}
                    <div className="w-1/2">
                        <InfoItem
                            title="Pending Projects"
                            icon="find-in-page"
                            theme="orange"
                            value={data?.pending_project_count?.aggregate?.count}
                            showInfo={true}
                        />
                    </div>
                    <div className="w-1/2">
                        <InfoItem
                            title="Projects Status"
                            icon="find-in-page"
                            theme="red"
                            activeProjects={data?.active_project_count?.aggregate?.count}
                            inactiveProjects={data?.inactive_project_count?.aggregate?.count}
                            showInfo={true}
                        />
                    </div>
                </div>
                <div className="flex md:flex-row flex-col gap-3 w-full">
                    <div className="md:w-1/2">
                        <InfoItem
                            title="Project Initiated"
                            icon="project-icon"
                            theme="green3"
                            value={data?.active_project_count?.aggregate?.count}
                            showClick={false}
                        />
                    </div>
                    <div className="md:w-1/2">
                        <InfoItem
                            title="Project Participated"
                            icon="project-filled"
                            value={parseInt(data?.total_comments_count?.aggregate?.count) + parseInt(data?.total_expression_count?.aggregate?.count)}
                            theme="red"
                            showInfo={false}
                        />
                    </div>
                    <div className="md:w-1/2">
                        <InfoItem title="Users" icon="people-icon" value={data?.all_users_count?.aggregate?.count} theme="blue2" showInfo={false} />
                    </div>
                    {/* <Grid item xs={6} lg={3} container alignItems="center">
                        <InfoItem
                            title="Participants"
                            icon="globe-icon"
                            value={
                                parseInt(data?.unique_users_comments_count?.aggregate?.count) +
                                parseInt(data?.unique_users_expression_count?.aggregate?.count)
                            }
                            theme="green"
                            showInfo={false}
                        />
                    </Grid> */}
                </div>
                <div className="flex w-full md:flex-row flex-col gap-3">
                    <div className="md:w-1/2">
                        <ProjectsCalender />
                    </div>
                    <div className="md:w-1/2">
                        <Comments
                            pendingComments={data?.pending_comments}
                            approvedComments={data?.approved_comments}
                            rejectedComments={data?.rejected_comments}
                        />
                    </div>
                </div>
            </div>
            <div className="w-full md:w-1/3">
                <RecentActivity comments={data?.recent_project_comments} expressions={data?.recent_project_expressions} />
                {/* <ProjectStats
                    totalProjects={data?.total_projects_count?.aggregate?.count}
                    totalApprovedProjects={data?.approve_project_count?.aggregate?.count}
                    totalPendingProjects={data?.pending_project_count?.aggregate?.count}
                /> */}
            </div>
        </m.div>
    );
};

export default Dashboard;
