import React, { Fragment, useContext, useState, useEffect, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useMutation } from "@apollo/client";
import { useVerifyEmailMutation } from "types/generated-types.ts";
import AuthUserContext from "contexts/session";
import ROUTES from "constants/routes";

const VerifyEmail = () => {
    const [searchParams] = useSearchParams();
    const { token } = Object.fromEntries([...searchParams]);
    const navigate = useNavigate();

    console.log(token)
    const [verifyEmail, { loading, error }] = useVerifyEmailMutation({
        onCompleted: (data) => {
            if (data && data.verifyEmail) {
                toast.dismiss();
                toast.success("Successfully Verfied Email");
                localStorage.setItem("authToken", data.verifyEmail);
                setTimeout(() => {
                    navigate(ROUTES.PROFILE);
                    // window.location.reload();
                }, 1500);
            }
        },
    });

    useEffect(() => {
        if (error) {
            toast.error("Error Verifying Email, please try again");
        }
    }, [error]);

    useEffect(() => {
        verifyEmail({
            variables: {
                token: token,
            },
        });
    }, []);

    return (
        <Grid container direction="column" sx={{ height: "100vh" }} alignItems="center" justifyContent="center">
            {loading && (
                <Fragment>
                    <CircularProgress />
                    <Typography>Verifying...</Typography>
                </Fragment>
            )}
        </Grid>
    );
};

export default VerifyEmail;
