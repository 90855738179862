import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import { RegisterModalFooter } from "..";

function Individual({ individualState, setIndividualState, email, setOpen, registerUser }) {
    let { individualName, individualPhone, individualAddress, individualOccupation, individualGender } = individualState;
    const handleIndividualChange = (e) => {
        setIndividualState((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };

    const handleBackClick = () => {
        setOpen("usertype");
    };

    const handleForwardClick = () => {
        if (!individualName || !individualPhone || !individualAddress || !individualOccupation || !individualGender) {
            return alert("please fill all individual userType details");
        }
        var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
        if (!re.test(individualPhone)) {
            return alert("Enter valid phone number");
        }
        registerUser();
    };
    return (
        <Grid item xs={12} className="common-modal__container" container direction="column" justifyContent="flex-start" alignItems="flex-start">
            <h3>
                Sign up as <span className="span-text">Individual.</span>
            </h3>
            <Grid item xs={12} className="field__grid" container direction="row" justifyContent="space-between" alignItems="center">
                <TextField
                    variant="standard"
                    label="Name"
                    className="input__field"
                    type="text"
                    name="individualName"
                    value={individualName}
                    onChange={handleIndividualChange}
                />
                <TextField variant="standard" label="Email" className="input__field" type="text" name="individualEmail" disabled value={email} />
            </Grid>
            <Grid item xs={12} className="field__grid" container justifyContent="space-between" alignItems="center">
                <FormControl className="radio__container">
                    <FormLabel id="radio-btns-label">Gender</FormLabel>
                    <RadioGroup row value={individualGender} onChange={handleIndividualChange} name="individualGender">
                        <FormControlLabel value="female" control={<Radio size="small" />} label="Female" />
                        <FormControlLabel value="male" control={<Radio size="small" />} label="Male" />
                        <FormControlLabel value="other" control={<Radio size="small" />} label="Other" />
                    </RadioGroup>
                </FormControl>
                <TextField
                    variant="standard"
                    label="Contact"
                    className="input__field"
                    type="text"
                    onKeyPress={(event) => {
                        // ignore "-", "+" and "e"
                        if (event?.key === "-" || event?.key === "+" || event?.key === "e") {
                            event.preventDefault();
                        }
                        // if character is not number then ignore except decimal point and
                        if (event?.key !== "." && event?.key && isNaN(+event.key)) {
                            event.preventDefault();
                        }
                        if (event.target.value.length >= 10) {
                            event.preventDefault();
                        }
                    }}
                    InputProps={{
                        maxLength: 10,
                    }}
                    name="individualPhone"
                    value={individualPhone}
                    onChange={handleIndividualChange}
                />
            </Grid>
            <Grid item xs={12} className="field__grid" container justifyContent="space-between" alignItems="center">
                <TextField
                    variant="standard"
                    label="Address"
                    className="input__field"
                    type="text"
                    name="individualAddress"
                    value={individualAddress}
                    onChange={handleIndividualChange}
                />
                <TextField
                    variant="standard"
                    label="Occupation"
                    className="input__field"
                    type="text"
                    name="individualOccupation"
                    value={individualOccupation}
                    onChange={handleIndividualChange}
                />
            </Grid>
            <Grid item xs={12} container direction="row" justifyContent="space-between" alignItems="center" className="button__container">
                <Button className="back__btn" type="button" onClick={handleBackClick}>
                    Back
                </Button>
                <Button className="continue__btn" type="button" onClick={handleForwardClick}>
                    Save and continue
                </Button>
            </Grid>
            <RegisterModalFooter />
        </Grid>
    );
}

export default Individual;
