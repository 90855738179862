import "./Title.scss";

const Title = props => (
  <section className="Title">
    <div className="title">
        <b>{props.title}</b>
    </div>
  </section>
);

export default Title;