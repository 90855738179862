// components
import { Link } from "react-router-dom";
import "./PrivacyContent.styles.scss";

const PrivacyContent = () => (
    <section className="PrivacyContent">
        <div className="container">
            <div className="panel panel-default">
                <div className="text-title">Privacy Policy</div>
                <div className="panel-body">
                    <p>
                        This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as applicable and the
                        amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000.
                        This electronic record is generated by a computer system and does not require any physical or digital signatures.
                    </p>
                    <p>
                        This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries
                        guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and Terms of Use for access or usage
                        of <a href="https://www.equippp.com">www.equippp.com</a>
                    </p>
                    <p>
                        <Link to={"/"}>www.equippp.com</Link> and affiliates respect your privacy. This Privacy Policy describes in brief the manner
                        in which your data is collected and used by <a href="https://www.equippp.com">www.equippp.com</a> .You are advised to please
                        read the Privacy Policy carefully. By accessing the website <a href="https://www.equippp.com">www.equippp.com</a>, you agree
                        to the collection and use of your data by <a href="https://www.equippp.com">www.equippp.com</a> in the manner provided in this
                        Privacy Policy.
                    </p>
                    <p>
                        <b>WE WANT YOU TO</b>
                        <ul>
                            <li>Feel comfortable using our web site</li>
                            <li>Feel secure submitting information to us</li>
                            <li>Contact us with your questions or concerns about privacy on this site</li>
                            <li>Know that by using our site you are consenting to the collection of certain data</li>
                        </ul>
                    </p>
                    <br />
                    <b>WHAT INFORMATION IS, OR MAY BE, COLLECTED FROM YOU?</b>
                    <p>
                        We will automatically receive and collect certain anonymous information in standard usage logs through our Web server,
                        including computer-identification information obtained from "cookies," sent to your browser from a
                    </p>
                    <ul>
                        <li>web server cookie stored on your hard drive</li>
                        <li>an IP address assigned to the computer which you use</li>
                        <li>the domain server through which you access our service</li>
                        <li>the type of computer you're using</li>
                        <li>the type of web browser you're using</li>
                        <li>the device id’s and other details</li>
                    </ul>
                    <br />
                    <b>In case you are a user, we may collect the following sensitive personal data or information (SPDI) about you:</b>
                    <ul>
                        <li>Name- including First Name & Last Name</li>
                        <li>Email id (official email id)</li>
                        <li>Contact No (Mobile No / Landline No)</li>
                        <li>Date of Birth</li>
                        <li>Gender</li>
                        <li>Occupation</li>
                        <li>Current Location</li>
                    </ul>
                    <br />
                    <b>In case you are a CSR user, we may collect the following sensitive personal data or information (SPDI) about you:</b>
                    <ul>
                        <li>Name of the Organisation</li>
                        <li>Category of Business / Sector</li>
                        <li>Your Designation</li>
                        <li>Communication Address</li>
                        <li>Representing Authority</li>
                        <li>Email id (Official email id)</li>
                        <li>Contact No (Mobile No or Landline No)</li>
                    </ul>
                    <br />
                    <b>In case of project(s), we may collect the following sensitive personal data or information (SPDI):</b>
                    <ul>
                        <li>Project Title</li>
                        <li>Project Start and End Dates</li>
                        <li>Project Location</li>
                        <li>Name of Unit (in case of CSR Project)</li>
                        <li>Name of Organisation (Corporate Name)</li>
                        <li>Primary & Secondary Contacts (email id & mobile no)</li>
                        <li>
                            Project Information including description, conditions, scope, type of participation, images, documents, YouTube videos (if
                            applicable)
                        </li>
                        <li>Project Estimated Budget</li>
                        <li>Approved value by Corporate (in case of CSR Projects)</li>
                    </ul>
                    <br />
                    <b>We may also collect the following information:</b>
                    <ul>
                        <li>about the pages you visit / access</li>
                        <li>
                            the links you click on <a href="https://www.equippp.com">www.equippp.com</a>
                        </li>
                        <li>
                            the number of times you access a page on <a href="https://www.equippp.com">www.equippp.com</a>
                        </li>
                    </ul>
                    <br />
                    <b>WHO COLLECTS THE INFORMATION</b>
                    <p>
                        Some of your sensitive personal data or information may be collected, received, possessed, stored, dealt with or handled by:
                    </p>
                    <br />
                    <b>M/s. NAME OF THE COMPANY</b>
                    <p># COMPLETE ADDRESS WITH PINCODE</p>
                    <p>
                        We will collect anonymous traffic information from you when you access <a href="https://www.equippp.com">www.equippp.com</a>
                    </p>
                    <p>
                        We may collect sensitive personal data or identifiable information about you only as a part of a voluntary registration
                        process.
                    </p>
                    <br />
                    <b>
                        HOW IS THE INFORMATION USED?
                        <br /> We use your sensitive personal information to:
                    </b>
                    <ul>
                        <li>help us provide personalized features</li>
                        <li>tailor www.equippp.com to your interest</li>
                        <li>to get in touch with you when necessary</li>
                        <li>to provide the services requested by you</li>
                        <li>to preserve social history as governed by existing law or policy</li>
                    </ul>
                    <br />
                    <b>We use contact information internally to:</b>
                    <ul>
                        <li>direct our efforts for improvement</li>
                    </ul>
                    <br />
                    <b>Generally, we use anonymous traffic information to:</b>
                    <ul>
                        <li>remind us of who you are in order to deliver to you a better and more personalized service;</li>
                        <li>
                            recognize your access privileges to <a href="https://www.equippp.com">www.equippp.com</a>
                        </li>
                        <li>
                            help diagnose problems with our server, <a href="https://www.equippp.com">www.equippp.com</a>
                        </li>
                        <li>
                            administer <a href="https://www.equippp.com">www.equippp.com</a>
                        </li>
                        <li>
                            track your session so that we can understand better how people use <a href="https://www.equippp.com">www.equippp.com</a>
                        </li>
                    </ul>
                    <br />
                    <b>DISCLOSURE POLICY IN COMPLIANCE WITH RULE 6 OF THE IT RULES 2011</b>
                    <p>
                        The sensitive personal data or information may be disclosed to any person, if such disclosure is required for a lawful purpose
                        connected with a function or activity of <a href="https://www.equippp.com">www.equippp.com</a>
                    </p>
                    <p>
                        The sensitive personal data or information may be disclosed, where the disclosure is necessary for compliance of a legal
                        obligation.
                    </p>
                    <p>
                        We do not rent, sell, publish or share your sensitive personal data and we will not disclose any of your personally
                        identifiable information unless:
                    </p>
                    <ul>
                        <li>we have your permission</li>
                        <li>
                            to help investigate, prevent or take action regarding unlawful and illegal activities, suspected fraud, including cyber
                            incidents potential threat, punishment of offences and to the safety or security of any person, violations of
                            www.equippp.com ‘s terms of use or to defend against legal claims;
                        </li>
                        <li>
                            Special circumstances such as compliance with subpoenas, court orders, requests/order from legal authorities or law
                            enforcement agencies requiring such disclosure.
                        </li>
                    </ul>
                    <br />
                    <b>WHAT CHOICES ARE AVAILABLE TO YOU REGARDING COLLECTION, USE AND DISTRIBUTION OF YOUR INFORMATION?</b>
                    <p>
                        Sharing your sensitive personal identifiable information is entirely voluntary. You need not register with us in order to use
                        www.equippp.com. However, we offer some services only to visitors who register. You may opt-in or opt-out of any services. You
                        may update your information and change your account settings at any time.
                    </p>
                    <p>
                        Upon request, we will remove/block your sensitive personal identifiable information from our database, thereby cancelling your
                        registration. However, your information may remain stored in archive on our servers even after the deletion or the termination
                        of your account.
                    </p>
                    <p>
                        You can accept or decline the cookies. All sites that are customizable require that you accept cookies. You also must accept
                        cookies to register as someone for access to some of our services. For information on how to set your browser to alert you to
                        cookies, or to reject cookies, go to <a href="http://www.cookiecentral.com/faq/">http://www.cookiecentral.com/faq/</a>.
                    </p>
                    <br />
                    <b>WHAT SECURITY PROCEDURES ARE IN PLACE TO PROTECT INFORMATION FROM LOSS, MISUSE OR ALTERATION?</b>
                    <p>
                        To protect against the loss, misuse and alteration of the information under our control, and to comply with Rule 8 of IT
                        Rules, 2011 (Reasonable security practices and procedures and sensitive personal data or information), we have in place
                        appropriate physical, electronic, technical, operational and managerial procedures.{" "}
                    </p>
                    <p>
                        Our servers are accessible only to authorized personnel and your information is shared with respective personnel to complete
                        the transaction and to provide the services requested by you.
                    </p>
                    <p>
                        Although we will endeavor to safeguard the confidentiality of your sensitive personal identifiable information, transmissions
                        made by means of the Internet cannot be made absolutely secure. By browsing, accessing, using www.equippp.com in whatsoever
                        manner, you agree that we shall have no liability for disclosure of your information due to errors in transmission or
                        unauthorized acts of third parties or acts beyond our control including any cyber-attacks.
                    </p>
                    <br />
                    <b>HOW CAN YOU CORRECT INACCURACIES IN THE INFORMATION?</b>
                    <p>
                        To correct or update any information you have provided during registration, you can update or correct the inaccuracies therein
                        through your registered account. However, you shall be responsible for informing us the same.
                    </p>
                    <p>In the event of loss of access details, you may </p>
                    <ul>
                        <li>
                            Send an e-mail to <a href="mailto: admin@equippp.com">admin@equippp.com</a>
                        </li>
                    </ul>
                    <br />
                    <b>POLICY UPDATES</b>
                    <p>
                        We reserve the right to change or update this policy at any time by placing a prominent notice on{" "}
                        <a href="https://www.equippp.com">www.equippp.com</a>. Such changes shall be effective immediately upon posting the same on{" "}
                        <a href="https://www.equippp.com">www.equippp.com</a>
                    </p>
                </div>
            </div>
        </div>
    </section>
);

export default PrivacyContent;
