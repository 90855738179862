import { useEffect, useState } from "react";
import { ToastContainer, Toast } from "react-bootstrap";
import PropTypes from "prop-types";
import "./NotificationToast.styles.scss";

/**
 * How to use this Notification Toast component on your page:
 * 1. Import component on the Parent page component and append it to the end of html.
 * 2. Make a state varible `toggle`.
 * 3. Make a prop `toggle={setToggle}` on CustomNavbar html of the page.
 * 4. Add prop of this component called at the end of html of the parent as `toggle={toggle}`, in addition to `header` and `body` props.
 * @param {object} props object of properties sent by parent
 * @returns {object} notification react object
 */
const NotificationToast = (props) => {
    const [toast, setToast] = useState(false);
    const [toastHeader, setToastHeader] = useState(<></>);
    const [toastBody, setToastBody] = useState(<></>);
    /**
     * Show Response Notification Toast at the bottom-right of the page for five seconds and hide if not hidden manually by the user
     */
    const toggleToast = async () => {
        setToast(true);
        await window.setTimeout(() => {
            setToast(false);
            return;
        }, 5000);
    };

    useEffect(() => {
        if (props.toggle !== false) toggleToast();
    }, [props.toggle]);

    return (
        <ToastContainer position="bottom-end" className="p-2 position-fixed">
            <Toast show={toast} onClose={() => setToast(false)} animation={true}>
                <Toast.Header closeButton={true}>
                    <strong className="me-auto">{props.header || ""}</strong>
                </Toast.Header>
                <Toast.Body>{props.body || ""}</Toast.Body>
            </Toast>
        </ToastContainer>
    );
};

export default NotificationToast;
NotificationToast.propTypes = {
    header: PropTypes.string,
    body: PropTypes.any,
    toggle: PropTypes.any,
};
