import { Dialog, DialogTrigger, DialogContent } from "../../components/ui/dialog";

const ContactDetails = ({ email, mobile }) => {
    return (
        <Dialog>
            <DialogTrigger className="">
                <button className="bg-[#d9d9d9] text-center  text-white px-3 py-1 flex items-center  rounded-full">
                    <img src="/assets/images/contact-logo.png" alt="contact" className="h-[18px] w-[23px] mr-2" />
                    <p className=" text-[#1C2B52] text-xs">Contact</p>
                </button>
            </DialogTrigger>
            <DialogContent className="w-[350px] md:w-1/2">
                <p>Admin Contact Details</p>
                <div className="border px-4 py-2">
                    <p>Email: {email}</p>
                    <p>Mobile: {mobile}</p>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default ContactDetails;
