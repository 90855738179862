import React, { useContext, useEffect, useState, Fragment } from "react";
import Linkify from "react-linkify";
import { MentionsInput, Mention } from "react-mentions";
import { toast } from "react-toastify";
import { Button } from "../../../../components/ui/button";
import { Input } from "../../../../components/ui/input";
import { useAddCommentMutation, useGetUsersQuery } from "types/generated-types.ts";
import { Avatar } from "@mui/material";
import AuthUserContext from "contexts/session";
import { set } from "lodash";

const ReplyCommentInput = ({ projectId, isReply, parentComment, parentUser, profilePicture, refetch, hasReplied }) => {
    const [comment, setComment] = useState("");
    const [validComment, setValidComment] = useState("");
    const { authUser } = useContext(AuthUserContext);
    const [users, setUsers] = useState([]);
    const { data: usersData } = useGetUsersQuery();

    const [openReply, setOpenReply] = useState(false);

    useEffect(() => {
        let usersArray = [];
        if (usersData) {
            usersData.users.forEach((item) => usersArray.push({ id: item.id, display: item.user_name }));
            setUsers(usersArray);
        }
    }, [usersData]);

    // useEffect(() => {
    //     if (parentUser) {
    //         setComment(`@[${parentUser?.user_name}](${parentUser?.id})`);
    //     }
    // }, [parentUser]);

    const isAdmin = authUser?.role === "admin";

    console.log(isAdmin, "is admin");

    useEffect(() => {
        console.log(comment);
    }, [comment]);

    const [addComment] = useAddCommentMutation({
        onCompleted: (data) => {
            if (data && data?.insert_comments_one?.status) {
                if (!isAdmin) {
                    toast.success("Your comment added successfully, it'll be visible to everyone once admin approves it");
                } else {
                    toast.success("Your comment added successfully!");
                }
                setComment("");
                setValidComment(false);
                //set openREPLY TO FALSE

                setOpenReply(false);

                refetch();
            }
        },
    });

    const submitComment = () => {
        if (isReply) {
            if (comment !== "") {
                addComment({
                    variables: {
                        is_verified: isAdmin ? true : false,
                        added_by: authUser.id,
                        project_id: projectId,
                        parent_comment_id: parentComment.id,
                        comment,
                    },
                });
            } else {
                setValidComment(false);
            }
        } else {
            if (comment !== "") {
                addComment({
                    variables: {
                        added_by: authUser.id,
                        project_id: projectId,
                        comment,
                        is_verified: isAdmin ? true : false,
                    },
                });
            } else {
                setValidComment(false);
            }
        }
    };

    console.log({ authUser });
    return (
        <Fragment>
            {!hasReplied && authUser?.role === "admin" && (
                <div className="px-16 w-full cursor-pointer text-gray-600 pb-2" onClick={() => setOpenReply(!openReply)}>
                    <p className="text-xs">Reply</p>
                </div>
            )}
            {openReply && (
                <div className="border py-2 px-3 border-gray-100 rounded-md">
                    <div className="flex w-full justify-between items-center">
                        <div className="flex w-full items-center">
                            <Avatar src={profilePicture || ""} alt="" className="w-10 h-10 mr-2 rounded-full" />
                            <p className="text-xs w-24">Your Reply :</p>
                            <Input
                                placeholder="Type your comment here"
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                className="flex-grow border"
                            />
                        </div>

                        <Button variant="contained" className="bg-blue-600 text-white rounded-md mx-2" onClick={submitComment}>
                            send
                        </Button>
                    </div>
                    {/* <div className="mt-2 w-full">
            <MentionsInput
                style={{
                    control: {
                        backgroundColor: "#fff",
                        fontSize: 16,
                    },
                    highlighter: {
                        padding: 9,
                        border: "1px solid transparent",
                    },
                    input: {
                        padding: 9,
                        border: "none",
                        outline: "none",
                    },
                    suggestions: {
                        list: {
                            backgroundColor: "white",
                            border: "1px solid rgba(0,0,0,0.15)",
                            fontSize: 16,
                            height: 250,
                            overflowY: "auto",
                        },
                        item: {
                            padding: "5px 15px",
                            borderBottom: "1px solid rgba(0,0,0,0.15)",
                            "&focused": {
                                backgroundColor: "rgb(104, 89, 249)",
                            },
                        },
                    },
                }}
                placeholder="Text Here"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
            >
                <Mention
                    trigger="@"
                    style={{ backgroundColor: "rgba(104, 89, 249, 0.5)" }}
                    data={users}
                    displayTransform={(id, display) => `@${display}`}
                />
            </MentionsInput>
        </div> */}
                </div>
            )}
        </Fragment>
    );
};

export default ReplyCommentInput;
