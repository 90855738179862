import { Fragment, useState, useEffect, useMemo, useContext, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useAtom } from "jotai";
import { atomWithHash } from "jotai/utils";
import PropTypes from "prop-types";
import LinesEllipsis from "react-lines-ellipsis";
import { useGetCurrentUserLazyQuery } from "types/generated-types.ts";
import ProjectDetails from "components/ProjectDetails";
import AuthUserContext from "contexts/session";
import ProfileMap from "../ProfileDetails/components/ProfileMap";
import { useMapStore } from "../ProfileDetails/components/ProfileMap/Map";
import ProjectItem from "../ProfileDetails/components/ProjectItem";
import { motion as m } from "framer-motion";
import { Trash2 } from "lucide-react";
import { Filter } from "lucide-react";
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "components/ui/dropdown-menu";
import { Search } from "lucide-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGetAllVerifiedActiveProjectQuery } from "types/generated-types.ts";
import ProfileAnalytics from "../ProfileDetails/components/profile-analytics";
import ContactDetails from "../ProfileDetails/ContactDetails";
import { toast } from "react-toastify";
import Invite from "../ProfileDetails/Invite";
import { Avatar } from "@mui/material";
import { CircleX } from "lucide-react";
import { useProjectModalStore } from "../ProfileDetails/components/ProfileMap/ProfileMap";
import { set } from "date-fns";
import { create } from "zustand";
import clsx from "clsx";
import { ListFilter } from "lucide-react";
import { Map } from "lucide-react";
import { ArrowDown } from "lucide-react";
import { ChevronDown } from "lucide-react";

const categories = [
    "Community",
    "Education",
    "Environment",
    "Healthcare",
    "Housing",
    "InfrastructurePower",
    "Roads & Bridges",
    "Social Justice",
    "Technology",
    "Waste Management",
    "Water & Sanitization",
    "Other",
];

const userTypes = ["All", "CSR", "MP / MLA LADS", "Probono", " Individual", "Corporate"];

const emptyArray = [];

const useExecutedStore = create((set) => ({
    isExecuted: false,
    actions: {
        toggle: () => set((state) => ({ isExecuted: !state.isExecuted })),
    },
}));

const useExpandedStore = create((set) => ({
    expandProfession: false,
    expandDescription: false,
    setExpandProfession: (value) => set({ expandProfession: value }),
    setExpandDescription: (value) => set({ expandDescription: value }),
}));

const NameAndProfession = ({ isLoading, user_type_details }) => {
    const { authUser } = useContext(AuthUserContext);

    const { expandProfession, expandDescription, setExpandProfession, setExpandDescription } = useExpandedStore();

    return (
        <div className="flex flex-col w-full text-center md:!text-left md:w-2/3 ml-4 ">
            <p className="md:text-2xl text-lg font-semibold text-[#1C2B52] w-full">
                {isLoading ? (
                    "...loading"
                ) : user_type_details?.name ? (
                    user_type_details?.name
                ) : (
                    <span className="text-underline">name is not added</span>
                )}
            </p>
            {expandProfession ? (
                <p className="md:text-sm text-xs text-[#1C2B52] w-full">
                    {user_type_details?.profession}

                    <span className="ml-2 text-center cursor-pointer text-blue-500" onClick={() => setExpandProfession(false)}>
                        see less...
                    </span>
                </p>
            ) : (
                <LinesEllipsis
                    text={user_type_details?.profession || "No profession listed"}
                    maxLine="3"
                    className="md:text-sm text-xs text-[#1C2B52] w-full"
                    ellipsis={
                        <span className="ml-2 text-center cursor-pointer text-blue-500" onClick={() => setExpandProfession(true)}>
                            see more...
                        </span>
                    }
                    trimRight
                    basedOn="letters"
                />
            )}
        </div>
    );
};
const AdminContactAndInvite = ({ emailId, phoneNumber, projectStatusFilter, setProjectStatusFilter }) => {
    const { authUser } = useContext(AuthUserContext);

    const { isExecuted, actions } = useExecutedStore();

    return (
        <Fragment>
            <div className="flex flex-wrap  items-center gap-x-2 w-full md:justify-end justify-center  md:h-fit">
                <DropdownMenu className="">
                    <DropdownMenuTrigger className="bg-[#d9d9d9] !hidden md:flex text-center !text-[#1C2B52] px-3 py-1  items-center  rounded-full">
                        <p className="text-xs">
                            {projectStatusFilter === "in-progress" && "OnGoing Projects"}
                            {projectStatusFilter === "completed" && "Executed Projects"}
                        </p>
                        <ChevronDown className="w-4 h-4 ml-1" />
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="p-2">
                        <button
                            onClick={() => setProjectStatusFilter("in-progress")}
                            className={`text-xs ${
                                projectStatusFilter === "in-progress" ? " !text-blue-500" : " !text-[#1C2B52]"
                            } w-fit flex items-center text-center px-2 py-1 rounded-full mb-1`}
                        >
                            Ongoing Projects
                        </button>
                        <button
                            onClick={() => setProjectStatusFilter("completed")}
                            className={`text-xs ${
                                projectStatusFilter === "completed" ? " !text-blue-500" : " !text-[#1C2B52]"
                            } w-fit flex items-center text-center px-2 py-1 rounded-full mb-1`}
                        >
                            Executed Projects
                        </button>
                    </DropdownMenuContent>
                </DropdownMenu>
                {/* <button
                    onClick={() => {
                        actions.toggle();
                    }}
                    className={`text-[13px] w-fit flex items-center ${
                        isExecuted ? "bg-[#1E5EFF]  !text-white" : "bg-[#d9d9d9] !text-[#1C2B52]"
                    }  text-center text-white px-2 py-1 flex items-center  rounded-full`}
                >
                    <img src={`/assets/images/project-grp${isExecuted ? "-white" : ""}.png`} alt="contact" className="h-4 w-4 mr-2" />
                    <p className="text-xs">{isExecuted ? "OnGoing Projects" : "Executed Projects"}</p>
                </button> */}
                <ContactDetails email={emailId} mobile={phoneNumber} />
                {(authUser?.role === "admin" || authUser?.is_invited_to_all_projects) && <Invite />}
            </div>
        </Fragment>
    );
};

const AdminSocialLink = ({ linkedin_link, facebook_link, twitter_link }) => {
    const { authUser } = useContext(AuthUserContext);
    return (
        <div className="md:flex hidden gap-2 pl-14">
            <img
                src="/assets/images/linked-in.png"
                alt="linkedIn"
                onClick={() => {
                    if (linkedin_link) {
                        window.open(linkedin_link);
                    } else {
                        toast.error("Linkedin link not available");
                    }
                }}
                className="h-[20px] w-[24px] cursor-pointer "
            />
            <img
                src="/assets/images/facebook-logo.png"
                alt="facebook"
                className="h-[22px] w-[28px] cursor-pointer "
                onClick={() => {
                    if (facebook_link) {
                        window.open(facebook_link);
                    } else {
                        toast.error("Facebook link not available");
                    }
                }}
            />
            <img
                src="/assets/images/twitter-x.png"
                alt="twitter-x"
                className="h-[20px] w-[18px] cursor-pointer "
                onClick={() => {
                    if (twitter_link) {
                        window.open(twitter_link);
                    } else {
                        toast.error("Twitter link not available");
                    }
                }}
            />
        </div>
    );
};

const PAGINATION_SIZE = 20;

const ProfileDetails = () => {
    const [projects, setProjects] = useState([]);

    const [showMap, setShowMap] = useState(false);

    const [locationCoordinates, setLocationCoordinates] = useState();

    const [projectFilter, setProjectFilter] = useState("all_projects");

    const { authUser } = useContext(AuthUserContext);
    const navigate = useNavigate();

    const [where, setWhere] = useState({
        search: "",
        category: [],
        type: [],
    });

    const [searchParams, setSearchParams] = useSearchParams();

    const projectStatusFilter = searchParams.get("projectStatusFilter") || "in-progress";

    const setProjectStatusFilter = (value) => {
        setSearchParams((previousValue) => {
            const previousValueObject = Object.fromEntries(previousValue.entries());
            return { ...previousValueObject, projectStatusFilter: value };
        });
    };

    const { isExecuted } = useExecutedStore();

    const isNotFirstRender = useRef(false);

    const { data: projectData, loading } = useGetAllVerifiedActiveProjectQuery({
        variables: {
            limit: PAGINATION_SIZE,
            offset: 0,
            added_by: {
                _eq: authUser?.id,
            },
            comments_where: {
                added_by: {
                    _eq: authUser?.id,
                },
            },
            and: [
                {
                    _or: [
                        {
                            title: { _ilike: `%${where.search}%` },
                        },
                        {
                            location_title: { _ilike: `%${where.search}%` },
                        },
                    ],
                },
                projectStatusFilter !== "all"
                    ? {
                          project_status: { _ilike: `%${projectStatusFilter}%` },
                      }
                    : {},
                ...(where.category.length
                    ? [
                          {
                              _or: where.category.map((category) => ({
                                  category: { _ilike: `%${category}%` },
                              })),
                          },
                      ]
                    : []),
                ...(where.type.length
                    ? [
                          {
                              _or: where.type.map((type) => ({
                                  type: { _ilike: `%${type === "all" ? "" : type}%` },
                              })),
                          },
                      ]
                    : []),
            ],
        },
        skip: !authUser?.id,
    });

    const allProjects = projectData?.projects || [];

    const [selectedProject, setCurrentProject] = useState("");

    const currentProject = selectedProject || allProjects[0]?.id;
    const [expandProfession, setExpandProfession] = useState(false);
    const [expandDescription, setExpandDescription] = useState(false);

    const [
        {
            kyc_status,
            user_type_details,
            profession,
            location_title,
            location_coordinates,
            description,
            gender,
            is_private,
            commentedProjects,
            promotedProjects,
            initiatedProjects,
            expressedProjects,
            profile_picture,
            twitter_link,
            facebook_link,
            linkedin_link,
        },
        setValue,
    ] = useState({
        kyc_status: "",
        user_type_details: {},
        profession: "",
        location_title: "",
        location_coordinates: { coordinates: [] },
        description: "",
        gender: "",
        is_private: false,
        commentedProjects: emptyArray,
        expressedProjects: emptyArray,
        promotedProjects: emptyArray,
        initiatedProjects: emptyArray,
        expressedProjects: emptyArray,
        profile_picture: "",
        twitter_link: "",
        facebook_link: "",
        linkedin_link: "",
    });

    const projectsFilterAtom = useMemo(() => atomWithHash("projectsFilter", "All projects"), []);
    const [projectsFilter, setProjectsFilter] = useAtom(projectsFilterAtom);

    const { search } = useLocation();
    const isAdmin = (authUser && authUser.role) === "admin";

    const [page, setPage] = useState(1);
    const [currentProjects, setCurrentProjects] = useState(null);
    const [filteredProjects, setFilteredProjects] = useState(projectData?.projects || []);

    const [filter, setFilter] = useState({
        category: ["all_categories"],
        userType: "all_user_types",
        // projectType: "all_projects",
    });

    const [projectsPerPage] = useState(4);

    const handleChange = (event, value) => {
        setPage(value);
    };

    const id = searchParams.get("id");

    const [getUsers, { data, loading: isLoading }] = useGetCurrentUserLazyQuery();

    useEffect(() => {
        if (id) {
            getUsers({
                variables: {
                    id: id,
                },
            });
        }
    }, [id, getUsers]);

    useEffect(() => {
        if (!data) return;
        const {
            kyc_status,
            profession,
            location_title,
            location_coordinates,
            description,
            gender,
            is_private,
            user_type_details,
            initiatedProjects = emptyArray,
            commentedProjects = emptyArray,
            promotedProjects = emptyArray,
            expressedProjects = emptyArray,
            profile_picture,
            twitter_link,
            facebook_link,
            linkedin_link,
        } = data?.users_by_pk || {};

        setValue({
            user_type_details: JSON.parse(user_type_details || "{}"),
            kyc_status: kyc_status,
            profession: profession,
            location_title: location_title,
            location_coordinates: location_coordinates,
            description: description,
            gender: gender,
            is_private: is_private,
            initiatedProjects: initiatedProjects.filter((item) => item.is_active === true),
            commentedProjects: commentedProjects.filter((item) => item.project.is_active === true).map((item) => item.project),
            promotedProjects: promotedProjects.filter((item) => item.project.is_active === true).map((item) => item.project),
            expressedProjects: expressedProjects.filter((item) => item.project.is_active === true).map((item) => item.project),
            profile_picture: profile_picture,
            twitter_link: twitter_link,
            facebook_link: facebook_link,
            linkedin_link: linkedin_link,
        });
    }, [data]);

    // useEffect(() => {
    //     const indexOfLastProject = page * projectsPerPage;

    //     const indexOfFirstProject = indexOfLastProject - projectsPerPage;

    //     // setTotalPages(Math.ceil(projects?.length / projectsPerPage));
    //     setCurrentProjects(projects?.slice(indexOfFirstProject, indexOfLastProject));
    // }, [projects, page]);

    const handleFilterChange = (event) => {
        setProjectsFilter(event.target.value);
    };

    const handleSearchChange = (event) => {
        setWhere((prev) => ({ ...prev, search: event.target.value.toLowerCase() }));
    };

    useEffect(() => {
        let filteredByType = [];

        const initiatedProjects = allProjects.filter((project) => project.added_by);
        // First, filter projects by type

        if (projectsFilter === "All projects") {
            filteredByType = allProjects;
        } else if (projectsFilter === "initiated") {
            filteredByType = initiatedProjects;
        } else if (projectsFilter === "promoted") {
            filteredByType = [...promotedProjects];
        } else if (projectsFilter === "commented") {
            filteredByType = [...commentedProjects];
        } else if (projectsFilter === "expressed") {
            filteredByType = [...expressedProjects];
        }

        setProjects(filteredByType);
    }, [projectsFilter, initiatedProjects, commentedProjects, promotedProjects, expressedProjects, allProjects]);

    useEffect(() => {
        if (isNotFirstRender.current) {
            return;
        }

        if (!loading && !projects.length && projectStatusFilter === "in-progress") {
            setProjectStatusFilter("completed");

            isNotFirstRender.current = true;
        }
    }, [projects, projectStatusFilter, loading]);

    const [dropdownOpen, setDropdownOpen] = useState();

    const [isOpen, setIsOpen] = useState(false);

    const openMenu = () => {
        setIsOpen(true);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    const handleDropDown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    return (
        <m.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.75, ease: "easeInOut" }}
            className="md:flex-row flex-col flex w-full md:gap-2 h-full"
        >
            <div className="md:w-2/3 w-full md:!rounded rounded-none shadow-none bg-white flex flex-col">
                <div className="bg-[#e2e3e9] md:!shadow-t-md md:!rounded-t  rounded-t-none h-[100px] w-full relative">
                    {/* <img
                        src="assets/images/Location.png"
                        alt=""
                        className="w-6 cursor-pointer map absolute md:hidden right-4 top-6"
                        onClick={() => setShowMap(!showMap)}
                    /> */}
                    <img src="/assets/images/equippp-3.png" alt="" className="w-20 h-20 block md:hidden left-2 absolute translate-y-[15%]" />
                    <img src="/assets/images/equippp-3.png" alt="" className="w-20 h-20 md:block hidden right-8 absolute translate-y-[15%]" />
                </div>

                <div className="flex flex-col md:flex-row px-2 md:px-8 flex-grow mt-3 md:mt-0">
                    <div className="w-full h-full  flex flex-col">
                        <div className="md:hidden flex  gap-2 mb-2">
                            <button
                                className={`text-xs flex items-center ${
                                    showMap ? "bg-blue-500 text-white" : "bg-gray-100 text-[#1C2B52]"
                                }   px-2 rounded-full`}
                                onClick={() => setShowMap(!showMap)}
                            >
                                <Map className="w-4 h-4 mr-1" /> Show Map
                            </button>
                            <div className="border flex-grow  rounded-full relative flex  md:hidden items-center">
                                <input
                                    onChange={handleSearchChange}
                                    type="search"
                                    placeholder="Search by name/area"
                                    className="outline-none pl-8 border-none rounded-full text-xs w-full h-6 "
                                />
                                <Search className="text-gray-400 w-4 absolute left-2" />
                            </div>
                        </div>

                        <div className="w-full text-xs  flex md:flex-row flex-row md:bg-[#f5f5f5]  rounded-full items-center  gap-2 my-2 md:!p-2 p-0 md:my-0">
                            <DropdownMenu open={isOpen} onOpenChange={setIsOpen} className="">
                                <DropdownMenuTrigger
                                    onClick={openMenu}
                                    className="bg-white shadow-sm text-center !text-[#1C2B52] px-3 py-1 flex items-center  rounded-full"
                                >
                                    <p className="text-xs">
                                        {projectStatusFilter === "in-progress" && "OnGoing Projects"}
                                        {projectStatusFilter === "completed" && "Executed Projects"}
                                    </p>
                                    <ChevronDown className="w-4 h-4 ml-1" />
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className="p-2">
                                    <button
                                        onClick={() => {
                                            setProjectStatusFilter("in-progress");
                                            closeMenu();
                                        }}
                                        className={`text-xs ${
                                            projectStatusFilter === "in-progress" ? " !text-blue-500" : " !text-[#1C2B52]"
                                        } w-fit flex items-center text-center px-2 py-1 rounded-full mb-1`}
                                    >
                                        Ongoing Projects
                                    </button>
                                    <button
                                        onClick={() => {
                                            setProjectStatusFilter("completed");
                                            closeMenu();
                                        }}
                                        className={`text-xs ${
                                            projectStatusFilter === "completed" ? " !text-blue-500" : " !text-[#1C2B52]"
                                        } w-fit flex items-center text-center px-2 py-1 rounded-full mb-1`}
                                    >
                                        Executed Projects
                                    </button>
                                </DropdownMenuContent>
                            </DropdownMenu>
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <div className="rounded-full  md:font-medium flex-grow md:flex-grow-0 md:w-1/6 bg-gray-100 md:bg-white py-1 text-sm text-black flex items-center justify-center">
                                        <p className="text-xs md:block hidden ">Project Category</p>
                                        <p className="text-xs md:hidden block text-[#1C2B52]">Category</p>
                                        <Filter className="w-4 ml-1 h-3 text-black" fill="black" />
                                    </div>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className="w-56 h-64 overflow-y-scroll">
                                    {categories.map((item, ind) => {
                                        const isExist = where.category.includes(item);
                                        return (
                                            <div
                                                key={ind}
                                                className="flex items-center text-xs px-2 py-1 hover:bg-gray-200 cursor-pointer"
                                                onClick={() => {
                                                    if (isExist) {
                                                        setWhere((prev) => ({ ...prev, category: where.category.filter((cat) => cat !== item) }));
                                                    } else {
                                                        setWhere((prev) => ({ ...prev, category: [...where.category, item] }));
                                                    }
                                                }}
                                            >
                                                <input type="checkbox" className="w-4 h-4 mr-2" checked={isExist} />
                                                {item}
                                            </div>
                                        );
                                    })}
                                </DropdownMenuContent>
                            </DropdownMenu>
                            <DropdownMenu>
                                <DropdownMenuTrigger className="md:hidden w-fit">
                                    <div className="rounded-full !px-4  md:font-medium w-fit md:w-fit bg-gray-100 md:bg-white py-1 text-sm text-black flex items-center justify-center">
                                        <p className="text-xs md:block hidden">Project Type</p>
                                        <p className="text-xs md:hidden block text-[#1C2B52]">Type</p>
                                        <ListFilter className="w-4 ml-1 h-3 text-black" fill="black" />
                                    </div>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className="w-56  overflow-y-scroll">
                                    {userTypes.map((item, ind) => {
                                        const isExist = where.type.includes(item);
                                        return (
                                            <div
                                                key={ind}
                                                className="flex items-center text-xs px-2 py-1 hover:bg-gray-200 cursor-pointer"
                                                onClick={() => {
                                                    if (isExist) {
                                                        setWhere((prev) => ({ ...prev, type: where.type.filter((cat) => cat !== item) }));
                                                    } else {
                                                        setWhere((prev) => ({ ...prev, type: [...where.type, item] }));
                                                    }
                                                }}
                                            >
                                                <input type="checkbox" className="w-4 h-4 mr-2" checked={isExist} />
                                                {item}
                                            </div>
                                        );
                                    })}
                                </DropdownMenuContent>
                            </DropdownMenu>
                            <div className="rounded-full text-xs flex-wrap  shadow-sm font-medium font-poppins flex-grow p-1 px-3 border bg-white hidden md:flex items-center justify-between">
                                <p
                                    onClick={() => {
                                        const isExist = where.type.includes("all");
                                        if (isExist) {
                                            setWhere((prev) => ({ ...prev, type: [...where.type.filter((cat) => cat !== "all")] }));
                                        } else {
                                            setWhere((prev) => ({ ...prev, type: [...where.type, "all"] }));
                                        }
                                    }}
                                    className={`cursor-pointer ${where.type?.includes("all") ? "text-blue-600 border-b border-blue-600" : ""}`}
                                >
                                    All
                                </p>
                                <p
                                    onClick={() => {
                                        const isExist = where.type.includes("csr");
                                        if (isExist) {
                                            setWhere((prev) => ({ ...prev, type: [...where.type.filter((cat) => cat !== "csr")] }));
                                        } else {
                                            setWhere((prev) => ({ ...prev, type: [...where.type, "csr"] }));
                                        }
                                    }}
                                    className={`cursor-pointer ${where.type?.includes("csr") ? "text-blue-600 border-b border-blue-600" : ""}`}
                                >
                                    CSR
                                </p>
                                <p
                                    onClick={() => {
                                        const isExist = where.type.includes("MLA");
                                        if (isExist) {
                                            setWhere((prev) => ({ ...prev, type: [...where.type.filter((cat) => cat !== "MLA")] }));
                                        } else {
                                            setWhere((prev) => ({ ...prev, type: [...where.type, "MLA"] }));
                                        }
                                    }}
                                    className={`cursor-pointer ${where.type?.includes("MLA") ? "text-blue-600 border-b border-blue-600" : ""}`}
                                >
                                    MP / MLA LADS
                                </p>
                                <p
                                    onClick={() => {
                                        const isExist = where.type.includes("Probono");
                                        if (isExist) {
                                            setWhere((prev) => ({ ...prev, type: [...where.type.filter((cat) => cat !== "Probono")] }));
                                        } else {
                                            setWhere((prev) => ({ ...prev, type: [...where.type, "Probono"] }));
                                        }
                                    }}
                                    className={`cursor-pointer ${where.type?.includes("Probono") ? "text-blue-600 border-b border-blue-600" : ""}`}
                                >
                                    Probono
                                </p>
                                <p
                                    onClick={() => {
                                        const isExist = where.type.includes("Individual");
                                        if (isExist) {
                                            setWhere((prev) => ({ ...prev, type: [...where.type.filter((cat) => cat !== "Individual")] }));
                                        } else {
                                            setWhere((prev) => ({ ...prev, type: [...where.type, "Individual"] }));
                                        }
                                    }}
                                    className={`cursor-pointer ${where.type?.includes("Individual") ? "text-blue-600 border-b border-blue-600" : ""}`}
                                >
                                    Individual
                                </p>
                                <p
                                    onClick={() => {
                                        const isExist = where.type.includes("Corporate");
                                        if (isExist) {
                                            setWhere((prev) => ({ ...prev, type: [...where.type.filter((cat) => cat !== "Corporate")] }));
                                        } else {
                                            setWhere((prev) => ({ ...prev, type: [...where.type, "Corporate"] }));
                                        }
                                    }}
                                    className={`cursor-pointer ${where.type?.includes("Corporate") ? "text-blue-600 border-b border-blue-600" : ""}`}
                                >
                                    Corporate
                                </p>
                                <p
                                    onClick={() => {
                                        const isExist = where.type.includes("NPO");
                                        if (isExist) {
                                            setWhere((prev) => ({ ...prev, type: [...where.type.filter((cat) => cat !== "NPO")] }));
                                        } else {
                                            setWhere((prev) => ({ ...prev, type: [...where.type, "NPO"] }));
                                        }
                                    }}
                                    className={`cursor-pointer ${where.type?.includes("NPO") ? "text-blue-600 border-b border-blue-600" : ""}`}
                                >
                                    NPO
                                </p>
                            </div>
                            <div className="flex-grow  border w-full md:w-40 shadow-sm rounded-full relative h-full hidden md:flex items-center">
                                <input
                                    onChange={handleSearchChange}
                                    type="search"
                                    placeholder="Search by name/area"
                                    className="outline-none pl-8 border-none text-xs w-full h-6 rounded-full"
                                />
                                <Search className="text-gray-400 w-4 absolute left-2" />
                            </div>
                        </div>

                        {where.category.length > 0 && (
                            <div className="w-full text-sm hidden md:flex  p-2 rounded-full items-center  gap-2">
                                {where.category.map((item, ind) => {
                                    return (
                                        <div key={ind} className="flex items-center pr-2 rounded-2xl shadow-sm  cursor-pointer">
                                            <p className="pl-4 py-1">{item}</p>
                                            <CircleX
                                                onClick={() => {
                                                    setWhere((prev) => ({ ...prev, category: where.category.filter((cat) => cat !== item) }));
                                                }}
                                                className="hover:text-red-400 w-6 h-6 px-1   ml-1"
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        )}

                        {isLoading || loading ? (
                            <div className="flex justify-center items-center h-full w-full bg-white"></div>
                        ) : (
                            <>
                                <div className={`basis-1 ${showMap ? "" : "hidden md:hidden"} md:basis-1/2 mb-3 mt-2`}>
                                    <div className="flex justify-between items-center border mb-2 p-2 rounded">
                                        <p className="text-sm text-center">No.of locations : {projects.length}</p>
                                        <DropdownMenu open={dropdownOpen}>
                                            <DropdownMenuTrigger asChild onClick={handleDropDown}>
                                                <div className="rounded-full !px-4 font-medium w-fit bg-white py-1 text-sm text-black flex items-center justify-center">
                                                    <button className="text-sm">Show List</button>
                                                </div>
                                            </DropdownMenuTrigger>
                                            <DropdownMenuContent className="md:w-fit w-72 max-h-62 overflow-y-scroll">
                                                {projects.map((item, ind) => {
                                                    return (
                                                        <div
                                                            key={ind}
                                                            className="flex items-center px-2 py-2  hover:bg-gray-200 cursor-pointer text-sm"
                                                            onClick={() => {
                                                                setCurrentProject(item.id);
                                                                useMapStore.getState().actions.setZoomLevel(14);
                                                                useMapStore.getState().actions.showMarkers();
                                                                useProjectModalStore.getState().actions.setSelectedProject(item);
                                                                setDropdownOpen(false);
                                                            }}
                                                        >
                                                            {item.location_title}
                                                        </div>
                                                    );
                                                })}
                                            </DropdownMenuContent>
                                        </DropdownMenu>
                                    </div>
                                    <ProfileMap
                                        position={
                                            currentProject
                                                ? allProjects.find((project) => project.id === currentProject)?.location_coordinates
                                                : location_coordinates
                                        }
                                        initiatedProjects={initiatedProjects}
                                        currentProject={currentProject}
                                        allPositions={
                                            allProjects?.map((project) => {
                                                const [lng, lat] = project.location_coordinates.coordinates;
                                                return { lat, lng };
                                            }) ?? []
                                        }
                                        projectsData={allProjects ?? []}
                                    />
                                    {/* {currentProject && currentProject !== "" && <ProfileAnalytics chartIdPrefix={"mobile"} currentProject={currentProject} />} */}
                                </div>

                                <Fragment>
                                    <m.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 0.3, ease: "easeInOut" }}
                                        className="flex-grow md:h-[100px] h-screen pb-10 md:pb-0 overflow-auto  no-scrollbar"
                                    >
                                        {projects?.length > 0 ? (
                                            <Fragment>
                                                {projects?.map((project, index) => {
                                                    const isActive = project.id === currentProject;

                                                    return (
                                                        <Fragment key={index}>
                                                            <div
                                                                className={`my-2  md:mb-0 cursor-pointer   rounded-lg md:px-2 pb-2 md:pb-0 md:py-1 ${
                                                                    isActive ? "md:bg-[#f3f3f3] border-2 bg-gray-100 rounded-lg " : ""
                                                                }`}
                                                                onClick={() => {
                                                                    setCurrentProject(project.id);
                                                                    useMapStore.getState().actions.setZoomLevel(8);
                                                                    useMapStore.getState().actions.showMarkers();
                                                                }}
                                                                // onMouseEnter={() => debouncedShowMarkers(project.id)}
                                                                // onMouseLeave={() => {
                                                                //     setCurrentProject("");
                                                                //     useMapStore.getState().actions.setZoomLevel(14);
                                                                //     useMapStore.getState().actions.showMarkers();
                                                                // }}
                                                            >
                                                                <ProjectItem
                                                                    type={project.type}
                                                                    navigate={navigate}
                                                                    status={project.project_status}
                                                                    id={project.id}
                                                                    estimatedAmount={project.estimated_cost}
                                                                    expressionAggregate={
                                                                        project.expressions_aggregate.aggregate.sum.investment_amount
                                                                    }
                                                                    title={project.title}
                                                                    date={project.created_at}
                                                                    description={project.objective}
                                                                    location={project.location_title}
                                                                    category={project.category}
                                                                    secondaryCategory={project.secondary_category}
                                                                    profilePicture={project?.project_profile_picture}
                                                                    isAdmin={isAdmin}
                                                                    comments={project.comments}
                                                                    expressions={project.expressions}
                                                                />
                                                            </div>
                                                            <hr />
                                                        </Fragment>
                                                    );
                                                })}
                                            </Fragment>
                                        ) : (
                                            <div className="flex flex-col  justify-center items-center h-full w-full bg-white">
                                                <p className=" text-center text-sm  text-black/80">No Projects Found</p>
                                                {!authUser && (
                                                    <p
                                                        className=" text-center text-sm cursor-pointer underline  text-blue-500 mt-4"
                                                        onClick={() => navigate("/login")}
                                                    >
                                                        Please login to view projects
                                                    </p>
                                                )}
                                            </div>
                                        )}
                                    </m.div>

                                    {/* <div className="flex flex-col justify-end bg-red-500 relative bottom-0 items-center py-2 ">
                                            <Pagination count={Math.ceil(projects.length / 6)} page={page} onChange={handleChange} />
                                        </div> */}
                                </Fragment>
                            </>
                        )}
                    </div>
                </div>
            </div>

            <div className={`md:w-1/3 hidden md:block w-full bg-white p-2 rounded`}>
                <div className="">
                    <div className="flex justify-between items-center border mb-2 p-2 rounded">
                        <p className="text-sm text-center">No.of locations : {projects.length}</p>
                        <DropdownMenu open={dropdownOpen}>
                            <DropdownMenuTrigger asChild onClick={handleDropDown}>
                                <div className="rounded-full !px-4 font-medium w-fit bg-white py-1 text-sm text-black flex items-center justify-center">
                                    <button className="text-sm">Show List</button>
                                </div>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent className="w-fit max-h-62 overflow-y-scroll">
                                {projects.map((item, ind) => {
                                    return (
                                        <div
                                            key={ind}
                                            className="flex items-center px-2 py-2  hover:bg-gray-200 cursor-pointer text-sm"
                                            onClick={() => {
                                                setCurrentProject(item.id);
                                                useMapStore.getState().actions.setZoomLevel(14);
                                                useMapStore.getState().actions.showMarkers();
                                                useProjectModalStore.getState().actions.setSelectedProject(item);
                                                setDropdownOpen(false);
                                            }}
                                        >
                                            {item.location_title}
                                        </div>
                                    );
                                })}
                            </DropdownMenuContent>
                        </DropdownMenu>
                    </div>
                    <ProfileMap
                        position={
                            currentProject ? allProjects.find((project) => project.id === currentProject)?.location_coordinates : locationCoordinates
                        }
                        initiatedProjects={initiatedProjects ?? []}
                        currentProject={currentProject}
                        allPositions={
                            allProjects?.map((project) => {
                                const [lng, lat] = project.location_coordinates.coordinates;
                                return { lat, lng };
                            }) ?? []
                        }
                        projectsData={allProjects ?? []}
                    />
                    {currentProject && currentProject !== "" && <ProfileAnalytics currentProject={currentProject} />}
                </div>
            </div>
        </m.div>
    );
};
export default ProfileDetails;

ProjectDetails.propTypes = {
    variant: PropTypes.oneOf(["commented", "initiated", "default"]),
};
