import { useState, useEffect, Fragment, useContext, useMemo } from "react";
import { Modal, Container, Form, Button, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { useForgotPasswordMutation } from "types/generated-types.ts";
import MapSelectPlace from "views/Profile/MapSelectPlace";
import { validateEmail, validatePhoneNumber } from "utils";
import AuthUserContext from "contexts/session";
import { useAddExpressionMutation, useUpdateExpressionMutation } from "./../../types/generated-types.ts";
import "./CustomModal.styles.scss";

const CustomModal = (props) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        // if (props.isOpen) setShow(oldValue => !oldValue);
        setShow(props.isOpen);
    }, [props.isOpen]);

    const handleClose = () => {
        setShow(false);
        props.setOpen(false);
    };

    // const handleShow = () => setShow(true);
    return (
        <Modal centered className={props.isNotStyleDefault ? " md:block flex w-full justify-center" : ""} show={show} onHide={handleClose}>
            {/* modal close button */}
            {!props.isNotStyleDefault && (
                <Fragment>
                    <button type="button" className="close-button absolute right-4" onClick={handleClose}>
                        <i className="fa-solid fa-xmark"></i>
                    </button>

                    {!props.header && <header className="modal-header">{props.header}</header>}
                </Fragment>
            )}
            <Container handleClose={handleClose}>{props.children}</Container>
        </Modal>
    );
};

export default CustomModal;
CustomModal.propTypes = {
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
    header: PropTypes.any,
    children: PropTypes.any,
    nextModal: PropTypes.any,
    remainingFund: PropTypes.number,
    toggle: PropTypes.func,
    projectId: PropTypes.string,
};
// modal content

const RegisterSuccessModalContent = (props) => {
    return (
        <div className="text-center p-4">
            <h2>Success!</h2>
            <div className="my-3">Thanks for registering into EquiPPP™. Please check your registered email id and verify your account.</div>
            <Button variant="success" onClick={props.setOpen}>
                OK
            </Button>
        </div>
    );
};

const LoginSuccessModalContent = (props) => {
    return (
        <div className="text-center p-4">
            <h2>Sucessfully Logged In!</h2>
            <Button variant="success" onClick={props.setOpen}>
                OK
            </Button>
        </div>
    );
};

const ForgotPasswordModalContent = (props) => {
    // const [forgotEmailError, setForgotEmailError] = useState("");
    const [forgotPhoneNumberError, setForgotPhoneNumberError] = useState("");
    const [apiStatus, setApiStatus] = useState(true);
    const [forgotPassword] = useForgotPasswordMutation({
        onCompleted: (data) => {
            setApiStatus(true);
            toast.dismiss();
            toast.success("Password reset link has been sent to your email id.");
        },
    });
    const sendForgotPassword = (e) => {
        e.preventDefault();
        // setForgotEmailError("");
        setForgotPhoneNumberError("");
        // const emailId = e.target.email.value;
        const phoneNumber = e.target.phoneNumber.value;
        // console.log(emailId);
        // const emailCheck = validateEmail(emailId);
        const phoneNumberCheck = validatePhoneNumber(phoneNumber);
        if (!phoneNumberCheck.status) {
            setForgotPhoneNumberError(phoneNumberCheck.message);
            return;
        }
        setApiStatus(false);
        forgotPassword({
            // variables: {
            //     email: emailId,
            // },
            variables: {
                phone_number: phoneNumber,
            },
        });
    };

    return (
        <>
            {/* modal form body */}
            <div className="mt-4">PLEASE FILL OUT YOUR EMAIL. NEW PASSWORD WILL BE SENT THERE.</div>
            <Form onSubmit={sendForgotPassword} className="w-full md:w-1/3">
                <Form.Group className="mb-4">
                    {/* <Form.Control type="text" name="email" placeholder="Email" /> */}
                    <Form.Control type="text" name="phoneNumber" placeholder="enter your mobile number" />
                    {/* <small className="text-danger">{forgotEmailError}</small> */}
                    {forgotPhoneNumberError && <small className="text-danger">{forgotPhoneNumberError}</small>}
                </Form.Group>
                <div className="flex flex-col items-center w-full px-8 ">
                    <button
                        className="bg-[#130160] w-full text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="submit"
                        disabled={!apiStatus}
                    >
                        {apiStatus ? <>Send</> : <Spinner animation="border" variant="light" size="sm" />}
                    </button>
                </div>
            </Form>
        </>
    );
};

const ExpressInterestModalContent = ({ handleClose, ...props }) => {
    const { authUser } = useContext(AuthUserContext);
    // states to hold error messages of user inputs
    // const [participationTypeError, setParticipationTypeError] = useState("");
    // const [cashTypeError, setCashTypeError] = useState("");

    console.log(props);
    const [cashAmountError, setCashAmountError] = useState("");
    const [expressionMessageError, setExpressionMessageError] = useState("");
    const [apiStatus, setApiStatus] = useState(true);
    const [isCashType, setIsCashType] = useState(false);
    const { investmentType, participant_type: participationType } = props.projectDetails || JSON.parse(window.localStorage.getItem("projectDetails"));

    useEffect(() => {
        setIsCashType(["cash"].includes(participationType));
    }, []);

    console.log(props.maxFund, props.remainingFund);

    const [addExpression, { error, loading: expressionsLoading }] = useAddExpressionMutation({
        onCompleted: (data) => {
            console.log(data);
            toast.success("Your Expression was added successfully");
            if (data && data.insert_expressions_one.status) {
                props.setOpen();
                props.toggle(Math.random());

                // handleClose();
            }
            setApiStatus(true);
        },
    });

    const [updateExpression] = useUpdateExpressionMutation({
        /* refetchQueries: [
            {
                query: GetExpressionDocument,
                variables: {}
            },
        ], */
        onCompleted: (data) => {
            toast.success("Expression Updated Successfully");
            // console.log(data?.update_expressions?.returning[0]?.id)
            if (data?.update_expressions) {
                props.setOpen();
                props.toggle(Math.random());
            }
            setApiStatus(true);
        },
    });

    /**
     * send API to save expression if all user inputs are valid
     * @param {object} e event object of the submitted form holding user inputs
     * @returns {void} nothing or sets error values
     */
    const expressInterest = (e) => {
        setCashAmountError("");
        setExpressionMessageError("");
        e.preventDefault();
        setApiStatus(false);

        if (formData.participationType.length <= 0) {
            toast.error("Please Select a Participation Type");
            return "";
        }

        console.log(props);

        const enteredAmount = parseInt(formData.amount);
        const maxFund = parseInt(props.maxFund);
        const receivedFund = props.receivedFund ? parseInt(props.receivedFund.replace(/,/g, "")) : 0;
        const remainingFund = maxFund - receivedFund;

        if (enteredAmount > remainingFund) {
            toast.error(`The maximum allowable investment is ${remainingFund} due to remaining fund limits.`);
            setApiStatus(true);
            return;
        }

        if (!formData.participationType) {
            toast.error("Please select a participation type.");
            setApiStatus(true);
            return;
        }

        // if (!formData.participationType) {
        //     toast.error("Please select a participation type.");
        //     setApiStatus(true);
        //     return;
        // }

        if (props?.isExpressionEdit) {
            updateExpression({
                variables: {
                    _set: {
                        // investment_type: formData.participationType,
                        // participation_type: formData.participationType,
                        investment_amount: formData.amount,
                        expression_message: formData.message,
                    },
                    id: props.existingExpression?.exp_id,
                    project_id: props?.projectId,
                    // investment_amount: (props.projectDetails.investment_amount ? parseInt(props?.projectDetails.investment_amount) + formData.amount : 0) + (formData.amount ? formData.amount : 0),
                },
            });
        } else {
            addExpression({
                variables: {
                    project_id: props.projectId,
                    investmentAmount: formData.amount || "0",
                    // investmentType: formData.participationType,
                    expressionMessage: formData.message,
                    // participationType: formData.participationType,
                    added_by: authUser.id,
                },
            });
        }
    };

    const [formData, setFormData] = useState({
        participationType: participationType ? participationType : "0",
        amount: "",
        message: "",
    });

    useMemo(() => {
        if (props?.isExpressionEdit) {
            const { investment_amount, expression_message, investment_type } = props?.existingExpression;

            setFormData({
                ...formData,
                participationType: investment_type,
                amount: investment_amount,
                message: expression_message,
            });
        }
    }, [props]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "amount") {
            // Only allow numeric input for the amount field
            const newValue = value.replace(/[^0-9]/g, "");

            setFormData({
                ...formData,
                [name]: newValue,
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    // const toggleCashType = e => setIsCashType(e.target.value === "cash");

    return (
        <Form onSubmit={expressInterest}>
            {/* <Grid container justifyContent="flex-start" alignItems="center">
                <Grid item xs={12} container alignItems="center">
                    <Typography className="label"> Participation Type</Typography>
                </Grid>
                <Grid item xs={12} container alignItems="center">
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formData.participationType}
                        onChange={handleChange}
                        fullWidth
                        name="participationType"
                    >
                        {(participationType === "equity" || participationType === "equity,grant" || participationType === "equity,kind,grant") && (
                            <MenuItem value={"equity"}>Equity</MenuItem>
                        )}
                        {(participationType === "kind" || participationType === "equity,kind,grant") && <MenuItem value={"kind"}>Kind</MenuItem>}
                        {(participationType === "grant" || participationType === "equity,kind,grant" || participationType === "equity,grant") && (
                            <MenuItem value={"grant"}>Grant</MenuItem>
                        )}
                    </Select>
                </Grid>
            </Grid> */}

            {/* {formData.participationType === "equity" || formData.participationType === "grant" ? ( */}
            <Grid container justifyContent="flex-start" alignItems="center" className="pt-4 mb-4">
                <Grid item xs={12} container alignItems="center">
                    <Typography className="label">Investment Amount</Typography>
                </Grid>
                <Grid item xs={12} container alignItems="center">
                    <input
                        id=""
                        type="text"
                        required
                        className="w-full border rounded mt-1 outline-none p-2"
                        placeholder="20,000"
                        name="amount"
                        onChange={handleChange}
                        value={formData.amount}
                    />
                </Grid>
            </Grid>
            {/* // ) : (
            //     ""
            // )} */}
            <Grid container justifyContent="flex-start" alignItems="center">
                <Grid item xs={12} container alignItems="center">
                    <Typography className="label">Message</Typography>
                </Grid>
                <Grid item xs={12} container alignItems="center">
                    <textarea
                        id=""
                        type="text"
                        required
                        fullWidth
                        className="border rounded w-full p-2 mt-1"
                        placeholder="Enter Message"
                        name="message"
                        onChange={handleChange}
                        value={formData.message}
                    />
                </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems="center">
                <button type="submit" className="border rounded text-white px-5 w-max py-1 my-4 bg-blue-600">
                    Express
                </button>
            </Grid>
            {/* <div>
                <div>Message</div>
                <textarea className="w-100" name="expression-message" id="expression-message" rows="3"></textarea>
                <small className="text-danger">{expressionMessageError ? expressionMessageError : ""}</small>
            </div> */}
            {/* <div className="submit-container">
                <Button className="submit" type="submit" disabled={!apiStatus}>
                    {apiStatus ? <>Participate</> : <Spinner animation="border" variant="light" size="sm" />}
                </Button>
            </div> */}
        </Form>
    );
};

const FeedbackModalContent = (props) => {
    const [feedbackFirstNameError, setFeedbackFirstNameError] = useState("");
    const [feedbackLastNameError, setFeedbackLastNameError] = useState("");
    const [feedbackProjectNameError, setFeedbackProjectNameError] = useState("");

    const submitFeedback = (e) => {
        e.preventDefault();
        props.setOpen();
    };

    return (
        <Form onSubmit={submitFeedback}>
            <Form.Group>
                <Form.Control type="text" name="firstName" placeholder="First Name" />
                <small className="text-danger">{feedbackFirstNameError}</small>
            </Form.Group>
            <Form.Group>
                <Form.Control type="text" name="lastName" placeholder="Last Name" />
                <small className="text-danger">{feedbackLastNameError}</small>
            </Form.Group>
            <Form.Group>
                <Form.Control type="text" name="projectName" placeholder="Project Name" />
                <small className="text-danger">{feedbackProjectNameError}</small>
            </Form.Group>
            {/* <Form.Select>
      <option value="" selected>--Select--</option>
      <option value="project1">Project 1</option>
      <option value="project2">Project 2</option>
    </Form.Select> */}
            <textarea
                style={{ border: "1px solid #ced4da" }}
                className="w-100"
                name="message"
                id="message"
                placeholder="  Feedback Message"
                rows="3"
            ></textarea>
            <div className="submit-container">
                <Button className="submit" type="submit">
                    Submit
                </Button>
            </div>
        </Form>
    );
};

const MapModalContent = (props) => {
    return (
        <Grid container>
            <MapSelectPlace onInputChange={props.onInputChange} onLocationChange={props.onLocationChange} isCustomeHeight={true} />
        </Grid>
    );
};

export {
    RegisterSuccessModalContent,
    LoginSuccessModalContent,
    ForgotPasswordModalContent,
    ExpressInterestModalContent,
    FeedbackModalContent,
    MapModalContent,
};
