import React from "react";

const PemmasaniLandingPage2 = () => {
    return (
        <div className="w-full relative bg-white overflow-hidden text-left text-base text-colors-gray666 font-poppins">
            <main className="hero-section md:flex justify-between items-left rounded-[21.22px] bg-opacity-100 w-full md:w-[800px] xl:w-[1358px] mx-auto mt-8 md:mt-12 md:pl-4">
                {/* Left Column (Text) */}
                <div className="md:w-[478px] xl:w-[478px] md:mx-auto xl:mx-0 md:pt-4 xl:pt-8">
                    <div className="text-justify md:text-left pr-4 md:pr-8">
                        <div className="md:w-[400px] xl:w-[600px] md:mx-auto xl:mx-0">
                            <div>
                                <p className="text-l text-left font-bold text-darkslateblue md:pt-0 pt-4 md:pl-0 pl-4">
                                    About Dr. Chandra Sekhar Pemmasani
                                </p>
                                <p className="text-base text-black1 opacity-60 md:pt-4 xl:pt-8 md:pr-0 pr-4 md:pl-0 pl-4">
                                    Dr. Chandra Sekhar Pemmasani is a graduate of Osmania Medical College in Hyderabad, India. He ranked 27th out of
                                    60,000 students who took the EAMCET medical school entrance examination, one of the most competitive examinations
                                    in the country. He completed his residency in internal medicine at Geisinger Medical Center in Danville,
                                    Pennsylvania, and obtained the highest percentile scores in most board examinations. During his postgraduate
                                    training, he represented the state of Pennsylvania in a national medical knowledge competition for 2 years. He
                                    then worked at Johns Hopkins University – Sinai Hospital for about 5 years as an attending physician, teaching
                                    residents and medical students.
                                </p>
                                <p className="text-base text-black1 opacity-60 md:pt-4 xl:pt-8 md:pr-0 pr-4 md:pl-0 pl-4">
                                    Dr. Pemmasani has worked in a free medical clinic providing care to uninsured/underinsured patients in the Indian
                                    community of Dallas. In India, he helped provide free and clean water to residents of the Palnadu area for over 10
                                    years, in the Guntur district, and donated land for a community hall in his father’s village. He has also provided
                                    educational funding to a number of children and adults and has made charitable donations to local community
                                    organizations. In addition, through the CSR and foundation activities, he continues the philanthropic activities
                                    focusing on education, global crisis, and service basic needs.
                                </p>
                                <p className="text-base text-black1 opacity-60 md:pt-4 xl:pt-8 md:pr-0 pr-4 md:pl-0 pl-4">
                                    What began as a passion project in their college dorm room has blossomed into a thriving company that students and
                                    professionals turn to when they require the best learning tools to prepare them for their pivotal exams. In 2001,
                                    during their medical residency, they began writing case-based learning questions for the US Medical Licensing Exam
                                    due to the poor quality and high expense of existing study resources.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Right Column (Image) */}
                <div className="xs:w-[100px] xl:w-[600px] md:ml-4  md:pr-0 pr-4 md:pl-0 pl-4 md:pb-0 pb-4">
                    <img
                        className="w-full h-auto mx-auto my-4 md:mx-0 md:pr-8 xl:pr-12 md:pt-4 xl:pt-8"
                        alt="Sridhar"
                        src="/assets/images/pemmasani.jpg"
                    />
                </div>
            </main>

            {/* Overview Section */}
            <div className="overview-section bg-opacity-100 w-full  mt-8   flex pl-[4.5rem]  md:pr-0 pr-4">
                {/* Text Container */}
                <div className="max-w-[960px]  lg:pl-0">
                    <p className="text-base md:text-l text-black1 opacity-60 mt-4">
                        Today, they oversee a growing company that leads the market in several different verticals, including medical, nursing,
                        pharmacy, law, accounting, and finance licensing exam prep. The hallmark of UWorld is an obsession with quality, driven by the
                        dedicated work of talented employees who are passionate educators and industry experts. This dedication has helped them create
                        the highest quality educational tools for high-stakes exams.
                    </p>
                    <p className="text-base md:text-l text-black1 opacity-60 mt-4">
                        Since embarking on this journey, their guiding principle has been to support and uplift others. This personal mission fuels
                        their daily efforts to empower individuals in their educational endeavors and professional growth. For them, philanthropy is a
                        natural extension of this ethos, engaging with their community driven by a genuine desire to contribute, not out of
                        obligation.
                    </p>
                    <p className="text-base md:text-l text-black1 opacity-60 mt-4">
                        They are dedicated to forging connections with local organizations that leave a lasting, positive mark on education. By
                        dedicating their time, skills, and resources, they aim to make a meaningful difference. From the people they surround
                        themselves with to the principles they uphold, their aspiration is for their legacy to be defined by the individuals they've
                        had the privilege to assist.
                    </p>
                </div>
            </div>

            <div className="flex text-l font-bold text-primary-text flex items-center md:pt-16 xl:pt-20 md:pl-4 xl:pl-8 md:pb-0 pb-4 md:pt-0 pt-4">
                <hr className="ml-4 mr-2 h-[1px] w-[120px] bg-primary-text " />
                <span> VIDEO</span>
            </div>
            <div className="flex justify-center items-center bg-yellow-light w-full md:h-[400px] xl:h-[800px] md:pt-8 xl:pt-12 md:pb-4 xl:pb-8 md:pl-12 xl:pl-8 md:pr-4 xl:pr-8 md:pl-0 pl-4 md:pr-0 pr-4 md:pb-0 pb-4">
                <iframe
                    className="w-full h-full"
                    src="https://www.youtube.com/embed/ReQ4ObDGggQ"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                ></iframe>
            </div>
        </div>
    );
};

export default PemmasaniLandingPage2;
