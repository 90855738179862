import React, { Fragment, useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useGetProjectQuery } from "types/generated-types.ts";
import verified from "../../assets/user-verified.png";
import "./ProjectReport.styles.scss";

const ProjectReport = React.forwardRef((props, ref) => {
    const { data: projectData } = useGetProjectQuery({
        variables: {
            id: props?.projectId,
        },
    });
    const {
        category,
        start_date,
        title,
        phone,
        email,
        type,
        end_date,
        user,
        objective,
        scope,
        problem,
        conditions,
        impact_of_project,
        govt_authority_name,
        agency_name,
        agency_contact,
        estimated_cost,
        approved_value,
        participant_type,
    } = projectData?.projects?.[0] || {};

    const projectInformations = [
        {
            title: "Project Category",
            value: category,
        },
        {
            title: "Project Start Date",
            value: start_date,
        },
        {
            title: "Name of Unit",
            value: title,
        },
        {
            title: "Primary Contact No.",
            value: phone,
        },
        {
            title: "Primary Email Contact",
            value: email,
        },
        {
            title: "Project Type",
            value: type,
        },
        {
            title: "Project End Date",
            value: end_date,
        },
        {
            title: "Organisation Name",
            value: user?.user_name,
        },
        {
            title: "Secondary Contact",
            value: user?.mobile,
        },
        {
            title: "Secondary Email Contact",
            value: user?.email_id,
        },
    ];

    const projectDetailedInformation = [
        {
            title: "Project Category",
            value: category,
        },
        {
            title: "Objective",
            value: objective,
        },
        {
            title: "Scope",
            value: scope,
        },
        {
            title: "Need of Project",
            value: objective,
        },
        {
            title: "Problem",
            value: problem,
        },
        {
            title: "Conditions",
            value: conditions,
        },
        {
            title: "Impact of Project",
            value: impact_of_project,
        },
        {
            title: "Targeted Govt. authority",
            value: govt_authority_name,
        },
        {
            title: "Implementing Agency Name",
            value: agency_name,
        },
        {
            title: "Implementing Agency Contact",
            value: agency_contact,
        },
        {
            title: "Implementing Agency Self",
            value: "NA",
        },
    ];

    const projectFinancialInformation = [
        {
            title: "Estimated Project Cost",
            value: estimated_cost,
        },
        {
            title: "Approved Value",
            value: approved_value,
        },
        {
            title: "Participation Type",
            // capitalize first letter
            value: participant_type
                ?.split(",")
                .map((type) => type.charAt(0).toUpperCase() + type.slice(1))
                .join(", "),
        },
        {
            title: "",
            value: "",
        },
    ];

    const [commentsFound, setCommentsFound] = useState(false);
    const [expertCommentFound, setExpertCommentFound] = useState(false);
    useEffect(() => {
        if (projectData) {
            projectData?.projects?.[0]?.comments?.map((comment, index) => {
                if (comment?.user?.role === "sme") {
                    setExpertCommentFound(true);
                } else {
                    setCommentsFound(true);
                }
            });
        }
    }, [projectData]);
    return (
        <Grid container className="project_report_container" ref={ref}>
            <Grid container item xs={12} justifyContent="space-between" alignItems="center" className="header">
                <img src="https://equippp-assets.s3.ap-south-1.amazonaws.com/images/equippp-logo.png" alt="Equipp" />
                <Grid item xs={4} className="header-textarea">
                    <Typography variant="h5">Project Report from Equippp.com</Typography>
                    <Grid container item xs={12} justifyContent="space-between" alignItems="flex-start" className="address-wrapper">
                        <Grid item xs="auto">
                            <Typography style={{ color: "black", marginRight: "0.5rem" }}>
                                <strong>Address :</strong>
                            </Typography>
                        </Grid>
                        <Grid item xs={true}>
                            <Typography>{projectData?.projects?.[0]?.location_title}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item xs={12} className="basic-info-tab">
                <Grid container item xs={12} alignItems="center" className="tabs-header">
                    <Typography>Project Basic Information</Typography>
                </Grid>
                <Grid container item xs={12}>
                    {projectInformations.map((info, index) => (
                        <Grid item container className="tabs-content" alignItems="center" xs={6} key={index}>
                            <Grid item xs={5}>
                                <h5>{info.title} :</h5>
                            </Grid>
                            <Grid item xs={true}>
                                <p>{info.value || "NA"}</p>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <br />
            <br />
            <Grid container className="detailed-info" item xs={12}>
                <Grid container xs={12} item alignItems="center" className="tabs-header">
                    <Typography>Project Detailed Information</Typography>
                </Grid>
                <Grid container xs={12} item>
                    {projectDetailedInformation.map((info, index) => (
                        <Grid item container className="tabs-content" alignItems="center" xs={12} key={index}>
                            <Grid item xs={3}>
                                <h5>{info.title} :</h5>
                            </Grid>
                            <Grid item xs={true}>
                                <p>{info.value || "NA"}</p>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Grid container item xs={12} className="financial-info" style={{ marginTop: "1.5rem" }}>
                <Grid container xs={12} item alignItems="center" className="tabs-header">
                    <Typography>Project Financials/Participation</Typography>
                </Grid>
                <Grid container xs={12} item>
                    {projectFinancialInformation.map((info, index) => (
                        <Grid item container className="tabs-content" alignItems="center" xs={6} key={index}>
                            <Grid item xs={5}>
                                {info.title && <h5>{info.title} :</h5>}
                            </Grid>
                            <Grid item xs={true}>
                                <p>{info.value ?? "NA"}</p>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Grid container item xs={12} className="comments-section" style={{ marginTop: "1.5rem" }}>
                <Grid container xs={12} item alignItems="center" className="tabs-header">
                    <Typography>Comments</Typography>
                </Grid>
                <Grid container xs={12} item className="tabs-content">
                    {projectData?.projects?.[0]?.comments?.map((comment, index) => {
                        return comment?.user?.role !== "sme" ? (
                            <Grid sx={{ my: 1 }} key={index} item container alignItems="center" xs={12}>
                                <Grid item xs={2}>
                                    <h5>Comment {index + 1} :</h5>
                                </Grid>
                                <Grid item xs={10}>
                                    <p>{comment.comment}</p>
                                </Grid>
                            </Grid>
                        ) : (
                            <Fragment></Fragment>
                        );
                    })}
                    {projectData?.projects?.[0]?.comments?.length === 0 ||
                        (!commentsFound && (
                            <Typography variant="h5" sx={{ my: 1 }}>
                                No Comments Found
                            </Typography>
                        ))}
                </Grid>
            </Grid>
            <Grid container item xs={12} className="comments-section">
                <Grid container xs={12} item alignItems="center" className="tabs-header">
                    <Typography>Expert Comments</Typography>
                </Grid>
                <Grid container xs={12} item className="tabs-content">
                    {projectData?.projects?.[0]?.comments?.map((comment, index) => {
                        return comment?.user?.role === "sme" ? (
                            <Grid sx={{ my: 1 }} key={index} item container alignItems="center" xs={12}>
                                <Grid item xs={2}>
                                    <h5>Comment {index + 1} :</h5>
                                </Grid>
                                <Grid item xs={10}>
                                    <p>{comment.comment}</p>
                                </Grid>
                            </Grid>
                        ) : (
                            <Fragment></Fragment>
                        );
                    })}
                    {projectData?.projects?.[0]?.comments?.length === 0 ||
                        (!expertCommentFound && (
                            <Typography variant="h5" sx={{ my: 1 }}>
                                No Comments Found
                            </Typography>
                        ))}
                </Grid>
            </Grid>
            <Grid container item xs={12} className="comments-section">
                <Grid container xs={12} item alignItems="center" className="tabs-header">
                    <Typography>Project Expressions</Typography>
                </Grid>
                <Grid container xs={12} item className="tabs-content">
                    <Grid sx={{ my: 1 }} item container alignItems="center" xs={12}>
                        <Grid item xs={2}></Grid>
                        <Grid item container justifyContent="center" alignItems="center" xs={2}>
                            <h5>User Name</h5>
                        </Grid>
                        <Grid item xs={2} container justifyContent="center" alignItems="center">
                            <p>Participation Type</p>
                        </Grid>
                        <Grid container justifyContent="center" alignItems="center" item xs={2}>
                            <h5>Investment Amount</h5>
                        </Grid>
                    </Grid>
                    {projectData?.projects?.[0]?.expressions?.map((exp, index) => {
                        return (
                            <Grid sx={{ my: 1 }} key={index} item container alignItems="center" xs={12}>
                                <Grid item xs={2}>
                                    <h5>Expression {index + 1} :</h5>
                                </Grid>
                                <Grid item xs={2} container justifyContent="center" alignItems="center">
                                    <p>
                                        {exp.user?.user_name}
                                        {exp.user?.kyc_status === "accepted" && (
                                            <Tooltip title="Verified" arrow placement="top">
                                                <img src={verified} alt="" width={"20px"} height={"20px"} style={{ marginLeft: "2px" }} />
                                            </Tooltip>
                                        )}
                                    </p>
                                </Grid>
                                <Grid item xs={2} container justifyContent="center" alignItems="center">
                                    <p>{exp.investment_type}</p>
                                </Grid>
                                <Grid item xs={2} container justifyContent="center" alignItems="center">
                                    <p>{exp.investment_amount}</p>
                                </Grid>
                            </Grid>
                        );
                    })}
                    <Grid container className="tabs-content"></Grid>
                    <Grid item container xs={12} sx={{ my: 1 }}>
                        <Grid item xs={2}>
                            <h5>Total Collected Amount :</h5>
                        </Grid>
                        <Grid item xs={2} />
                        <Grid item xs={2} />
                        <Grid item xs={2} container justifyContent={"center"} alignItems="center">
                            <Typography variant="h5">{projectData?.projects?.[0]?.expressions_aggregate.aggregate.sum.investment_amount}</Typography>
                        </Grid>
                    </Grid>
                    {projectData?.projects?.[0]?.expressions?.length === 0 && (
                        <Typography variant="h5" sx={{ my: 1 }}>
                            No Expressions Found
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
});

export default ProjectReport;
