import { React, useState, useEffect, Fragment } from "react";
import Linkify from "react-linkify";
import { toast } from "react-toastify";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useUpdateCommentStatusMutation, useDeleteCommentMutation } from "types/generated-types.ts";
import MentionToLinks from "views/Project/ProjectComments/components/MentionToLinks";
import "./PendingComments.scss";

const PendingComments = (props) => {
    const [accessToken, setAccessToken] = useState(false);
    const [tableRows, setTableRows] = useState([]);
    const [userType, setUserType] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleChange = (e) => {
        setUserType(e.target.value);
    };
    useEffect(() => {
        const token = window.localStorage.getItem("authToken");
        setAccessToken(token);
        const rows = props.commentDetails?.map((el) => {
            let commentText = el.comment;
            if (el.comment.includes("@[")) {
                commentText = <MentionToLinks key={el.id} text={el.comment} />;
            } else if (el.comment.includes("http")) {
                commentText = <Linkify key={el.id}>{el.comment}</Linkify>;
            }
            return {
                projectName: el.project.title,
                comment: commentText,
                commentedBy: el.user.email_id,
                commentedOn: el.created_at.substring(0, 10),
                userRole: el.user.role,
                addBtn: (
                    <Grid container className="action__btns">
                        <Grid item xs={6}>
                            <button onClick={() => approveComment(el.id)} className="approve">
                                <img src={`https://equippp-assets.s3.ap-south-1.amazonaws.com/images/success.png`} alt="" className=" w-6 h-6" />
                            </button>
                        </Grid>

                        <Grid item xs={6}>
                            <button onClick={() => rejectComment(el.id)} className="reject">
                                <img src={`https://equippp-assets.s3.ap-south-1.amazonaws.com/images/close.png`} alt="" className="w-6 h-6" />
                            </button>
                        </Grid>
                    </Grid>
                ),
            };
        });
        if (userType === "") {
            setTableRows(rows);
        } else {
            let filteredItems = rows.filter((item) => {
                return item.userRole === userType;
            });
            setTableRows(filteredItems);
        }
    }, [props.commentDetails, userType]);

    const [updateCommentStatus] = useUpdateCommentStatusMutation({
        onCompleted: (data) => {
            toast.success("Comment Approved Successfully");
            if (data && data.response) {
                props.action("approve", data.response.id);
            }
        },
    });

    const [deleteComment] = useDeleteCommentMutation({
        onCompleted: (data) => {
            toast.success("Comment Rejected Successfully");
            props.action("reject", data.id);
        },
    });
    const approveComment = (commentId) => {
        updateCommentStatus({
            variables: {
                _set: {
                    is_verified: true,
                },
                id: commentId,
            },
        });
    };

    const rejectComment = (commentId) => {
        deleteComment({
            variables: {
                id: commentId,
            },
        });
    };

    return (
        <Fragment>
            {!!accessToken ? (
                <Grid item className="pending-comments__container">
                    <TableContainer>
                        <Table>
                            <TableHead className="table-header-font-style">
                                <TableRow>
                                    <TableCell>Project Name</TableCell>
                                    <TableCell>Comments</TableCell>
                                    <TableCell>
                                        <Grid container alignItems="center" justifyContent="space-betweenr" className="commented-by-column">
                                            <span>Commented By</span>
                                            <Select id="demo-simple-select" value={userType} onChange={handleChange}>
                                                <MenuItem value={"user"}>user</MenuItem>
                                                <MenuItem value={"sme"}>sme</MenuItem>
                                                <MenuItem value={"expert"}>expert</MenuItem>
                                                <MenuItem value={"marquee"}>marquee</MenuItem>
                                            </Select>
                                        </Grid>
                                    </TableCell>
                                    <TableCell>Commented On</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableRows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="left" component="td" scope="row">
                                            {row.projectName}
                                        </TableCell>
                                        <TableCell>{row.comment}</TableCell>
                                        <TableCell>{row.commentedBy}</TableCell>
                                        <TableCell style={{ whiteSpace: "nowrap", width: "8rem" }}>{row.commentedOn}</TableCell>
                                        <TableCell className="rm-td__padding">{row.addBtn}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {tableRows && tableRows.length > 0 && (
                        <TablePagination
                            component="div"
                            count={tableRows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            rowsPerPageOptions={[5, 10]}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    )}
                </Grid>
            ) : (
                <>Not logged in</>
            )}
        </Fragment>
    );
};

export default PendingComments;
