import { Fragment, useState, useEffect, useMemo, useContext } from "react";
import ContactDetails from "./ContactDetails";
import { toast } from "react-toastify";
import Invite from "./Invite";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LinesEllipsis from "react-lines-ellipsis";
import ProfileMap from "./components/ProfileMap";
import ProjectItem from "./components/ProjectItem";
import { useLocation } from "react-router-dom";
import { CircleX } from "lucide-react";
import { useAtom } from "jotai";
import { atomWithHash } from "jotai/utils";
import { Filter } from "lucide-react";
import "./ProfileDetails.styles.scss";
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "components/ui/dropdown-menu";
import { Search } from "lucide-react";
import AuthUserContext from "contexts/session";
import { useGetCurrentUserLazyQuery } from "types/generated-types.ts";
import { create } from "zustand";
import clsx from "clsx";
import { useMapStore } from "views/ProfileDetails/components/ProfileMap/Map";
import { useProjectModalStore } from "views/ProfileDetails/components/ProfileMap/ProfileMap";
import ProfileAnalytics from "./components/profile-analytics";

const categories = [
    "Community",
    "Education",
    "Environment",
    "Healthcare",
    "Housing",
    "InfrastructurePower",
    "Roads & Bridges",
    "Social Justice",
    "Technology",
    "Waste Management",
    "Water & Sanitization",
    "Other",
];

const userTypes = ["All", "CSR", "MP / MLA LADS", "Probono", " Individual", "Corporate"];

const useExecutedStore = create((set) => ({
    isExecuted: false,
    actions: {
        toggle: () => set((state) => ({ isExecuted: !state.isExecuted })),
    },
}));

const useExpandedStore = create((set) => ({
    expandProfession: false,
    expandDescription: false,
    setExpandProfession: (value) => set({ expandProfession: value }),
    setExpandDescription: (value) => set({ expandDescription: value }),
}));

const NameAndProfession = ({ isLoading, user_type_details }) => {
    const { authUser } = useContext(AuthUserContext);

    const { expandProfession, expandDescription, setExpandProfession, setExpandDescription } = useExpandedStore();

    return (
        <div className="flex flex-col w-full text-center md:!text-left md:w-2/3 ml-4 ">
            <p className="text-2xl font-semibold text-[#1C2B52] w-full">
                {isLoading ? (
                    "...loading"
                ) : user_type_details?.name ? (
                    user_type_details?.name
                ) : (
                    <span className="text-underline">name is not added</span>
                )}
            </p>
            {expandProfession ? (
                <p className="text-sm text-[#1C2B52] w-full">
                    {user_type_details?.profession}

                    <span className="ml-2 text-center cursor-pointer text-blue-500" onClick={() => setExpandProfession(false)}>
                        see less...
                    </span>
                </p>
            ) : (
                <LinesEllipsis
                    text={user_type_details?.profession || "No profession listed"}
                    maxLine="3"
                    className="text-sm text-[#1C2B52] w-full"
                    ellipsis={
                        <span className="ml-2 text-center cursor-pointer text-blue-500" onClick={() => setExpandProfession(true)}>
                            see more...
                        </span>
                    }
                    trimRight
                    basedOn="letters"
                />
            )}
        </div>
    );
};
const AdminContactAndInvite = ({ emailId, phoneNumber }) => {
    const { authUser } = useContext(AuthUserContext);

    const { isExecuted, actions } = useExecutedStore();

    return (
        <Fragment>
            <div className="flex flex-wrap  items-center gap-x-2 w-full md:justify-end justify-center  md:h-fit">
                <button
                    onClick={() => {
                        actions.toggle();
                    }}
                    className={`text-[13px] w-fit flex items-center ${
                        isExecuted ? "bg-[#1E5EFF]  !text-white" : "bg-[#d9d9d9] !text-[#1C2B52]"
                    }  text-center text-white px-2 py-1 flex items-center  rounded-full`}
                >
                    <img src={`/assets/images/project-grp${isExecuted ? "-white" : ""}.png`} alt="contact" className="h-4 w-4 mr-2" />
                    <p className="text-xs">{isExecuted ? "OnGoing Projects" : "Executed Projects"}</p>
                </button>
                <ContactDetails email={emailId} mobile={phoneNumber} />
                {(authUser?.role === "admin" || authUser?.is_invited_to_all_projects) && <Invite />}
            </div>
        </Fragment>
    );
};

const AdminSocialLink = ({ linkedin_link, facebook_link, twitter_link }) => {
    const { authUser } = useContext(AuthUserContext);
    return (
        <div className="md:flex hidden gap-2 pl-14">
            <img
                src="/assets/images/linked-in.png"
                alt="linkedIn"
                onClick={() => {
                    if (linkedin_link) {
                        window.open(linkedin_link);
                    } else {
                        toast.error("Linkedin link not available");
                    }
                }}
                className="h-[20px] w-[24px] cursor-pointer "
            />
            <img
                src="/assets/images/facebook-logo.png"
                alt="facebook"
                className="h-[22px] w-[28px] cursor-pointer "
                onClick={() => {
                    if (facebook_link) {
                        window.open(facebook_link);
                    } else {
                        toast.error("Facebook link not available");
                    }
                }}
            />
            <img
                src="/assets/images/twitter-x.png"
                alt="twitter-x"
                className="h-[20px] w-[18px] cursor-pointer "
                onClick={() => {
                    if (twitter_link) {
                        window.open(twitter_link);
                    } else {
                        toast.error("Twitter link not available");
                    }
                }}
            />
        </div>
    );
};

const emptyArray = [];

const LeftPanel = ({
    allProjects,
    currentProject,
    loading,
    setCurrentProject,
    isExecuted,
    setIsExecuted,
    where,
    setWhere,
    setLocationCoordinates,

    showMap,
    setShowMap,
}) => {
    const navigate = useNavigate();

    const { authUser } = useContext(AuthUserContext);

    const [projects, setProjects] = useState();

    const [expandProfession, setExpandProfession] = useState(false);
    const [expandDescription, setExpandDescription] = useState(false);

    const [
        {
            kyc_status,
            user_type_details,
            profession,
            location_title,
            location_coordinates,
            description,
            gender,
            is_private,
            commentedProjects,
            promotedProjects,
            initiatedProjects,
            expressedProjects,
            profile_picture,
            twitter_link,
            facebook_link,
            linkedin_link,
        },
        setValue,
    ] = useState({
        kyc_status: "",
        user_type_details: {},
        profession: "",
        location_title: "",
        location_coordinates: { coordinates: [] },
        description: "",
        gender: "",
        is_private: false,
        commentedProjects: emptyArray,
        expressedProjects: emptyArray,
        promotedProjects: emptyArray,
        initiatedProjects: emptyArray,
        expressedProjects: emptyArray,
        profile_picture: "",
        twitter_link: "",
        facebook_link: "",
        linkedin_link: "",
    });

    useEffect(() => {
        if (location_coordinates) {
            setLocationCoordinates(location_coordinates);
        }
    }, [location_coordinates]);

    const projectsFilterAtom = useMemo(() => atomWithHash("projectsFilter", "All projects"), []);
    const [projectsFilter, setProjectsFilter] = useAtom(projectsFilterAtom);

    const { search } = useLocation();
    const isAdmin = (authUser && authUser.role) === "admin";

    const [page, setPage] = useState(1);
    const [currentProjects, setCurrentProjects] = useState(null);

    const [projectsPerPage] = useState(4);

    const handleChange = (event, value) => {
        setPage(value);
    };

    // get id from search
    const id = useMemo(() => {
        return search.substr(4);
    }, [search]);

    const [getUsers, { data, loading: isLoading }] = useGetCurrentUserLazyQuery();

    useEffect(() => {
        if (id) {
            getUsers({
                variables: {
                    id: id,
                },
            });
        }
    }, [id, getUsers]);

    useEffect(() => {
        if (!data) return;
        const {
            kyc_status,
            profession,
            location_title,
            location_coordinates,
            description,
            gender,
            is_private,
            user_type_details,
            initiatedProjects = emptyArray,
            commentedProjects = emptyArray,
            promotedProjects = emptyArray,
            expressedProjects = emptyArray,
            profile_picture,
            twitter_link,
            facebook_link,
            linkedin_link,
        } = data?.users_by_pk || {};

        setValue({
            user_type_details: JSON.parse(user_type_details || "{}"),
            kyc_status: kyc_status,
            profession: profession,
            location_title: location_title,
            location_coordinates: location_coordinates,
            description: description,
            gender: gender,
            is_private: is_private,
            initiatedProjects: initiatedProjects.filter((item) => item.is_active === true),
            commentedProjects: commentedProjects.filter((item) => item.project.is_active === true).map((item) => item.project),
            promotedProjects: promotedProjects.filter((item) => item.project.is_active === true).map((item) => item.project),
            expressedProjects: expressedProjects.filter((item) => item.project.is_active === true).map((item) => item.project),
            profile_picture: profile_picture,
            twitter_link: twitter_link,
            facebook_link: facebook_link,
            linkedin_link: linkedin_link,
        });
    }, [data]);

    useEffect(() => {
        const indexOfLastProject = page * projectsPerPage;

        const indexOfFirstProject = indexOfLastProject - projectsPerPage;

        // setTotalPages(Math.ceil(projects?.length / projectsPerPage));
        setCurrentProjects(projects?.slice(indexOfFirstProject, indexOfLastProject));
    }, [projects, page]);

    const handleFilterChange = (event) => {
        setProjectsFilter(event.target.value);
    };

    const handleSearchChange = (event) => {
        setWhere((prev) => ({ ...prev, search: event.target.value.toLowerCase() }));
    };

    useEffect(() => {
        let filteredByType = [];

        const initiatedProjects = allProjects.filter((project) => project.added_by);
        // First, filter projects by type

        if (projectsFilter === "All projects") {
            filteredByType = allProjects;
        } else if (projectsFilter === "initiated") {
            filteredByType = initiatedProjects;
        } else if (projectsFilter === "promoted") {
            filteredByType = [...promotedProjects];
        } else if (projectsFilter === "commented") {
            filteredByType = [...commentedProjects];
        } else if (projectsFilter === "expressed") {
            filteredByType = [...expressedProjects];
        }

        setProjects(filteredByType);
    }, [projectsFilter, initiatedProjects, commentedProjects, promotedProjects, expressedProjects, allProjects]);

    console.log({ user_type_details });

    return (
        <Fragment>
            <div className="md:w-2/3 w-full md:!rounded rounded-none shadow-none bg-white flex flex-col">
                <div className="md:w-2/3 w-full md:!rounded rounded-none shadow-none bg-white flex flex-col">
                    <div className="bg-[#e2e3e9] md:!shadow-t-md md:!rounded-t  rounded-t-none h-[100px] w-full relative">
                        <img
                            src="assets/images/Location.png"
                            alt=""
                            className="w-6 cursor-pointer map absolute md:hidden right-4 top-6"
                            onClick={() => setShowMap(!showMap)}
                        />
                        <img src="/assets/images/equippp-3.png" alt="" className="w-20 h-20 md:block hidden right-8 absolute translate-y-[15%]" />
                    </div>
                    <div className="flex justify-between px-4 w-full pt-3">
                        <div className="flex flex-col items-center md:flex-row w-full md:w-3/5">
                            <div className="bg-white rounded-2xl relative w-fit h-fit -mt-14">
                                {profile_picture ? (
                                    <Avatar
                                        alt="profile_picture"
                                        src={profile_picture}
                                        className="w-[154px] border-2  !border-[#FF720C] h-36 rounded-2xl"
                                    />
                                ) : (
                                    <Avatar className="w-[154px] h-36 rounded-2xl" />
                                )}
                                {isAdmin ? (
                                    <img
                                        alt=""
                                        src="assets/images/edit.svg"
                                        className="w-12 h-12 absolute md:!hidden block -right-6 top-[36px] "
                                        onClick={() => navigate("/profile")}
                                    />
                                ) : (
                                    ""
                                )}
                            </div>
                            <NameAndProfession isLoading={isLoading} user_type_details={user_type_details} />
                        </div>
                        <div className="md:flex hidden flex-wrap md:flex-grow flex-col md:h-full  h-fit">
                            <AdminContactAndInvite emailId={data?.users_by_pk?.email_id} phoneNumber={data?.users_by_pk?.phone_number} />
                        </div>
                    </div>

                    <div className="flex justify-center md:justify-start items-center md:mt-2  w-full">
                        <AdminSocialLink linkedin_link={linkedin_link} facebook_link={facebook_link} twitter_link={twitter_link} />

                        <div
                            className={clsx(
                                " px-2  mt-2 w-full border-b border-t pb-2  pt-2 ",
                                "md:!mt-0 md:px-0 md:border-b-0 md:border-t-0  md:pt-0 md:pb-0 md:ml-10"
                            )}
                        >
                            {expandDescription ? (
                                <p
                                    className={clsx(
                                        "text-gray-500 text-sm w-full text-center mt-0",
                                        "md:!text-left md:pl-7 md:pr-4 md:py-3 md:!mt-0",
                                        showMap ? "hidden md:block" : ""
                                    )}
                                    onClick={() => setExpandDescription(false)}
                                >
                                    {description}
                                    <span className="ml-2 text-center cursor-pointer text-blue-500" onClick={() => setExpandDescription(false)}>
                                        see less...
                                    </span>
                                </p>
                            ) : (
                                <LinesEllipsis
                                    text={description}
                                    maxLine="2"
                                    className={`text-gray-500 text-sm md:pl-7 md:pr-4 md:py-3 w-full text-center md:!text-left mt-0 md:!mt-0 ${
                                        showMap ? "hidden md:block" : ""
                                    }`}
                                    ellipsis={
                                        <span className="ml-2 text-center cursor-pointer text-blue-500" onClick={() => setExpandDescription(true)}>
                                            see more...
                                        </span>
                                    }
                                    trimRight
                                    basedOn="letters"
                                />
                            )}
                        </div>
                    </div>
                    <div className="md:hidden flex items-center pt-3 pb-3  justify-center flex-wrap flex-grow w-full  h-fit">
                        <AdminContactAndInvite emailId={data?.users_by_pk?.email_id} phoneNumber={data?.users_by_pk?.phone_number} />
                    </div>

                    <div className="flex flex-col md:flex-row px-2 md:px-8 flex-grow">
                        <div className={`basis-1 ${showMap ? "" : "hidden md:hidden"} md:basis-1/2`}>
                            <ProfileMap
                                position={
                                    currentProject
                                        ? allProjects.find((project) => project.id === currentProject)?.location_coordinates
                                        : location_coordinates
                                }
                                initiatedProjects={initiatedProjects}
                                currentProject={currentProject}
                                allPositions={
                                    allProjects?.map((project) => {
                                        const [lng, lat] = project.location_coordinates.coordinates;
                                        return { lat, lng };
                                    }) ?? []
                                }
                                projectsData={allProjects ?? []}
                            />
                            {currentProject && currentProject !== "" && <ProfileAnalytics chartIdPrefix={"mobile"} currentProject={currentProject} />}
                        </div>
                        <div className="w-full h-full  flex flex-col">
                            <div className="w-full text-sm flex md:flex-row flex-row md:bg-[#f5f5f5]  p-2 rounded-full items-center  gap-2 mb-2">
                                <DropdownMenu>
                                    <DropdownMenuTrigger asChild>
                                        <div className="rounded-full  font-medium  w-full md:w-1/6 bg-gray-100 md:bg-white py-1 text-sm text-black flex items-center justify-center">
                                            <p className="text-sm md:block hidden">Project Category</p>
                                            <p className="text-xs md:hidden block">Category</p>
                                            <Filter className="w-4 ml-1 h-3 text-black" fill="black" />
                                        </div>
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent className="w-56 h-64 overflow-y-scroll">
                                        {categories.map((item, ind) => {
                                            const isExist = where.category.includes(item);
                                            return (
                                                <div
                                                    key={ind}
                                                    className="flex items-center text-sm px-2 py-1 hover:bg-gray-200 cursor-pointer"
                                                    onClick={() => {
                                                        if (isExist) {
                                                            setWhere((prev) => ({ ...prev, category: where.category.filter((cat) => cat !== item) }));
                                                        } else {
                                                            setWhere((prev) => ({ ...prev, category: [...where.category, item] }));
                                                        }
                                                    }}
                                                >
                                                    <input type="checkbox" className="w-4 h-4 mr-2" checked={isExist} />
                                                    {item}
                                                </div>
                                            );
                                        })}
                                    </DropdownMenuContent>
                                </DropdownMenu>
                                <DropdownMenu>
                                    <DropdownMenuTrigger className="md:hidden w-fit">
                                        <div className="rounded-full !px-4  font-medium w-fit md:w-fit bg-gray-100 md:bg-white py-1 text-sm text-black flex items-center justify-center">
                                            <p className="text-xs md:block hidden">Project Type</p>
                                            <p className="text-xs md:hidden block">Type</p>
                                            <Filter className="w-4 ml-1 h-3 text-black" fill="black" />
                                        </div>
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent className="w-56 h-64 overflow-y-scroll">
                                        {userTypes.map((item, ind) => {
                                            const isExist = where.type.includes(item);
                                            return (
                                                <div
                                                    key={ind}
                                                    className="flex items-center px-2 py-1 hover:bg-gray-200 cursor-pointer"
                                                    onClick={() => {
                                                        if (isExist) {
                                                            setWhere((prev) => ({ ...prev, type: where.type.filter((cat) => cat !== item) }));
                                                        } else {
                                                            setWhere((prev) => ({ ...prev, type: [...where.type, item] }));
                                                        }
                                                    }}
                                                >
                                                    <input type="checkbox" className="w-4 h-4 mr-2" checked={isExist} />
                                                    {item}
                                                </div>
                                            );
                                        })}
                                    </DropdownMenuContent>
                                </DropdownMenu>
                                <div className="rounded-full text-sm  shadow-sm font-medium font-poppins w-3/6 p-1 px-3 border bg-white hidden md:flex items-center justify-between">
                                    <p
                                        onClick={() => {
                                            const isExist = where.type.includes("all");
                                            if (isExist) {
                                                setWhere((prev) => ({ ...prev, type: [...where.type.filter((cat) => cat !== "all")] }));
                                            } else {
                                                setWhere((prev) => ({ ...prev, type: [...where.type, "all"] }));
                                            }
                                        }}
                                        className={`cursor-pointer ${where.type?.includes("all") ? "text-blue-600 border-b border-blue-600" : ""}`}
                                    >
                                        All
                                    </p>
                                    <p
                                        onClick={() => {
                                            const isExist = where.type.includes("csr");
                                            if (isExist) {
                                                setWhere((prev) => ({ ...prev, type: [...where.type.filter((cat) => cat !== "csr")] }));
                                            } else {
                                                setWhere((prev) => ({ ...prev, type: [...where.type, "csr"] }));
                                            }
                                        }}
                                        className={`cursor-pointer ${where.type?.includes("csr") ? "text-blue-600 border-b border-blue-600" : ""}`}
                                    >
                                        CSR
                                    </p>
                                    <p
                                        onClick={() => {
                                            const isExist = where.type.includes("MLA");
                                            if (isExist) {
                                                setWhere((prev) => ({ ...prev, type: [...where.type.filter((cat) => cat !== "MLA")] }));
                                            } else {
                                                setWhere((prev) => ({ ...prev, type: [...where.type, "MLA"] }));
                                            }
                                        }}
                                        className={`cursor-pointer ${where.type?.includes("MLA") ? "text-blue-600 border-b border-blue-600" : ""}`}
                                    >
                                        MP / MLA LADS
                                    </p>
                                    <p
                                        onClick={() => {
                                            const isExist = where.type.includes("Probono");
                                            if (isExist) {
                                                setWhere((prev) => ({ ...prev, type: [...where.type.filter((cat) => cat !== "Probono")] }));
                                            } else {
                                                setWhere((prev) => ({ ...prev, type: [...where.type, "Probono"] }));
                                            }
                                        }}
                                        className={`cursor-pointer ${
                                            where.type?.includes("Probono") ? "text-blue-600 border-b border-blue-600" : ""
                                        }`}
                                    >
                                        Probono
                                    </p>
                                    <p
                                        onClick={() => {
                                            const isExist = where.type.includes("Individual");
                                            if (isExist) {
                                                setWhere((prev) => ({ ...prev, type: [...where.type.filter((cat) => cat !== "Individual")] }));
                                            } else {
                                                setWhere((prev) => ({ ...prev, type: [...where.type, "Individual"] }));
                                            }
                                        }}
                                        className={`cursor-pointer ${
                                            where.type?.includes("Individual") ? "text-blue-600 border-b border-blue-600" : ""
                                        }`}
                                    >
                                        Individual
                                    </p>
                                    <p
                                        onClick={() => {
                                            const isExist = where.type.includes("Corporate");
                                            if (isExist) {
                                                setWhere((prev) => ({ ...prev, type: [...where.type.filter((cat) => cat !== "Corporate")] }));
                                            } else {
                                                setWhere((prev) => ({ ...prev, type: [...where.type, "Corporate"] }));
                                            }
                                        }}
                                        className={`cursor-pointer ${
                                            where.type?.includes("Corporate") ? "text-blue-600 border-b border-blue-600" : ""
                                        }`}
                                    >
                                        Corporate
                                    </p>
                                    <p
                                        onClick={() => {
                                            const isExist = where.type.includes("NPO");
                                            if (isExist) {
                                                setWhere((prev) => ({ ...prev, type: [...where.type.filter((cat) => cat !== "NPO")] }));
                                            } else {
                                                setWhere((prev) => ({ ...prev, type: [...where.type, "NPO"] }));
                                            }
                                        }}
                                        className={`cursor-pointer ${where.type?.includes("NPO") ? "text-blue-600 border-b border-blue-600" : ""}`}
                                    >
                                        NPO
                                    </p>
                                </div>
                                <div className="flex-grow border w-full md:w-2/6 shadow-sm rounded-full relative h-full flex items-center">
                                    <input
                                        onChange={handleSearchChange}
                                        type="search"
                                        placeholder="Search by name/area"
                                        className="outline-none pl-8 border-none text-sm w-full h-8 rounded-full"
                                    />
                                    <Search className="text-gray-400 w-4 absolute left-2" />
                                </div>
                            </div>
                            {where.category.length > 0 && (
                                <div className="w-full text-sm hidden md:flex  p-2 rounded-full items-center  gap-2">
                                    {where.category.map((item, ind) => {
                                        return (
                                            <div key={ind} className="flex items-center pr-2 rounded-2xl shadow-sm  cursor-pointer">
                                                <p className="pl-4 py-1">{item}</p>
                                                <CircleX
                                                    onClick={() => {
                                                        setWhere((prev) => ({ ...prev, category: where.category.filter((cat) => cat !== item) }));
                                                    }}
                                                    className="hover:text-red-400 w-6 h-6 px-1   ml-1"
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            )}

                            {isLoading || loading ? (
                                "...Loading"
                            ) : (
                                <>
                                    {projects?.length ? (
                                        <Fragment>
                                            <div className="flex-grow md:h-[100px] overflow-auto no-scrollbar">
                                                {projects.map((project, index) => {
                                                    const isActive = project.id === currentProject;

                                                    return (
                                                        <Fragment key={index}>
                                                            <div
                                                                className={`my-2 cursor-pointer rounded-lg md:px-2 md:py-1 ${
                                                                    isActive ? "bg-[#f3f3f3] border-2  rounded-lg " : ""
                                                                }`}
                                                                onClick={() => {
                                                                    setCurrentProject(project.id);
                                                                    useMapStore.getState().actions.setZoomLevel(8);
                                                                    useMapStore.getState().actions.showMarkers();
                                                                }}
                                                                // onMouseEnter={() => debouncedShowMarkers(project.id)}
                                                                // onMouseLeave={() => {
                                                                //     setCurrentProject("");
                                                                //     useMapStore.getState().actions.setZoomLevel(14);
                                                                //     useMapStore.getState().actions.showMarkers();
                                                                // }}
                                                            >
                                                                <ProjectItem
                                                                    type={project.type}
                                                                    navigate={navigate}
                                                                    status={project.project_status}
                                                                    id={project.id}
                                                                    estimatedAmount={project.estimated_cost}
                                                                    expressionAggregate={
                                                                        project.expressions_aggregate.aggregate.sum.investment_amount
                                                                    }
                                                                    title={project.title}
                                                                    date={project.created_at}
                                                                    description={project.objective}
                                                                    location={project.location_title}
                                                                    category={project.category}
                                                                    secondaryCategory={project.secondary_category}
                                                                    profilePicture={project?.project_profile_picture}
                                                                    isAdmin={isAdmin}
                                                                    comments={project.comments}
                                                                    expressions={project.expressions}
                                                                />
                                                            </div>
                                                            <hr />
                                                        </Fragment>
                                                    );
                                                })}
                                            </div>

                                            {/* <div className="flex flex-col justify-end bg-red-500 relative bottom-0 items-center py-2 ">
                                            <Pagination count={Math.ceil(projects.length / 6)} page={page} onChange={handleChange} />
                                        </div> */}
                                        </Fragment>
                                    ) : (
                                        <div className="text-center text-black h-full">No projects found</div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default LeftPanel;
