import { Button, Grid } from "@mui/material";
import "./header.styles.scss";

const LoginHeader = (props) => {
    return (
        <Grid item container className="header" direction="row" justifyContent="flex-end" alignItems="center">
            <Button className="nav__btn active">Login</Button>
            <Button className="nav__btn" onClick={() => props.setOpen("register")}>
                SignUp
            </Button>
        </Grid>
    );
};

export default LoginHeader;
