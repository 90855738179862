import { Fragment, useState, useContext } from "react";
import Grid from "@mui/material/Grid";
import CustomFooter from "components/CustomFooter";
import MainNavbar from "components/MainNavbar";
import NotificationToast from "components/NotificationToast";
import "./MainLayout.styles.scss";
import MobileNavbar from "components/MobileNavbar";
import MobileBottomBar from "components/MobileBottomBar/MobileBottomBar";
import InviteOthers from "components/InviteOthers";
import InviteUsers from "admin/components/invite-users/invite-users";
import AuthUserContext from "contexts/session";
import { useLocation } from "react-router-dom";

const MainLayout = ({ children }) => {
    const { authUser } = useContext(AuthUserContext);
    const [toggle, setToggle] = useState(false);
    const location = window.location.href;

    const isInvitePage = location.includes("/invite-users/");
    const path = useLocation();
    const isHome = path.pathname === "/";
    const isTerm = path.pathname === "/terms-of-use";
    const projectRegex = /^\/projects\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    const isProject = projectRegex.test(path.pathname);
    console.log("Current path:", path.pathname);

    const isManthani = path.pathname === "/about-duddila-sridhar-babu";
    const isSuggestedProject = path.pathname === "/suggest-project";
    const isZaheerabad = path.pathname === "/about-bheemarao-basawanthrao-patil";
    const isPemmasani = path.pathname === "/about-chandra-sekhar-pemmasani";

    console.log({ authUser }, "authUser");

    return (
        <div className="flex flex-col justify-center items-center flex-grow-1">
            {
                location.includes("otp-reset-password") ||
                location.includes("reset-verify") ||
                location.includes("terms-of-use") ||
                location.includes("project-report") ||
                location.includes("login") ||
                location.includes("onboarding") ||
                location.includes("signup") ||
                location.includes("verify-otp") ||
                location.includes("forgot-password") ||
                location.includes("confirm-email") ||
                location.includes("check-email") ||
                location.includes("select-user-type") ||
                location.includes("csr-foundation") ||
                location.includes("individual") ||
                location.includes("non-profit-organization") ||
                location.includes("subject-matter-expert") ||
                location.includes("pro-bono") ||
                location.includes("public-sector-bank") ||
                location.includes("member-of-parliament") ||
                location.includes("member-of-legislative-assembly") ||
                location.includes("students") ||
                location.includes("others") ||
                isInvitePage ? (
                    <Fragment>{children}</Fragment>
                ) : (
                    <Fragment>
                        <div className="w-full md:block hidden">
                            <MainNavbar toggle={(val) => setToggle(val)} bgColor="#fff" />
                        </div>

                        <div className="w-full block md:hidden">
                            <MobileNavbar toggle={(val) => setToggle(val)} />
                        </div>

                        <div
                            id="main-layout__content"
                            className={`!h-full md:h-auto w-full ${
                                isHome || isProject || isManthani || isZaheerabad || isPemmasani || isSuggestedProject
                                    ? "px-0 py-0"
                                    : "px-0 py-0 h-full md:!px-4 md:!py-2 md:!bg-[#F6F6F9]"
                            }`}
                        >
                            {children}
                        </div>
                        <NotificationToast header="Success" body="Please Check your email for further instructions" toggle={toggle} />
                        {(isHome || isManthani || isZaheerabad || isPemmasani) && (
                            <Grid item container className="footer__container md:block  hidden">
                                <CustomFooter />
                            </Grid>
                        )}

                        {/* <div className="md:hidden flex justify-end flex-col fixed bottom-0  w-full bg-white">
                        <MobileBottomBar />
                    </div> */}
                    </Fragment>
                )
                // )}
            }
        </div>
    );
};

export default MainLayout;
