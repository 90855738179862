import { useState } from "react";

import { useUser } from "contexts/user-type/user-type-context.js";
import { useNavigate } from "react-router-dom";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";

const SelectUserType = () => {
    const navigate = useNavigate();
    const { state, setState } = useUser();
    const { userType } = state;
    const [userTypeError, setUserTypeError] = useState(() => "");
    const [otherUserType, setOtherUserType] = useState("");

    const handleUserTypeChange = (selectedValue) => {
        if (selectedValue === "Others") {
            setState((prevState) => ({
                ...prevState,
                userType: selectedValue,
            }));
        } else {
            setState((prevState) => ({
                ...prevState,
                userType: selectedValue,
            }));
        }
    };

    const handleForwardClick = (e) => {
        e.preventDefault();
        if (!userType) {
            setUserTypeError("Please select user type");
            return;
        }
        if (userType === "Individual") {
            navigate("/signup/individual");
        } else if (userType === "CSR foundation") {
            navigate("/signup/csr-foundation");
        } else if (userType === "Non-Profit Organization") {
            navigate("/signup/non-profit-organization");
        } else if (userType === "Subject Matter Expert") {
            navigate("/signup/subject-matter-expert");
        } else if (userType === "Pro-Bono") {
            navigate("/signup/pro-bono");
        } else if (userType === "Public Sector Bank") {
            navigate("/signup/public-sector-bank");
        } else if (userType === "Member of Parliament") {
            navigate("/member-of-parliament");
        } else if (userType === "Member of Legislative Assembly") {
            navigate("/signup/member-of-legislative-assembly");
        } else if (userType === "Students") {
            navigate("/signup/students");
        } else if (userType === "Others") {
            navigate("/signup//others");
        }
    };
    return (
        <div className="flex h-full w-full justify-center items-center my-auto flex-col bg-[#F9F9F9] md:px-0 px-6">
            <div className="flex justify-center flex-col mb-10">
                <p className="text-[#0D0140] font-bold text-3xl text-center">Create Account as</p>
                <p className="text-[#0D0140] font-thin text-base mt-2 text-center">Please select the industry below to continue</p>
                <img src="/assets/images/user.svg" alt="select option" className="mt-2" />
            </div>

            <form className="w-full md:w-1/3">
                <Select value={userType} onValueChange={handleUserTypeChange} className="modal-select-input">
                    <SelectTrigger aria-label="User Type">{userType || "Select user type"}</SelectTrigger>
                    <SelectContent>
                        <SelectItem value="Individual">Individual</SelectItem>
                        {/* <SelectItem value="CSR foundation">CSR foundation</SelectItem>
                        <SelectItem value="Non-Profit Organization">Non-Profit Organization</SelectItem>
                        <SelectItem value="Subject Matter Expert">Subject Matter Expert</SelectItem>
                        <SelectItem value={"Pro-Bono"}>Pro-Bono</SelectItem>
                        <SelectItem value={"Public Sector Bank"}>Public Sector Bank</SelectItem>
                        <SelectItem value={"Member of Parliament"}>Member of Parliament</SelectItem>
                        <SelectItem value={"Member of Legislative Assembly"}>Member of Legislative Assembly</SelectItem>
                        <SelectItem value={"Students"}>Student</SelectItem>
                        <SelectItem value={"Others"}>Others</SelectItem> */}
                    </SelectContent>
                </Select>
                {userType === "Others" && (
                    <div className="mt-2">
                        <span className="mr-2"> Others</span>
                        <input
                            type="text"
                            name="Others"
                            className="p-1"
                            value={otherUserType}
                            placeholder="mention here"
                            onChange={(e) => setOtherUserType(e.target.value)}
                        />
                    </div>
                )}
                {userTypeError && <p className="text-red-500 text-xs italic">{userTypeError}</p>}
                <div className="flex flex-col items-center w-full px-8 mt-10">
                    <button
                        className="bg-[#130160] w-full text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="button"
                        onClick={handleForwardClick}
                    >
                        CONTINUE
                    </button>
                    {/* <button
                        className="bg-[#D6CDFE] w-full mt-4  font-semibold py-2 px-4 rounded text-white focus:outline-none focus:shadow-outline"
                        type="button"
                    >
                        SIGN UP WITH GOOGLE
                    </button> */}
                </div>
            </form>
        </div>
    );
};

export default SelectUserType;
