import { Accordion, Container } from "react-bootstrap";
import "./FaqsContent.styles.scss";

const FaqsContent = () => {
    const data = [
        {
            header: "What is EquiPPP™ ?",
            body: (
                <>
                    <p>
                        EquiPPP™ is a “marketplace” of different types of social value projects across geographies, appropriately categorized for ease
                        of use, where different individuals and entities can actively showcase and participate.
                    </p>
                    <p>
                        It acts as a “merchant banker” for such social value projects collecting expressions of interest and thus facilitating
                        execution by CSR mandated organizations, NGOs government agencies etc.
                    </p>
                    <p>
                        EquiPPP™ is a platform specially designed to enable scaling up of Social Value Investing and also bringing smaller
                        low-visibility but high local impact projects into the investment radar.
                    </p>
                </>
            ),
        },
        {
            header: "Why should I use EquiPPP™ ?",
            body: (
                <>
                    <p>
                        EquiPPP™ is a powerful marketplace acting as a ready reckoner for social value projects properly categorized, visible to
                        several interested groups viz. Individuals, CSR Companies, Governments, Institutions, etc. For details of different groups.
                    </p>
                    <p>
                        Based on your interest, EquiPPP™ is a powerful tool for finding the most impactful socially relevant projects, investing or
                        participating in any other manner suitable and understanding the actual impact that they have created.
                    </p>
                </>
            ),
        },
        {
            header: "How to use EquiPPP™ ?",
            body: (
                <>
                    <p>
                        EquiPPP™ can we used by different kinds of participants. They include Individuals, CSR Companies, Governments, Institutions,
                        etc. as specified below.
                    </p>
                    <p>
                        However, simple stated one can
                        <ul>
                            <li>Upload projects</li>
                            <li>Specify or comment on the basic budget and/or impact</li>
                            <li>Express interest in participating (financially or through specific involvement)</li>
                            <li>Indicate progress, and the kind of impact that has been achieved by executed projects.</li>
                        </ul>
                    </p>
                    <p>
                        EquiPPP™ is also an excellent database for different categories of social value projects for governments and other
                        organisations for analysis.
                    </p>
                </>
            ),
        },
        {
            header: "Who can use EquiPPP™ ?",
            body: (
                <>
                    <p>Different types of users and how EquiPPP™ is useful to each is listed in the link below</p>
                    <p>
                        However, to summarize EquiPPP™ can be used for the following purposes:
                        <ul>
                            <li>
                                CSR - Organizations can showcase their CSR activities and use EquiPPP™ as an internal collaboration and tracking tool.
                            </li>
                            <li>
                                General Public - To quickly upload social value projects identified anywhere. For example, A person might see a severe
                                problem with connectivity for a community, issues on sanitation, requirement of school facilities or any social
                                requirements for community and upload it to EquiPPP™ through a simple upload process.
                            </li>
                            <li>
                                To validate and specify projects: Certain categories of users can validate the requirement and / or provide a
                                high-level specification / budget for uploaded projects to enable decision making by other types of participants.
                            </li>
                            <li>To indicate expressions of interest for financial participation or to take up execution of specific projects.</li>
                            <li>
                                Note: EquiPPP™ is not a financial platform and does not support any financial transactions. Interested parties may
                                view of expression of interest and decide to collaborate and execute the display social value projects.
                            </li>
                            <li>
                                For analysis by Governments and authorised agencies. EquiPPP™ generates a wealth of data on presence and
                                implementation of social value projects and provides extensive and highly useful information to Governments and
                                authorised agencies to improve governance.
                            </li>
                        </ul>
                    </p>
                </>
            ),
        },
        {
            header: "Any financial transactions happen through EquiPPP™ ?",
            body: (
                <>
                    <p>
                        EquiPPP™ is a not a financial platform and no monetary transactions are allowed. However, interested entities or individuals
                        can state their expressions of interest and specify contributions that they would be ready to make.
                    </p>
                </>
            ),
        },
        {
            header: "How can I create a Project ?",
            body: (
                <>
                    <p>For Creators of Projects on EquiPPP™ :</p>
                    <p>
                        EquiPPP™ has a very simple process to upload social value projects. All registered users are allowed to upload social value
                        projects that they may come across. However there is a validation process where projects are validated for accuracy and impact
                        which is done by specific types of users with validation privileges. As of now only validated projects are displayed on the
                        platform.
                    </p>
                </>
            ),
        },
        {
            header: "How to sign up with EquiPPP™ ?",
            body: (
                <>
                    <p>
                        Any one can sign up as a registered user. The account gets operational after authorisation by administrator. CSR users and
                        other institutional users required to necessarily apply using company / institution official email id’s.
                    </p>
                </>
            ),
        },
        {
            header: "What are the different types of users ?",
            body: (
                <>
                    <p>
                        There are 11 types of users in EquiPPP™.
                        <ul>
                            <li>
                                Individual:
                                <p>
                                    An Individual User can be any citizen who is willing to contribute for Social Projects.
                                    <ul style={{ listStyleType: "circle" }}>
                                        <li>
                                            Many CSR and Non-Profit Organization's Social Projects are happening across the world by Region wise and
                                            Sector wise , these projects are listed in the EquiPPP™ platform. If you like any Social Projects in the
                                            platform, you can like that project, appreciate them through your comments and encourage them.
                                        </li>
                                        <li>
                                            If any Individual user get inspired by any Social Project, they can post the details of that specific
                                            project, upload a picture, video or both and create a project in the place where they want a similar
                                            project to be executed.
                                        </li>
                                    </ul>
                                </p>
                            </li>
                            <li>
                                Social Value Partner:
                                <p>
                                    A Social Value Partner User can be any company/organization who are participating or willing to participate in the
                                    Social Projects or Social Value Investing
                                    <ul style={{ listStyleType: "circle" }}>
                                        <li>
                                            Social Value Partners can participate or create Social Projects in the platform, help the society and get
                                            visibility
                                        </li>
                                    </ul>
                                </p>
                            </li>
                            <li>
                                CSR:
                                <p>
                                    Corporate social responsibility (CSR) is a self-regulating business model that helps a company be socially
                                    accountable to itself, its stakeholders, and the public. CSR is mandatory in India for companies fall under
                                    specific criteria.
                                    <ul style={{ listStyleType: "circle" }}>
                                        <li>
                                            MCSR user can browse the projects available in the platform, raise the expression of interest for any
                                            specific project by sector or region and help in driving the project. Post their CSR projects in the
                                            platform get visibility to the public, government and stakeholders
                                        </li>
                                        <li>Cross-Sector Partnerships helps CSR to participate in more number of projects</li>
                                    </ul>
                                </p>
                            </li>
                            <li>
                                Projects by Non-Profit Organizations:
                                <p>
                                    <ul style={{ listStyleType: "circle" }}>
                                        <li>
                                            Nonprofit organization will serve the public through the offering of goods, services or a combination of
                                            the two.
                                        </li>
                                        <li>
                                            In this platform, Non-Profit Organizations can post their projects or participate in the existing Social
                                            Projects. Projects created by Non-Profit Organizations in this platform have a high visibility as they are
                                            flagged separately for the interest of Non-Profit.
                                        </li>
                                    </ul>
                                </p>
                            </li>
                            <li>
                                Projects under Social Impact Bonds:
                                <p>
                                    A social impact bond (SIB) is a contract with the public sector or governing authority, whereby it pays for better
                                    social outcomes in certain areas and passes on the part of the savings achieved to investors.
                                    <ul style={{ listStyleType: "circle" }}>
                                        <li>
                                            In this platform, SIB users can post their projects for cross sector partnership, express interest or
                                            participate in Social Projects and create the impact on Social Value Investing
                                        </li>
                                    </ul>
                                </p>
                            </li>
                            <li>
                                Pro Bono:
                                <p>
                                    For professional work undertaken voluntarily and without payment. Unlike traditional volunteerism, Pro Bono Users
                                    can use the specific skills of professionals to provide services to those who are unable to afford them.
                                    <ul style={{ listStyleType: "circle" }}>
                                        <li>In this platform, PRO Bono Users can create or participate in Social Projects.</li>
                                    </ul>
                                </p>
                            </li>
                            <li>
                                Projects by Public Sector Banks (Specific to India):
                                <p>
                                    Indian Public Sector Banks are the primary source of debt funding for any industries.
                                    <ul style={{ listStyleType: "circle" }}>
                                        <li>
                                            Selected Projects that has the social blend, users can place the projects it in the platform for
                                            Cross-Sector Partnership.
                                        </li>
                                    </ul>
                                </p>
                            </li>
                            <li>
                                Projects under MP / MLA LADS (Specific to India):
                                <p>
                                    MP and MLA Local Area Development Schemes are Indian Government fund given to MP and MLA's has the choice to
                                    suggest to the District Collector for works to the tune of Rs.5 Crores and Rs.2 Crores respectively per annum to
                                    be taken up in his/her constituency.
                                    <ul style={{ listStyleType: "circle" }}>
                                        <li>
                                            In this platform, MP / MLA LADS can browse the social projects in their constituency or create a social
                                            project in their constituency.
                                        </li>
                                        <li>
                                            When there is a need for more fundraising for the projects in their area, MP/MLA’s can post the projects
                                            and invite for Cross-Sector Partnerships, execute the projects and get more credibility
                                        </li>
                                    </ul>
                                </p>
                            </li>
                            <li>
                                Projects under Swachh Bharat (Specific to India):
                                <p>
                                    A Mission started by Indian Government for a clean India by 2nd Oct 2019 and a pay a tribute to Mahatma Gandhi on
                                    his 150th birth anniversary.
                                    <ul style={{ listStyleType: "circle" }}>
                                        <li>
                                            Swachh Bharat Users can track projects specific to Swachh Bharat, raise expression for the existing
                                            projects or create a new Swachh Bharat Project and drive them for a clean India.
                                        </li>
                                    </ul>
                                </p>
                            </li>
                            <li>
                                Others:
                                <p>
                                    EquiPPP™ is a flexible platform, as the platform grows other categories of users will be added. Meanwhile, if a
                                    user feels he doesn't fit into any of the listed User Types, he/she can participate in or create projects through
                                    others User type.
                                </p>
                            </li>
                            <li>
                                Projects by Students:
                                <p>
                                    Students have a great identity and visibility in Society. In this platform, Students can participate or create
                                    Social Projects and help in driving them through cash or kind and show the Student Power.
                                </p>
                            </li>
                        </ul>
                    </p>
                    <p>
                        * EquiPPP™ is an advanced and robust platform for Social Projects. All the users get insights of the regions and sectors
                        regarding social projects through advanced tools, Management Information System, Artificial Intelligence and Advanced
                        Analytics available in the platform
                    </p>
                    <p>* Customized services are available for all the users</p>
                </>
            ),
        },
        {
            header: "What should I do after creating a project ?",
            body: (
                <>
                    <p>
                        EquiPPP™ is a full life cycle platform, Covering aspects from identification, validation, collaboration to impact analysis.
                        Users may express interest, collobarate, track and analyse social impact projects on the platform. Actual execution and
                        financial transactions happen outside the scope of the platform.
                    </p>
                </>
            ),
        },
        {
            header: "How can I delete my project ?",
            body: (
                <>
                    <p>
                        Project can be deleted by the creator before approval. After approval only the administrator can delete a project. All delete
                        requests go to the administrator.
                    </p>
                </>
            ),
        },
        {
            header: "Does EquiPPP™ charge fee ?",
            body: (
                <>
                    <p>
                        All basic EquiPPP™ functionality is free. However customization, special reports and any services that may be requested will
                        be on a chargeable basis.
                    </p>
                </>
            ),
        },
    ];
    return (
        <main className="FaqsContent">
            <Container>
                <section>
                    <Accordion defaultActiveKey="0" flush>
                        {data.map((el, idx) => (
                            <Accordion.Item className="faq_accordion_item" key={idx} eventKey={idx}>
                                <Accordion.Header className="faq_accordion_header">{el.header}</Accordion.Header>
                                <Accordion.Body className="faq_accordion_body">{el.body}</Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                </section>
            </Container>
        </main>
    );
};

export default FaqsContent;
