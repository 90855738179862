import React, { useState, Fragment } from "react";
import { GoogleMap, Marker, Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import { googleMapInitializer } from "constants";

const libraries = ["places"];
/*
    You have to enable the following APIs in the Google Cloud Console:
    1. Google Maps JavaScript API
    2. Google Maps Places API
*/

const MapSelectPlace = ({ onInputChange, onLocationChange, isCustomeHeight }) => {
    const mapStyles = {
        height: !isCustomeHeight ? "100%" : "300px",
        width: "100%",
        display: "inline-block",
    };

    const defaultCenter = {
        lat: 41.3851,
        lng: 2.1734,
    };

    const [currentPosition, setCurrentPosition] = useState(defaultCenter);
    const [autoComplete, setAutoComplete] = useState(null);
    const { isLoaded, loadError } = useJsApiLoader(googleMapInitializer);

    const onMarkerDragEnd = (e) => {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        setCurrentPosition({ lat, lng });
        onLocationChange({ lat, lng });
    };

    console.log("currentPosition", currentPosition);

    const onPlaceChanged = () => {
        if (autoComplete !== null) {
            const { formatted_address, geometry } = autoComplete.getPlace();
            const { lat, lng } = geometry?.location;
            const coordinates = { lat: lat(), lng: lng() };
            setCurrentPosition(coordinates);
            onInputChange("location_title", formatted_address);
            onInputChange("location_coordinates", coordinates);
        } else {
            alert("Autocomplete is not loaded yet!");
        }
    };

    if (loadError) {
        return <div>Map cannot be loaded right now, sorry.</div>;
    }

    return (
        <Fragment>
            {isLoaded ? (
                <GoogleMap mapContainerStyle={mapStyles} zoom={13} center={currentPosition}>
                    <Autocomplete onLoad={setAutoComplete} onPlaceChanged={onPlaceChanged}>
                        <input
                            type="text"
                            placeholder="Search for a place"
                            autoFocus
                            style={{
                                boxSizing: `border-box`,
                                border: `1px solid transparent`,
                                width: `240px`,
                                height: `32px`,
                                padding: `0 12px`,
                                borderRadius: `3px`,
                                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                fontSize: `14px`,
                                outline: `none`,
                                textOverflow: `ellipses`,
                                position: "absolute",
                                left: "50%",
                                marginLeft: "-120px",
                            }}
                        />
                    </Autocomplete>
                    {currentPosition.lat ? (
                        <Marker position={currentPosition} onPlaceChanged={onPlaceChanged} onDragEnd={(e) => onMarkerDragEnd(e)} draggable={true} />
                    ) : null}
                </GoogleMap>
            ) : (
                <h4>Loading...</h4>
            )}
        </Fragment>
    );
};

export default MapSelectPlace;
