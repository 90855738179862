import { Fragment } from "react";
import { NavLink } from "react-router-dom";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ROUTES from "constants/routes";
import "./Sidebar.styles.scss";

const PROFILE_ROUTE = {
    route: ROUTES.PROFILE,
    title: "Profile",
};
const PROJECT_LINKED_ROUTE = {
    route: ROUTES.PROJECT_LINKED,
    title: "Project Linked",
};
const PROJECT_COMMENTED_ROUTE = {
    route: ROUTES.PROJECT_COMMENTED,
    title: "Project Commented",
};
const PROJECT_PROMOTED_ROUTE = {
    route: ROUTES.PROJECT_PROMOTED,
    title: "Project Promoted",
};

const SIDE_BAR_ROUTES = [PROFILE_ROUTE, PROJECT_LINKED_ROUTE, PROJECT_COMMENTED_ROUTE, PROJECT_PROMOTED_ROUTE];
const Sidebar = () => {
    return (
        <Fragment>
            <List id="sidebar-container">
                <Grid container direction="column" alignItems="center">
                    {SIDE_BAR_ROUTES.map(({ route, title }) => (
                        <NavLink exact activeClassName="active" to={route} key={route} className={"link"}>
                            <Grid container alignItems="center">
                                <span className="nav__title">{title}</span>
                            </Grid>
                        </NavLink>
                    ))}
                </Grid>
            </List>
        </Fragment>
    );
};

export default Sidebar;
