import { Link } from "react-router-dom";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import "./CustomFooter.styles.scss";

const CustomFooter = () => {
    return (
        <footer className="">
            <div className="footer__section  flex items-center text-sm  px-8 justify-between text-white">
                <img src="/assets/images/equippp-3.png" alt="logo" className="w-12 h-12" />
                <div>
                    @Powered by <span>EQUIPPP</span>
                </div>
                <div>Contact us at developer@equippp.com</div>
            </div>
        </footer>
    );
};

export default CustomFooter;
