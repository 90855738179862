import { useState } from "react";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { RegisterModalFooter } from "..";

function PublicSectorBank({ psbState, setPsbState, email, setOpen, registerUser }) {
    let { psbBankName, psbContributionCategories, psbPointOfContact, psbAddress, psbRepresentativeRole } = psbState;
    const [selected, setSelected] = useState(false);

    const handlePsbChange = (e) => {
        if (e.target.value.indexOf("All Categories") > -1) {
            setPsbState((prevState) => ({
                ...prevState,
                [e.target.name]: psbContributionCategories.length !== 0 ? [] : all_projects.map((option) => option),
            }));
        } else {
            setPsbState((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value,
            }));
        }
    };
    const handleClick = (e) => {
        if (psbContributionCategories.length === all_projects.length) {
            setSelected(false);
            e.stopPropagation();
        } else {
            setSelected(true);
        }
    };
    const all_projects = [
        "All Categories",
        "Agriculture",
        "Community",
        "Education",
        "Environment",
        "HealthCare",
        "Housing",
        "InfraStructurePower",
        "Roads and Bridges",
        "Social Justice",
        "Technology",
        "Waste Management",
        "Water & Sanitization",
        "Others",
    ];
    const handleBackClick = () => {
        setOpen("usertype");
    };

    const handleForwardClick = () => {
        if (!psbBankName || psbContributionCategories.length === 0 || !psbPointOfContact || !psbAddress || !psbRepresentativeRole) {
            return alert("please fill all public sector bank details");
        }
        // const checkEmail = validateEmail(psbEmail);

        // if (!checkEmail.status) {
        //     return alert("enter valid psb email");
        // }

        //cin number validation
        let regex = new RegExp(/^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/);

        // matched the ReGex
        // if (!regex.test(psbCinNumber)) {
        //     return alert("enter valid psb CIN Number");
        // }

        registerUser();
    };
    return (
        <Grid item xs={12} className="common-modal__container" container direction="column" justifyContent="flex-start" alignItems="flex-start">
            <h3>
                Sign up as <span className="span-text">PSB.</span>
            </h3>
            <Grid item xs={12} className="field__grid" container justifyContent="space-between" alignItems="center" mb="15px">
                <TextField
                    variant="standard"
                    label="Bank Name"
                    className="input__field"
                    fullWidth
                    type="text"
                    name="psbBankName"
                    value={psbBankName}
                    onChange={handlePsbChange}
                />
                <TextField variant="standard" label="E-mail" className="input__field" fullWidth type="text" name="psbEmail" value={email} disabled />
            </Grid>

            <Grid item xs={12} className="field__grid" container justifyContent="space-between" alignItems="center" mb="15px">
                <FormControl variant="standard" sx={{ width: "45%" }}>
                    <InputLabel id="demo-simple-select-standard-label">Focus of Interest</InputLabel>
                    <Select
                        labelId="demo-simple-select-standard-label"
                        multiple
                        value={psbContributionCategories}
                        name="psbContributionCategories"
                        onChange={handlePsbChange}
                    >
                        <MenuItem
                            value={"All Categories"}
                            onClick={handleClick}
                            style={selected ? { backgroundColor: "rgba(25, 118, 210, 0.08)" } : {}}
                        >
                            All Categories
                        </MenuItem>
                        {all_projects.map((name) => (
                            <MenuItem key={name} value={name}>
                                {name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    variant="standard"
                    label="Contact"
                    className="input__field"
                    fullWidth
                    type="text"
                    onKeyPress={(event) => {
                        // ignore "-", "+" and "e"
                        if (event?.key === "-" || event?.key === "+" || event?.key === "e") {
                            event.preventDefault();
                        }
                        // if character is not number then ignore except decimal point and
                        if (event?.key !== "." && event?.key && isNaN(+event.key)) {
                            event.preventDefault();
                        }
                        if (event.target.value.length >= 10) {
                            event.preventDefault();
                        }
                    }}
                    InputProps={{
                        maxLength: 10,
                    }}
                    name="psbPointOfContact"
                    value={psbPointOfContact}
                    onChange={handlePsbChange}
                />
            </Grid>

            <Grid item xs={12} className="field__grid" container justifyContent="space-between" alignItems="center" mb="15px">
                <TextField
                    variant="standard"
                    label="Address"
                    className="input__field"
                    fullWidth
                    type="text"
                    name="psbAddress"
                    value={psbAddress}
                    onChange={handlePsbChange}
                />
                <TextField
                    variant="standard"
                    label="Representative Role"
                    className="input__field"
                    fullWidth
                    type="text"
                    name="psbRepresentativeRole"
                    value={psbRepresentativeRole}
                    onChange={handlePsbChange}
                />
            </Grid>
            <Grid item xs={12} container direction="row" justifyContent="space-between" alignItems="center" className="button__container">
                <Button className="back__btn" type="button" onClick={handleBackClick}>
                    Back
                </Button>
                <Button className="continue__btn" type="button" onClick={handleForwardClick}>
                    Save and continue
                </Button>
            </Grid>
            <RegisterModalFooter />
        </Grid>
    );
}

export default PublicSectorBank;
