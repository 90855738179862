import { useEffect } from "react";
import CustomFooter from "components/CustomFooter/CustomFooter";
// components
import MainNavbar from "components/MainNavbar";
import TermsContent from "components/TermsContent/TermsContent";
import "./TermsOfUse.styles.scss";

const TermsofUse = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <section className="TermsofUse">
            <TermsContent />
        </section>
    );
};

export default TermsofUse;
