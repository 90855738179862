import { useState, useEffect, useRef, useContext, useCallback, Fragment } from "react";
import { Input } from "../../../../../components/ui/input";
import { Textarea } from "../../../../../components/ui/text-area";
import { CalendarIcon } from "@radix-ui/react-icons";
import { format, isBefore, addDays, startOfDay, set } from "date-fns";
import { get, useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { cn } from "../../../../../lib/utils";
import { Button } from "../../../../../components/ui/button";
import { Calendar } from "../../../../../components/ui/calendar";
import { useNavigate } from "react-router-dom";
import { useCreateProjectMutation, useUpl } from "types/generated-types.ts";
import AuthUserContext from "contexts/session";
import CustomModal, { MapModalContent } from "components/CustomModal/CustomModal";
import { Popover, PopoverContent, PopoverTrigger } from "../../../../../components/ui/popover";
import { Camera, Plus, CircleX } from "lucide-react";
import { useGetUploadUrlMutation } from "types/generated-types.ts";
import axios from "axios";
import { LoaderCircle } from "lucide-react";
import { create } from "zustand";
import { v4 as uuid } from "uuid";
import { useDropzone } from "react-dropzone";
import CustomSelect from "../../../../../components/CustomSelect";
import clsx from "clsx";

const comments = [
    "Congratulations on completing such a remarkable project! Your dedication and hard work truly shine through in every aspect.",

    "I'm thoroughly impressed by the creativity and attention to detail evident in this project. It's clear that a lot of thought and effort went into making it a success.",

    "What an incredible achievement! Your project serves as an inspiration to others, showcasing what can be accomplished with passion and determination.",

    "I'm amazed by the level of innovation displayed in this project. It's refreshing to see new ideas being brought to life in such a thoughtful and impactful way.",

    "This project is a testament to your team's expertise and collaborative spirit. Your ability to work together seamlessly has resulted in something truly exceptional.",

    "Kudos to everyone involved in bringing this project to fruition! Your commitment to excellence is evident in every aspect of its execution.",

    "I'm blown away by the results of this project. It's clear that you've set a high standard for quality and excellence.",

    "This project not only meets but exceeds expectations. It's a testament to your team's ingenuity and determination to push the boundaries.",

    "I'm thrilled to see the positive impact that this project is already having. It's a testament to the power of innovation and collaboration.",

    "Bravo! This project represents a significant milestone, showcasing the potential for positive change through creativity and dedication.",

    "I'm truly impressed by the thoughtfulness and thoroughness evident in every aspect of this project. It's clear that a lot of heart and soul went into its development.",

    "This project is a shining example of what happens when talent, dedication, and vision come together. It's been a privilege to witness such a remarkable achievement.",

    "It's evident that this project was approached with a keen understanding of both the problem and the solution. The result is a testament to your team's strategic thinking and execution.",

    "This project not only meets the brief but also surpasses it in terms of innovation and impact. It's a testament to your team's ability to think outside the box.",

    "I'm continually impressed by the level of professionalism demonstrated throughout this project. It's clear that every detail was carefully considered and executed with precision.",

    "The level of craftsmanship displayed in this project is truly exceptional. It's evident that each member of the team brought their unique skills and expertise to the table.",

    "This project is a testament to what can be achieved when passion meets purpose. It's inspiring to see how your team has translated vision into reality.",

    "I'm in awe of the transformative power of this project. It's evident that you've not only created something remarkable but also laid the groundwork for lasting change.",

    "This project serves as a reminder of the importance of perseverance and resilience in the face of challenges. Your team's ability to adapt and overcome obstacles is truly commendable.",

    "What a remarkable journey this project has been! From inception to completion, it's been a testament to your team's unwavering commitment to excellence.",
];

const useImagesStore = create((set) => ({
    images: [],
    actions: {
        reset: () => set({ images: [] }),
        addImage: ({ file }) =>
            set((state) => ({
                images: [
                    ...state.images,
                    {
                        id: uuid(),
                        imageUrl: "",
                        state: "idle",
                        file,
                    },
                ],
            })),
        removeImage: (id) =>
            set((state) => ({
                images: state.images.filter((image) => image.id !== id),
            })),
        markImageAsUploading: ({ id }) =>
            set((state) => ({
                images: state.images.map((image) => {
                    if (image.id === id) {
                        return {
                            ...image,
                            state: "uploading",
                        };
                    }
                    return image;
                }),
            })),

        markImageAsUploaded: ({ id, imageUrl }) =>
            set((state) => ({
                images: state.images.map((image) => {
                    if (image.id === id) {
                        return {
                            ...image,
                            imageUrl,
                            state: "uploaded",
                        };
                    }
                    return image;
                }),
            })),
        markImageaAsErrored: ({ id }) =>
            set((state) => ({
                images: state.images.map((image) => {
                    if (image.id === id) {
                        return {
                            ...image,
                            state: "errored",
                        };
                    }
                    return image;
                }),
            })),
    },
}));

const Image = ({ id }) => {
    const { images, actions } = useImagesStore();

    const currentImage = images.find((image) => image.id === id);

    const [getUploadURL] = useGetUploadUrlMutation({
        onCompleted: async (data) => {
            if (data?.getUploadURL?.success) {
                try {
                    let { downloadUrl, uploadParams } = data.getUploadURL;

                    uploadParams = JSON.parse(uploadParams);
                    let formData = new FormData();
                    Object.keys(uploadParams.fields).forEach((key) => {
                        formData.append(key, uploadParams.fields[key]);
                    });
                    formData.append("file", currentImage.file);
                    await axios.post(uploadParams.url, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    });
                    toast.dismiss();
                    actions.markImageAsUploaded({ id, imageUrl: downloadUrl });
                } catch (e) {
                    console.log(e);
                } finally {
                }
            }
        },
        onError: (error) => {
            toast.error(`Failed to upload: ${error.message ?? "Something went wrong please contact support"}`);
            actions.markImageaAsErrored({ id });
        },
    });

    useEffect(() => {
        if (currentImage.state === "idle") {
            getUploadURL({
                variables: {
                    fileName: currentImage.file.name,
                    fileSize: currentImage.file.size,
                    path: `\${type}/\${userId}/${currentImage.file.name}`,
                    uploadType: "images",
                },
            });

            actions.markImageAsUploading({
                id: currentImage.id,
            });
        }
    }, []);

    return (
        <div className="relative w-full h-32 rounded flex items-center justify-center" onClick={(e) => e.stopPropagation()}>
            <img src={URL.createObjectURL(currentImage.file)} alt="Preview" className="w-full  object-cover h-32 rounded-md" />
            {currentImage.state === "uploading" && (
                <div className="flex justify-center items-center absolute top-0 left-0 w-full h-full bg-black/30 text-white">
                    <LoaderCircle className="animate-spin" />
                </div>
            )}

            <CircleX
                fill="black"
                className="absolute top-0 right-0 translate-x-1/2 cursor-pointer text-white -translate-y-1/2"
                onClick={() => {
                    actions.removeImage(id);
                }}
            />

            {currentImage.state === "errored" && (
                <div className="flex justify-center items-center absolute top-0 left-0 w-full h-full bg-red-500/30 text-white">
                    <p>Failed to upload</p>
                </div>
            )}
        </div>
    );
};

const categories = [
    { value: "Community", label: "Community" },
    { value: "Education", label: "Education" },
    { value: "Environment", label: "Environment" },
    { value: "Healthcare", label: "Healthcare" },
    { value: "Housing", label: "Housing" },
    { value: "Infrastructure", label: "InfrastructurePower" },
    { value: "Roads & Bridges", label: "Roads & Bridges" },
    { value: "Social Justice", label: "Social Justice" },
    { value: "Technology", label: "Technology" },
    { value: "Waste Management", label: "Waste Management" },
    { value: "Water & Sanitization", label: "Water & Sanitization" },
    { value: "Other", label: "Other" },
];

const types = [
    { value: "CSR", label: "CSR" },
    { value: "MP/MLA", label: "MP/MLA Lads" },
    { value: "Probono", label: "Probono" },
    { value: "Individual", label: "Individual" },
    { value: "Corporate", label: "Corporate" },
    { value: "NPO", label: "NPO" },
];

const BasicInformationNew = () => {
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState(null);
    const { authUser } = useContext(AuthUserContext);
    const ImageRef = useRef(null);
    const [isMapModalOpen, setIsMapModalOpen] = useState(false);
    const [endDate, setEndDate] = useState(null);
    const [fileUploadType, setFileUploadType] = useState("");
    const [file, setFile] = useState(null);
    const [imageFiles, setImageFiles] = useState([]);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control,
        watch,
    } = useForm();

    const projectStatus = watch("project_status");

    console.log({ projectStatus });

    const today = startOfDay(new Date());

    const getDisabledDays = () => {
        return projectStatus?.value === "completed" ? {} : { before: today };
    };

    const disabledDays = getDisabledDays();

    const disabledEndDateDays = startDate ? { before: addDays(new Date(startDate), 1) } : {};

    const handleStartDateChange = (date) => {
        setStartDate(date);
        setEndDate("");
        setValue("startDate", date ? format(date, "yyyy-MM-dd") : "");
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
        setValue("endDate", date ? format(date, "yyyy-MM-dd") : "");
    };

    const [createProject, { loading }] = useCreateProjectMutation();

    const [imageFile, setImageFile] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState("");
    const [profileImagePreview, setProfileImagePreview] = useState("");
    const profileImageInputRef = useRef(null);
    const imageInputRef = useRef(null);

    const { images, actions } = useImagesStore();

    const onDrop = useCallback(
        (acceptedFiles) => {
            // Check if the total number of images after adding new ones exceeds 5
            if (images.length + acceptedFiles.length > 5) {
                toast.error("You can only upload up to 5 images.");
                return;
            }

            // Process each accepted file
            acceptedFiles.forEach((file) => {
                const reader = new FileReader();
                reader.onabort = () => console.log("file reading was aborted");
                reader.onerror = () => console.log("file reading has failed");
                reader.onload = () => {
                    // Assuming a method to add images to your state
                    actions.addImage({ file, imageUrl: reader.result });
                };
                reader.readAsDataURL(file);
            });
        },
        [images.length, actions]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: "image/*",
        maxFiles: 5 - images.length,
        maxSize: 5242880, // 5MB limit per file
    });

    const [getUploadURL] = useGetUploadUrlMutation({
        onCompleted: async (data) => {
            if (data?.getUploadURL?.success) {
                try {
                    let { downloadUrl, uploadParams } = data.getUploadURL;

                    uploadParams = JSON.parse(uploadParams);
                    let formData = new FormData();
                    Object.keys(uploadParams.fields).forEach((key) => {
                        formData.append(key, uploadParams.fields[key]);
                    });
                    formData.append("file", file);
                    await axios.post(uploadParams.url, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    });
                    toast.dismiss();
                    toast.success("File Uploaded Successfully");
                    setProfileImagePreview(downloadUrl);
                    setImagePreviewUrl(downloadUrl);
                } catch (e) {
                    console.log(e);
                } finally {
                }
            }
        },
    });

    const handleMoreImageChange = (event) => {
        const newFiles = Array.from(event.target.files);
        const totalFiles = images.length + newFiles.length;

        if (totalFiles > 5) {
            toast.error("You can only upload up to 5 images.");
            return;
        }

        newFiles.slice(0, 5 - images.length).forEach((file) => {
            actions.addImage({ file });
        });
    };

    const handleImageChange = (event) => {
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
            return;
        }
        if (fileObj.size / 1024 / 1024 > 5) {
            alert("File Size must be less than 5mb");
            return;
        }
        toast.loading("Uploading file, please wait...");
        setFile(fileObj);
        const type = "user-images";
        getUploadURL({
            variables: { fileName: fileObj.name, fileSize: fileObj.size, path: `\${type}/\${userId}/${fileObj.name}`, uploadType: "images" },
        });
        setFileUploadType("images");
    };

    const onClickProfileImageUpload = () => {
        console.log("clicked");
        console.log(profileImageInputRef.current);
        profileImageInputRef.current?.click();
    };

    const onClickImageUpload = () => {
        imageInputRef.current?.click();
    };

    const onSubmit = async (data) => {
        // check if any of the images are still uploading
        if (images.some((image) => image.state === "uploading")) {
            toast.error("Please wait for all images to finish uploading");
            return;
        }

        // check if any of the images are not uploaded
        if (images.some((image) => image.state !== "uploaded")) {
            toast.error("Some of the images are not uploaded, please remove them and retry");
            return;
        }

        let category = data.category.map((item) => item.value);

        let type = data.type.map((item) => item.value);

        if (!startDate) {
            toast.error("Please select a start date");
            return;
        }

        if (!endDate) {
            toast.error("Please select an end date");
            return;
        }

        const projectData = {
            title: data.title,
            problem: data.description,
            requirements: data.requirements,
            estimated_cost: data.estimatedCost,
            added_by: authUser.id,
            location_coordinates: {
                type: "Point",
                coordinates: [data?.location_coordinates?.lng, data?.location_coordinates?.lat],
            },
            location_title: data.location_title,
            category: JSON.stringify(category),
            project_status: data.project_status.value,
            type: JSON.stringify(type),
            project_profile_picture: profileImagePreview,
            start_date: startDate ? format(startDate, "yyyy-MM-dd") : "",
            end_date: endDate ? format(endDate, "yyyy-MM-dd") : "",
            images: {
                data: images.map(({ imageUrl }) => ({
                    image_url: imageUrl,
                })),
            },
        };

        try {
            const response = await createProject({
                variables: {
                    object: projectData,
                },
            });

            if (response.data && response.data.insert_projects_one) {
                toast.success("Project successfully created");
                navigate(`/projects/${response.data.insert_projects_one.id}`);
            }

            actions.reset();
        } catch (error) {
            console.error("Error creating project:", error);
            toast.error("Failed to create project");
        }
    };

    useEffect(() => {
        setValue("startDate", startDate ? format(startDate, "yyyy-MM-dd") : "");
        setValue("endDate", endDate ? format(endDate, "yyyy-MM-dd") : "");
    }, [startDate, endDate, setValue]);

    useEffect(() => {
        actions.reset();
    }, [actions]);

    return (
        <div className="bg-white flex flex-col  w-full h-full rounded-md px-4 md:px-0">
            <div className="border-b">
                <p className="w-fit px-4 py-3 border-b-2 border-blue-500 text-base font-semibold text-blue-500">Basic Information</p>
            </div>
            <form className="lg:px-4 py-4 w-full text-sm" onSubmit={handleSubmit(onSubmit)}>
                {profileImagePreview ? (
                    <div className="mb-4">
                        <img src={profileImagePreview} alt="Project" className="h-40 w-40 object-cover" />
                    </div>
                ) : (
                    <div className="mb-4">
                        <input type="file" ref={profileImageInputRef} style={{ display: "none" }} accept="image/*" onChange={handleImageChange} />
                        <div className="flex items-center border rounded w-fit p-1 cursor-pointer" onClick={onClickProfileImageUpload}>
                            <Camera className="w-6 h-6 text-gray-500 mr-2" />
                            add project profile image
                        </div>
                    </div>
                )}
                <div className="w-full mb-4">
                    <p className="mb-2 font-medium">Project Title*</p>
                    <Input className="" {...register("title", { required: "This field is required" })} />
                    {errors.title && <p className="text-xs text-red-500 mt-2 error">{errors.title.message}</p>}
                </div>
                <div className="mb-4 flex md:flex-row flex-col gap-4 w-full">
                    <div className="flex md:w-1/2 gap-4">
                        <div className="flex flex-col w-full">
                            <p className="mb-2 font-medium">Project Category*</p>
                            <Controller
                                name={"category"}
                                control={control}
                                render={({ field: { onChange, onBlur, value, name, ref } }) => {
                                    return (
                                        <CustomSelect
                                            placeholder="Select"
                                            multiple={true}
                                            isSearchable={true}
                                            options={categories}
                                            reactHookFormRegister={{
                                                onChange: async (e) => {
                                                    onChange(e);
                                                },
                                                onBlur: async () => {
                                                    onBlur();
                                                },
                                                value: value || null,
                                                name,
                                                ref,
                                            }}
                                        />
                                    );
                                }}
                            />
                            {errors.category && <p className="text-xs text-red-500 mt-2 error">{errors.category.message}</p>}
                        </div>
                        <div className="flex flex-col w-full">
                            <p className="mb-2 font-medium">Project Type</p>
                            <Controller
                                name={"type"}
                                control={control}
                                render={({ field: { onChange, onBlur, value, name, ref } }) => {
                                    return (
                                        <CustomSelect
                                            placeholder="Select"
                                            multiple={true}
                                            isSearchable={true}
                                            options={types}
                                            reactHookFormRegister={{
                                                onChange: async (e) => {
                                                    onChange(e);
                                                },
                                                onBlur: async () => {
                                                    onBlur();
                                                },
                                                value: value || null,
                                                name,
                                                ref,
                                            }}
                                        />
                                    );
                                }}
                            />

                            {errors.subcategory && <p className="text-xs text-red-500 mt-2 error">{errors.subcategory.message}</p>}
                        </div>
                    </div>
                    <div className="flex md:w-1/2 gap-4 w-full">
                        <div className="flex flex-col md:w-1/2">
                            <p className="mb-2 font-medium">Project Status*</p>
                            <Controller
                                name={"project_status"}
                                control={control}
                                render={({ field: { onChange, onBlur, value, name, ref } }) => {
                                    return (
                                        <CustomSelect
                                            placeholder="Select Project Status"
                                            multiple={false}
                                            isSearchable={true}
                                            options={[
                                                {
                                                    label: "Completed",
                                                    value: "completed",
                                                },
                                                {
                                                    label: "In Progress",
                                                    value: "in-progress",
                                                },
                                            ]}
                                            reactHookFormRegister={{
                                                onChange: async (e) => {
                                                    onChange(e);
                                                },
                                                onBlur: async () => {
                                                    onBlur();
                                                },
                                                value: value || null,
                                                name,
                                                ref,
                                            }}
                                        />
                                    );
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="flex  flex-col lg:flex-row justify-between gap-4 mb-4 w-full">
                    <div className="flex flex-col w-full lg:w-1/2">
                        <p className="mb-2 font-medium">Project Description*</p>
                        <Textarea className="resize-none h-40" {...register("description", { required: "This field is required" })} />
                        {errors.description && <p className="text-xs text-red-500 mt-2 error">{errors.description.message}</p>}
                    </div>
                    <div className="flex flex-col w-full lg:w-1/2">
                        <p className="mb-2 font-medium">Project Requirements*</p>
                        <Textarea className="resize-none h-40" {...register("requirements", { required: "This field is requirements" })} />
                        {errors.requirements && <p className="text-xs text-red-500 mt-2 error">{errors.requirements.message}</p>}
                    </div>
                </div>

                <div className="flex flex-col  lg:flex-row justify-between gap-4 w-full  text-sm">
                    <div className="md:w-1/2">
                        <div className="flex flex-col lg:flex-row gap-4 lg:gap-2 w-full ">
                            <div className="relative flex flex-col w-full ">
                                <p className="mb-3 font-medium">Project Start Date*</p>
                                <Popover>
                                    <PopoverTrigger asChild>
                                        <Button
                                            variant={"outline"}
                                            className={cn("justify-start text-left font-normal", !startDate && "text-muted-foreground")}
                                        >
                                            <CalendarIcon className="mr-2 h-4 w-4" />
                                            {startDate ? format(startDate, "PPP") : <span>Pick a startDate</span>}
                                        </Button>
                                    </PopoverTrigger>
                                    <PopoverContent className="w-auto p-0" align="start">
                                        <Calendar
                                            {...register("startDate", { required: "This field is requirements" })}
                                            mode="single"
                                            selected={startDate}
                                            onSelect={handleStartDateChange}
                                            initialFocus
                                            defaultMonth={today}
                                            disabled={disabledDays}
                                        />
                                    </PopoverContent>
                                </Popover>
                                {errors.startDate && <p className="text-xs text-red-500 mt-2 error">{errors.startDate.message}</p>}
                                {/* <img src="/assets/images/calendar.svg" alt="calendar" className="absolute cursor-pointer w-6 h-6 right-3 top-10" /> */}
                            </div>
                            <div className="relative flex flex-col w-full ">
                                <p className="mb-3 font-medium">Project End Date*</p>
                                <Popover>
                                    <PopoverTrigger asChild>
                                        <Button
                                            variant={"outline"}
                                            className={cn(" justify-start text-left font-normal", !endDate && "text-muted-foreground")}
                                        >
                                            <CalendarIcon className="mr-2 h-4 w-4" />
                                            {endDate ? format(endDate, "PPP") : <span>Pick a endDate</span>}
                                        </Button>
                                    </PopoverTrigger>
                                    <PopoverContent className="w-auto p-0" align="start">
                                        <Calendar
                                            {...register("endDate", { required: "This field is requirements" })}
                                            mode="single"
                                            selected={endDate}
                                            minDate={startDate}
                                            onSelect={handleEndDateChange}
                                            initialFocus
                                            defaultMonth={startDate ? addDays(new Date(startDate), 1) : new Date()}
                                            disabled={disabledEndDateDays}
                                        />
                                    </PopoverContent>
                                </Popover>
                                {errors.endDate && <p className="text-xs text-red-500 mt-2 error">{errors.endDate.message}</p>}
                            </div>
                        </div>

                        <div className="flex flex-col lg:flex-row gap-4 lg:gap-2 mt-4 w-full ">
                            <div className="flex flex-col w-full ">
                                <p className="mb-3 font-medium">Proposed Estimated Budget*</p>
                                <Input
                                    step="0.01"
                                    type="number"
                                    className=""
                                    {...register("estimatedCost", { required: "This field is required" })}
                                />
                                {errors.estimatedCost && <p className="text-xs text-red-500 mt-2 error">{errors.estimatedCost.message}</p>}
                            </div>
                            <div className="flex flex-col w-full ">
                                <p className="mb-3 font-medium">Address*</p>
                                <Input
                                    className=""
                                    value={watch("location_title") || ""}
                                    // {...register("address", { required: "This field is required" })}
                                    onClick={() => setIsMapModalOpen(!isMapModalOpen)}
                                />
                                {errors.address && <p className="text-xs text-red-500 mt-2 error">{errors.address.message}</p>}
                            </div>
                        </div>
                    </div>

                    <div className="flex  md:w-1/2">
                        <div className="w-full ">
                            <div className="flex items-center mb-2">
                                <lable className="text-sm mr-2">Add Project Photos</lable>
                                {/* <div className="flex items-center  rounded  cursor-pointer">
                                    <Camera className="w-5 h-5 text-gray-500 " onClick={onClickImageUpload} />
                                </div> */}
                            </div>
                            <input
                                type="file"
                                ref={imageInputRef}
                                style={{ display: "none" }}
                                accept="image/*"
                                multiple
                                onChange={handleMoreImageChange}
                            />

                            {/* {images.length === 0 ? (
                                <div
                                    className="w-full flex-col border-dashed border-2 h-[147px] border-gray-300 bg-gray-100 rounded-md flex justify-center items-center"
                                    onClick={onClickImageUpload}
                                >
                                    <img src="/assets/images/gallery.png" className="w-6 h-6 text-gray-500" />
                                    <p className="text-gray-500">Add Project Photos</p>
                                </div>
                            ) : (
                                <div className="grid  grid-cols-5 h-[147px]  gap-3  p-3 mb-3">
                                    {images.map(({ id }) => (
                                        <Image key={id} id={id} />
                                    ))}
                                </div>
                            )} */}

                            {/* {images.length === 0 ? (
                                <div {...getRootProps()} className="dropzone">
                                    <input {...getInputProps()} />
                                    {isDragActive ? (
                                        <div className="w-full flex-col border-dashed border-2 h-[147px] border-gray-300 bg-gray-100 rounded-md flex justify-center items-center">
                                            <img src="/assets/images/gallery.png" className="w-6 h-6 text-gray-500" />
                                            <p className="text-gray-500">Drop files here...</p>
                                        </div>
                                    ) : (
                                        <aside>
                                            <div
                                                onClick={onClickImageUpload}
                                                className="w-full flex-col cursor-pointer border-dashed border-2 h-[147px] border-gray-200 bg-gray-100 rounded-md flex justify-center items-center"
                                            >
                                                <img src="/assets/images/gallery.png" className="w-6 h-6 text-gray-500" />
                                                <p className="text-gray-500">Add Project Photos</p>
                                            </div>
                                        </aside>
                                    )}
                                </div>
                            ) : (
                                <div className="grid  grid-cols-5 h-[147px] items-center justify-center rounded-md  gap-3   mb-3">
                                    {images.map(({ id }) => (
                                        <Image key={id} id={id} />
                                    ))}
                                </div>
                            )} */}
                            <div {...getRootProps()} className="dropzone">
                                <input {...getInputProps()} />
                                {isDragActive ? (
                                    <div className="w-full flex-col border-dashed border-2 h-[147px] border-gray-300 bg-gray-100 rounded-md flex justify-center items-center">
                                        <img src="/assets/images/gallery.png" className="w-6 h-6 text-gray-500" />
                                        <p className="text-gray-500">Drop files here...</p>
                                    </div>
                                ) : (
                                    <aside>
                                        <div
                                            onClick={onClickImageUpload}
                                            className={clsx(
                                                "w-full flex-col p-3 cursor-pointer border-dashed border-2 border-gray-200 bg-gray-100 rounded-md flex justify-center items-center",
                                                images.length === 0 ? "h-[147px]" : ""
                                            )}
                                        >
                                            {images.length === 0 ? (
                                                <Fragment>
                                                    <img src="/assets/images/gallery.png" className="w-6 h-6 text-gray-500" />
                                                    <p className="text-gray-500">Add Project Photos</p>
                                                </Fragment>
                                            ) : (
                                                <div className="grid grid-cols-5 items-center justify-center rounded-md gap-3">
                                                    {images.map(({ id }) => (
                                                        <Image key={id} id={id} />
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </aside>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex justify-end mt-4">
                    <button className="bg-primary text-white px-14 py-2 rounded-md">{loading ? "Submitting..." : "Submit"}</button>
                </div>
            </form>
            <CustomModal isOpen={isMapModalOpen} header="&nbsp;Choose your Location" setOpen={setIsMapModalOpen}>
                <MapModalContent
                    onLocationChange={(value) => {
                        setValue("location_title", value);
                    }}
                    onInputChange={(name, value) => {
                        setValue(name, value);
                    }}
                    setOpen={setIsMapModalOpen}
                />
            </CustomModal>
        </div>
    );
};

export default BasicInformationNew;
