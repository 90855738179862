import { useState } from "react";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { RegisterModalFooter } from "..";

function ProBono({ proBonoState, setProBonoState, email, setOpen, registerUser }) {
    let { proBonoName, proBonoProfession, proBonoContributionCategories, proBonoSkills, proBonoPointOfContact, proBonoGender } = proBonoState;
    const [selected, setSelected] = useState(false);

    const handleProBonoChange = (e) => {
        if (e.target.value.indexOf("All Categories") > -1) {
            setProBonoState((prevState) => ({
                ...prevState,
                [e.target.name]: proBonoContributionCategories.length !== 0 ? [] : all_projects.map((option) => option),
            }));
        } else {
            setProBonoState((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value,
            }));
        }
    };
    const handleClick = (e) => {
        if (proBonoContributionCategories.length === all_projects.length) {
            setSelected(false);
            e.stopPropagation();
        } else {
            setSelected(true);
        }
    };

    const all_projects = [
        "Agriculture",
        "Community",
        "Education",
        "Environment",
        "HealthCare",
        "Housing",
        "InfraStructurePower",
        "Roads and Bridges",
        "Social Justice",
        "Technology",
        "Waste Management",
        "Water & Sanitization",
        "Others",
    ];

    const handleBackClick = () => {
        setOpen("usertype");
    };

    const handleForwardClick = () => {
        if (
            !proBonoName ||
            !proBonoProfession ||
            proBonoContributionCategories.length === 0 ||
            !proBonoSkills ||
            !proBonoPointOfContact ||
            !proBonoGender
        ) {
            return alert("please fill all Pro Bono details");
        }
        registerUser();
    };

    return (
        <Grid item xs={12} className="common-modal__container" container direction="column" justifyContent="flex-start" alignItems="flex-start">
            <h3>
                Sign up as <span className="span-text">Pro-Bono.</span>
            </h3>
            <Grid item xs={12} className="field__grid" container justifyContent="space-between" alignItems="center" mb="15px">
                <TextField
                    variant="standard"
                    label="Name"
                    className="input__field"
                    fullWidth
                    type="text"
                    name="proBonoName"
                    value={proBonoName}
                    onChange={handleProBonoChange}
                />
                <TextField
                    variant="standard"
                    label="Email"
                    className="input__field"
                    fullWidth
                    type="text"
                    name="proBonoEmail"
                    disabled
                    value={email}
                />
            </Grid>

            <Grid item xs={12} className="field__grid" container justifyContent="space-between" alignItems="center" mb="15px">
                <TextField
                    variant="standard"
                    label="Profession"
                    className="input__field"
                    fullWidth
                    type="text"
                    name="proBonoProfession"
                    value={proBonoProfession}
                    onChange={handleProBonoChange}
                />

                <TextField
                    variant="standard"
                    label="Contact"
                    className="input__field"
                    fullWidth
                    onKeyPress={(event) => {
                        // ignore "-", "+" and "e"
                        if (event?.key === "-" || event?.key === "+" || event?.key === "e") {
                            event.preventDefault();
                        }
                        // if character is not number then ignore except decimal point and
                        if (event?.key !== "." && event?.key && isNaN(+event.key)) {
                            event.preventDefault();
                        }
                        if (event.target.value.length >= 10) {
                            event.preventDefault();
                        }
                    }}
                    InputProps={{
                        maxLength: 10,
                    }}
                    type="text"
                    name="proBonoPointOfContact"
                    value={proBonoPointOfContact}
                    onChange={handleProBonoChange}
                />
            </Grid>

            <Grid item xs={12} className="field__grid" container justifyContent="space-between" alignItems="center" mb="15px">
                <FormControl variant="standard" sx={{ width: "45%" }}>
                    <InputLabel id="demo-simple-select-standard-label">Focus of Interest</InputLabel>
                    <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-multiple-name"
                        multiple
                        value={proBonoContributionCategories}
                        name="proBonoContributionCategories"
                        onChange={handleProBonoChange}
                    >
                        <MenuItem
                            value={"All Categories"}
                            onClick={handleClick}
                            style={selected ? { backgroundColor: "rgba(25, 118, 210, 0.08)" } : {}}
                        >
                            All Categories
                        </MenuItem>
                        {all_projects.map((name) => (
                            <MenuItem key={name} value={name}>
                                {name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    variant="standard"
                    label="Skills"
                    className="input__field"
                    fullWidth
                    type="text"
                    name="proBonoSkills"
                    value={proBonoSkills}
                    onChange={handleProBonoChange}
                />
            </Grid>
            <Grid>
                <FormControl className="radio__container">
                    <FormLabel id="radio-btns-label">Gender</FormLabel>
                    <RadioGroup row value={proBonoGender} onChange={handleProBonoChange} name="proBonoGender">
                        <FormControlLabel value="female" control={<Radio size="small" />} label="Female" />
                        <FormControlLabel value="male" control={<Radio size="small" />} label="Male" />
                        <FormControlLabel value="other" control={<Radio size="small" />} label="Other" />
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12} container direction="row" justifyContent="space-between" alignItems="center" className="button__container">
                <Button className="back__btn" type="button" onClick={handleBackClick}>
                    Back
                </Button>
                <Button className="continue__btn" type="button" onClick={handleForwardClick}>
                    Save and continue
                </Button>
            </Grid>
            <RegisterModalFooter />
        </Grid>
    );
}

export default ProBono;
