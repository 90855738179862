import React from "react";
import { useNavigate } from "react-router-dom";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import "./InfoItem.styles.scss";

const InfoItem = ({ title, icon, value, theme, showInfo, activeProjects, inactiveProjects }) => {
    const navigate = useNavigate();
    const handleClick = () => {
        if (title.includes("Users")) {
            navigate("/admin/backend/users-kyc");
        } else if (title.includes("Projects")) {
            navigate("/admin/backend/all-projects");
        }
    };
    return (
        <Grid item container className="item_container">
            <Grid item xs={12} container className={`item-header__container ${theme}`} direction="column">
                <Typography className="item-title">{title}</Typography>
                {showInfo && (
                    <Typography className="item-click" onClick={handleClick}>
                        Click for more <KeyboardDoubleArrowRightIcon className="more-icon" />{" "}
                    </Typography>
                )}
                <img src={`https://equippp-assets.s3.ap-south-1.amazonaws.com/images/${icon}.png`} className="user-icon" alt="" />
            </Grid>
            <Grid item xs={12} container alignItems="center" className="item-value__container">
                {title === "Projects Status" ? (
                    <Grid container xs={12}>
                        <Grid container xs={12}>
                            <Grid item xs={6}>
                                <Typography className="item-click"> Active </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className="item-click"> Inactive </Typography>
                            </Grid>
                        </Grid>
                        <Grid container xs={12}>
                            <Grid item xs={6}>
                                <Typography className="item-value">{activeProjects}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className="item-value">{inactiveProjects}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <Typography className="item-value">{value}</Typography>
                )}
            </Grid>
        </Grid>
    );
};

export default InfoItem;
