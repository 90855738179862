import React from "react";
import { Grid, ImageList, ImageListItem, Typography, Box } from "@mui/material";
import "./Gallery.styles.scss";

const Gallery = ({ galleryType }) => {
    return (
        <div className="gallery_container">
            <Grid container item xs={12} direction="column" justifyContent="center" alignItems="center" className="gallery_header">
                <Typography>Home &gt;&gt; {galleryType === "image" ? "Image Gallery" : "Video Gallery"}</Typography>
                <Typography variant="h1"> {galleryType === "image" ? "Image Gallery" : "Video Gallery"} </Typography>
                <Typography>
                    If you have business inquiries or other questions, please fill out the following form to contact us. Thank you.
                </Typography>
            </Grid>
            <Box variant="div" className="image_gallery_container">
                <ImageList style={{ gap: "2rem" }} cols={3} rowHeight={400}>
                    <ImageListItem>
                        {galleryType === "image" ? (
                            <img src="https://equippp-assets.s3.ap-south-1.amazonaws.com/images/gallery-placeholder.jpg" loading="lazy" alt="" />
                        ) : (
                            <video className="gallery_video_player" controls>
                                <source src="https://joy.videvo.net/videvo_files/video/free/2013-07/large_watermarked/hd0051_preview.mp4" />
                                Sorry, your browser doesn't support embedded videos.
                            </video>
                        )}
                    </ImageListItem>
                    <ImageListItem>
                        {galleryType === "image" ? (
                            <img src="https://equippp-assets.s3.ap-south-1.amazonaws.com/images/gallery-placeholder.jpg" loading="lazy" alt="" />
                        ) : (
                            <video className="gallery_video_player" controls>
                                <source src="https://joy.videvo.net/videvo_files/video/free/2013-07/large_watermarked/hd0051_preview.mp4" />
                                Sorry, your browser doesn't support embedded videos.
                            </video>
                        )}
                    </ImageListItem>
                    <ImageListItem>
                        {galleryType === "image" ? (
                            <img src="https://equippp-assets.s3.ap-south-1.amazonaws.com/images/gallery-placeholder.jpg" loading="lazy" alt="" />
                        ) : (
                            <video className="gallery_video_player" controls>
                                <source src="https://joy.videvo.net/videvo_files/video/free/2013-07/large_watermarked/hd0051_preview.mp4" />
                                Sorry, your browser doesn't support embedded videos.
                            </video>
                        )}
                    </ImageListItem>
                </ImageList>
            </Box>
        </div>
    );
};

export default Gallery;
