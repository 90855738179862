import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import { RegisterModalFooter } from "..";

function MP({ mpState, setMpState, email, setOpen, registerUser }) {
    let { mpName, mpConstituency, mpContactDetails, mpPartyName, mpTenureDuration, mpGender } = mpState;
    const handleMpChange = (e) => {
        setMpState((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };

    const handleBackClick = () => {
        setOpen("usertype");
    };

    const handleForwardClick = () => {
        if (!mpName || !mpConstituency || !mpContactDetails || !mpPartyName || !mpTenureDuration) {
            return alert("please fill all  member of parliament  details");
        }
        var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
        if (!re.test(mpContactDetails)) {
            return alert("enter valid mp contact number");
        }
        registerUser();
    };
    return (
        <Grid item xs={12} className="common-modal__container" container direction="column" justifyContent="flex-start" alignItems="flex-start">
            <h3>
                Sign up as <span className="span-text">MP.</span>
            </h3>
            <Grid item xs={12} className="field__grid" container justifyContent="space-between" alignItems="center" mb="15px">
                <TextField
                    variant="standard"
                    label="Name"
                    className="input__field"
                    fullWidth
                    type="text"
                    name="mpName"
                    value={mpName}
                    onChange={handleMpChange}
                />
                <TextField variant="standard" label="Email" className="input__field" fullWidth type="text" name="mpEmail" disabled value={email} />
            </Grid>

            <Grid item xs={12} className="field__grid" container justifyContent="space-between" alignItems="center" mb="15px">
                <TextField
                    variant="standard"
                    label="Constituency"
                    className="input__field"
                    fullWidth
                    type="text"
                    name="mpConstituency"
                    value={mpConstituency}
                    onChange={handleMpChange}
                />
                <TextField
                    variant="standard"
                    label="Contact"
                    className="input__field"
                    fullWidth
                    type="text"
                    onKeyPress={(event) => {
                        // ignore "-", "+" and "e"
                        if (event?.key === "-" || event?.key === "+" || event?.key === "e") {
                            event.preventDefault();
                        }
                        // if character is not number then ignore except decimal point and
                        if (event?.key !== "." && event?.key && isNaN(+event.key)) {
                            event.preventDefault();
                        }
                        if (event.target.value.length >= 10) {
                            event.preventDefault();
                        }
                    }}
                    InputProps={{
                        maxLength: 10,
                    }}
                    name="mpContactDetails"
                    value={mpContactDetails}
                    onChange={handleMpChange}
                />
            </Grid>

            <Grid item xs={12} className="field__grid" container justifyContent="space-between" alignItems="center" mb="15px">
                <TextField
                    variant="standard"
                    label="Party Name"
                    className="input__field"
                    fullWidth
                    type="text"
                    name="mpPartyName"
                    value={mpPartyName}
                    onChange={handleMpChange}
                />
                <TextField
                    variant="standard"
                    label="Tenure/Duration"
                    className="input__field"
                    fullWidth
                    type="text"
                    name="mpTenureDuration"
                    value={mpTenureDuration}
                    onChange={handleMpChange}
                />
            </Grid>
            <Grid item xs={12} className="field__grid" container justifyContent="flex-start" alignItems="center" mb="15px">
                <FormControl className="radio__container">
                    <FormLabel id="radio-btns-label">Gender</FormLabel>
                    <RadioGroup row name="mpGender" value={mpGender} onChange={handleMpChange}>
                        <FormControlLabel value="female" control={<Radio size="small" />} label="Female" />
                        <FormControlLabel value="male" control={<Radio size="small" />} label="Male" />
                        <FormControlLabel value="other" control={<Radio size="small" />} label="Other" />
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12} container direction="row" justifyContent="space-between" alignItems="center" className="button__container">
                <Button className="back__btn" type="button" onClick={handleBackClick}>
                    Back
                </Button>
                <Button className="continue__btn" type="button" onClick={handleForwardClick}>
                    Save and continue
                </Button>
            </Grid>
            <RegisterModalFooter />
        </Grid>
    );
}

export default MP;
