import React, { useContext, useEffect, useState } from "react";
import Linkify from "react-linkify";
import { MentionsInput, Mention } from "react-mentions";
import { toast } from "react-toastify";
import { Avatar, Button, Grid, TextField } from "@mui/material";
import { useAddCommentMutation, useGetUsersQuery } from "types/generated-types.ts";
import AuthUserContext from "contexts/session";

const CommentInput = ({ projectId, isReply, parentComment, parentUser, profilePicture }) => {
    const [comment, setComment] = useState("");
    const [validComment, setValidComment] = useState("");
    const { authUser } = useContext(AuthUserContext);
    const [users, setUsers] = useState([]);
    const { data: usersData } = useGetUsersQuery();

    useEffect(() => {
        let usersArray = [];
        if (usersData) {
            usersData.users.forEach((item) => usersArray.push({ id: item.id, display: item.user_name }));
            setUsers(usersArray);
        }
    }, [usersData]);

    useEffect(() => {
        if (parentUser) {
            setComment(`@[${parentUser?.user_name}](${parentUser?.id})`);
        }
    }, [parentUser]);

    useEffect(() => {
        console.log(comment);
    }, [comment]);

    const [addComment] = useAddCommentMutation({
        onCompleted: (data) => {
            if (data && data?.insert_comments_one?.status) {
                toast.success("Your comment added successfully, it'll be visible to everyone once admin approves it");
                setComment("");
                setValidComment(false);
            }
        },
    });

    const submitComment = () => {
        const trimmedComment = comment.trim();
        if (trimmedComment.length === 0) {
            toast.error("You can't post empty spaces.");
            setValidComment(false);
            return;
        }
        if (isReply) {
            if (comment !== "") {
                addComment({
                    variables: {
                        added_by: authUser.id,
                        project_id: projectId,
                        parent_comment_id: parentComment.id,
                        comment,
                    },
                });
            } else {
                setValidComment(false);
            }
        } else {
            if (comment !== "") {
                addComment({
                    variables: {
                        added_by: authUser.id,
                        project_id: projectId,
                        comment,
                    },
                });
            } else {
                setValidComment(false);
            }
        }
    };

    console.log(authUser, comment, "here");
    return (
        <Grid item xs={12} container alignItems="center" className="mt-3">
            <div className="flex w-full justify-between mb-3">
                <Avatar
                    src={profilePicture ? profilePicture : "https://equippp-assets.s3.ap-south-1.amazonaws.com/images/no_project_image.jpeg"}
                    alt=""
                    className="w-10 h-10 rounded-full mr-3"
                />
                <div className="w-full ">
                    <MentionsInput
                        className="bg-gray-100 h-20 pb-2  rounded-md"
                        style={{
                            control: {
                                fontSize: 16,
                            },
                            highlighter: {
                                border: "1px solid transparent",
                            },
                            input: {
                                padding: 9,
                                border: "none",
                                outline: "none",
                            },
                            suggestions: {
                                list: {
                                    backgroundColor: "white",
                                    border: "1px solid rgba(0,0,0,0.15)",
                                    fontSize: 16,
                                    height: 250,
                                    padding: 6,
                                    overflowY: "auto",
                                },
                                item: {
                                    padding: "5px 15px",
                                    borderBottom: "1px solid rgba(0,0,0,0.15)",
                                },
                            },
                        }}
                        placeholder="Write a comment !!!"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    >
                        <Mention
                            trigger="@"
                            style={{ backgroundColor: "rgba(104, 89, 249, 0.5)" }}
                            data={users}
                            displayTransform={(id, display) => `@${display}`}
                        />
                    </MentionsInput>
                </div>
            </div>

            <div className="w-full flex justify-end">
                <Button variant="contained" className="bg-blue-600 text-white rounded-full px-4 text-xs" onClick={submitComment}>
                    Post
                </Button>
            </div>
        </Grid>
    );
};

export default CommentInput;
