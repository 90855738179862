import { useRef, useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import {
    useGetAllVerifiedActiveProjectQuery,
    useGetAllLocationCoordinatesQuery,
    useGetAllVerifiedActiveProjectForCurrentUserQuery,
} from "types/generated-types.ts";
import { ProjectTile } from "views/Projects/components";
import Map from "./Map";
import "./ProfileMap.styles.scss";
import AuthUserContext from "contexts/session";
import { create } from "zustand";

const PAGINATION_SIZE = 20;

export const useProjectModalStore = create((set) => ({
    selectedProject: null,
    actions: {
        close: () => set({ selectedProject: false }),
        setSelectedProject: (selectedProject) => set({ selectedProject }),
    },
}));

const ProjectModal = () => {
    const { selectedProject, actions } = useProjectModalStore();

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",

        bgcolor: "background.paper",
        borderRadius: "10px",
        boxShadow: 24,
        p: 1,
    };

    return (
        <Modal open={!!selectedProject} onClose={actions.close}>
            <Box sx={style} className="w-[400px] md:w-[500px]">
                {selectedProject !== null && <ProjectTile project={selectedProject} />}
            </Box>
        </Modal>
    );
};

const ProfileMap = ({ position, height = 310, allPositions, projectsData }) => {
    const projectRefs = useRef([]);

    const onMarkerClick = (index, position) => {
        if (projectRefs.current[index]) {
            projectRefs.current[index].scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }

        useProjectModalStore.getState().actions.setSelectedProject(projectsData[index]);
    };

    return (
        <div className="!h-[310px]">
            <div className="relative h-full">
                <Map currentPosition={position} height={height} onMarkerClick={onMarkerClick} allPositions={allPositions} />
            </div>
            <ProjectModal />
        </div>
    );
};

export default ProfileMap;
