import { useState, Fragment, useContext, useMemo } from "react";
import LinesEllipsis from "react-lines-ellipsis";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CurrencyRupeeTwoToneIcon from "@mui/icons-material/CurrencyRupeeTwoTone";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { usePromoteProjectMutation } from "types/generated-types.ts";
import CustomModal, {
    RegisterSuccessModalContent,
    LoginSuccessModalContent,
    ForgotPasswordModalContent,
    FeedbackModalContent,
} from "components/CustomModal/CustomModal";
import { RegisterModalContent, LoginModalContent } from "components/CustomModal/Forms";
import AuthUserContext from "contexts/session";
import "./ProjectTile.styles.scss";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    color: "#fff",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#2a2a2a",
    borderRadius: "0.5rem",
    boxShadow: 24,
    p: 4,
};

const ProjectTile = ({ project, ...props }) => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");

    console.log("project", project);

    const [expanded, setExpanded] = useState(false);
    const { authUser } = useContext(AuthUserContext);
    const authLocal = localStorage.getItem("authUser");
    const [isModalOpen, setModal] = useState(() => false);
    const toggleAuthModal = (value) => {
        if (value) setModal(value);
        else setModal(false);
    };

    const [promoteProject, { data: promotionData, loading: promotionLoading }] = usePromoteProjectMutation({
        onCompleted: (data) => {
            if (data?.insert_project_promotions_one?.message === message) {
                toast.success("Project promoted successfully");
            } else {
                toast.error("Something went wrong");
            }
            setOpen(false);
            setMessage("");
        },
    });

    const handleClose = () => setOpen(false);

    let navigate = useNavigate();

    const viewProject = () => {
        localStorage.setItem("project-tileDetails", JSON.stringify(props.projectDetails));
        navigate(`/projects/${project?.id}`, { replace: false });
    };

    const receivedExpressions = useMemo(() => {
        if (project && project?.expressions && project?.expressions?.length) {
            return project?.expressions?.reduce((acc, curr) => (curr.is_verified ? acc + curr.investment_amount : acc), 0);
        }
        return 0;
    }, [project]);

    return (
        <Grid className="project-tile__container" container direction="column">
            {/* {project?.agency_name && (
                <Typography variant="h6" className="project-tile__self-or-others">
                    {project?.agency_name}
                </Typography>
            )} */}

            <Grid item container>
                <Grid item xs={3} className="project-tile__image__container">
                    <img
                        alt=""
                        src={
                            project.project_profile_picture
                                ? project.project_profile_picture
                                : "https://equippp-assets.s3.ap-south-1.amazonaws.com/images/no_project_image.jpeg"
                        }
                        className="object-cover w-full h-28 rounded-lg"
                    />
                </Grid>
                <Grid item xs={9} container direction="column" justifyContent="flex-start" className="project-tile__detail">
                    <Grid item container alignItems="center" style={{ marginBottom: "0.2rem" }}>
                        <Typography variant="h6" className="project-tile__name">
                            {project?.title}
                        </Typography>
                        <div className="project-tile__scope">
                            {expanded ? (
                                project?.objective
                            ) : (
                                <LinesEllipsis
                                    text={project?.objective || ""}
                                    maxLine="3"
                                    ellipsis={
                                        <a
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setExpanded(true);
                                            }}
                                            style={{
                                                textDecoration: "none",
                                                marginLeft: "0.3rem",
                                            }}
                                        >
                                            show more.....
                                        </a>
                                    }
                                    trimRight
                                />
                            )}
                        </div>
                    </Grid>
                    {project?.location_title && (
                        <Grid item container className="project-tile__description" justifyContent="center" alignItems="center">
                            <Grid item xs="auto" container alignItems="flex-start">
                                <MyLocationIcon className="project-tile-icon" />
                            </Grid>
                            <Grid item xs={true} container alignItems="center">
                                <Typography variant="body2" className="project-tile__description__text">
                                    {project?.location_title}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}

                    <Grid item container className="project-tile__description" justifyContent="center" alignItems="center">
                        <Grid item xs="auto" container alignItems="flex-start">
                            <CurrencyRupeeTwoToneIcon className="project-tile-icon" />
                        </Grid>
                        <Grid item xs={true} container alignItems="center">
                            {project?.project_status === "completed" ? (
                                <Typography variant="body2" className="project-tile__description__text">
                                    Expression Received:{project?.estimated_cost}
                                </Typography>
                            ) : (
                                <Typography variant="body2" className="project-tile__description__text">
                                    Expression Received: <span>{(receivedExpressions && receivedExpressions.toLocaleString("en-IN")) || 0}</span>
                                    {/*{project?.expressions_aggregate?.aggregate?.sum?.investment_amount || 0}*/}
                                    &nbsp;&nbsp;&nbsp;/
                                    <span>{project?.estimated_cost ? project?.estimated_cost?.toLocaleString() : "NA"}</span>
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item container className="project-tile__description" justifyContent="center" alignItems="center">
                        <Grid item xs="auto" container alignItems="flex-start">
                            <AccessTimeOutlinedIcon className="project-tile-icon" />
                        </Grid>
                        <Grid item xs={true} container alignItems="center">
                            <Typography variant="h6" className="project-tile__description__text">
                                {(project?.start_date && project?.start_date?.substring(0, 10)) || "Not Available"}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container justifyContent="space-between" alignItems="center" className="project-tile__like__section">
                        <Grid container direction="row" justifyContent="end" alignItems="center">
                            {/* {authUser && authLocal ? (
                                <Grid item xs={"auto"} style={{ cursor: "pointer" }}>
                                    <Button variant="contained" className="view-btn" onClick={() => setOpen(true)}>
                                        Promote Project
                                    </Button>
                                </Grid>
                            ) : (
                                <Grid item xs={"auto"}>
                                    <Button variant="contained" className="view-btn" onClick={() => toggleAuthModal("login")}>
                                        Login
                                    </Button>
                                </Grid>
                            )} */}

                            <Grid item xs={"auto"}>
                                <Button variant="contained" className="view-btn" onClick={() => viewProject()}>
                                    View Project
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* login modal */}
            <CustomModal isOpen={isModalOpen === "login"} isNotStyleDefault={true} header="" setOpen={setModal}>
                <LoginModalContent setOpen={setModal} isAdmin={props.isAdmin} />
            </CustomModal>

            {/* register modal */}
            <CustomModal isOpen={isModalOpen === "register"} isNotStyleDefault={true} header={""} setOpen={setModal}>
                <RegisterModalContent setOpen={setModal} />
            </CustomModal>

            {/* register success modal */}
            <CustomModal isOpen={isModalOpen === "register-success"} header="&nbsp;" setOpen={setModal}>
                <RegisterSuccessModalContent setOpen={setModal} />
            </CustomModal>

            {/* login success modal */}
            <CustomModal isOpen={isModalOpen === "login-success"} header="&nbsp;SUCCESS" setOpen={setModal}>
                <LoginSuccessModalContent setOpen={setModal} />
            </CustomModal>

            {/* feedback modal */}
            <CustomModal isOpen={isModalOpen === "feedback"} header="&nbsp;GIVE YOUR FEEDBACK HERE" setOpen={setModal}>
                <FeedbackModalContent setOpen={setModal} />
            </CustomModal>

            {/* forgot password modal */}
            <CustomModal isOpen={isModalOpen === "forgot"} header="FORGOT PASSWORD?" setOpen={setModal}>
                <ForgotPasswordModalContent setOpen={setModal} toggle={(val) => props.toggle(val)} />
            </CustomModal>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Typography
                        id="settle-modal__title"
                        variant="h6"
                        style={{
                            fontSize: "1rem",
                            marginBottom: "1rem",
                        }}
                        component="h6"
                    >
                        Please mention a message to be displayed on the promoted project
                    </Typography>
                    <TextField
                        fullWidth
                        label="Message"
                        className="project-tile__promotion__message__input"
                        style={{
                            margin: "1rem 0",
                            color: "white",
                        }}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{ display: "flex", justifyContent: "center" }}>
                        {promotionLoading ? (
                            <CircularProgress />
                        ) : (
                            <Fragment>
                                <Button
                                    variant="contained"
                                    name="yes"
                                    onClick={(e) => {
                                        if (message === "") {
                                            toast.error("Please enter a valid message");
                                        } else {
                                            promoteProject({
                                                variables: {
                                                    project_id: project.id,
                                                    user_id: authUser.id,
                                                    message: message,
                                                },
                                            });
                                        }
                                    }}
                                    id="settle-modal__yes"
                                    style={{ backgroundColor: "green", margin: "0.6rem" }}
                                >
                                    Submit
                                </Button>
                                <Button
                                    variant="contained"
                                    name="no"
                                    style={{ backgroundColor: "red", margin: "0.6rem" }}
                                    onClick={() => {
                                        setOpen(false);
                                    }}
                                    id="settle-modal__no"
                                >
                                    Cancel
                                </Button>
                            </Fragment>
                        )}
                    </Typography>
                </Box>
            </Modal>
        </Grid>
    );
};

export default ProjectTile;
ProjectTile.propTypes = {
    projectDetails: PropTypes.any,
};
