import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const mentionRegex = /@\[([A-Za-z0-9_\s]+)\]\(([A-Za-z0-9-\s]+)\)/g;

const convertMentionsToLinks = (text, authUser) => {
    const authLocal = localStorage.getItem("authUser");
    // get index ranges of the mentions
    const ranges = [];
    let match;
    while ((match = mentionRegex.exec(text))) {
        ranges.push([match.index, mentionRegex.lastIndex]);
    }

    if (ranges.length === 0) return text;

    const elements = [];
    // make sure to handle null values while matching
    for (let i = 0; i < ranges.length; i++) {
        const [start, end] = ranges[i];
        const [prevStart, prevEnd] = ranges[i - 1] || [0, 0];
        const [nextStart, nextEnd] = ranges[i + 1] || [text.length, text.length];

        // add the text before the mention
        if (prevEnd < start) {
            elements.push(text.substring(prevEnd, start));
        }

        // add the mention
        const mention = text.substring(start, end);
        // mention regex need to be defined here again to reset the pointer of the regex, do not delete this
        // refer: https://stackoverflow.com/questions/4724701/regexp-exec-returns-null-sporadically
        const mentionRegex = /@\[([A-Za-z0-9_\s]+)\]\(([A-Za-z0-9-\s]+)\)/g;
        const mentionParts = mentionRegex.exec(mention);
        const mentionName = mentionParts?.[1];
        const mentionId = mentionParts?.[2];
        if (authUser && authLocal) {
            elements.push(<Link to={`/profile-details?id=${mentionId}`}>{`@${mentionName}`}</Link>);
        } else {
            elements.push(<Fragment>{`@${mentionName}`}</Fragment>);
        }

        // add the text after the mention
        if (end < nextStart) {
            elements.push(text.substring(end, nextStart));
        }
    }

    return elements;
};

const MentionToLinks = ({ text }) => {
    return <div>{convertMentionsToLinks(text)}</div>;
};
export default MentionToLinks;
