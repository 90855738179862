import { Fragment, useContext, useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";
import infoIcon from "../../assets/info-icon.svg";
import Line from "../../assets/connecting-line.svg";
import { Lock } from "lucide-react";
import AuthUserContext from "contexts/session";
import InviteUsers from "admin/components/invite-users/invite-users";
import { Progress } from "../../components/ui/progress";

// import "./ProjectQuickInfo.styles.scss";

const formatNumber = (number) => {
    if (typeof number === "string") {
        number = number.replace(/,/g, ""); // Remove commas
    }
    return new Intl.NumberFormat("en-IN", {
        maximumFractionDigits: 2,
        style: "decimal",
    }).format(number);
};

function numberToWordsIN(number) {
    // if (number === 0) return "zero";
    // const numStr = number.toString();
    // const numLength = numStr.length;
    // const placeValues = ["", "thousand", "lakh", "crore"];
    // let words = "";
    // // Helper function to convert a three-digit number into words
    // // Splitting the number into groups
    // const numGroups = [];
    // let tempNum = number;
    // while (tempNum > 0) {
    //     numGroups.unshift(tempNum % 1000); // Get the last three digits and add to the front
    //     tempNum = Math.floor(tempNum / 1000); // Move to the next group of digits
    // }
    // numGroups.forEach((group, index) => {
    //     if (group > 0) {
    //         const groupWords = threeDigitsToWords(group);
    //         const placeValue = placeValues[numGroups.length - index - 1]; // Determine the place value based on the group's position
    //         words += groupWords + " " + placeValue + " ";
    //     }
    // });
    // return words.trim();
}

const wordify = (num) => {
    const single = ["Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
    const double = ["Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
    const tens = ["", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
    const formatTenth = (digit, prev) => {
        return 0 == digit ? "" : " " + (1 == digit ? double[prev] : tens[digit]);
    };
    const formatOther = (digit, next, denom) => {
        return (0 != digit && 1 != next ? " " + single[digit] : "") + (0 != next || digit > 0 ? " " + denom : "");
    };
    let res = "";
    let index = 0;
    let digit = 0;
    let next = 0;
    let words = [];
    if (((num += ""), isNaN(parseInt(num)))) {
        res = "";
    } else if (parseInt(num) > 0 && num.length <= 10) {
        for (index = num.length - 1; index >= 0; index--)
            switch (((digit = num[index] - 0), (next = index > 0 ? num[index - 1] - 0 : 0), num.length - index - 1)) {
                case 0:
                    words.push(formatOther(digit, next, ""));
                    break;
                case 1:
                    words.push(formatTenth(digit, num[index + 1]));
                    break;
                case 2:
                    words.push(0 != digit ? " " + single[digit] + " Hundred" + (0 != num[index + 1] && 0 != num[index + 2] ? " and" : "") : "");
                    break;
                case 3:
                    words.push(formatOther(digit, next, "Thousand"));
                    break;
                case 4:
                    words.push(formatTenth(digit, num[index + 1]));
                    break;
                case 5:
                    words.push(formatOther(digit, next, "Lakh"));
                    break;
                case 6:
                    words.push(formatTenth(digit, num[index + 1]));
                    break;
                case 7:
                    words.push(formatOther(digit, next, "Crore"));
                    break;
                case 8:
                    words.push(formatTenth(digit, num[index + 1]));
                    break;
                case 9:
                    words.push(0 != digit ? " " + single[digit] + " Hundred" + (0 != num[index + 1] || 0 != num[index + 2] ? " and" : " Crore") : "");
            }
        res = words.reverse().join("");
    } else res = "";
    return res;
};

// console.log(numberToWordsIN(1234)); // "one thousand two hundred thirty four"
// console.log(numberToWordsIN(1234567)); // Expected: "one thousand two hundred thirty four"

const ProjectQuickInfo = ({ quickInfoData, participant_type, loading, openExpressInterestModal, status }) => {
    const { authUser } = useContext(AuthUserContext);
    const participantTypeArr = participant_type?.split(",");
    const formatValueForDisplay = (value) => {
        if (typeof value !== "string") {
            value = String(value);
        }

        const numericValue = parseInt(value.replace(/,/g, ""), 10);
        return isNaN(numericValue) ? "Invalid number" : wordify(numericValue);
    };

    console.log(quickInfoData, participantTypeArr);

    console.log(quickInfoData.expressionsReceived, "helo");

    let receivedAmount = parseInt(quickInfoData.expressionsReceived ? quickInfoData.expressionsReceived.toString().replace(/,/g, "") : "0");
    const totalBudget = parseInt(quickInfoData.estimateBudget ? quickInfoData.estimateBudget.toString().replace(/,/g, "") : "0");
    let isFull = receivedAmount >= totalBudget;

    if (status === "completed") {
        receivedAmount = totalBudget;
        isFull = true;
    }

    const progress = totalBudget > 0 ? Math.round((receivedAmount / totalBudget) * 100) : 0;

    console.log({ status });

    return (
        <div className="rounded-xl  mb-4 p-3 md:border md:border-gray-100 bg-gray-100 md:bg-transparent">
            <p className="font-medium md:block hidden text-lg text-blue-600 mb-3">Quick Information</p>
            {/* <div>
                {quickInfoData &&
                    quickInfoData.map(({ Icon, parameter, title }, index) => (
                        <div key={index} className="div__box" direction="column" container item xs={true}>
                            {Icon}
                            <p className="title-text">{parameter}</p>
                            <p className="content-text">{title}</p>
                        </div>
                    ))}
            </div> */}
            {/* <div className="flex w-full items-center justify-between gap-1">
                <div className="w-1/4 ">
                    <p className="text-sm font-medium text-gray-400">Project Start Date</p>
                    <p>{quickInfoData.startDate}</p>
                </div>
                <div className="w-2/4 px-2">
                    <img src={Line} alt="" />
                </div>
                <div className="w-1/4 ">
                    <p className="text-sm font-medium text-gray-400">Project End Date</p>
                    <p className="">{quickInfoData.endDate}</p>
                </div>
            </div> */}
            <div className="flex md:flex-row flex-col w-full md:white justify-between items-start  md:!items-center   mb-3">
                <div className=" border-gray-300  md:pr-3 md:border-r  w-full md:w-auto">
                    <p className="text-blue-600 md:text-gray-400 text-base md:text-sm md:mb-0 mb-1 font-semibold md:font-normal">
                        Proposed Estimated Budget
                    </p>
                    <p className="text-base md:text-sm font-semibold  ">
                        ₹{" "}
                        {quickInfoData.estimateBudget < 100000
                            ? formatNumber(quickInfoData.estimateBudget) + " (" + quickInfoData.estimateBudget / 1000 + " k)"
                            : quickInfoData.estimateBudget < 10000000
                            ? formatNumber(quickInfoData.estimateBudget) + " (" + quickInfoData.estimateBudget / 100000 + " lakh)"
                            : formatNumber(quickInfoData.estimateBudget) + " (" + (quickInfoData.estimateBudget / 10000000).toFixed(2) + " Cr)"}
                    </p>
                </div>
                {status === "completed" ? (
                    <div className="md:w-1/2 md:pl-2 mt-3 md:!mt-0">
                        <p className="text-base md:text-sm text-blue-600 md:text-gray-400 md:mb-0 mb-1 font-semibold md:font-normal">
                            Expression Received
                        </p>
                        <p className="text-base md:text-sm text-black/70 font-semibold">fulfilled</p>
                    </div>
                ) : (
                    <Fragment>
                        {quickInfoData.expressionsReceived ? (
                            <div className="md:w-1/2 md:pl-2 md:!mt-0 mt-3">
                                <p className="text-base md:text-sm text-blue-600 md:text-gray-400 md:mb-0 mb-1 font-semibold md:font-normal">
                                    Expression Received
                                </p>
                                <p className="text-base md:text-sm font-semibold">
                                    {loading ? "loading..." : <>{loading ? "loading..." : `₹${formatNumber(quickInfoData.expressionsReceived)}`}</>}
                                </p>
                            </div>
                        ) : (
                            <div className="md:w-1/2 md:pl-2 md:!mt-0 mt-3  ">
                                <p className="text-base md:text-sm text-blue-600 md:text-gray-400 md:mb-0 mb-1 font-semibold md:font-normal">
                                    Expression Received
                                </p>
                                <p className="text-base md:text-sm font-semibold">no expression yet</p>
                            </div>
                        )}
                    </Fragment>
                )}
            </div>
            {/* <div className="">
                {participantTypeArr && (
                    <Fragment>
                        <div xs={4} container>
                            <p className="heading">This Project Accepts:</p>
                        </div>
                        <div item spacing={2}>
                            {participantTypeArr.map((item, index) => (
                                <Fragment>
                                    <div item xs={3} container justifyContent={"flex-start"}>
                                        <div key={index} className="item" container item xs={true} justifyContent="space-around">
                                            <p> &#8226; </p>
                                            <p>{item.charAt(0).toUpperCase() + item.slice(1)}</p>
                                        </div>
                                    </div>
                                    <div item xs={1} container alignItems={"center"} justifyContent={"center"}>
                                        <Tooltip
                                            title={
                                                item === "kind"
                                                    ? "refers to lend goods, services, and transactions not involving money or not measured in monetary terms."
                                                    : item === "grant"
                                                    ? "a sum of money given by a govt. or other org. for a particular purpose with no intention of expecting it to pay back."
                                                    : "NA"
                                            }
                                            arrow
                                            className="tooltip-customized"
                                            placement="bottom"
                                        >
                                            <span className="info-icon">
                                                <img src={infoIcon} alt="info" />
                                            </span>
                                        </Tooltip>
                                    </div>
                                </Fragment>
                            ))}
                        </div>
                    </Fragment>
                )}
            </div> */}
            <Fragment>
                <p className="text-xs  mb-2 text-gray-400 font-medium">Expression Progress</p>
                <p className="text-blue-500  mb-2 font-medium text-sm">{progress}% Received</p>
                <Progress value={progress} className="w-full bg-gray-100" />
            </Fragment>
            <div className="flex items-center  w-full mt-3 mb-2">
                <button
                    onClick={() => {
                        if (!isFull) {
                            openExpressInterestModal();
                        }
                    }}
                    className={`w-[70%] rounded-md text-center  py-2 text-white ${
                        isFull ? "bg-gray-300 cursor-not-allowed" : "bg-[#ff720c] hover:bg-[#e66000]"
                    } relative`}
                    disabled={isFull}
                >
                    {isFull && (
                        <Fragment>
                            <div className="absolute inset-0 flex justify-center items-center">
                                <Lock className="text-gray-600 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                            </div>
                            <span className="relative">Express Here</span>
                        </Fragment>
                    )}
                    {!isFull && "Express Here"}
                </button>

                {(authUser?.role === "admin" || authUser?.is_invited_to_all_projects) && (
                    <div className="w-[30%] ml-2 hidden md:flex flex-grow justify-center ">
                        <InviteUsers />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProjectQuickInfo;
