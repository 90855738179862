import { useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import Popper from "@mui/material/Popper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import MainNavbar from "components/MainNavbar";
import Sidebar from "views/Profile/Sidebar";
import "./ProjectPromote.styles.scss";

const ProjectPromote = () => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popper" : undefined;
    return (
        <Grid container justifyContent="flex-start" alignItems="center" className="profile">
            <MainNavbar bgColor="fff"></MainNavbar>
            <Grid container item xs={12} justifyContent="flex-start" alignitems="center">
                <Typography className="title">Profile</Typography>
            </Grid>
            <Grid container item xs={12} justifyContent="flex-start" alignItems="center">
                <Card className="main-profile">
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item xs={2} container justifyContent="flex-start" alignItems="center">
                            <Drawer
                                sx={{
                                    width: 200,
                                    flexShrink: 0,
                                    [`& .MuiDrawer-paper`]: {
                                        width: 200,
                                        marginTop: 23.5,
                                        boxSizing: "border-box",
                                        position: "absolute",
                                    },
                                }}
                                variant="persistent"
                                anchor="left"
                                open={true}
                            >
                                <Sidebar />
                                <img src="https://equippp-assets.s3.ap-south-1.amazonaws.com/images/map.png" alt="map" className="profile_map" />
                            </Drawer>
                        </Grid>
                        <Grid item xs={10} container justifyContent="flex-start" alignItems="center">
                            <Grid container item xs={12} justifyContent="space-between" alignItems="center">
                                <Typography component="h6">Projects</Typography>
                            </Grid>
                            {[1, 2, 3, 4].map((index) => {
                                return (
                                    <Grid
                                        item
                                        xs={12}
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                        className="project-container"
                                        key={index}
                                    >
                                        <Card className="project-info">
                                            <Grid container alignItems="center">
                                                <Grid item xs={12} className="project--desc" container>
                                                    <Grid item xs="auto">
                                                        <img src="https://equippp-assets.s3.ap-south-1.amazonaws.com/images/Avatar.png" alt="avatar" className="avatar--icon" />
                                                    </Grid>
                                                    <Grid item xs={true} container alignItems="center">
                                                        <Grid item xs={12}>
                                                            <Typography className="project-subtitle">
                                                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores voluptates voluptas
                                                                consequatur quibusdam iure?
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography className="project-name">Project Name</Typography>
                                                </Grid>
                                                <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                                                    <Grid item xs={8}>
                                                        <Typography className="project-detail">
                                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium sapiente, fugiat sit
                                                            iste deserunt laborum dolores illo consequuntur modi nisi reiciendis nemo est facere harum
                                                            porro id minus perspiciatis cumque quidem quos!
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={4} className="btn-content" container justifyContent="flex-end" alignItems="center">
                                                        <Button variant="container" className="btn filled">
                                                            View Project
                                                        </Button>
                                                        <Button aria-describedby={id} onClick={handleClick} variant="container" className="btn">
                                                            Share
                                                        </Button>
                                                        <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-end">
                                                            <Box className="popper">
                                                                <Grid container justifyContent="space-between">
                                                                    <Grid item xs={9}>
                                                                        <TextField
                                                                            variant="standard"
                                                                            disabled
                                                                            value="xyz_1gyyfyuitrf78ifu"
                                                                            fullWidth
                                                                            autoComplete="off"
                                                                            className="input-share"
                                                                            InputProps={{ disableUnderline: true }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={3}>
                                                                        <Button className="copy-btn">
                                                                            Copy Link <ContentCopyIcon className="copy-icon" />
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container justifyContent="space-around" className="social-container">
                                                                    <Grid item xs={"auto"} container justifyContent="center" alignItems="center">
                                                                        <Grid item xs={12} container justifyContent="center">
                                                                            <img
                                                                                src="https://equippp-assets.s3.ap-south-1.amazonaws.com/images/WhatsApp.png"
                                                                                alt="WhatsApp"
                                                                                className="social-icon"
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} container justifyContent="center">
                                                                            <Typography className="social-title">WhatsApp</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item xs={"auto"} container justifyContent="center" alignItems="center">
                                                                        <Grid item xs={12} container justifyContent="center">
                                                                            <img
                                                                                src="https://equippp-assets.s3.ap-south-1.amazonaws.com/images/Twitter.png"
                                                                                alt="Twitter"
                                                                                className="social-icon"
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} container justifyContent="center">
                                                                            <Typography className="social-title">Twitter</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item xs={"auto"} container justifyContent="center" alignItems="center">
                                                                        <Grid item xs={12} container justifyContent="center">
                                                                            <img
                                                                                src="https://equippp-assets.s3.ap-south-1.amazonaws.com/images/Facebook.png"
                                                                                alt="Facebook"
                                                                                className="social-icon"
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} container justifyContent="center">
                                                                            <Typography className="social-title">Facebook</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item xs={"auto"} container justifyContent="center" alignItems="center">
                                                                        <Grid item xs={12} container justifyContent="center">
                                                                            <img
                                                                                src="https://equippp-assets.s3.ap-south-1.amazonaws.com/images/Instagram.png"
                                                                                alt="Instagram"
                                                                                className="social-icon"
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} container justifyContent="center">
                                                                            <Typography className="social-title">Instagram</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item xs={"auto"} container justifyContent="center" alignItems="center">
                                                                        <Grid item xs={12} container justifyContent="center">
                                                                            <img
                                                                                src="https://equippp-assets.s3.ap-south-1.amazonaws.com/images/Telegram.png"
                                                                                alt="Telegram"
                                                                                className="social-icon"
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} container justifyContent="center">
                                                                            <Typography className="social-title">Telegram</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        </Popper>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={"auto"} container justifyContent="flex-start" alignItems="center" className="location">
                                                    <Grid item xs={"auto"}>
                                                        <GpsFixedIcon className="location-icon" />
                                                    </Grid>
                                                    <Grid item xs={"auto"}>
                                                        <Typography className="location-title">Location</Typography>
                                                        <Typography className="location-value">Telanglana</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    );
};
export default ProjectPromote;
