import React, { useContext } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { PopupModals } from "components/PopupModals/PopupModals";
import AuthUserContext from "contexts/session";
import { useGetProjectsAggregationQuery, useGetRegionAdminQuery } from "../../types/generated-types.ts";

import ROUTES from "constants/routes";

import "./Home.styles.scss";

const MobileHome = ({ handleCreateProjectClick, toggleAuthModal, isModalOpen, setModal, data, error, navigate, projects }) => {
    const { authUser } = useContext(AuthUserContext);
    const { data: regionAdminData } = useGetRegionAdminQuery({});
    return (
        <div className="home__container ">
            <div className="px-4 py-20 flex flex-col items-center justify-center text-white ">
                <div className="text-center mb-3">
                    <p
                        className="font-lato font-bold text-3xl  leading-relaxed

                        "
                        style={{
                            fontFamily: "Lato",
                        }}
                    >
                        SOCIAL PROJECTS EXECUTION MADE EASY THROUGH <br /> CROSS SECTOR PARTNERSHIPS
                    </p>
                </div>
                <div className="text-center ">
                    <p className="leading-normal text-xl">
                        EquiPPP™ is a collaborative platform that vitalizes crowd participation in Public-Private Projects and connects Corporates,{" "}
                        <br />
                        Citizens, Domain Experts, NGOs and Philanthropists with the Government to Initiate and Participate together in socially
                        relevant projects.
                    </p>
                </div>
                <br />
                <div className="w-full flex items-center justify-center mt-4">
                    {authUser?.role === "admin" && (
                        <button className="text-white bg-[#5344e0] rounded-md px-4 py-2 mr-4 shadow-md" onClick={handleCreateProjectClick}>
                            Create Project
                        </button>
                    )}

                    <button
                        className="text-white bg-[#5344e0] rounded-md px-4 py-2 shadow-md"
                        on
                        onClick={() => {
                            navigate(`/profile-details?id=${regionAdminData?.users[0].id}`);
                        }}
                    >
                        View Projects
                    </button>
                </div>
            </div>

            <Grid container className="panel-top" justifyContent="center">
                <div class="w-full text-white flex flex-col px-4 py-10 text-center bg-[url('https://equippp-assets.s3.ap-south-1.amazonaws.com/images/some-count-that-matter-background.png')] bg-no-repeat bg-cover bg-center ">
                    <p className="font-bold text-3xl mb-2">Some count that matters</p>
                    <p className="font-medium text-lg mb-4">Our achievement in the journey depicted in numbers</p>
                    <div className="flex flex-col justify-center items-center gap-2 p-2 ">
                        <div className="mb-4">
                            <p className="text-lg">{data ? data.projects_aggregate.aggregate.count : "0"}</p>
                            <p className="font-bold ">INITIATIVES</p>
                        </div>
                        <div className="mb-4">
                            <p className="text-lg">{data ? data.distinct_aggregate.aggregate.count : "0"}</p>
                            <p className="font-bold">PARTICIPANTS</p>
                        </div>
                        <div>
                            <p className="text-lg">1</p>
                            <p className="font-bold">GEOGRAPHIES COVERED</p>
                        </div>
                    </div>
                </div>
                <div className="bg-[#f9fafc]">
                    <Grid container direction="column" className="panel-four" justifyContent="center" alignItems="center">
                        <Grid className="inner__section" item container direction="column" xs={9} alignItems="center">
                            <p className="text-3xl font-bold mb-4">ABOUT US</p>
                            <p className="text-center text-lg px-4">
                                EquiPPP™ is a collaborative platform that vitalizes crowd participation in Public-Private Projects and connects
                                Corporates, Citizens, Domain Experts, NGOs and Philanthropists with the Government to Initiate and Participate
                                together in socially relevant projects.
                            </p>
                        </Grid>
                        <div>
                            <div className="bg-white rounded shadow-md mx-4 px-4 py-4 mb-4 flex flex-col ">
                                <p className="font-bold">
                                    <img src="https://equippp-assets.s3.ap-south-1.amazonaws.com/images/feature_icon.svg" alt="" className="my-2" />
                                    STEP: 1 - LOCATE
                                </p>
                                <p className="mt-2">
                                    EquiPPP™ has a database of projects selected across sectors and geographies started by Individuals and
                                    Organizations
                                </p>
                            </div>
                            <div className="bg-white rounded shadow-md mx-4 px-4 py-4 mb-4 flex flex-col ">
                                <p className="font-bold">
                                    <img src="https://equippp-assets.s3.ap-south-1.amazonaws.com/images/feature_icon.svg" alt="" className="my-2" />
                                    STEP: 2 - INITIATE
                                </p>
                                <p className="mt-2">
                                    EquiPPP™ enables Individuals and Organizations to Initiate projects based on innovative solutions for
                                    socio-economic problems
                                </p>
                            </div>
                            <div className="bg-white rounded shadow-md mx-4 px-4 py-4 mb-4 flex flex-col ">
                                <p className="font-bold">
                                    <img src="https://equippp-assets.s3.ap-south-1.amazonaws.com/images/feature_icon.svg" alt="" className="my-2" />
                                    STEP: 3 - MOTIVATE
                                </p>
                                <p className="mt-2">
                                    EquiPPP™ intends to Motivate Individuals and Organizations through its network of projects to collaborate,
                                    Initiate and/or Participate
                                </p>
                            </div>
                            <div className="bg-white rounded shadow-md mx-4 px-4 py-4 mb-4 flex flex-col ">
                                <p className="font-bold">
                                    <img src="https://equippp-assets.s3.ap-south-1.amazonaws.com/images/feature_icon.svg" alt="" className="my-2" />
                                    STEP: 4 - PARTICIPATE
                                </p>
                                <p className="mt-2">
                                    The EquiPPP™ ecosystem through the collaborative work of Individuals and Organizations encourages participation in
                                    projects through expressions of interest on the platform
                                </p>
                            </div>
                        </div>
                    </Grid>
                    <div className="bg-[#f9fafc] flex w-full justify-center items-center pt-10 pb-20">
                        <div className="items-center flex-col flex ">
                            <p className="font-bold mb-6 text-3xl text-center px-3">PROJECTS IN THE EQUIPPP ECOSYSTEM</p>
                            <div className="flex flex-wrap gap-6 justify-center items-center">
                                {projects.map(({ name, img }, index) => (
                                    <div key={index} className="w-4/12 flex items-center flex-col">
                                        <div>
                                            <p className="text-center font-semibold mb-2">{name}</p>
                                        </div>
                                        <div>
                                            <img
                                                src={`https://equippp-assets.s3.ap-south-1.amazonaws.com/images/${img}`}
                                                alt={name}
                                                className="w-20 h-20"
                                            />{" "}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </Grid>
            <PopupModals isModalOpen={isModalOpen} setModal={setModal} />
        </div>
    );
};

export default MobileHome;
