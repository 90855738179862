import { React, useState, useEffect, Fragment } from "react";
import { toast } from "react-toastify";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useUpdateExpressionStatusMutation, useDeleteExpressionMutation } from "types/generated-types.ts";
import "./PendingExpressions.scss";

const PendingExpressions = (props) => {
    const [accessToken, setAccessToken] = useState(false);
    const [tableRows, setTableRows] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        const token = window.localStorage.getItem("authToken");
        setAccessToken(token);
        const rows = props.expressionDetails?.map((el) => {
            return {
                projectName: el.project.title,
                investedType: el.investment_type,
                investedAmount: el.investment_amount,
                investmentMsg: el.expression_message,
                investedBy: el.user.email_id,
                date: el.created_at.substring(0, 10),
                addBtn: (
                    <Grid container className="action__btns">
                        <Grid item xs={6}>
                            <button onClick={() => approveExpression(el.id, el.project_id, el.investment_amount)}>
                                <img
                                    src={`https://equippp-assets.s3.ap-south-1.amazonaws.com/images/success.png`}
                                    alt=""
                                    className="action__icon w-6 h-6"
                                />
                            </button>
                        </Grid>

                        <Grid item xs={6}>
                            <button onClick={() => rejectExpression(el.id)}>
                                <img
                                    src={`https://equippp-assets.s3.ap-south-1.amazonaws.com/images/close.png`}
                                    alt=""
                                    className="action__icon w-6 h-6 ml-2"
                                />
                            </button>
                        </Grid>
                    </Grid>
                ),
            };
        });
        setTableRows(rows);
    }, [props.expressionDetails]);

    const [updateExpressionStatus] = useUpdateExpressionStatusMutation({
        onCompleted: (data) => {
            console.log(data);
            toast.success("Expression Approved Successfully");
            props.action("approve", data.id);
        },
    });
    const [deleteExpression] = useDeleteExpressionMutation({
        onCompleted: (data) => {
            toast.success("Expression Rejected Successfully");
            props.action("reject", data.id);
        },
    });
    const approveExpression = (expressionId) => {
        updateExpressionStatus({
            variables: {
                _set: {
                    is_verified: true,
                },
                id: expressionId,
            },
        });
    };

    const rejectExpression = (expressionId) => {
        deleteExpression({
            variables: {
                id: expressionId,
            },
        });
    };

    return (
        <Grid container className="pending-exp__container">
            {!!accessToken ? (
                <Grid item>
                    <TableContainer>
                        <Table>
                            <TableHead className="table-header-font-style">
                                <TableRow>
                                    <TableCell className="header-title">Project Name</TableCell>
                                    <TableCell>Investment Type</TableCell>
                                    <TableCell>Investment Amount</TableCell>
                                    <TableCell>Expression Message</TableCell>
                                    <TableCell>Invested By</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableRows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell style={{ textAlign: "left" }}>{row.projectName}</TableCell>
                                        <TableCell>{row.investedType}</TableCell>
                                        <TableCell>{row.investedAmount}</TableCell>
                                        <TableCell>{row.investmentMsg}</TableCell>
                                        <TableCell>{row.investedBy}</TableCell>
                                        <TableCell style={{ whiteSpace: "none", width: "8rem" }}>{row.date}</TableCell>
                                        <TableCell className="rm-td__padding">{row.addBtn}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {tableRows && tableRows.length > 0 && (
                        <TablePagination
                            component="div"
                            count={tableRows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            rowsPerPageOptions={[5, 10]}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    )}
                </Grid>
            ) : (
                <>Not logged in</>
            )}
        </Grid>
    );
};

export default PendingExpressions;
