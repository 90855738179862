import React, { useState, useContext, Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CustomModal, {
    RegisterSuccessModalContent,
    LoginSuccessModalContent,
    ForgotPasswordModalContent,
    FeedbackModalContent,
} from "components/CustomModal/CustomModal";
import { RegisterModalContent, LoginModalContent } from "components/CustomModal/Forms";
import AuthUserContext from "contexts/session";
import { useGetCommentQuery } from "./../../../types/generated-types.ts";
import CommentBar from "./components/CommentBar";
import CommentInput from "./components/CommentInput";
import "./ProjectComments.styles.scss";

const ProjectComments = ({ projectId, profileURL, toggle, isAdmin }) => {
    console.log({ projectId });
    const [comment, setComment] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [allComments, setAllComments] = useState([]);
    const [{ commentIndex, openReply }, setOpenReplyState] = useState({ commentIndex: "", openReply: false });
    const { authUser } = useContext(AuthUserContext);
    const authLocal = localStorage.getItem("authUser");
    const [parentComments, setParentComments] = useState([]);

    const navigate = useNavigate();

    const [isModalOpen, setModal] = useState(() => false);
    const toggleAuthModal = (value) => {
        if (value) setModal(value);
        else setModal(false);
    };

    console.log(projectId, "hh");
    const { data: commentData, refetch } = useGetCommentQuery({
        variables: {
            id: projectId,
        },

        skip: !projectId,
    });

    useEffect(() => {
        if (commentData) {
            setParentComments(commentData?.comments.filter((item) => !item.parent_comment_id));
            if (searchValue === "") {
                setAllComments(commentData.comments);
            } else {
                const filteredComments = commentData.comments.filter((item) => item.user?.name.includes(searchValue));
                setAllComments(filteredComments);
            }
        }
    }, [commentData, searchValue]);

    return (
        <div className="">
            {authUser?.role !== "admin" && (
                <Fragment>
                    {authUser && authLocal ? (
                        <CommentInput isReply={false} toggle={toggle} projectId={projectId} profilePicture={profileURL} />
                    ) : (
                        <Grid item xs={12} alignItems="center">
                            <Button variant="contained" className="btn" onClick={() => navigate("/login")}>
                                Login to view/Add Comments
                            </Button>
                        </Grid>
                    )}
                </Fragment>
            )}

            <div className={authUser && authLocal ? "comment-container" : "comment-container blur_bg"}>
                {/* <Grid item xs={12} container alignItems="center" className="comment-input__container">
                    <Grid item xs={10}>
                        <TextField
                            type="text"
                            variant="standard"
                            name="search"
                            value={searchValue}
                            disabled={authUser && authLocal ? false : true}
                            onChange={(e) => {
                                setSearchValue(e.target.value);
                            }}
                            placeholder="Search comments of a user"
                            InputProps={{ disableUnderline: true }}
                            className="comment-input"
                        />
                    </Grid>
                </Grid> */}
                {authUser?.role == "admin" ? (
                    <div className={`  ${authUser && authLocal ? "w-full " : "w-full blur-bg"}`}>
                        {commentData &&
                            parentComments.map((comment, index) =>
                                comment.is_verified ? (
                                    <CommentBar
                                        refetch={refetch}
                                        parentComment={comment}
                                        commentsData={commentData}
                                        projectId={projectId}
                                        toggleAuthModal={toggleAuthModal}
                                    />
                                ) : (
                                    <Fragment></Fragment>
                                )
                            )}

                        {commentData && commentData.comments && commentData.comments.length <= 0 && (
                            <p className="no-comments text-center my-4 text-gray-500">No Comments Yet</p>
                        )}
                    </div>
                ) : (
                    <div className={` h-96 overflow-scroll no-scrollbar ${authUser && authLocal ? "w-full " : "w-full blur-bg"}`}>
                        {commentData &&
                            parentComments.map(
                                (comment, index) =>
                                    comment.is_verified && (
                                        <CommentBar
                                            key={index}
                                            parentComment={comment}
                                            commentsData={commentData}
                                            projectId={projectId}
                                            toggleAuthModal={toggleAuthModal}
                                        />
                                    )
                            )}

                        {commentData && commentData.comments && commentData.comments.length <= 0 && (
                            <Typography className="no-comments text-center my-4 text-gray-500">No Comments Yet</Typography>
                        )}
                    </div>
                )}

                {/* <div className="flex justify-center items-center mt-2">
                    <div className="h-[1px] w-1/4 bg-gray-200"></div>
                    <div className="border rounded-full text-xs py-[2px] text-blue-600 px-3">New message</div>
                    <div className="h-[1px] w-1/4 bg-gray-200"></div>
                </div> */}
                {/* login modal */}
                <CustomModal isOpen={isModalOpen === "login"} isNotStyleDefault={true} header="" setOpen={setModal}>
                    <LoginModalContent setOpen={setModal} isAdmin={isAdmin} />
                </CustomModal>

                {/* register modal */}
                <CustomModal isOpen={isModalOpen === "register"} isNotStyleDefault={true} header={""} setOpen={setModal}>
                    <RegisterModalContent setOpen={setModal} />
                </CustomModal>

                {/* register success modal */}
                <CustomModal isOpen={isModalOpen === "register-success"} header="&nbsp;" setOpen={setModal}>
                    <RegisterSuccessModalContent setOpen={setModal} />
                </CustomModal>

                {/* login success modal */}
                <CustomModal isOpen={isModalOpen === "login-success"} header="&nbsp;SUCCESS" setOpen={setModal}>
                    <LoginSuccessModalContent setOpen={setModal} />
                </CustomModal>

                {/* feedback modal */}
                <CustomModal isOpen={isModalOpen === "feedback"} header="&nbsp;GIVE YOUR FEEDBACK HERE" setOpen={setModal}>
                    <FeedbackModalContent setOpen={setModal} />
                </CustomModal>

                {/* forgot password modal */}
                <CustomModal isOpen={isModalOpen === "forgot"} header="FORGOT PASSWORD?" setOpen={setModal}>
                    <ForgotPasswordModalContent setOpen={setModal} toggle={(val) => toggle(val)} />
                </CustomModal>
            </div>
        </div>
    );
};

export default ProjectComments;
