import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import ProjectReport from "./ProjectReport";

const ProjectPrintReport = () => {
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const componentRef = useRef();
    const { projectId } = useParams();

    return (
        <Grid container justifyContent="center">
            <ProjectReport ref={componentRef} forwardRef={true} projectId={projectId} />
            <Button variant="contained" className="print-btn" onClick={handlePrint}>
                Print this out!
            </Button>
        </Grid>
    );
};

export default ProjectPrintReport;
