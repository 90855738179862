import { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import CustomFooter from "components/CustomFooter/CustomFooter";
import FaqsContent from "components/FaqsContent/FaqsContent";
import "./Faqs.styles.scss";

const Faqs = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <section className="Faqs">
            <Grid container direction="column" justifyContent="center" alignItems="center" className="faqs_header">
                <Typography>Home &gt; &gt; FAQS</Typography>
                <Typography variant="h1">Frequently Asked Questions</Typography>
                <Typography>Social value Partners are Companies / Organizations / People who are supporting the Social Projects</Typography>
            </Grid>
            <FaqsContent />
        </section>
    );
};

export default Faqs;
