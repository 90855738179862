import {
    Menu,
    LayoutDashboard,
    Presentation,
    Newspaper,
    Tv2,
    Handshake,
    MessageCircleQuestion,
    MessagesSquare,
    AlertCircle,
    Home,
    Settings,
} from "lucide-react";
import { Sheet, SheetContent, SheetHeader, SheetTrigger } from "../ui/sheet";
import { NavLink } from "react-router-dom";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../../components/ui/accordion";
import React, { useEffect, useState, Fragment, useContext } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import Avatar from "@mui/material/Avatar";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Popper from "@mui/material/Popper";
import { useGetCurrentUserLazyQuery } from "types/generated-types.ts";
import { PopupModals } from "components/PopupModals/PopupModals";
import ROUTES from "constants/routes";
import AuthUserContext from "../../contexts/session";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { useGetRegionAdminQuery } from "../../types/generated-types.ts";

const MobileNavbar = (props) => {
    const { authUser } = useContext(AuthUserContext);
    const userName = authUser && authUser.name;
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const location = useLocation();
    const [getUsers, { data: userData, loading: isLoading, error: dataEr }] = useGetCurrentUserLazyQuery();
    useEffect(() => {
        if (authUser && authUser.id) {
            getUsers({
                variables: {
                    id: authUser.id,
                },
            });
        }
    }, [authUser, getUsers]);
    const isHome = location.pathname === ROUTES.HOME;

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popper" : undefined;
    const [isScrolled, setScrolled] = useState(() => false);

    useEffect(() => {
        /**
         * Function which returns false if the page is scrolled to top.
         * Returns false if the page is scrolled down.
         * @param {object} e Event object to catch scroll position of the document
         */
        const onScroll = (e) => {
            setScrolled(!!e.target.documentElement.scrollTop);
        };
        window.addEventListener("scroll", onScroll);

        // remove the scroll event listener if the page is destroyed
        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    const [isModalOpen, setModal] = useState(() => false);
    /**
     * Watch for change in state of Authentication modal.
     * The value of `isModalOpen` changes to desired modal name whenever the modal changes its state.
     * The modal is hidden if no parameter value is given.
     * @param {string} value The name of the modal to be triggered
     * @author DK
     */
    const toggleAuthModal = (value) => {
        if (value) setModal(value);
        else setModal(false);
    };
    function capitalizeFirstLetter(string) {
        return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
    }

    /**
     * Check if user is logged in.
     * If so, set isLoggedIn state to `true`, else `false`.
     */
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const { data: regionAdminData } = useGetRegionAdminQuery({});

    useEffect(() => {
        const adminAccessToken = window.localStorage.getItem("admin_access_token");
        const authToken = window.localStorage.getItem("authToken");
        setIsLoggedIn(!!adminAccessToken || !!authToken);
        if (typeof props.loginStatus === "function") props.loginStatus(!!adminAccessToken || !!authToken);
    }, []);

    const logout = () => {
        const authToken = window.localStorage.getItem("authToken");
        if (!authToken) return setIsLoggedIn(false);

        localStorage.clear();
        navigate("/");
        setIsLoggedIn(false);
    };

    const getPageName = (pathname) => {
        const routeNameMap = {
            "/": "Home",
            "/projects": "Projects",
            "/feed": "Feed",
            "/media": "Media",
            "/socialpartners": "Social Partners",
            "/feedback": "Feedback",
            "/faqs": "FAQs",
            "/about": "About",
            "/profile-details": "Profile",
        };
        return routeNameMap[pathname] || "";
    };
    const currentPageName = getPageName(location.pathname);

    const handleMyActivityClick = () => {
        navigate(`/my-activity?id=${authUser?.id}`);
    };

    const userTypeName = userData?.users_by_pk?.user_type_details ? JSON.parse(userData?.users_by_pk?.user_type_details) : {};
    const name = userTypeName ? userTypeName.name : "No name";

    const handleNavigate = () => {
        if (userData?.users_by_pk?.role === "admin") {
            navigate(`/profile-details?id=${authUser.id}`);
        } else {
            navigate(`/profile-details?id=${regionAdminData?.users[0].id}`);
        }
    };

    return (
        <div className="flex flex-row w-full px-2 pt-6 pb-4  justify-between items-center bg-[#6859f9]">
            <div className="flex justify-start items-center">
                <Sheet>
                    <SheetTrigger>
                        <Menu className="text-white w-6 h-6" />
                    </SheetTrigger>
                    <SheetContent side={"left"} className="w-[45%] p-2 h-full bg-slate-100">
                        <SheetHeader className="h-full">
                            <div className="flex flex-col items-start w-full mt-20">
                                <div onClick={handleNavigate} className={`mb-4 ${location.pathname.includes("/") ? "active" : "none"}`}>
                                    <div className="flex items-center">
                                        <Home className="mr-2 text-[#6859f9] w-6 h-6" />
                                        <p className="nav-item">Home</p>
                                    </div>
                                </div>
                                {authUser?.role === "admin" && (
                                    <React.Fragment>
                                        <div className={`mb-4  ${location.pathname.includes("/dashboard") ? "active" : "none"}`}>
                                            <NavLink to="/admin">
                                                <div className="flex items-center">
                                                    <LayoutDashboard className="mr-2 text-[#6859f9] w-6 h-6" />
                                                    <p className="nav-item">Dashboard</p>
                                                </div>
                                            </NavLink>
                                        </div>
                                        <div className="mb-4">
                                            <NavLink to={ROUTES.ADMIN_ANALYTICS}>
                                                <div className="flex items-center">
                                                    <LayoutDashboard className="mr-2 text-[#6859f9] w-6 h-6" />
                                                    <p className="nav-item">Analytics</p>
                                                </div>
                                            </NavLink>
                                        </div>
                                        <Accordion type="single" collapsible className="w-full  ">
                                            <AccordionItem value="item-1">
                                                <AccordionTrigger className="p-0 flex w-full pb-3 pt-3 justify-between items-center">
                                                    <p className="">Projects</p>
                                                </AccordionTrigger>
                                                <AccordionContent>
                                                    <div className="flex items-start justify-start w-full flex-col ">
                                                        <Link to={ROUTES.ADMIN_CREATE_PROJECT} className=" mb-3 text-gray-500">
                                                            Add Project
                                                        </Link>
                                                        <Link to={ROUTES.ADMIN_EXPRESSIONS} className=" mb-3 text-gray-500">
                                                            Expressions
                                                        </Link>
                                                        <Link to={ROUTES.ADMIN_PROJECT_COMMENTS} className=" mb-3 text-gray-500">
                                                            Project Comments
                                                        </Link>
                                                        <Link to={ROUTES.ADMIN_ALL_PROJECTS} className=" mb-2 text-gray-500">
                                                            All Projects
                                                        </Link>
                                                    </div>
                                                </AccordionContent>
                                            </AccordionItem>
                                        </Accordion>

                                        <Accordion type="single" collapsible className="w-full">
                                            <AccordionItem value="item-2">
                                                <AccordionTrigger className="p-0 pb-3 pt-3 flex w-full justify-between items-center">
                                                    <p className="">User</p>
                                                </AccordionTrigger>
                                                <AccordionContent>
                                                    <div className="flex flex-col items-start justify-start ">
                                                        <Link to={ROUTES.ADMIN_CREATE_USER} className=" mb-2 text-gray-500">
                                                            Create User
                                                        </Link>

                                                        <Link to={ROUTES.ADMIN_CREATE_ADMIN_USER} className=" mb-2 text-gray-500">
                                                            Create Admin User
                                                        </Link>

                                                        <Link to={ROUTES.ADMIN_CHANGE_USER_ROLE} className=" mb-2 text-gray-500">
                                                            Change User's Role
                                                        </Link>
                                                    </div>
                                                </AccordionContent>
                                            </AccordionItem>
                                        </Accordion>
                                        <div className={`mb-4 mt-4 ${location.pathname.includes("/projects") ? "active" : "none"}`}>
                                            <NavLink to="/projects">
                                                <div className="flex items-center">
                                                    <Presentation className="mr-2 text-[#6859f9] w-6 h-6" />
                                                    <p className="nav-item">View Projects</p>
                                                </div>
                                            </NavLink>
                                        </div>
                                        {/* <div className={`mb-4 ${location.pathname.includes("/feed") ? "active" : "none"}`}>
                                            <NavLink to="/feed">
                                                <div className="flex items-center">
                                                    <Newspaper className="mr-2 text-[#6859f9] w-6 h-6" />
                                                    <p className="nav-item">Feed</p>
                                                </div>
                                            </NavLink>
                                        </div> */}
                                    </React.Fragment>
                                )}

                                {/* <div className={`mb-4 ${location.pathname.includes("/media") ? "active" : "none"}`}>
                                    <NavLink to="#">
                                        <div className="flex items-center ">
                                            <Tv2 className="mr-2 text-[#6859f9] w-6 h-6" />
                                            <p className="nav-item">Media</p>
                                        </div>
                                    </NavLink>
                                </div> */}

                                {/* <div className={`mb-4 ${location.pathname.includes("/socialpartners") ? "active" : "none"}`}>
                                    <NavLink to="#">
                                        <div className="flex items-center">
                                            <Handshake className="mr-2 text-[#6859f9] w-6 h-6" />
                                            <p className="nav-item">Social Partners</p>
                                        </div>
                                    </NavLink>
                                </div> */}
                                {/* <div className={`mb-4 ${location.pathname.includes("/feedback") ? "active" : "none"}`}>
                                    <NavLink to="#">
                                        <div className="flex items-center">
                                            <MessagesSquare className="mr-2 text-[#6859f9] w-6 h-6" />
                                            <p className="nav-item">Feedback</p>
                                        </div>
                                    </NavLink>
                                </div> */}
                                {/* <div className={`mb-4 ${location.pathname.includes("/faqs") ? "active" : "none"}`}>
                                    <NavLink to="/faqs">
                                        <div className="flex items-center">
                                            <MessageCircleQuestion className="mr-2 text-[#6859f9] w-6 h-6" />
                                            <p className="nav-item">FAQs</p>
                                        </div>
                                    </NavLink>
                                </div> */}
                                {/* <div className={`mb-4 ${location.pathname.includes("/about") ? "active" : "none"}`}>
                                    <Link to="/#about">
                                        <div className="flex items-center">
                                            <AlertCircle className="mr-2 text-[#6859f9] w-6 h-6" />
                                            <p className="nav-item">About</p>
                                        </div>
                                    </Link>
                                </div> */}
                            </div>
                            <div
                                className={`mb-4 ${location.pathname.includes("/about") ? "active" : "none"} flex h-full flex-col justify-end`}
                                onClick={logout}
                            >
                                <div className="flex items-center">
                                    <LogoutIcon className="w-6 h-6 mr-2 text-[#6859f9]" />
                                    <p className=" active:text-[#6859f9] hover:text-[#6859f9]">Logout</p>
                                </div>
                            </div>
                        </SheetHeader>
                    </SheetContent>
                </Sheet>
                {/* <NavLink to="/">
                    <img src="https://equippp-assets.s3.ap-south-1.amazonaws.com/images/equippp-logo.png" alt="Equipp" className="w-12 h-12 ml-4" />
                </NavLink> */}
            </div>

            <div className="flex justify-end items-center">
                {isLoggedIn ? (
                    <Fragment>
                        {authUser?.role !== "admin" && (
                            <p className="text-sm text-white/90 font-semibold md:block hidden mr-4 cursor-pointer" onClick={handleMyActivityClick}>
                                My Activity
                            </p>
                        )}
                        <div className="flex items-center">
                            <p className="text-white font-medium mr-2" onClick={handleNavigate}>
                                {capitalizeFirstLetter(userData?.users_by_pk?.user_name || userData?.users_by_pk?.name || userName || name)}
                            </p>
                            <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                                <div className="flex items-center">
                                    <Popover>
                                        <PopoverTrigger className="flex items-center">
                                            <Avatar
                                                variant="rounded"
                                                src={userData?.users_by_pk?.profile_picture}
                                                onClick={handleClick}
                                                alt="Profile Picture"
                                            />
                                            <KeyboardArrowDown className="text-white" onClick={handleClick} />
                                        </PopoverTrigger>
                                        <PopoverContent>
                                            <div className="popper-item" onClick={() => navigate("/profile")}>
                                                <p className="text-sm">Edit Profile</p>
                                            </div>
                                            {userData?.users_by_pk?.role !== "admin" && (
                                                <div onClick={() => navigate(`/profile-details?id=${regionAdminData?.users[0].id}`)}>
                                                    <p className="text-sm mt-2">View Admins Profile</p>
                                                </div>
                                            )}
                                            {/* {userData?.users_by_pk?.role === "admin" && (
                                                <div
                                                    item
                                                    xs={12}
                                                    container
                                                    alignItems="center"
                                                    className="popper-item"
                                                    onClick={() => navigate(`/profile-details?id=${authUser.id}`)}
                                                >
                                                    <PersonIcon className="popper-icon" />
                                                    <p>My Profile</p>
                                                </div>
                                            )} */}
                                            <div className="popper-item" onClick={logout}>
                                                <p className="text-sm mt-2">Logout</p>
                                            </div>
                                        </PopoverContent>
                                    </Popover>
                                </div>
                            </ClickAwayListener>
                        </div>
                    </Fragment>
                ) : (
                    <div container onClick={() => navigate("/login")} style={{ cursor: "pointer" }}>
                        <PersonIcon className="nav-icon" />
                        <p fontSize={"15px"} className="text-black">
                            Login/Signup
                        </p>
                    </div>
                )}
            </div>
            <PopupModals isModalOpen={isModalOpen} setModal={setModal} isAdmin={props.isAdmin} toggle={props.toggle} />
        </div>
    );
};

export default MobileNavbar;
