import { React, useEffect, useState } from "react";
import { TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import "bootstrap/dist/css/bootstrap.min.css";
import { useGetCommentsQuery } from "types/generated-types.ts";
import ApprovedComments from "../ApprovedComments/ApprovedComments";
import PendingComments from "../PendingComments/PendingComments";
import "./ProjectComments.scss";

const ProjectComments = () => {
    const [tab, setTab] = useState("pending");
    const [accessToken, setAccessToken] = useState(false);
    const [tableRows, setTableRows] = useState([]);
    const [projectComments, setprojectComments] = useState(() => []);
    const [pendingProjectComments, setPendingProjectComments] = useState(() => []);
    const [approvedProjectComments, setApprovedProjectComments] = useState(() => []);
    const [searchValue, setSearchValue] = useState("");
    const currentab = (e) => {
        setTab(e);
    };
    const { data: commentsData } = useGetCommentsQuery({});

    useEffect(() => {
        if (commentsData && commentsData.comments) {
            let approvedComments = [],
                pendingComments = [];
            commentsData?.comments?.forEach((comment) => {
                comment.is_verified === true ? approvedComments.push(comment) : pendingComments.push(comment);
            });
            setApprovedProjectComments(approvedComments);
            setPendingProjectComments(pendingComments);
        }
    }, [commentsData]);

    useEffect(() => {
        if (searchValue === "") {
            if (commentsData && commentsData.comments) {
                let approvedComments = [],
                    pendingComments = [];
                commentsData?.comments?.forEach((comment) => {
                    comment.is_verified === true ? approvedComments.push(comment) : pendingComments.push(comment);
                });
                setApprovedProjectComments(approvedComments);
                setPendingProjectComments(pendingComments);
                return;
            }
        }
        if (tab === "pending") {
            const filteredComments = commentsData?.comments.filter(
                (item) => item.project.title.toLowerCase().includes(searchValue) && item.is_verified === false
            );
            setPendingProjectComments(filteredComments);
        } else {
            const filteredComments = commentsData?.comments.filter(
                (item) => item.project.title.toLowerCase().includes(searchValue) && item.is_verified === true
            );
            setApprovedProjectComments(filteredComments);
        }
        console.log(searchValue);
    }, [searchValue]);

    const performedAction = (action, id) => {
        setprojectComments((oldValue) => {
            return oldValue.filter((exp) => {
                return exp.id !== id;
            });
        });

        setPendingProjectComments((oldValue) => {
            return oldValue.filter((exp) => {
                return exp.id !== id;
            });
        });

        if (action === "approve") {
            setApprovedProjectComments((oldValue) => {
                return oldValue.filter((exp) => {
                    return exp.id !== id;
                });
            });
        }
    };

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
        setSearchValue("");
    };

    return (
        <div className="project-comments__container tabs">
            <Typography className="title">Project Comments</Typography>
            <br />
            <Tabs scrollButtons={false} value={tab} className="status__tabs tabs__container" onChange={handleTabChange}>
                <Tab style={{ textTransform: "capitalize" }} disableRipple className="single__tab" label={"Approved"} value="approved" wrapped />
                <Tab style={{ textTransform: "capitalize" }} disableRipple className="single__tab" label={"Pending"} value="pending" />
            </Tabs>
            <TextField
                sx={{ my: 2, width: "50%" }}
                placeholder="Search by project name"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
            />
            <br />
            <div className="expTable">
                {tab === "pending" ? (
                    <PendingComments commentDetails={pendingProjectComments} action={performedAction} />
                ) : tab === "approved" ? (
                    <ApprovedComments commentDetails={approvedProjectComments} action={performedAction} />
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

export default ProjectComments;
