import React, { useContext, Fragment } from "react";
import { useGetUserQuery, useGetUsersWhoJoinedWithoutInviteQuery } from "../../../../types/generated-types.ts";
import ConnectorIcon from "../../../../assets/connector.png";
import AuthUserContext from "contexts/session";

const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options).split("/").reverse().join(".");
};

const FlowPopup = ({ userId, isAdmin }) => {
    const [nestedUserId, setNestedUserId] = React.useState(null);

    const [selectedIndex, setSelectedIndex] = React.useState(null);

    const { authUser } = useContext(AuthUserContext);

    const { data } = useGetUserQuery({
        variables: {
            user_id: userId,
        },
    });

    const { data: usersWhoJoinedWithoutInvite } = useGetUsersWhoJoinedWithoutInviteQuery({
        skip: !isAdmin,
    });

    const allData = [
        ...(data?.users_by_pk.invite_links ?? []).map((link) => link.invited_user),
        ...(usersWhoJoinedWithoutInvite?.users ?? []).map((user) => ({ ...user, did_not_join_through_invite: true })),
    ].filter((user) => user && authUser && user.id !== authUser.id);

    const hasNoInvitedUsers = allData.length === 0;

    return (
        <Fragment>
            <div className="bg-white rounded-md text-sm basis-6/12 border shadow-md p-4">
                <p className="text-sm font-bold mb-2">Invited users</p>
                <div>
                    <div className="flex px-2 py-3 border-b text-gray-500 justify-evenly gap-8">
                        <div className="w-28">Name</div>
                        <div className="w-28 text-center">Date</div>
                        {/* <div className="basis-1/5 text-center">Login</div> */}
                        <div className="basis-1/5">Expressed</div>
                        <div className="basis-1/5">Commented</div>
                    </div>
                    {hasNoInvitedUsers ? (
                        <div className="flex justify-center items-center h-24">No invited users found</div>
                    ) : (
                        <div className="flex flex-col items-center justify-evenly w-full ">
                            {allData?.map((link, index) => {
                                const invitedUserName = JSON.parse(link?.user_type_details ? link?.user_type_details : "{}");
                                return link ? (
                                    <Fragment key={index}>
                                        <div
                                            className={`flex py-3 cursor-pointer justify-evenly items-center border-b w-full relative gap-8 
                                                ${link.did_not_join_through_invite ? "bg-yellow-100" : ""}
                                            `}
                                            onClick={() => {
                                                setNestedUserId(link.id);
                                                setSelectedIndex(index);
                                            }}
                                        >
                                            <div className="w-28 font-medium">{link?.user_name ?? "No name found"}</div>
                                            <div className="w-fit">{formatDate(link.created_at)}</div>
                                            {/* <div className="basis-1/5 text-center">{link.invited_user.last_login}</div> */}
                                            <div className={` text-center`}>
                                                {link?.expressions_aggregate?.aggregate?.count > 0 ? (
                                                    <span className="text-blue-500">Yes</span>
                                                ) : (
                                                    <span className="text-red-400">No</span>
                                                )}
                                            </div>
                                            <div className={`basis-1/5 text-center`}>
                                                {link?.comments_aggregate?.aggregate?.count > 0 ? (
                                                    <span className="text-blue-500">Yes</span>
                                                ) : (
                                                    <span className="text-red-400">No</span>
                                                )}
                                            </div>
                                            {selectedIndex === index && (
                                                <Fragment>
                                                    <div className="absolute -right-[calc(24px+40px)] translate-x-1/2">
                                                        <img src={ConnectorIcon} className="w-16" alt="" />
                                                    </div>
                                                    <div className="absolute top-0 -right-[calc(24px)] translate-x-[calc(100%+80px)] -translate-y-[105px]">
                                                        {nestedUserId && <FlowPopup userId={nestedUserId} isAdmin={link.role === "admin"} />}
                                                    </div>
                                                </Fragment>
                                            )}
                                        </div>
                                    </Fragment>
                                ) : null;
                            })}
                        </div>
                    )}
                </div>
            </div>
        </Fragment>
    );
};

export default FlowPopup;
