const ROUTES = {
    // BASIC ROUTES
    HOME: "/",
    PROFILE: "/profile",
    PROFILE_DETAILS: "/profile-details",
    MY_ACTIVITY: "/my-activity",
    PRESS_RELEASE: "/press-release",
    RESET_VERIFY: "/reset-verify",
    SUGGEST_PROJECT: "/suggest-project",
    CONFIRM_EMAIL: "/confirm-email",
    RESET_PASSWORD: "/reset-password",
    CREATE_PROJECT: "/create-project",
    ABOUT_MANTHANI: "/about-duddila-sridhar-babu",
    ABOUT_ZAHEERABAD: "/about-bheemarao-basawanthrao-patil",
    ABOUT_PEMMASANI: "/about-chandra-sekhar-pemmasani",
    PROJECTS: "/projects",
    INVITE_PAGE: "/invite-users/:inviteId",
    PROJECT_DETAILS: "/projects/:projectId",
    DISTRICT: "/suggest-project/:state/:district",
    PROJECT_REPORT: "/projects/project-report/:projectId",
    PROJECT_LINKED: "/project-linked",
    PROJECT_COMMENTED: "/project-comment",
    PROJECT_PROMOTED: "/project-promote",
    OTP_RESET_PASSWORD: "/otp-reset-password",
    FEED: "/feed",
    MOBILE_LOGIN: "/login",
    MOBILE_SIGNUP: "/signup",
    SPLASH_SCREEN: "/onboarding",
    FORGOT_PASSWORD: "/forgot-password",
    CHECK_EMAIL: "/check-email",
    VERIFY_EMAIL: "/verify-email",
    VERIFY_OTP: "/verify-otp",
    SELECT_USER_TYPE: "/signup/select-user-type",
    CSR_FOUNDATION: "/signup/csr-foundation",
    INDIVIDUAL: "/signup/user-details",
    NON_PROFIT_ORGANIZATION: "/signup/non-profit-organization",
    SUBJECT_MATTER_EXPERT: "/signup/subject-matter-expert",
    PRO_BONO: "/signup/pro-bono",
    PUBLIC_SECTOR_BANK: "/signup/public-sector-bank",
    MEMBER_OF_PARLIAMENT: "/signup/member-of-parliament",
    MEMBER_OF_LEGISLATIVE_ASSEMBLY: "/signup/member-of-legislative-assembly",
    STUDENTS: "/signup/students",
    OTHERS: "/signup/others",

    // ADMIN ROUTES
    ADMIN: "/admin",
    ADMIN_BACKEND: "/admin/backend",
    ADMIN_DASHBOARD: "admin-dashboard",
    ADMIN_ANALYTICS: "/admin/backend/analytics",
    ADMIN_CREATE_PROJECT: "/admin/backend/create-project",
    ADMIN_EDIT_PROJECT: "/admin/edit-project/:projectId",
    ADMIN_EXPRESSIONS: "/admin/backend/expressions",
    ADMIN_PROJECT_COMMENTS: "/admin/backend/project-comments",
    ADMIN_ALL_PROJECTS: "/admin/backend/all-projects",
    ADMIN_CREATE_USER: "/admin/backend/create-user",
    ADMIN_CREATE_ADMIN_USER: "/admin/backend/create-admin-user",
    ADMIN_CHANGE_USER_ROLE: "/admin/backend/change-user-role",
    ADMIN_USERS_KYC: "users-kyc",
    ADMIN_PROJECT_DETAILS: "/admin/projects/:projectId",
    ADMIN_RESET_VERIFY: "/admin/reset-verify",
    ADMIN_INVITE_USERS: "/invite-users",

    //STATIC
    SOCIAL_PARTNER: "/social-partner",
    CONTACT_US: "/contact-us",
    MEDIA_VIDEOS: "/media/videos",
    MEDIA_IMAGES: "/media/images",
    FAQS: "/faqs",
    TERMS_OF_USE: "/terms-of-use",
    PRIVACY_POLICY: "/privacy-policy",
};

export default ROUTES;
