import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import "./CountCard.styles.scss";

const ICONS = {
    promoted: <RocketLaunchOutlinedIcon />,
    commented: <img src="https://equippp-assets.s3.ap-south-1.amazonaws.com/images/comment-icon.png" alt="" className="comment-icon" />,
    initiated: <img src="https://equippp-assets.s3.ap-south-1.amazonaws.com/images/initaite-icon.png" className="initiate-icon" alt="" />,
    expressed: <img src="https://equippp-assets.s3.ap-south-1.amazonaws.com/images/express-icon.png" alt="" className="express-icon" />,
};

const LABELS = {
    promoted: "Promoted",
    commented: "Commented",
    initiated: "Initiated",
    expressed: "Expressed",
};

const CountCard = ({ variant, isSelected, handleClick, count, isPrivate }) => {
    return (
        <Grid
            item
            xs={true}
            className={isSelected ? "profile-details__count-card__container--selected" : "profile-details__count-card__container"}
            container
            onClick={handleClick}
        >
            <div className="profile-details__count-card__circle" />
            <Grid item xs={12}>
                {variant === "commented" && (
                    <img
                        src={`https://equippp-assets.s3.ap-south-1.amazonaws.com/images/comment-icon${isSelected ? "__active" : ""}.png`}
                        alt=""
                        className="comment-icon"
                    />
                )}
                {variant === "expressed" && (
                    <img
                        src={`https://equippp-assets.s3.ap-south-1.amazonaws.com/images/express-icon${isSelected ? "__active" : ""}.png`}
                        alt=""
                        className="comment-icon"
                    />
                )}

                {variant !== "commented" && variant !== "expressed" && ICONS[variant]}
            </Grid>
            <Grid item xs={12}>
                <Typography className="project-details__count-card__count">{isPrivate ? "" : count}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className="project__title">Projects {LABELS[variant]}</Typography>
            </Grid>
        </Grid>
    );
};

export default CountCard;

CountCard.propTypes = {
    variant: PropTypes.oneOf(["promoted", "commented", "initiated"]),
    isSelected: PropTypes.bool,
    count: PropTypes.number,
    handleClick: PropTypes.func,
};
