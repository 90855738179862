import { Fragment, useEffect, useState, useMemo, memo, useRef } from "react";
import { useContext } from "react";
import MenuIcon from "../../assets/Ellipsis.svg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { RWebShare } from "react-web-share";
import { AreaChart } from "@tremor/react";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { ArrowLeft } from "lucide-react";
import { styled } from "@mui/material/styles";
import pdfIcon from "../../assets/Export Pdf.png";
import PropTypes from "prop-types";
import CustomModal, { ExpressInterestModalContent } from "components/CustomModal/CustomModal";
import ProjectQuickInfo from "components/ProjectQuickInfo/ProjectQuickInfo";
import AuthUserContext from "contexts/session";
import { useGetProjectQuery, useGetCommentQuery, useUpdateProjectBackgroundMutation } from "./../../types/generated-types.ts";
import { useGetProjectAnalyticsQuery, useGetProjectAnalyticsChartQuery, useGetTotalInvitesQuery } from "types/generated-types.ts";
import ProjectComments from "./ProjectComments";
import "./Project.styles.scss";
import MobileViewProject from "./MobileViewProject";
import { Map } from "views/Projects";
import EditIcon from "../../assets/Edit.png";
import moment from "moment";
import { useGetUploadUrlMutation, useGetRegionAdminQuery } from "types/generated-types.ts";
import axios from "axios";
import Chart from "chart.js/auto";
import { Pencil } from "lucide-react";
import { CircularProgress } from "@mui/material";
import { Images } from "lucide-react";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "../../components/ui/dialog";
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "../../components/ui/carousel";
import { ChevronRight } from "lucide-react";
import { ChevronLeft } from "lucide-react";
import { replace } from "formik";
import { GalleryHorizontal } from "lucide-react";
import { Image } from "lucide-react";
import { Popover, PopoverContent, PopoverTrigger } from "../../components/ui/popover";
import { Menu } from "lucide-react";
import { EllipsisVertical } from "lucide-react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../components/ui/tabs";
import { Share2 } from "lucide-react";
import { create } from "zustand";
import InviteUsers from "admin/components/invite-users/invite-users";
import { useGetCurrentUserLazyQuery, useGetProjectsQuery, useInviteLinkMutation } from "types/generated-types.ts";
import { BlockCopyCodeButton } from "../../components/ui/copy-button";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { motion as m } from "framer-motion";
import { useLocation } from "react-router-dom";

const useShowMap = create((set) => ({
    showMap: false,
    toggleMap: () => set((state) => ({ showMap: !state.showMap })),
}));

const useShowImages = create((set) => ({
    showImages: false,
    toggleImages: () => set((state) => ({ showImages: !state.showImages })),
}));

const groupDataForEachDay = (data) => {
    // Extract all unique dates from the data
    const uniqueDates = [...new Set(data.map((item) => new Date(item.created_at).toDateString()))];

    // Group data for each unique date
    const groupedData = uniqueDates.map((dateString) => {
        const date = new Date(dateString);
        const dayData = data.filter((item) => {
            const itemDate = new Date(item.created_at);
            return itemDate.toDateString() === dateString;
        });
        return {
            date: new Date().getTime(),
            Amount: dayData.reduce((acc, item) => acc + item.investment_amount, 0),
        };
    });

    return groupedData;
};

const BackGroundDetails = ({ handleBack, showEdit, triggerFileInput, handleEditProject }) => {
    const { showMap, toggleMap } = useShowMap();

    const { authUser } = useContext(AuthUserContext);

    const [inviteLink, { data, error, loading }] = useInviteLinkMutation();

    // Function to handle WhatsApp share
    const handleWhatsAppShare = () => {
        const whatsappMessage = encodeURIComponent(`Join us by using this invite link: ${data?.inviteLink}`);
        const whatsappUrl = `https://wa.me/?text=${whatsappMessage}`;
        window.open(whatsappUrl, "_blank");
    };

    return (
        <Fragment>
            <div className="md:flex hidden md:px-14 w-full absolute top-14 justify-between items-center">
                <ArrowLeft onClick={handleBack} className="rounded-full cursor-pointer p-1 w-8 h-8 bg-white text-black" />
                <p className="text-white md:block hidden absolute left-1/2 -translate-x-1/2 text-2xl font-semibold">Project Details</p>
                {showEdit && (
                    <div className="flex items-center gap-4">
                        <button
                            onClick={triggerFileInput}
                            className=" bg-black/35  rounded-full px-4 py-2 flex items-center justify-center   text-white"
                        >
                            <Image alt="edit-icon" className="w-4 h-4 mr-2" />
                            <p className="text-xs">Change Cover Page</p>
                        </button>
                        <button
                            onClick={handleEditProject}
                            className=" bg-black/35  rounded-full px-4 py-2 flex items-center justify-center   text-white"
                        >
                            <Pencil className="w-4 h-4 mr-2" />
                            <p className="text-xs">Edit Project Details</p>
                        </button>
                    </div>
                )}
            </div>
            <div className="flex md:hidden md:px-14 w-full px-2 absolute top-10 justify-between items-center">
                <img src="/assets/images/back.png" onClick={handleBack} className="rounded-full w-8  text-center   " />
                <div className="flex gap-4 items-center">
                    <img src="/assets/images/map2.png" className="w-6 " onClick={toggleMap} />
                    <Dialog className="">
                        <DialogTrigger asChild className="">
                            <Share2
                                className="w-6 text-white"
                                fill="white"
                                onClick={() => {
                                    inviteLink({
                                        variables: {
                                            user_id: authUser.id,
                                            is_all: true,
                                        },
                                    });
                                }}
                            />
                        </DialogTrigger>
                        <DialogContent className="w-[350px] md:w-1/2">
                            <p>Copy and Send</p>
                            {loading ? (
                                <p className="border px-4 py-2 text-center">
                                    <CircularProgress />
                                </p>
                            ) : (
                                <>
                                    <div className="border px-2 py-2 flex w-full items-center">
                                        <p>{data?.inviteLink}</p>
                                        <BlockCopyCodeButton code={data?.inviteLink} />
                                    </div>
                                    <div className="flex items-center   w-full justify-between  ">
                                        <div onClick={handleWhatsAppShare} className="w-fit flex rounded cursor-pointer hover:bg-gray-100 ">
                                            <WhatsAppIcon style={{ cursor: "pointer", color: "green" }} className="mr-2" />
                                            <p>Share via whatsapp</p>
                                        </div>
                                        {/* <div onClick={handleGmailShare} className="w-fit flex rounded cursor-pointer hover:bg-gray-100 ">
                                    <EmailIcon className="mr-2 text-black" />
                                    <p>Share via Email</p>
                                </div> */}
                                    </div>
                                </>
                            )}
                        </DialogContent>
                    </Dialog>

                    {showEdit && (
                        <Popover>
                            <PopoverTrigger>
                                <EllipsisVertical className="w-6 h-6 text-white" />
                            </PopoverTrigger>
                            <PopoverContent className="w-fit p-2 bg-black/60  text-white border-0">
                                <div>
                                    <p onClick={triggerFileInput} className="text-xs mb-2">
                                        Change Cover Page
                                    </p>

                                    <p onClick={handleEditProject} className="text-xs mb-2">
                                        Edit Project Details
                                    </p>

                                    <p className="text-xs">Download project report</p>
                                </div>
                            </PopoverContent>
                        </Popover>
                    )}
                </div>
            </div>
        </Fragment>
    );
};

const TitleAndOtherDetails = ({ project, isAdmin, categories, types }) => {
    const navigate = useNavigate();
    const { authUser } = useContext(AuthUserContext);
    const { data: regionAdminData } = useGetRegionAdminQuery({});
    const { toggleImages, showImages } = useShowImages();
    return (
        <Fragment>
            <div className="md:flex hidden w-full justify-between items-start">
                <div className="flex w-4/6 items-center justify-between">
                    <div className="flex items-center gap-3 ">
                        <div>
                            <img
                                src={
                                    project.project_profile_picture
                                        ? project.project_profile_picture
                                        : "https://equippp-assets.s3.ap-south-1.amazonaws.com/images/no_project_image.jpeg"
                                }
                                alt=""
                                className="w-40 h-28 object-fill rounded-md"
                            />
                        </div>{" "}
                        <div>
                            <p className="text-lg font-bold">{project?.title}</p>
                            {!isAdmin ? (
                                <p className="text-[#034AFF] text-sm  cursor-pointer">
                                    <span className="text-black/70 font-medium">initiated by : </span>
                                    <span onClick={() => navigate(`/profile-details?id=${regionAdminData?.users[0].id}`)}>
                                        {project?.user?.user_name}
                                    </span>
                                </p>
                            ) : (
                                <p className="text-[#034AFF] text-sm  cursor-pointer">
                                    <span className="text-black/70 font-medium">initiated by : </span>
                                    <span onClick={() => navigate(`/profile-details?id=${authUser.id}`)}>{project?.user?.user_name}</span>
                                </p>
                            )}
                            <div className="mt-2">
                                <p className="text-sm text-black/70 ">
                                    <span className="text-black font-medium">Project Category : </span>
                                    {categories.map((category, index) => (
                                        <Fragment key={index}>
                                            {category} {index < categories.length - 1 ? ", " : ""}
                                        </Fragment>
                                    ))}{" "}
                                    {project?.secondary_category && `, ${project?.secondary_category}`}
                                </p>
                            </div>
                            <div>
                                <p className="text-sm text-black/70 ">
                                    <span className="text-black font-medium">Project Type : </span>

                                    {types.map((type, index) => (
                                        <Fragment key={index}>
                                            {type} {index < categories.length - 1 ? ", " : ""}
                                        </Fragment>
                                    ))}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-grow  items-center justify-end gap-10">
                    <p className="text-sm text-black/70">Posted on, {moment(project?.created_at).format("MMM DD")}</p>
                    {authUser?.role == "admin" && (
                        <button className="rounded-full text-sm py-2 px-4 bg-blue-600 text-white flex items-center justify-center gap-2">
                            <img src={pdfIcon} alt="" className="w-5" />
                            Download Project Report
                        </button>
                    )}
                </div>
            </div>
            <div className="flex flex-col md:hidden w-full  px-4 mb-3">
                <img
                    src={
                        project.project_profile_picture
                            ? project.project_profile_picture
                            : "https://equippp-assets.s3.ap-south-1.amazonaws.com/images/no_project_image.jpeg"
                    }
                    alt=""
                    className="w-32  -mt-16 h-32 object-fill rounded-full"
                />

                <div className="flex mt-2">
                    <div>
                        <p className="text-lg font-bold">{project?.title}</p>
                        {!isAdmin ? (
                            <p className="text-[#034AFF] text-sm  cursor-pointer">
                                <span className="text-black/70 font-medium">initiated by : </span>
                                <span onClick={() => navigate(`/profile-details?id=${regionAdminData?.users[0].id}`)}>
                                    {project?.user?.user_name}
                                </span>
                            </p>
                        ) : (
                            <p className="text-[#034AFF] text-sm  cursor-pointer">
                                <span className="text-black/70 font-medium">initiated by : </span>
                                <span onClick={() => navigate(`/profile-details?id=${authUser.id}`)}>{project?.user?.user_name}</span>
                            </p>
                        )}
                        {/* <div className="mt-1">
                            <p className="text-sm text-black/70 ">
                                <span className="text-black font-medium">Project Category : </span>
                                {categories.map((category, index) => (
                                    <Fragment key={index}>
                                        {category} {index < categories.length - 1 ? ", " : ""}
                                    </Fragment>
                                ))}{" "}
                                {project?.secondary_category && `, ${project?.secondary_category}`}
                            </p>
                        </div>
                        <div>
                            <p className="text-sm text-black/70 ">
                                <span className="text-black font-medium">Project Type : </span>

                                {types.map((type, index) => (
                                    <Fragment key={index}>
                                        {type} {index < categories.length - 1 ? ", " : ""}
                                    </Fragment>
                                ))}
                            </p>
                        </div> */}
                    </div>
                </div>
                <div className="flex justify-end w-full absolute top-4 right-4">
                    <div className="flex items-center border rounded-md p-2" onClick={toggleImages}>
                        <Images className="text-gray-400 w-6 h-6 mr-2" />
                        <p className="text-xs">{showImages ? "hide images" : "view images"}</p>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

const TabsAndDetails = ({
    description,
    requirements,
    project,
    scrollLeft,
    scrollRight,
    imageContainerRef,
    loading,
    status,
    quickInfoData,
    participant_type,
    openExpressInterestModal,
    projectId,
    userProfilePicture,
}) => {
    const [activeTab, setActiveTab] = useState("summary");

    return (
        <Tabs defaultValue="summary" className="px-2 p-0 mt-2 " value={activeTab} onValueChange={setActiveTab}>
            <TabsList className="bg-gray-100 rounded-lg  p-2 w-full flex">
                <TabsTrigger
                    value="summary"
                    onClick={() => setActiveTab("summary")}
                    className={`px-2 py-1 mx-0 m-0 rounded-md mt-0 w-full ${activeTab === "summary" ? "bg-blue-600 text-white" : "text-black"}`}
                >
                    Summary
                </TabsTrigger>
                <TabsTrigger
                    value="comments"
                    onClick={() => setActiveTab("comments")}
                    className={`px-2 py-1 mx-0 m-0 rounded-md mt-0 w-full ${activeTab === "comments" ? "bg-blue-600 text-white" : "text-black"}`}
                >
                    Comment
                </TabsTrigger>
                <TabsTrigger
                    onClick={() => setActiveTab("expressions")}
                    value="expressions"
                    className={`px-2 py-1 mx-0 m-0 rounded-md mt-0 w-full ${activeTab === "expressions" ? "bg-blue-600 text-white" : "text-black"}`}
                >
                    Expression
                </TabsTrigger>
            </TabsList>
            <TabsContent value="summary" className=" max-h-[800px] overflow-y-scroll no-scrollbar ">
                <div className="bg-gray-100 rounded-md p-2">
                    <p className="text-lg font-semibold text-blue-500 mb-2">Description</p>
                    <p className="text-sm">{description}</p>
                </div>
                <div className="bg-gray-100 rounded-md p-2 mt-2">
                    <p className="text-lg font-semibold text-blue-500 mb-2">Requirements</p>
                    <p className="text-sm">{requirements}</p>
                </div>
            </TabsContent>
            <TabsContent value="comments">
                <div className="rounded-xl  max-h-[480px] overflow-y-scroll no-scrollbar p-3 border border-gray-100">
                    <div className="flex justify-between items-center ">
                        <p className="font-semibold ">Comments</p>
                    </div>
                    <ProjectComments profileURL={userProfilePicture} projectId={projectId} />
                </div>
            </TabsContent>
            <TabsContent value="expressions">
                <ProjectQuickInfo
                    loading={loading}
                    status={status}
                    quickInfoData={quickInfoData}
                    participant_type={participant_type}
                    openExpressInterestModal={openExpressInterestModal}
                />
            </TabsContent>
        </Tabs>
    );
};

const Project = (props) => {
    const [isUserLoggedIn, setIsLoggedIn] = useState(false);
    const [tableRows, setTableRows] = useState([]);
    const [project, setProject] = useState({});
    const [expertCommentExist, setExpertCommentExist] = useState(false);
    const [shareContent, setShareContent] = useState({});
    const [projectImages, setProjectImages] = useState([]);
    const [projectDocuments, setProjectDocuments] = useState([]);
    const backgroundImageInputRef = useRef(null);
    const [file, setFile] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState("");
    const [fileUploadType, setFileUploadType] = useState("");
    const imageContainerRef = useRef(null);
    const [selectedImage, setSelectedImage] = useState(null);

    const scrollLeft = () => {
        if (imageContainerRef.current) {
            imageContainerRef.current.scrollBy({ left: -300, behavior: "smooth" });
        }
    };

    const scrollRight = () => {
        if (imageContainerRef.current) {
            imageContainerRef.current.scrollBy({ left: 300, behavior: "smooth" });
        }
    };

    // const [state, setState] = useState({
    //     editorState: EditorState.createWithContent(convertFromRaw(JSON.parse(post.content))),
    // });
    const navigate = useNavigate();
    const { projectId } = useParams();

    const { data: regionAdminData } = useGetRegionAdminQuery({});

    const [updateProjectBackground] = useUpdateProjectBackgroundMutation();

    const [Tabs, setTabs] = useState([]);

    const { data: projectComments } = useGetCommentQuery({
        variables: {
            id: projectId,
        },
        skip: !projectId,
    });

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        let flag = false;
        projectComments?.comments.forEach((item) => {
            if (item?.user?.role === "sme" || item?.user?.user_type === "Subject Matter Expert") {
                setExpertCommentExist(true);
                flag = true;
            }
        });
        if (windowWidth > 768) {
            if (flag) {
                setTabs(["Details", "Scope", "Documents", "Comments", "Expert Comments", "Expressions", "Location"]);
            } else {
                setTabs(["Details", "Scope", "Documents", "Comments", "Expressions", "Location"]);
            }
        } else {
            setTabs(["Summary", "Comments"]);
        }
        console.log(window.innerWidth, "kk");
    }, [projectComments, windowWidth]);

    const history = useNavigate();
    const { authUser } = useContext(AuthUserContext);
    const authLocal = localStorage.getItem("authUser");

    const setNotification = (val, header, body) => {};

    const { data: projectData, loading } = useGetProjectQuery({
        variables: {
            id: projectId,
        },
    });

    // console.log(
    //     projectData,
    //     "jr",
    //     projectData?.projects[0]?.location_coordinates?.coordinates[1],
    //     projectData?.projects[0]?.location_coordinates?.coordinates[0]
    // );

    useEffect(() => {
        if (props.isAdmin) {
            const adminAccessToken = window.localStorage.getItem("authUser")?.role !== "admin";
            if (!adminAccessToken) history("/admin");
        }
    }, [history, projectId, props.isAdmin]);

    useEffect(() => {
        if (projectData) {
            setProject(projectData && projectData.projects[0]);
        }
        const rows = projectData?.projects?.[0]?.expressions.map((el) => {
            let profileURL = `/profile-details?id=${el?.added_by}`;
            if (el?.is_verified) {
                return {
                    userName: <a href={profileURL}>{capitalizeFirstLetter(el?.user?.user_name || el?.user?.name || el?.user?.email_id)}</a>,
                    kyc_status: el.user.kyc_status,

                    participant: el.participation_type,
                    investmentType: el.participation_type,
                    amount: el.investment_amount,
                    message: el.expression_message,
                    date: el.created_at.substring(0, 10),
                };
            }
        });
        setTableRows(rows);
    }, [projectData]);

    let profileURL = `/profile-details?id=${project?.added_by}`;
    const asideData = {
        User_Type: "Individual",
        Address: (project && project?.user?.location_title) || "NA",
        Phone: (project && project?.user?.mobile) || "NA",
        Email: (project && project?.user?.email_id) || "NA",
        User_Name: (project && `${project?.user?.user_name} ${project?.user?.kyc_status === "accepted" ? "verified" : ""}`) || "NA",
        Profile_Page:
            (project && <a href={authUser && authLocal ? profileURL : null}>{project?.user?.user_name || project?.user?.email_id}</a>) || "NA",
        Followers: 0,
    };

    const receivedExpressions = useMemo(() => {
        if (project && project?.expressions && project?.expressions?.length) {
            return project?.expressions?.reduce((acc, curr) => (curr.is_verified ? acc + curr.investment_amount : acc), 0);
        }

        return 0;
    }, [project]);

    useEffect(() => {
        if (project) {
            setProjectImages([]);
            setProjectDocuments([]);
            project?.project_assets?.map((el, index) => {
                if (el.asset_type === "images") {
                    setProjectImages((prev) => [...prev, el]);
                } else if (el.asset_type === "documents") {
                    setProjectDocuments((prev) => [...prev, el]);
                } else {
                    return "";
                }
                return "";
            });
        }
    }, [project]);

    const quickInfoData = {
        startDate: (project.start_date && project.start_date.substring(0, 10)) || "NA",
        endDate: (project.end_date && project.end_date.substring(0, 10)) || "NA",
        status: project.project_status || "",
        estimateBudget: (project.estimated_cost && project.estimated_cost.toLocaleString("en-IN")) || "NA",
        expressionsReceived:
            project && project.participant_type === "kind"
                ? "Kind expression"
                : (receivedExpressions && receivedExpressions.toLocaleString("en-IN")) || 0,
    };
    /**
     * Change the value of the tab when the user clicks on the respesctive tab
     * @param {object} e event object of the click
     */

    const [isModalOpen, setModal] = useState(() => false);
    /**
     * Watch for change in state of Authentication modal.
     * The value of `isModalOpen` changes to desired modal name whenever the modal changes its state.
     * The modal is hidden if no parameter value is given.
     * @param {string} value The name of the modal to be triggered
     * @author DK
     */
    const toggleAuthModal = (value) => {
        if (value) setModal(value);
        else setModal(false);
    };

    const capitalizeFirstLetter = (string) => {
        return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
    };

    /**
     * 1. Check if project is fully funded.
     * 1. If yes, `return;` so that nothing happens and it shows a popover: _This project is fully funded_.
     * 1. Check if user is logged in.
     * 1. If yes, show the expression modal.
     * 1. Else, show the login modal and then show expression modal on successful login.
     */
    const openExpressInterestModal = () => {
        const authToken = window.localStorage.getItem("authToken");
        if (!authToken) {
            navigate("/login");
        } else {
            toggleAuthModal("express-interest");
        }
    };

    const [tabIndex, setTabIndex] = useState(0);

    const [shareOpen, setShareOpen] = useState(false);
    const assetsArray = project?.project_assets?.map((asset, index) => {
        if (asset?.asset_type === "images") {
            return asset?.url;
        }
    });
    let projectImage;

    assetsArray?.forEach((item) => {
        if (item !== undefined) {
            projectImage = item;
        }
    });

    console.log(project);

    if (!authUser) {
        navigate("/login", { replace: true });
    }

    const showEdit = authUser?.role === "admin";

    console.log({ project });

    const [getUploadURL, { error }] = useGetUploadUrlMutation({
        onCompleted: async (data) => {
            if (data?.getUploadURL?.success) {
                try {
                    let { downloadUrl, uploadParams } = data.getUploadURL;

                    uploadParams = JSON.parse(uploadParams);
                    let formData = new FormData();
                    Object.keys(uploadParams.fields).forEach((key) => {
                        formData.append(key, uploadParams.fields[key]);
                    });
                    formData.append("file", file);
                    await axios.post(uploadParams.url, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    });

                    updateProjectBackground({
                        variables: {
                            url: downloadUrl,
                            id: project.id,
                        },
                    })
                        .then((response) => {
                            if (response.data) {
                                setProject((prev) => ({ ...prev, background: downloadUrl }));
                                toast.success("Background updated successfully!");
                            }
                        })
                        .catch((err) => {
                            console.error("Error updating the project background", err);
                            toast.error("Failed to update project background");
                        });
                } catch (e) {
                    console.error("Error during image upload", e);
                    toast.error("Failed to upload image");
                } finally {
                    toast.dismiss();
                }
            }
        },
        onError: (uploadError) => {
            console.error("Upload error", uploadError);
            toast.error("Error during file upload");
        },
    });

    const handleBackGroundImageChange = async (event) => {
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
            return;
        }
        if (fileObj.size / 1024 / 1024 > 5) {
            alert("File Size must be less than 5mb");
            return;
        }
        toast.loading("Uploading file, please wait...");
        setFile(fileObj);

        const fileName = fileObj.name.split(".")[0] + "_" + Date.now() + "." + fileObj.name.split(".")[1];

        const { data } = await getUploadURL({
            variables: {
                fileName: fileName,
                fileSize: fileObj.size,
                path: `\${type}/\${userId}/${fileName}`,
                uploadType: "images",
            },
        });
        console.log({ data });
        setFileUploadType("images");
    };

    const triggerFileInput = () => {
        backgroundImageInputRef.current.click();
    };

    console.log({ projectId });

    const dataFormatter = (number) => {
        if (number >= 1000000) {
            return (number / 1000000).toFixed(2) + "M";
        } else if (number >= 1000) {
            return (number / 1000).toFixed(2) + "k";
        } else {
            return Intl.NumberFormat("en").format(number);
        }
    };

    const { data } = useGetProjectAnalyticsQuery({
        variables: {
            id: projectId,
        },
        skip: !projectId,
    });

    const [getUsers, { data: userData, loading: isLoading }] = useGetCurrentUserLazyQuery();
    useEffect(() => {
        if (authUser && authUser.id) {
            getUsers({
                variables: {
                    id: authUser.id,
                },
            });
        }
    }, [authUser, getUsers]);

    console.log({ userData });

    const { data: chartData } = useGetProjectAnalyticsChartQuery({
        variables: {
            projectId: projectId,
        },
        skip: !projectId,
    });

    const chartsData = useMemo(() => {
        if (chartData && chartData.expressions) {
            return groupDataForEachDay(chartData.expressions);
        }

        return groupDataForEachDay([]);
    }, [chartData]);

    useEffect(() => {
        if (!chartsData.length) return;

        const orderedData = chartsData.sort((a, b) => a.date - b.date);

        orderedData.unshift({ date: orderedData[0].date - 86400000, Amount: 0 });

        const ctx = document.getElementById("acquisitions");

        const chart = new Chart(ctx, {
            type: "line",
            options: {
                scales: {
                    y: {
                        ticks: {
                            maxTicksLimit: 10, // Limits the number of y-axis ticks to 5
                            callback: function (value, index, ticks) {
                                return dataFormatter(value);
                            },
                        },
                        // max: Math.max(...orderedData.map((row) => row.Amount)) * 1.2,
                    },
                    x: {
                        ticks: {
                            maxTicksLimit: 10,
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                let label = context.dataset.label || "";
                                if (label) {
                                    label += ": ";
                                }
                                label += dataFormatter(context.parsed.y);
                                return label;
                            },
                        },
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
            },
            data: {
                labels: orderedData.map((row) => new Date(row.date).toLocaleDateString("en-IN")),
                datasets: [
                    {
                        data: orderedData.map((row) => row.Amount),
                        fill: true,
                        borderColor: "#2D5BFF",
                        tension: 0,
                        pointRadius: 4,
                        backgroundColor: "rgba(45, 91, 255, 0.1)",
                    },
                ],
            },
        });

        return () => {
            chart.destroy();
        };
    }, [chartsData]);

    console.log(
        "Formatted Chart Data:",
        chartsData.map((row) => ({ ...row, Amount: dataFormatter(row.Amount) }))
    );

    const location = useLocation();

    const handleBack = () => {
        // if (authUser?.role === "admin") {
        //     navigate("/projects");
        // } else {
        //     navigate(`/profile-details?id=${regionAdminData?.users[0].id}`);
        // }
        if (location?.state?.navigateTo) {
            navigate(location.state.navigateTo);
        } else {
            navigate(-1);
        }
    };

    const isAdmin = authUser?.role === "admin";

    const categories = project?.category ? JSON?.parse(project?.category ?? []) ?? [project?.category] : [];
    const types = project?.type ? JSON?.parse(project?.type ?? []) ?? [project?.type] : [];

    console.log({ project });
    const handleEditProject = () => {
        navigate(`/admin/edit-project/${projectId}`);
    };

    const { showMap } = useShowMap();

    const { showImages } = useShowImages();

    const userProfilePicture = userData?.users_by_pk?.profile_picture || "";

    const [isTransition, setIsTransition] = useState(true);

    useEffect(() => {
        // Set a timeout to switch isLoading to false after 5 seconds
        const timer = setTimeout(() => {
            setIsTransition(false);
        }, 2000000);

        return () => clearTimeout(timer);
    }, []);

    if (isLoading) {
        return (
            <div className="flex justify-center items-center w-screen h-screen bg-white  absolute top-0 left-0">
                {/* <img src="/assets/images/gif.gif" alt="Loading..." className="" /> */}
            </div>
        );
    }

    return (
        <m.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, ease: "easeInOut" }}
            className="w-full h-full  bg-white"
        >
            <div className="relative">
                <input
                    type="file"
                    ref={backgroundImageInputRef}
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={handleBackGroundImageChange}
                />

                <div
                    alt="construction"
                    className={`bg-no-repeat bg-cover bg-center w-full backdrop-blur h-44  md:h-72 ${project.background ? "" : "bg-black/40"}`}
                    style={{ backgroundImage: project.background ? `url(${project.background})` : "" }}
                ></div>

                <BackGroundDetails
                    handleBack={handleBack}
                    showEdit={showEdit}
                    triggerFileInput={triggerFileInput}
                    handleEditProject={handleEditProject}
                />
            </div>
            <div className="bg-white md:px-14  md:pb-6">
                <div className=" relative md:-mt-40 md:bg-white  md:shadow-md rounded-xl md:py-6 md:px-4">
                    <TitleAndOtherDetails project={project} isAdmin={isAdmin} categories={categories} types={types} />

                    <div className="w-full hidden md:flex justify-between gap-6 mt-4">
                        <div className="flex-grow w-1/2 flex flex-col">
                            <div className="basis-1/2 rounded-xl max-h-96 overflow-y-scroll no-scrollbar mb-4 p-3 border border-gray-100">
                                <p className="font-medium text-blue-600 mb-1 text-base">Description</p>
                                <p className="text-sm mb-3"> {project.problem}</p>
                                <p className="font-medium text-blue-600 text-base mb-1">Requirement</p>
                                <p className="text-sm "> {project.requirements}</p>

                                {project?.images?.length > 0 && (
                                    <Fragment>
                                        <div className="w-full flex justify-between mt-3 mb-3">
                                            <p className="font-medium  text-blue-600 text-base ">Project Photos</p>
                                            <div className="flex gap-2">
                                                <ChevronLeft
                                                    className="w-6 h-6 rounded-full text-center p-1 bg-gray-300 text-white active:bg-blue-600"
                                                    onClick={scrollLeft}
                                                />
                                                <ChevronRight
                                                    className="w-6 h-6 rounded-full text-center p-1 bg-gray-300 text-white active:bg-blue-600"
                                                    onClick={scrollRight}
                                                />
                                            </div>
                                        </div>
                                        <Dialog>
                                            <DialogTrigger>
                                                <div className="flex gap-2 overflow-x-scroll no-scrollbar" ref={imageContainerRef}>
                                                    {project?.images?.map((image) => (
                                                        <img
                                                            src={image.image_url}
                                                            alt=""
                                                            className="w-72 object-cover h-40 rounded-md cursor-pointer"
                                                        />
                                                    ))}
                                                </div>
                                            </DialogTrigger>
                                            <DialogContent className="bg-transparen w-1/2 p-0 border-0 ">
                                                <Carousel className="w-full h-full ">
                                                    <CarouselContent className="">
                                                        {project?.images?.map((image) => (
                                                            <CarouselItem className="">
                                                                <img src={image.image_url} alt="" className="w-full h-full  " />
                                                            </CarouselItem>
                                                        ))}
                                                    </CarouselContent>
                                                    <CarouselPrevious />
                                                    <CarouselNext />
                                                </Carousel>
                                            </DialogContent>
                                        </Dialog>
                                    </Fragment>
                                )}
                            </div>
                            <div className="rounded-xl  max-h-[480px] overflow-y-scroll no-scrollbar p-3 border border-gray-100">
                                <div className="flex justify-between items-center ">
                                    <p className="font-semibold ">Comments</p>
                                </div>
                                <ProjectComments profileURL={userProfilePicture} projectId={projectId} />
                            </div>
                        </div>

                        <div className="flex flex-col w-1/3">
                            <div className="h-48">
                                <Map
                                    allPositions={[
                                        {
                                            lat: projectData?.projects[0]?.location_coordinates?.coordinates[1],
                                            lng: projectData?.projects[0]?.location_coordinates?.coordinates[0],
                                        },
                                    ]}
                                    isMobileView={true}
                                    currentPosition={{
                                        lat: projectData?.projects[0]?.location_coordinates?.coordinates[1],
                                        lng: projectData?.projects[0]?.location_coordinates?.coordinates[0],
                                    }}
                                />
                            </div>
                            <div className="my-4 px-2 flex items-center">
                                <img src="/assets/images/map-location.png" alt="" className="w-6 h-6 mr-2 " />
                                <p className="text-[#034AFF] text-base text-black/50">{project?.location_title}</p>
                            </div>

                            <ProjectQuickInfo
                                loading={loading}
                                status={quickInfoData.status}
                                quickInfoData={quickInfoData}
                                participant_type={project.participant_type}
                                openExpressInterestModal={openExpressInterestModal}
                            />
                            <div className="flex-grow border rounded-xl p-3">
                                {quickInfoData.status === "completed" ? (
                                    <div className="flex items-center justify-center h-full w-full">
                                        <p className="text-gray-600">Expressions FulFilled</p>
                                    </div>
                                ) : (
                                    <Fragment>
                                        {chartsData.length > 0 ? (
                                            <canvas className="h-72" id="acquisitions"></canvas>
                                        ) : (
                                            <div className="flex items-center justify-center h-full w-full">
                                                <p className="text-gray-600">No Expressions Received Yet</p>
                                            </div>
                                        )}
                                    </Fragment>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="block md:hidden">
                {showMap && (
                    <Fragment>
                        <div className="h-48 px-2 mt-4">
                            <Map
                                allPositions={[
                                    {
                                        lat: projectData?.projects[0]?.location_coordinates?.coordinates[1],
                                        lng: projectData?.projects[0]?.location_coordinates?.coordinates[0],
                                    },
                                ]}
                                isMobileView={true}
                                currentPosition={{
                                    lat: projectData?.projects[0]?.location_coordinates?.coordinates[1],
                                    lng: projectData?.projects[0]?.location_coordinates?.coordinates[0],
                                }}
                            />
                        </div>
                        <div className="my-3 px-2 flex items-center">
                            <img src="/assets/images/map-location.png" alt="" className="w-4 h-4 mr-2 " />
                            <p className="text-[#034AFF] text-xs text-black/50">{project?.location_title}</p>
                        </div>
                    </Fragment>
                )}

                {showImages && (
                    <div className="px-2 ">
                        {project?.images?.length > 0 && (
                            <Fragment>
                                <div className="w-full  flex justify-between mt-3 mb-3">
                                    <div className="flex gap-2">
                                        <ChevronLeft
                                            className="w-6 h-6 rounded-full text-center p-1 bg-gray-300 text-white active:bg-blue-600"
                                            onClick={scrollLeft}
                                        />
                                        <ChevronRight
                                            className="w-6 h-6 rounded-full text-center p-1 bg-gray-300 text-white active:bg-blue-600"
                                            onClick={scrollRight}
                                        />
                                    </div>
                                </div>
                                <Dialog>
                                    <DialogTrigger>
                                        <div className="flex gap-2 overflow-x-scroll no-scrollbar" ref={imageContainerRef}>
                                            {project?.images?.map((image) => (
                                                <img src={image.image_url} alt="" className="w-72 object-cover h-40 rounded-md cursor-pointer" />
                                            ))}
                                        </div>
                                    </DialogTrigger>
                                    <DialogContent className="bg-transparen w-1/2 p-0 border-0 ">
                                        <Carousel className="w-full h-full ">
                                            <CarouselContent className="">
                                                {project?.images?.map((image) => (
                                                    <CarouselItem className="">
                                                        <img src={image.image_url} alt="" className="w-full h-full  " />
                                                    </CarouselItem>
                                                ))}
                                            </CarouselContent>
                                            <CarouselPrevious />
                                            <CarouselNext />
                                        </Carousel>
                                    </DialogContent>
                                </Dialog>
                            </Fragment>
                        )}
                    </div>
                )}

                <TabsAndDetails
                    description={project.problem}
                    requirements={project.requirements}
                    project={project}
                    scrollLeft={scrollLeft}
                    scrollRight={scrollRight}
                    imageContainerRef={imageContainerRef}
                    loading={loading}
                    status={quickInfoData.status}
                    quickInfoData={quickInfoData}
                    participant_type={project.participant_type}
                    openExpressInterestModal={openExpressInterestModal}
                    userProfilePicture={userProfilePicture}
                    projectId={projectId}
                />
            </div>

            <CustomModal isOpen={isModalOpen === "express-interest"} setOpen={setModal}>
                <ExpressInterestModalContent
                    setOpen={setModal}
                    projectId={projectId}
                    maxFund={quickInfoData.estimateBudget}
                    receivedFund={quickInfoData.expressionsReceived}
                    remainingFund={project.investment_amount - receivedExpressions}
                    toggle={(val, header = "Success!", body = "Expression Interest sent to Admin for approval.") =>
                        setNotification(val, header, body)
                    }
                    projectDetails={project}
                />
            </CustomModal>
        </m.div>
    );
};

export default Project;
Project.propTypes = {
    isAdmin: PropTypes.bool,
};
