import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Eye, EyeOff } from "lucide-react";
import { validateEmail, validatePassword } from "utils";
import { useUser } from "contexts/user-type/user-type-context.js";
import { useGetUsersQuery } from "../../types/generated-types.ts";
import { useCheckUserExistsMutation } from "../../types/generated-types.ts";

const MobileSignUp = () => {
    const navigate = useNavigate();

    const { state, setState } = useUser();
    const { phoneNumber, email, password, reEnterPassword } = state;
    const [emailError, setEmailError] = useState(() => "");
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const [signUpError, setSignUpError] = useState("");

    const [passwordError, setPasswordError] = useState(() => "");

    const [reEnterPasswordError, setReRenterPasswordError] = useState(() => "");

    const [acceptTerms, setAcceptTerms] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [showReEnterPassword, setShowReEnterPassword] = useState(false);

    const [users, setUsers] = useState();

    const validatePhoneNumber = (phoneNumber) => {
        const isValidPhoneNumber = /^[0-9]{10}$/.test(phoneNumber);
        return isValidPhoneNumber;
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleChange = (e) => {
        setState((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };

    const { data: usersData } = useGetUsersQuery();
    useEffect(() => {
        if (usersData) {
            setUsers(usersData.users);
        }
    }, [usersData]);

    console.log({ usersData });

    console.log(users);

    const [checkUserExists, { loading: checkingUserExists }] = useCheckUserExistsMutation();

    const handleClick = async (e) => {
        e.preventDefault();

        console.log({ users });

        if (users && users?.filter((item) => item.email_id.toLowerCase() === email.toLowerCase()).length > 0) {
            setEmailError("User With this Email Already Exists");
            return;
        }

        const checkEmail = validateEmail(email);
        if (!checkEmail.status) {
            setEmailError(checkEmail.message);
            return;
        }

        //check email and phone number already exists

        if (!validatePhoneNumber(phoneNumber)) {
            setPhoneNumberError("Invalid phone number. Please enter a 10-digit phone number.");
            return;
        }

        const checkPassword = validatePassword(password, reEnterPassword);
        if (!checkPassword.status) {
            checkPassword.field === "password" ? setPasswordError(checkPassword.message) : setReRenterPasswordError(checkPassword.message);
            return;
        }

        if (!acceptTerms) {
            return alert("please accept terms and conditions");
        }

        // Check if email and phone number already exist
        try {
            console.log({ email, phoneNumber });
            const response = await checkUserExists({ variables: { email, phone_number: phoneNumber } });
            console.log({ response });
            if (response.data && response.data.checkUserExists) {
                setSignUpError("user with this email or phone number already exists");
            } else {
                // Navigate to user type selection page
                navigate("/signup/user-details");
            }
        } catch (error) {
            console.error("Error checking user existence:", error);
            // Optionally handle the error, such as showing a generic error message
        }
    };

    /**
     * Register a new user in the system. The user only needs to put email and password.
     * @param {object} event event object of the submitted form
     * @author DK
     */
    return (
        <div className="flex h-full w-full justify-center items-center my-auto flex-col bg-[#F9F9F9] md:px-0 px-6">
            <div className="flex justify-center mb-10">
                <p className="text-[#0D0140] font-bold text-3xl">Create an Account</p>
            </div>
            <form className="w-full md:w-1/3">
                <div className="mb-4 ">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2" for="username">
                        Email
                    </label>
                    <input
                        className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
                        id="username"
                        value={email}
                        onChange={handleChange}
                        type="text"
                        name="email"
                        placeholder="Brandonelouis@gmail.com"
                    />
                    {emailError && <p className="text-red-500 text-xs italic">{emailError}</p>}
                </div>

                <div className="mb-4">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2" htmlFor="phoneNumber">
                        Phone Number
                    </label>
                    <input
                        className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline"
                        id="phoneNumber"
                        name="phoneNumber"
                        value={phoneNumber}
                        onChange={handleChange}
                        type="tel"
                        placeholder="Enter your phone number"
                    />
                    {phoneNumberError && <p className="text-red-500 text-xs italic">{phoneNumberError}</p>}
                </div>

                <div className="mb-6 relative">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2" htmlFor="password">
                        Password
                    </label>
                    <input
                        className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
                        id="password"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter your password"
                        value={password}
                        onChange={handleChange}
                    />
                    <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                        className="absolute   flex items-center text-sm right-3 top-[55%] "
                    >
                        {showPassword ? <EyeOff className="h-6 w-6 text-[#60778C]" /> : <Eye className="h-6 w-6 text-[#60778C]" />}
                    </button>
                    {passwordError && <p className="text-red-500 text-xs italic">{passwordError}</p>}
                </div>
                <div className="mb-6 relative">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2" htmlFor="password">
                        Confirm Password
                    </label>
                    <input
                        className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
                        name="reEnterPassword"
                        id="reEnterPassword"
                        value={reEnterPassword}
                        onChange={handleChange}
                        type={showReEnterPassword ? "text" : "password"}
                        placeholder="Re-enter your password"
                    />
                    <button
                        type="button"
                        onClick={() => setShowReEnterPassword(!showReEnterPassword)}
                        className="absolute   flex items-center text-sm right-3 top-[55%] "
                    >
                        {showReEnterPassword ? <EyeOff className="h-6 w-6 text-[#60778C]" /> : <Eye className="h-6 w-6 text-[#60778C]" />}
                    </button>
                    {reEnterPasswordError && <p className="text-red-500 text-xs italic">{reEnterPasswordError}</p>}
                </div>
                <div class="flex items-center justify-between mb-4">
                    <div className="items-center flex">
                        <input id="default-checkbox" type="checkbox" value="" class="w-4 h-4 bg-[#E6E1FF] border-0" />
                        <label for="default-checkbox" className="ml-2 text-sm  text-[#AAA6B9] ">
                            Remember me
                        </label>
                    </div>
                    <div className="flex items-center">
                        <p
                            className="inline-block cursor-pointer align-baseline font-semibold text-sm text-[#0D0140] hover:text-[#E6E1FF]"
                            onClick={() => navigate("/forgot-password")}
                        >
                            Forgot Password?
                        </p>
                    </div>
                </div>

                <div className="mb-4">
                    <label className="inline-flex items-center">
                        <input
                            type="checkbox"
                            className="w-4 h-4 text-blue-600 border-gray-200 rounded focus:ring-blue-500"
                            name="acceptTerms"
                            value={acceptTerms}
                            onChange={(e) => {
                                setAcceptTerms(e.target.checked);
                            }}
                        />
                        <span className="ml-2 text-sm text-gray-600">
                            I accept the{" "}
                            <span className="text-blue-600 underline cursor-pointer" onClick={() => navigate("/terms-of-use")}>
                                Terms and Conditions
                            </span>
                            .
                        </span>
                    </label>
                    {!acceptTerms && <p className="text-red-500 text-xs italic">You must accept the terms and conditions to continue.</p>}
                </div>
                {signUpError && <p className="text-red-500 text-xs my-2 italic text-center">{signUpError}</p>}
                <div className="flex flex-col items-center w-full px-8 ">
                    <button
                        className="bg-[#130160] w-full text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="button"
                        onClick={handleClick}
                    >
                        CONTINUE
                    </button>
                    {/* <button
                        className="bg-[#D6CDFE] w-full mt-4  font-semibold py-2 px-4 rounded text-white focus:outline-none focus:shadow-outline"
                        type="button"
                    >
                        SIGN UP WITH GOOGLE
                    </button> */}
                </div>
            </form>

            <p className="text-center text-gray-500 text-xs mt-6">
                Already have an account yet?{" "}
                <span className="underline underline-offset-2 text-[#FF9228] cursor-pointer" onClick={() => navigate("/login")}>
                    Sign in
                </span>
            </p>
        </div>
    );
};

export default MobileSignUp;
