import React, { useState, Fragment, useEffect, useContext } from "react";
import { Search } from "lucide-react";
import { useNavigate } from "react-router-dom";
import AuthUserContext from "contexts/session";
import moment from "moment";
import {
    useGetAllVerifiedActiveProjectQuery,
    useGetAllLocationCoordinatesQuery,
    useGetCurrentUserExpressesProjectQuery,
    useGetRegionAdminQuery,
    useGetCurrentUserLazyQuery,
} from "types/generated-types.ts";
import { Avatar } from "@mui/material";
import { Map } from "views/Projects";
import MyActivityMap from "./components/my-activity-map";
const MyActivity = () => {
    const navigate = useNavigate();
    const { authUser, getMe } = useContext(AuthUserContext);
    const [searchTerm, setSearchTerm] = useState("");
    const { data: regionAdminData } = useGetRegionAdminQuery({});

    const {
        data: projectData,
        loading,
        error,
    } = useGetCurrentUserExpressesProjectQuery({
        variables: {
            user_id: authUser?.id,
        },
        skip: !authUser?.id,
    });

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    if (loading) return <p>Loading...</p>;

    console.log({ projectData });
    return (
        <div className="p-6 w-full">
            <div className="flex items-center justify-between flex-wrap">
                <p className="text-2xl font-bold">Expression History</p>
                <div className="w-2/5 rounded-full bg-[#f5f5f5] p-1 relative h-full flex justify-end items-center">
                    <input
                        onChange={handleSearchChange}
                        type="search"
                        placeholder="Search by name/area"
                        className="outline-none pl-8 border-none text-sm w-full h-8 rounded-full"
                    />
                    <Search className="text-gray-400 w-4 absolute left-4" />
                </div>
            </div>
            <div className="flex justify-between mt-4 text-[#9197B3]  border-b-2 pb-4 px-4">
                <p>Project Details</p>
                <div className="flex gap-36">
                    <p>Project Location</p>
                    <p>Project Date</p>
                </div>
            </div>
            {projectData ? (
                <Fragment>
                    {projectData?.projects.map((project) => {
                        const commentId = project.comments[0].id;
                        const adminComment = project.comments.filter((item) => item.parent_comment_id === commentId);

                        console.log(adminComment, "here");

                        return (
                            <div className="bg-white rounded-lg mt-4 border">
                                <div className=" flex gap-4 justify-between  w-full p-2  border-b-2 pb-2">
                                    <div className="flex items-center  ">
                                        <img
                                            src={
                                                project.project_profile_picture ||
                                                "https://equippp-assets.s3.ap-south-1.amazonaws.com/images/no_project_image.jpeg"
                                            }
                                            className="w-20 mr-2 h-16 rounded-lg"
                                            alt=""
                                        />
                                        <div className="flex flex-col">
                                            <p className="font-medium  text-black text-sm mb-1">{project.title}</p>
                                            <p className="text-[#034AFF] text-sm mb-3 cursor-pointer">
                                                <span className="text-black/70">initiated by : </span>
                                                <span onClick={() => navigate(`/profile-details?id=${regionAdminData?.users[0].id}`)}>
                                                    {project?.user?.user_name}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex   ">
                                        <div className="flex w-full items-center">
                                            <div className="flex gap-32 pr-6">
                                                <p className="flex items-center text-xs">
                                                    <img src="assets/images/location-black.png" alt="" className="w-[14px] h-[15px] mr-1" />
                                                    {project.location_title}
                                                </p>
                                                <p className="text-xs text-gray-400">{moment(project.created_at).format("MMM DD")}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-between gap-10 items-centers w-full px-2 py-4 ">
                                    <div className="basis-1/2 bg-[#fbfbfd] border rounded-lg p-2 px-3  font-medium ">
                                        <div className="flex items-start">
                                            <Avatar className="w-8 h-8 mr-4" />
                                            <div className="flex flex-col w-full">
                                                <div className="flex justify-between ">
                                                    <p className="text-sm font-semibold mt-1">Your Comment</p>
                                                    {/* <p className="text-xs text-black/50">{moment(project.comments[0].created_at).format("MMM DD")}</p> */}
                                                </div>
                                                <div className="mt-3 mb-4">
                                                    <p className="text-xs text-black/70 w-full">{project.comments[0].comment}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex items-start ml-10">
                                            {adminComment.length > 0 && (
                                                <Fragment>
                                                    <Avatar className="w-8 h-8 mr-4" />
                                                    <div className="w-full">
                                                        <div className="flex w-full items-start justify-between mb-2">
                                                            <p className="text-sm font-semibold mt-1">Admin</p>
                                                            <p className="text-xs w-1/6 text-right text-black/50">
                                                                {moment(adminComment[0]?.created_at).format("MMM DD")}
                                                            </p>
                                                        </div>
                                                        <p className="text-xs w-5/6 text-black/60">{adminComment[0]?.comment}</p>
                                                    </div>
                                                </Fragment>
                                            )}
                                        </div>
                                    </div>

                                    <div className="flex basis-1/2">
                                        <div className="flex flex-col w-full bg-[#fbfbfd] border rounded-lg p-2 px-3 font-medium">
                                            <div className="flex items-start w-full">
                                                <Avatar className="w-8 h-8 mr-4" />
                                                <div className="flex justify-between items-start w-full">
                                                    <p className="text-sm font-semibold mt-1">Your Expression</p>
                                                    <p className="text-xs text-black/50">
                                                        {moment(project?.expressions[0]?.created_at).format("MMM DD")}
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="mt-3 flex justify-between gap-4 items-start">
                                                <div className="flex flex-col basis-1/2">
                                                    <p className="text-xs text-[#1E5EFF] mb-2 font-semibold">Investment Amount</p>
                                                    <p className="text-xs text-black/70">{project?.expressions[0]?.investment_amount}</p>
                                                </div>
                                                <div className="flex flex-col basis-1/2">
                                                    <p className="text-xs mb-2 text-[#1E5EFF] font-semibold">Message</p>
                                                    <p className="text-xs text-black/70">{project?.expressions[0]?.expression_message}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" w-72 h-40 relative rounded-md">
                                        <MyActivityMap currentPosition={project?.location_coordinates} />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </Fragment>
            ) : (
                <Fragment>No Recent Activity</Fragment>
            )}
        </div>
    );
};

export default MyActivity;
