import { useContext } from "react";
import { useGetCurrentUserLazyQuery, useGetProjectsQuery, useInviteLinkMutation } from "types/generated-types.ts";
import AuthUserContext from "contexts/session";
import { Dialog, DialogTrigger, DialogContent } from "../../components/ui/dialog";
import { CircularProgress } from "@mui/material";
import { BlockCopyCodeButton } from "../../components/ui/copy-button";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useLocation } from "react-router-dom";

const Invite = () => {
    // const {
    //     data: projectData,
    //     refetch,
    //     loading: projectLoading,
    // } = useGetProjectsQuery({
    //     variables: {
    //         limit: Infinity,
    //         offset: 0,
    //     },
    // });
    const { authUser } = useContext(AuthUserContext);

    const [inviteLink, { data, error, loading }] = useInviteLinkMutation();

    // Function to handle WhatsApp share
    const handleWhatsAppShare = () => {
        const whatsappMessage = encodeURIComponent(`Join us by using this invite link: ${data?.inviteLink}`);
        const whatsappUrl = `https://wa.me/?text=${whatsappMessage}`;
        window.open(whatsappUrl, "_blank");
    };
    const path = useLocation();

    const isProjects = window.location.href;
    // const handleGmailShare = () => {
    //     const subject = encodeURIComponent("You are invited!");
    //     const body = encodeURIComponent(`Join us by using this invite link: ${data?.inviteLink}\n\nBest regards,`);
    //     const mailtoUrl = `mailto:?subject=${subject}&body=${body}`;
    //     window.open(mailtoUrl, "_blank");
    // };

    return (
        <Dialog>
            <DialogTrigger asChild className="">
                <button
                    className="bg-[#d9d9d9] text-white px-3 py-1 flex justify-end items-center  rounded-full"
                    onClick={() => {
                        inviteLink({
                            variables: {
                                user_id: authUser.id,
                                is_all: true,
                            },
                        });
                    }}
                >
                    <img src="/assets/images/invite-logo.svg" alt="invite" className="w-[17px] h-[14px] mr-2" />
                    <p className="text-center text-[#1C2B52] text-xs">Invite</p>
                </button>
            </DialogTrigger>
            <DialogContent className="w-[350px] md:w-1/2">
                <p>Copy and Send</p>
                {loading ? (
                    <p className="border px-4 py-2 text-center">
                        <CircularProgress />
                    </p>
                ) : (
                    <>
                        <div className="border px-4 py-2 flex w-full items-center">
                            <p>{data?.inviteLink}</p>
                            <BlockCopyCodeButton code={data?.inviteLink} />
                        </div>
                        <div className="flex items-center   w-full justify-between  ">
                            <div onClick={handleWhatsAppShare} className="w-fit flex rounded cursor-pointer hover:bg-gray-100 ">
                                <WhatsAppIcon style={{ cursor: "pointer", color: "green" }} className="mr-2" />
                                <p>Share via whatsapp</p>
                            </div>
                            {/* <div onClick={handleGmailShare} className="w-fit flex rounded cursor-pointer hover:bg-gray-100 ">
                                    <EmailIcon className="mr-2 text-black" />
                                    <p>Share via Email</p>
                                </div> */}
                        </div>
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default Invite;
