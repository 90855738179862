import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";

function RegisterModalFooter() {
    return (
        <Fragment>
            <Typography className="terms__text">
                This site is protected by reCAPTCHA and the Google <Link to="/">Privacy Policy</Link> and <Link to="/">Terms of Service</Link> apply
            </Typography>
        </Fragment>
    );
}

export default RegisterModalFooter;
