import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../components/ui/select";
import { useUser } from "contexts/user-type/user-type-context.js";

const Individual = () => {
    const navigate = useNavigate();

    const { state, setState, registerUser } = useUser();
    const { userType, individualState, firstName, lastName } = state;

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({
            ...prevState,

            individualState: {
                ...prevState.individualState,
                [name]: value,
            },
        }));
    };

    const handleFirstNameChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            firstName: value,
        }));
    };

    const handleLastNameChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            lastName: value,
        }));
    };

    const handleSelectChange = (value) => {
        setState((prevState) => ({
            ...prevState,
            individualState: {
                ...prevState.individualState,
                individualGender: value,
            },
        }));
    };

    console.log("individualState", individualState);

    const handleForwardClick = () => {
        if (
            !firstName ||
            !lastName ||
            !individualState.individualCity ||
            !individualState.individualOccupation ||
            !individualState.individualGender
        ) {
            return alert("please fill all individual userType details");
        }
        // var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
        // if (!re.test(individualState.individualPhone)) {
        //     return alert("Enter valid phone number");
        // }
        registerUser();
    };

    return (
        <div className="flex h-full w-full justify-center items-center my-auto flex-col bg-[#F9F9F9] md:px-0 px-6">
            <div className="flex justify-center flex-col mb-10 md:mb-4">
                <p className="text-[#0D0140] font-bold text-2xl md:text-3xl text-center">Create Account</p>
                <p className="text-[#0D0140] text-center font-thin text-base mt-2">Please fill the below options to continue</p>
            </div>
            <form className="w-full md:w-1/3">
                <div className="mb-4 flex w-full justify-between gap-2">
                    <div className="w-1/2">
                        <label className="block text-[#0D0140] text-sm font-bold mb-2" for="username">
                            First Name
                        </label>
                        <input
                            className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
                            name="individualName"
                            type="text"
                            value={firstName}
                            onChange={handleFirstNameChange}
                            placeholder="Brandone Louis"
                        />
                    </div>
                    <div className="w-1/2">
                        <label className="block text-[#0D0140] text-sm font-bold mb-2" for="username">
                            Last Name
                        </label>
                        <input
                            className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
                            name="individualName"
                            type="text"
                            value={lastName}
                            onChange={handleLastNameChange}
                            placeholder="Brandone Louis"
                        />
                    </div>
                </div>
                {/* <div className="mb-4 ">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2" for="username">
                        Full Name
                    </label>
                    <input
                        className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
                        name="individualName"
                        type="text"
                        value={individualState.individualName}
                        onChange={handleInputChange}
                        placeholder="Brandone Louis"
                    />
                </div> */}
                <div className="mb-4 ">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2" for="username">
                        Gender
                    </label>
                    <Select className="modal-select-input" value={individualState.individualGender || ""} onValueChange={handleSelectChange}>
                        <SelectTrigger aria-label="User Type" className="border-0">
                            <SelectValue placeholder="Select Gender" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="female">Female</SelectItem>
                            <SelectItem value="male">Male</SelectItem>
                            <SelectItem value="other">Other</SelectItem>
                        </SelectContent>
                    </Select>
                </div>

                <div className="mb-4 ">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2" for="username">
                        City
                    </label>
                    <input
                        className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
                        type="text"
                        value={individualState.individualCity}
                        onChange={handleInputChange}
                        name="individualCity"
                        placeholder="Enter your city"
                    />
                </div>
                <div className="mb-4 ">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2">Occupation</label>
                    <input
                        className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
                        type="text"
                        value={individualState.individualOccupation}
                        onChange={handleInputChange}
                        name="individualOccupation"
                        placeholder="Example Business"
                    />
                </div>

                <div className="flex flex-col items-center w-full px-8 ">
                    <button
                        className="bg-[#130160] w-full text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="button"
                        onClick={handleForwardClick}
                    >
                        CONTINUE
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Individual;
