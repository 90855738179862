import config from "../config";
import axios from "axios";

// Append all the routes with the URL_PREFIX
export const buildURLs = ({ urls, prefix }) => {
    const newUrls = {};

    for (const key of Object.keys(urls)) {
        newUrls[key] = config.apiUrl + prefix + urls[key];
    }

    return newUrls;
};

// Build keys for react query
export const buildKeys = (urls) => {
    const keys = {};

    for (const key of Object.keys(urls)) {
        keys[key] = key;
    }

    return keys;
};

const getCommonHeaders = () => ({
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("access_token") || "",
});

export const getGenericGetRequest =
    (url, shouldUseBasURL = true) =>
    async ({ pathParams, queryParams } = {}) => {
        const headers = {
            ...getCommonHeaders(),
        };

        let urlWithPathParams = url;

        // Add path params to url
        if (pathParams) {
            for (const key of Object.keys(pathParams)) {
                urlWithPathParams = urlWithPathParams.replace(`:${key}`, pathParams[key]);
            }
        }

        const options = shouldUseBasURL
            ? {
                  headers,
                  baseURL: "/",
              }
            : {};

        // Add query params to options
        if (queryParams) {
            options.params = queryParams;
        }

        const response = await axios.get(urlWithPathParams, options);

        return response.data;
    };

// Function to return the generic post request
export const getGenericPostRequest = (url) => async (postData) => {
    const headers = {
        ...getCommonHeaders(),
    };

    const { pathParams, ...data } = postData || {};

    let urlWithPathParams = url;

    // Add path params to url
    if (pathParams) {
        for (const key of Object.keys(pathParams)) {
            urlWithPathParams = urlWithPathParams.replace(`:${key}`, pathParams[key]);
        }
    }

    const options = {
        headers,
        baseURL: "/",
    };

    const response = await axios.post(url, data || {}, options);
    return response.data;
};

// Function to return the generic put request
export const getGenericPutRequest = (url) => async (data) => {
    const headers = {
        ...getCommonHeaders(),
    };

    const response = await axios.put(url, data || {}, {
        headers,
        baseURL: "/",
        data: JSON.stringify(data),
    });
    return response.data;
};
