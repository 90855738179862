import React from "react";
import { components } from "react-select";

const DropDownValueContainer = (props) => {
    return (
        <components.ValueContainer {...props} className="!max-h-16 !overflow-auto">
            {props.children}
        </components.ValueContainer>
    );
};

export default DropDownValueContainer;
