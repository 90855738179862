import { useEffect, Fragment } from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import KEYS, { verifyUserAPI } from "api";
import "./ConfirmEmail.styles.scss";

const ConfirmEmail = () => {
    const navigate = useNavigate();

    const { search } = useLocation();
    const token = new URLSearchParams(search).get("token");

    const { data, isLoading } = useQuery(
        [KEYS.VERIFY_USER, token],
        () =>
            verifyUserAPI({
                queryParams: {
                    token,
                },
            }),
        {
            enabled: !!token,
        }
    );

    useEffect(() => {
        if (!data) return;

        if (!data.success) {
            toast.error(data.message || "Something went wrong");
        }
    }, [data]);

    const status = data && data.success;

    return (
        <Fragment>
            {status ? (
                <div className="flex h-full w-full justify-center items-center my-auto flex-col bg-[#F9F9F9] md:px-0 px-6">
                    <div className="flex justify-center items-center flex-col ">
                        <p className="text-[#0D0140] font-bold text-3xl mb-8">Successfully</p>
                        <img src="assets/images/success.svg" alt="logo" className="mb-8" />
                    </div>
                    <div className="w-full md:w-1/3">
                        <div className="flex flex-col items-center w-full px-8 ">
                            <button
                                className="bg-[#130160] w-full text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                type="button"
                                onClick={() => navigate("/")}
                            >
                                CONTINUE
                            </button>
                            <button
                                className="bg-[#D6CDFE] w-full mt-4  font-semibold py-2 px-4 rounded text-white focus:outline-none focus:shadow-outline"
                                type="button"
                                onClick={() => navigate("/login")}
                            >
                                BACK TO LOGIN
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="flex h-full w-full justify-center items-center my-auto flex-col bg-[#F9F9F9] md:px-0 px-6">
                    <div className="flex justify-center flex-col ">
                        <img src="assets/images/alert.svg" alt="logo" className="mb-8" />
                        <p className="text-red-500 font-bold text-3xl mb-8">ERROR!</p>
                    </div>
                    <div className="w-full md:w-1/3">
                        <div className="flex flex-col items-center w-full px-8 ">
                            <button
                                className="bg-red-500 w-full text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                type="button"
                                onClick={() => navigate("/login")}
                            >
                                Try Again
                            </button>
                            <button
                                className="bg-[#D6CDFE] w-full mt-4  font-semibold py-2 px-4 rounded text-white focus:outline-none focus:shadow-outline"
                                type="button"
                                onClick={() => navigate("/login")}
                            >
                                BACK TO LOGIN
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default ConfirmEmail;
