import React from "react";
import { useNavigate } from "react-router-dom";
import { LocateFixed } from "lucide-react";
import { useGetPromotedProjectsQuery } from "types/generated-types.ts";

const Feed = () => {
    const navigate = useNavigate();
    const { data, loading } = useGetPromotedProjectsQuery();

    if (loading) return <div>Loading...</div>;

    if (!data?.project_promotions || data?.project_promotions.length === 0) {
        return <div className="text-center py-10">No promoted projects found.</div>;
    }

    return (
        <div className="flex flex-wrap justify-center w-full py-10 md:py-20 h-full gap-4 ">
            {data?.project_promotions?.map((project, index) => (
                <div key={index} className=" shadow-md  border rounded w-full md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/3 p-4">
                    <div>
                        <img
                            src={
                                project?.project.project_profile_picture ||
                                "https://equippp-assets.s3.ap-south-1.amazonaws.com/images/no_project_image.jpeg"
                            }
                            alt="project"
                            className="w-full h-64 mb-2 object-cover rounded"
                        />
                    </div>
                    <p className="text-black font-semibold mb-2">{project?.project?.title}</p>
                    <p className="my-2">{project?.message}</p>
                    <div className="bg-slate-100  rounded mb-4  border  w-[70%] flex items-center p-2">
                        <div>
                            <LocateFixed className="text-blue-700 mr-2 w-8 h-8" />
                        </div>

                        <p className=" text-start">
                            <span className="text-black text-start">Location : </span>
                            {project?.project.location_title}
                        </p>
                    </div>
                    <div className="flex justify-end flex-col mt-auto items-end">
                        <button
                            class="bg-blue-500 hover:bg-blue-700 w-fit text-white font-bold py-2 px-4 text-sm rounded"
                            onClick={() => {
                                localStorage.setItem("projectDetails", JSON.stringify(data?.project_promotions[index]?.project));
                                navigate(`/projects/${project?.project.id}`, { replace: false });
                            }}
                        >
                            View Project
                        </button>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Feed;
