import React from "react";
function playVideo() {
    var cover = document.getElementById("cover");
    var video = document.getElementById("video");
    cover.style.display = "none"; // Hide the cover image
    video.style.display = "block"; // Show the video
    if (video.src.indexOf("autoplay=1") < 0) {
        // Ensure autoplay is in the URL
        video.src += "&autoplay=1";
    }
    // video.play(); // Attempt to play the video, note this might not work without user interaction
}
const ZaheerabadLandingPage2 = () => {
    return (
        <div className="w-full relative bg-white h-auto overflow-hidden text-left text-base text-colors-gray666 font-poppins py-6 px-14">
            <div className="flex w-full">
                <div className="flex flex-col w-[60%]">
                    <p className="text-lg text-[#1D2130] mb-4 tracking-wide font-bold">About BB Patil</p>
                    <p className="text-base text-[#525560] mb-4 leading-7 ">
                        Zaherabad's Member of Parliament (MP), BB Patil, is a dynamic leader who has a strong dedication to the well-being and
                        advancement of his community. Patil, who was nurtured in Zaherabad's colorful cultural and ethical fabric, has become a pillar
                        of the community's politics, standing up for the interests and goals of his constituents.
                    </p>
                    <p className="text-base text-[#525560]  leading-7">
                        Patil's political career started with grassroots activity, where he supported the rights of the poor and disenfranchised
                        segments of society. The individuals he serves have come to trust and admire him because of his unrelenting dedication and
                        hard efforts.
                    </p>
                    <p className="text-base text-[#525560]  leading-7">
                        Patil has a long history of legislative experience and public service as the elected MP representing Zaherabad. He has
                        continuously worked to promote progress and constructive change while upholding the moral principles and interests of his
                        constituents.
                    </p>
                    <p className="text-base text-[#525560]  leading-7">
                        Patil has made numerous contributions to Zaherabad. He has played a key role in the conception and execution of numerous
                        development initiatives meant to enhance the locals' access to healthcare, education, and employment prospects. His
                        inspirational leadership has steered Zaherabad toward development and wealth.
                    </p>
                </div>
                <div className="w-[30%] flex justify-end ml-10">
                    <img alt="Sridhar" src="/assets/images/bbpatil.png" className="object-contain" />
                </div>
            </div>

            <div className="flex flex-col mt-4">
                <p className="text-base text-[#525560] leading-7 mb-4 ">
                    Furthermore, Patil is renowned for being approachable and attentive to the issues raised by his residents. He keeps his doors
                    open, actively interacting with the community to learn about its needs and complaints while striving to find workable solutions.
                </p>
                <p className="text-base text-[#525560] leading-7 mb-4">
                    In conclusion, BB Patil's extraordinary commitment, inspiring leadership, and palpable contributions to the development of
                    Zaherabad confirm his standing as an esteemed and well-liked Member of Parliament in the Indian political system.
                </p>
            </div>

            <div className="relative w-full h-[448px] mt-16">
                <img
                    src="/assets/images/zahe-video.png"
                    alt="play"
                    id="cover"
                    className="absolute inset-0 w-full h-full  z-50 cursor-pointer"
                    onClick={playVideo}
                />
                <iframe
                    id="video"
                    className="absolute inset-0 w-full h-full object-fill rounded-2xl z-50"
                    src="https://www.youtube.com/embed/iiVwe-1vJb4"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ display: "none" }} // Correctly formatted style attribute for JSX
                ></iframe>
            </div>
            <div className="w-full absolute bottom-0 z-0 left-0 right-0 bg-[#FCEDC6] h-[353px]"></div>
        </div>
    );
};

export default ZaheerabadLandingPage2;
