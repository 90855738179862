import * as React from "react";
import { ChevronLeftIcon, ChevronRightIcon, DotsHorizontalIcon } from "@radix-ui/react-icons";

const cn = (...classes) => classes.filter(Boolean).join(" ");

const Pagination = ({ className, ...props }) => (
    <nav role="navigation" aria-label="pagination" className={cn("mx-auto flex w-full justify-center", className)} {...props} />
);

const PaginationContent = React.forwardRef(({ className, ...props }, ref) => (
    <ul ref={ref} className={cn("flex flex-row items-center gap-1", className)} {...props} />
));

const PaginationItem = React.forwardRef(({ className, ...props }, ref) => <li ref={ref} className={cn("", className)} {...props} />);

const PaginationLink = ({ className, isActive, size = "icon", ...props }) => (
    <a aria-current={isActive ? "page" : undefined} className={cn(className)} {...props} />
);

const PaginationPrevious = ({ className, hideText, ...props }) => (
    <PaginationLink aria-label="Go to previous page" className={cn("gap-1 pl-2.5", className)} {...props}>
        <ChevronLeftIcon className="h-4 w-4" />
        {!hideText && <span>Previous</span>}
    </PaginationLink>
);

const PaginationNext = ({ className, hideText, ...props }) => (
    <PaginationLink aria-label="Go to next page" className={cn("gap-1 pr-2.5", className)} {...props}>
        {!hideText && <span>Next</span>}
        <ChevronRightIcon className="h-4 w-4" />
    </PaginationLink>
);

const PaginationEllipsis = ({ className, ...props }) => (
    <span aria-hidden className={cn("flex h-9 w-9 items-center justify-center", className)} {...props}>
        <DotsHorizontalIcon className="h-4 w-4" />
        <span className="sr-only">More pages</span>
    </span>
);

export { Pagination, PaginationContent, PaginationLink, PaginationItem, PaginationPrevious, PaginationNext, PaginationEllipsis };
