import { Fragment, useState } from "react";
import { useUpdateCommentMutation } from "types/generated-types.ts";
import { Dialog, DialogTrigger, DialogContent } from "components/ui/dialog";
import { Pencil } from "lucide-react";
import { toast } from "react-toastify";

const UpdateComment = ({ comment, id, refetch }) => {
    const [updateComment, { loading }] = useUpdateCommentMutation({
        onCompleted: (data) => {
            if (data?.updateComment === "success") {
                toast.dismiss();

                toast.success("Comment updated successfully");

                refetch();
                isDialogClose();
            } else {
                toast.dismiss();
                toast.error(data?.updateComment ?? "Failed to update comment");
            }
        },
    });

    const [isOpen, setIsOpen] = useState(false);

    const isDialogOpen = () => {
        setIsOpen(true);
    };

    const isDialogClose = () => {
        setIsOpen(false);
    };

    const [newComment, setNewComment] = useState(comment);

    return (
        <div className="flex rounded  bg-[white] border-0 md:!border p-0 md:p-2 items-center">
            <Dialog isOpen={isOpen} onOpenChange={setIsOpen}>
                <DialogTrigger onClick={isDialogOpen} className="">
                    <div className="flex  cursor-pointer hover:text-blue-500">
                        <Pencil className="w-4 h-4 mr-2" />
                        <p className="text-xs hover:text-blue-500 hidden md:block">Edit Comment</p>
                    </div>
                </DialogTrigger>
                <DialogContent className="w-1/2">
                    <p>Edit Comment</p>

                    <Fragment>
                        <div className=" px-4 py-2 flex w-full items-center">
                            <input
                                type="text"
                                value={newComment}
                                onChange={(e) => setNewComment(e.target.value)}
                                className="w-full outline-none border px-4 py-2 mr-2 rounded"
                            />
                            <button
                                onClick={() => {
                                    {
                                        updateComment({
                                            variables: {
                                                commentId: id,
                                                comment: newComment,
                                            },
                                        });
                                        isDialogClose();
                                    }
                                }}
                                disabled={loading}
                                className="bg-blue-500 text-white px-4 py-2 rounded"
                            >
                                Update
                            </button>
                        </div>
                    </Fragment>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default UpdateComment;
