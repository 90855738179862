import React, { useState, Fragment, useContext } from "react";
import ConnectorIcon from "../../../assets/connector.png";
import UsersIcon from "../../../assets/users.png";
import AuthUserContext from "contexts/session";
import Avatar from "@mui/material/Avatar";
import ArrowIcon from "../../../assets/doubleArrow.png";
import { SettingsPhoneTwoTone } from "@mui/icons-material";
import {
    useGetInviteLinksGeneratedByIndividualUsersQuery,
    useGetUsersQuery,
    useGetUsersWhoJoinedWithoutInviteCountQuery,
    useGetUsersWhoJoinedWithoutInviteQuery,
    useGetTotalInvitesQuery,
} from "../../../types/generated-types.ts";
import {
    Pagination,
    PaginationContent,
    PaginationEllipsis,
    PaginationItem,
    PaginationLink,
    PaginationNext,
    PaginationPrevious,
} from "../../../components/ui/pagination";
import { set } from "date-fns";
import { Separator } from "../../../components/ui/separator";
import FlowPopup from "./components/flow-popup";

import { Table, TableBody, TableCaption, TableCell, TableFooter, TableHead, TableHeader, TableRow } from "../../../components/ui/table";

const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options).split("/").reverse().join(".");
};

const Analytics = () => {
    const { authUser } = useContext(AuthUserContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [show, setShow] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [flowOpen, setFlowOpen] = useState(false);
    const [showInitial, setShowInitial] = useState(false);

    const { data: invitesData } = useGetTotalInvitesQuery({});

    const { data: usersData, loading: usersDataLoading, error } = useGetUsersQuery();

    const { data: usersWhoJoinedWithoutInviteCount, loading: usersWhoJoinedWithoutInviteCountLoading } =
        useGetUsersWhoJoinedWithoutInviteCountQuery();

    const { data: inviteLinksGeneratedByIndividualUsers, loading: inviteLinksGeneratedByIndividualUsersLoading } =
        useGetInviteLinksGeneratedByIndividualUsersQuery();

    const loading = usersDataLoading || inviteLinksGeneratedByIndividualUsersLoading || usersWhoJoinedWithoutInviteCountLoading;

    const sortedUsers = usersData
        ? [...usersData.users].sort((a, b) => {
              if (a.role === "admin" && b.role !== "admin") {
                  return -1;
              } else if (a.role !== "admin" && b.role === "admin") {
                  return 1;
              }
              return 0;
          })
        : [];
    const itemsPerPage = 4;
    const pageCount = Math.ceil(sortedUsers.length / itemsPerPage);

    const currentUsers = sortedUsers.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    const users = usersData ? usersData.users : [];

    console.log({ usersData });

    const changePage = (page) => {
        if (page > 0 && page <= pageCount) {
            setCurrentPage(page);
        }
    };

    console.log({ usersData });

    const handleSelectedUser = (user) => {
        setShow(true);
        setShowInitial(!showInitial);
        setSelectedUser(user);
    };

    const handleFlowOpen = () => {
        setFlowOpen(!flowOpen);
    };
    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    console.log({ selectedUser });

    const selectedUserName = selectedUser?.user_name ? JSON.parse(selectedUser.user_type_details) : "no name";

    const inviteNumbers = 834;

    const totalEffectiveLoginsExcludingAdmin = users.reduce((acc, user) => {
        if (user.role === "admin") {
            return acc;
        }
        return acc + user.effective_users_count;
    }, 0);

    return (
        <div className="w-full  h-full">
            <div className="flex items-center h-fit  mb-3">
                <p className="text-base ">
                    Total users: <span className="font-semibold text-sm">{users.length}</span>
                </p>
                <div className="mx-4">|</div>
                <div className="flex items-center">
                    <div className="w-8 h-4 mr-2 bg-yellow-100"></div>
                    <p className="text-base ">
                        <span> - </span>individual user
                    </p>
                </div>
            </div>

            <div className="bg-white rounded-md shadow-md max-w-full overflow-x-scroll md:!overflow-x-hidden border p-4 mb-5">
                <div className="w-full min-w-[650px]">
                    <div className="flex  items-center gap-1 px-2 text-sm font-medium text-gray-500 m-0 ">
                        <div className="basis-1/6 py-2">User Name</div>
                        <div className="basis-1/6 text-center py-2">No of Invites</div>
                        <div className="basis-1/6 text-center py-2 ">No of Logins</div>

                        <div className="basis-1/6 text-center py-2">No of Effective Invites</div>
                        <div className="basis-1/6 text-center py-2">No of Effective Logins</div>
                        <div className="basis-1/6 py-2"></div>
                    </div>
                    <Separator className="mb-1" />

                    <Pagination className="w-full flex flex-col">
                        <PaginationContent className="w-full flex !flex-col">
                            {currentUsers.map((user, index) => {
                                const userTypeName = user.user_type_details ? JSON.parse(user.user_type_details) : {};
                                const userName = user.user_name || "No name";

                                const isAdmin = user.role === "admin";

                                let extraUsers = isAdmin ? usersWhoJoinedWithoutInviteCount?.users_aggregate?.aggregate?.count ?? 0 : 0;

                                if (extraUsers > 0) {
                                    // don't count current user
                                    extraUsers = extraUsers - 1;
                                }

                                const extraInviteLinks = isAdmin
                                    ? inviteLinksGeneratedByIndividualUsers?.invite_links_aggregate?.aggregate?.count ?? 0
                                    : 0;

                                // console.log({ userLinks: user.user });
                                return (
                                    <Fragment>
                                        <PaginationItem
                                            key={user.id}
                                            className={`flex gap-x-1  w-full items-center text-sm  ${
                                                !user.invited_by.length && !isAdmin ? "bg-yellow-100 rounded" : ""
                                            } px-2 `}
                                        >
                                            <div className="basis-1/6 py-2 text-xs sm:text:sm flex items-center justify-start ">
                                                <Avatar
                                                    variant="rounded"
                                                    src={user?.profile_picture}
                                                    alt="Profile Picture"
                                                    className="rounded-full mr-2"
                                                />
                                                {userName}
                                            </div>
                                            <div className="basis-1/6 py-2 flex relative items-center justify-center">
                                                <img src={UsersIcon} className="w-4 mr-2" alt="" />
                                                {user?.role === "admin"
                                                    ? invitesData?.invite_links_aggregate?.aggregate?.count + inviteNumbers
                                                    : user.total_invite_links + extraInviteLinks + extraUsers}

                                                <img src={ArrowIcon} className="w-4 mr-2 absolute -right-5" alt="" />
                                            </div>
                                            <div className="basis-1/6 py-3 flex items-center justify-center">
                                                <img src={UsersIcon} className="w-4 mr-2" alt="" />

                                                {/* {user.invite_links.filter((link) => link.invited_user !== null).length + extraUsers} */}
                                                {user.role === "admin" ? users.length : user.effective_users_count || 0}
                                            </div>

                                            <div className="basis-1/6 py-2 relative flex items-center justify-center">
                                                <img src={UsersIcon} className="w-4 mr-2" alt="" />
                                                {user.role === "admin" ? users.length : user.effective_users_count || 0}
                                                <img src={ArrowIcon} className="w-4 mr-2 absolute -right-5" alt="" />
                                            </div>
                                            <div className="basis-1/6 py-2 flex items-center justify-center">
                                                <img src={UsersIcon} className="w-4 mr-2" alt="" />
                                                {user.role === "admin" ? users.length : user.effective_users_count || 0}
                                            </div>
                                            <div className="basis-1/6 py-2 flex items-center justify-center text-blue-500 cursor-pointer">
                                                {/* <button onClick={() => setShow(!show)}> {show ? "Hide" : "View all"}</button> */}
                                                <button onClick={() => handleSelectedUser(user)}> View all</button>
                                            </div>
                                        </PaginationItem>
                                        <Separator />
                                    </Fragment>
                                );
                            })}
                            <div className="flex w-full mt-4 gap-1 justify-end items-center">
                                <p>
                                    {currentPage} - {Math.max(pageCount, 1)}
                                </p>
                                <PaginationPrevious
                                    hideText={true}
                                    className="cursor-pointer p-1 px-2 rounded-md flex justify-center items-center"
                                    onClick={() => changePage(currentPage - 1)}
                                    disabled={currentPage === 1}
                                />
                                <PaginationNext
                                    hideText={true}
                                    className="cursor-pointer p-1 px-2 rounded-md flex justify-center items-center"
                                    onClick={() => changePage(currentPage + 1)}
                                    disabled={currentPage >= Math.max(pageCount, 2)}
                                />
                            </div>
                        </PaginationContent>
                    </Pagination>
                </div>
            </div>
            {show && selectedUser && (
                <Fragment>
                    <div className={` ${show ? "w-full max-w-full overflow-x-scroll" : "hidden"}`}>
                        <div className="flex min-w-[850px] w-fit pb-12 justify-start items-start ">
                            <div className="bg-white  rounded-md mr-[80px] p-4 basis-4/12 shadow-md border ">
                                <p className="text-sm font-bold ">{capitalizeFirstLetter(selectedUser.role)}</p>
                                <div className="flex py-3 border-b justify-evenly text-gray-500 gap-8">
                                    <div className="basis-1/3 ">Name</div>
                                    <div className="basis-1/3 text-center">Date</div>
                                    <div className="basis-1/3 text-end ">Role</div>
                                </div>
                                <div
                                    className="flex py-3 relative border-b items-center cursor-pointer justify-evenly w-full gap-8"
                                    onClick={handleFlowOpen}
                                >
                                    <div className="basis-1/3 ">{selectedUser.user_name || "no name"}</div>
                                    <div className="basis-1/3 text-center">{formatDate(selectedUser.created_at)}</div>
                                    <div className="basis-1/3 text-center">{selectedUser.role}</div>

                                    {flowOpen && (
                                        <Fragment>
                                            <div className="absolute translate-y-1/2 -right-[calc(24px+40px)] translate-x-1/2">
                                                <img src={ConnectorIcon} className="w-16" alt="" />
                                            </div>
                                        </Fragment>
                                    )}
                                </div>
                            </div>

                            {flowOpen && <FlowPopup userId={selectedUser.id} isAdmin={selectedUser.role === "admin" ? true : false} />}
                        </div>
                    </div>
                </Fragment>
            )}
        </div>
    );
};

export default Analytics;
