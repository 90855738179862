import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../components/ui/select";
import { useUser } from "contexts/user-type/user-type-context.js";
const MemberOfParliament = () => {
    const navigate = useNavigate();
    const { state, setState, registerUser } = useUser();
    const { userType, mpState } = state;

    let { mpName, mpConstituency, mpContactDetails, mpPartyName, mpTenureDuration, mpGender } = mpState;

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            mpState: {
                ...prevState.mpState,
                [name]: value,
            },
        }));
    };

    const handleSelectChange = (value) => {
        setState((prevState) => ({
            ...prevState,
            mpState: {
                ...prevState.mpState,
                mpGender: value,
            },
        }));
    };

    const handleForwardClick = () => {
        if (!mpName || !mpConstituency || !mpContactDetails || !mpPartyName || !mpTenureDuration || !mpGender) {
            return alert("please fill all Member of Parliament userType details");
        }
        registerUser();
    };

    return (
        <div className="flex h-full w-full justify-center items-center my-auto flex-col bg-[#F9F9F9] md:px-0 px-6">
            <div className="flex justify-center flex-col mb-10 md:mb-4">
                <p className="text-[#0D0140] font-bold text-2xl md:text-3xl text-center">Create Account as {userType}</p>
                <p className="text-[#0D0140] text-center font-thin text-base mt-2">Please fill the below options to continue</p>
            </div>
            <form className="w-full md:w-1/3">
                {/* <div className="mb-4 ">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2" for="username">
                        Email
                    </label>
                    <input
                        className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
                        type="email"
                        placeholder="Brandonelouis@gmail.com"
                        disabled
                    />
                </div> */}
                <div className="mb-4 ">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2" for="username">
                        Full Name
                    </label>
                    <input
                        className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
                        id="username"
                        type="text"
                        name="mpName"
                        value={mpName}
                        onChange={handleInputChange}
                        placeholder="Brandone Louis"
                    />
                </div>
                <div className="mb-4 ">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2" for="username">
                        Gender
                    </label>
                    <Select className="modal-select-input" value={mpGender || ""} onValueChange={handleSelectChange}>
                        <SelectTrigger aria-label="User Type" className="border-0">
                            <SelectValue placeholder="Select Gender" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="female">Female</SelectItem>
                            <SelectItem value="male">Male</SelectItem>
                            <SelectItem value="other">Other</SelectItem>
                        </SelectContent>
                    </Select>
                </div>
                <div className="mb-4 ">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2" for="username">
                        Constituency
                    </label>
                    <input
                        className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
                        type="text"
                        name="mpConstituency"
                        value={mpConstituency}
                        onChange={handleInputChange}
                        placeholder="Constituency"
                    />
                </div>
                <div className="mb-4 ">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2" for="username">
                        Party Name
                    </label>
                    <input
                        className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
                        type="text"
                        name="mpPartyName"
                        value={mpPartyName}
                        onChange={handleInputChange}
                        placeholder="Party Name"
                    />
                </div>
                <div className="mb-4 ">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2">Contact</label>
                    <input
                        className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
                        type="text"
                        name="mpContactDetails"
                        value={mpContactDetails}
                        onChange={handleInputChange}
                        placeholder="Contact"
                    />
                </div>
                <div className="mb-4 ">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2">Tenure/Duration</label>
                    <input
                        className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
                        type="text"
                        name="mpTenureDuration"
                        value={mpTenureDuration}
                        onChange={handleInputChange}
                        placeholder="Tenure/Duration"
                    />
                </div>

                <div className="flex flex-col items-center w-full px-8 ">
                    <button
                        className="bg-[#130160] w-full text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="button"
                        onClick={handleForwardClick}
                    >
                        CONTINUE
                    </button>
                </div>
            </form>
        </div>
    );
};

export default MemberOfParliament;
