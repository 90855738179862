import { useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "config";
import Title from "../Title/Title";
import "./CreateAdminUser.scss";

const CreateAdminUser = () => {
    const [userRole, setUserRole] = useState("");
    const [userName, setUserName] = useState("");
    const [email, setEmail] = useState("");
    const [userType, setUserType] = useState("");
    const history = useNavigate();

    function ValidateEmail(mail) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
            return true;
        }
        return false;
    }

    const addAdmin = () => {
        if (userName === "") {
            alert("Please enter User Name");
            return;
        }
        if (email === "") {
            alert("Please enter E-Mail");
            return;
        }
        if (!ValidateEmail(email)) {
            alert("Please enter valid E-Mail Address");
            return;
        }
        if (userType === "") {
            alert("Please select User Type");
            return;
        }
        if (userRole === "") {
            alert("Please select User Role");
            return;
        }

        const reqBody = {
            emailId: email,
            userName: userName,
            userType: userType,
            role: userRole,
        };

        fetch(`${config.apiUrl}/api/admin/addAdminUser`, {
            method: "POST",
            body: JSON.stringify(reqBody),
            headers: {
                Authorization: window.localStorage.getItem("authToken"),
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.status === 1) {
                    alert(data.message);
                } else if (data.status === 0 && ["Invalid token.", "Token expired."].includes(data.message)) {
                    alert(data.message + " Please login again.");
                    window.localStorage.setItem("isValidToken", false);
                    window.localStorage.removeItem("authToken");
                    history("/admin");
                } else if (data.status === 0 && data.message === "User is exist with given email Id or userName.") {
                    alert("User already exists.");
                } else if (data.status === 0) {
                    alert("Something went wrong.");
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    return (
        <section>
            <Title title={"Create Admin User"} />
            <div className="createAdminUser">
                <div className="form-group">
                    <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                        <label for="userName">User Name *</label>
                        <input
                            className="form-control input-sm"
                            id="userName"
                            placeholder="Enter User Name"
                            name="userName"
                            onChange={(e) => setUserName(e.target.value)}
                            required
                        />
                    </div>
                </div>
                <br />
                <div className="form-group">
                    <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                        <label for="email">Email *</label>
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder="Enter EMail"
                            name="email"
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                </div>
                <br />
                <div className="form-group">
                    <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                        <label for="userType">User Type *</label>
                        <br />
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                id="csr"
                                value="csr"
                                name="userType"
                                onChange={(e) => setUserType(e.target.value)}
                            />
                            <label className="form-check-label" for="inlineCheckbox1">
                                CSR
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                id="individual"
                                value="individual"
                                name="userType"
                                onChange={(e) => setUserType(e.target.value)}
                            />
                            <label className="form-check-label" for="inlineCheckbox2">
                                Individual
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                id="others"
                                value="others"
                                name="userType"
                                onChange={(e) => setUserType(e.target.value)}
                            />
                            <label className="form-check-label" for="inlineCheckbox3">
                                Others
                            </label>
                        </div>
                    </div>
                </div>
                <br />
                <div className="form-group">
                    <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                        <label for="userRole">User Role *</label>
                        <br />
                        <div className="form-check form-check-inline">
                            <input
                                type="radio"
                                className="form-check-input"
                                id="admin"
                                name="userRole"
                                value="Admin"
                                onChange={(e) => setUserRole(e.target.value)}
                            />
                            <label className="form-check-label" for="admin">
                                Admin
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                type="radio"
                                className="form-check-input"
                                id="sme"
                                name="userRole"
                                value="SME"
                                onChange={(e) => setUserRole(e.target.value)}
                            />
                            <label className="form-check-label" for="sme">
                                SME
                            </label>
                        </div>
                    </div>
                </div>
                <button type="submit" className="btn btn-primary mt-3" onClick={addAdmin}>
                    Create
                </button>
            </div>
        </section>
    );
};

export default CreateAdminUser;
