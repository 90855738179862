import React, { useState, useEffect } from "react";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { validateEmail, validatePassword } from "utils";
import { useGetUsersQuery } from "../../../../types/generated-types.ts";
import { RegisterHeader } from "../Header/index.jsx";
import RegisterModalFooter from "./Components/RegisterModalFooter/RegisterModalFooter";
import "./RegisterModalContent.styles.scss";

const RegisterModalContent = (props) => {
    let { email, password, reEnterPassword, setState } = props;

    const [emailError, setEmailError] = useState(() => "");

    const [passwordError, setPasswordError] = useState(() => "");

    const [reEnterPasswordError, setReRenterPasswordError] = useState(() => "");

    const [acceptTerms, setAcceptTerms] = useState(false);

    const [showPassword, setShowPassword] = useState(false);

    const [users, setUsers] = useState();

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleChange = (e) => {
        setState((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };

    const { data: usersData } = useGetUsersQuery();
    useEffect(() => {
        if (usersData) {
            setUsers(usersData.users);
        }
    }, [usersData]);

    const handleClick = (e) => {
        e.preventDefault();

        //check existing email
        if (users.filter((item) => item.email_id.toLowerCase() === email.toLowerCase()).length > 0) {
            setEmailError("User With this Email Already Exists");
            return;
        }

        // email field verification
        const checkEmail = validateEmail(email);
        if (!checkEmail.status) {
            setEmailError(checkEmail.message);
            return;
        }

        // password field verification
        const checkPassword = validatePassword(password, reEnterPassword);
        if (!checkPassword.status) {
            checkPassword.field === "password" ? setPasswordError(checkPassword.message) : setReRenterPasswordError(checkPassword.message);
            return;
        }

        if (!acceptTerms) {
            return alert("please accept terms and conditions");
        }

        props.setOpen("usertype");
    };

    /**
     * Register a new user in the system. The user only needs to put email and password.
     * @param {object} event event object of the submitted form
     * @author DK
     */

    return (
        <Grid container className="register-modal-content__container">
            <RegisterHeader {...props} />
            <Grid className="user-register-form" container alignItems="center" justifyContent="center" direction="column">
                <p className="title-label">START FOR FREE</p>
                <h3 className="title">Sign up to Equippp</h3>
                <form>
                    <Grid item xs={12} className="field__grid" container direction="column" justifyContent="center" alignItems="center">
                        <TextField
                            variant="standard"
                            id="email"
                            type="text"
                            name="email"
                            label="Email"
                            className="input__field"
                            helperText={emailError && <small className="text-danger">{emailError}</small>}
                            value={email}
                            onChange={handleChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                                            <img src="assets/images/user.png" alt="" />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <TextField
                            id="password"
                            variant="standard"
                            label="Password"
                            type={showPassword ? "text" : "password"}
                            name="password"
                            className="input__field"
                            helperText={passwordError && <small className="text-danger">{passwordError}</small>}
                            value={password}
                            onChange={handleChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                                            {showPassword ? <LockOpenOutlinedIcon /> : <LockOutlinedIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <TextField
                            type={showPassword ? "text" : "password"}
                            id="confirm-password"
                            label="Confirm Password"
                            variant="standard"
                            className="input__field"
                            name="reEnterPassword"
                            helperText={reEnterPasswordError && <small className="text-danger">{reEnterPasswordError}</small>}
                            value={reEnterPassword}
                            onChange={handleChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                                            {showPassword ? <LockOpenOutlinedIcon /> : <LockOutlinedIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid container className="checkbox__container">
                        <p>
                            <Checkbox className="checkbox" size="small" /> Yes, I want to receive Latest Updates to email.
                        </p>
                        <p>
                            <Checkbox
                                className="checkbox"
                                size="small"
                                value={acceptTerms}
                                onChange={(e) => {
                                    setAcceptTerms(e.target.checked);
                                }}
                            />{" "}
                            I agree to all the Term, Privacy Policy and Fees.
                        </p>
                    </Grid>
                    <Grid item xs={12} container direction="row" justifyContent="flex-end" alignItems="center" className="button__container">
                        <Button className="continue__btn" type="button" onClick={handleClick}>
                            Save and continue
                        </Button>
                    </Grid>
                </form>
                <RegisterModalFooter />
            </Grid>
        </Grid>
    );
};

export default RegisterModalContent;
