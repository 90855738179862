import React, { Fragment, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import useEmblaCarousel from "embla-carousel-react";
import "swiper/swiper-bundle.css";

import CustomFooter from "components/CustomFooter";
import { useNavigate } from "react-router-dom";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Navigation, Pagination, EffectCoverflow } from "swiper";
import { ChevronLeft } from "lucide-react";
import { ChevronRight } from "@mui/icons-material";
import { ChevronRightCircle } from "lucide-react";
import { ChevronLeftCircle } from "lucide-react";
import { ArrowLeft } from "lucide-react";
import { ArrowRight } from "lucide-react";
import EmblaCarousel from "./embla-carousel";

const OPTIONS = { loop: true };
const SLIDE_COUNT = 5;
const SLIDES = Array.from(Array(SLIDE_COUNT).keys());

const Carousel = () => {
    return <EmblaCarousel options={OPTIONS} />;
};

function playVideo() {
    var cover = document.getElementById("cover");
    var video = document.getElementById("video");
    cover.style.display = "none"; // Hide the cover image
    video.style.display = "block"; // Show the video
    if (video.src.indexOf("autoplay=1") < 0) {
        // Ensure autoplay is in the URL
        video.src += "&autoplay=1";
    }
    // video.play(); // Attempt to play the video, note this might not work without user interaction
}

const ZaheerabadLandingPage = () => {
    const navigate = useNavigate();

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [scrollSnaps, setScrollSnaps] = useState([]);
    const [emblaRef, embla] = useEmblaCarousel({ loop: true });
    const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
    const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

    const scrollPrev = () => embla && embla.scrollPrev();
    const scrollNext = () => embla && embla.scrollNext();
    const scrollTo = (index) => embla && embla.scrollTo(index);

    // Update button states based on the current slide
    useEffect(() => {
        if (!embla) return;
        const onSelect = () => {
            setSelectedIndex(embla.selectedScrollSnap());
            setPrevBtnDisabled(!embla.canScrollPrev());
            setNextBtnDisabled(!embla.canScrollNext());
        };
        embla.on("select", onSelect);
        onSelect();
        setScrollSnaps(embla.scrollSnapList());
    }, [embla]);
    return (
        <div className="w-full relative bg-white h-auto overflow-hidden text-left text-base text-colors-gray666 font-poppins py-4 px-14">
            <main className="flex justify-between items-center  bg-yellow-400 bg-opacity-100 w-full rounded-2xl px-12 py-16">
                <div className="w-1/2">
                    <div className="text-justify md:text-left ">
                        <h1 className="text-4xl text-left font-bold mb-4 text-[#1C2B52]">Bheemarao Basawanthrao Patil</h1>
                        <p className="text-base text-black1 opacity-60 leading-7 ">
                            Zaherabad's Member of Parliament (MP), BB Patil, is a dynamic leader who has a strong dedication to the well-being and
                            advancement of his community. Patil, who was nurtured in Zaherabad's colorful cultural and ethical fabric, has become a
                            pillar of the community's politics, standing up for the interests and goals of his constituents. Patil's political career
                            started with grassroots activity, where he supported the rights of the poor and disenfranchised segments of society. The
                            individuals he serves have come to trust and admire him because of his unrelenting dedication and hard efforts.
                        </p>
                        <div className=" mt-4">
                            <button
                                className="py-3 px-4 cursor-pointer bg-white rounded flex flex-row items-center justify-center"
                                onClick={() => navigate("/about-bheemarao-basawanthrao-patil")}
                            >
                                <div className="text-base font-medium text-[#1D2130]">Read more</div>
                            </button>
                        </div>
                    </div>
                </div>
                {/* Right Column (Image) */}
                <div className="w-[500px] h-[448px]">
                    <img className="w-full h-full object-fill rounded-2xl" alt="Sridhar" src="/assets/images/bbpatil.jpg" />
                </div>
            </main>

            <div className="w-full mt-20 mb-20">
                <div className="flex items-center mb-4">
                    <p className="text-4xl font-medium text-[#1D2130]  mr-4 mb-2">Overview</p>
                    <hr className="border w-full border-gray-500" />
                </div>

                <div className="flex justify-between w-full items-center">
                    <p className="text-[#666666] text-base leading-7 w-1/2">
                        Bheemarao Basavantarao Patil is a prominent politician and member of the 17th Lok Sabha representing Zahirabad, Telangana.
                        Initially elected under the Telangana Rashtra Samithi banner in 2014 and 2019, he joined the Bharatiya Janata Party on March
                        1, 2024. Notable for his influence in the region, he has been nominated to contest the 2024 general elections from the same
                        constituency. Beyond politics, Patil owns the Patil Group of Companies, involved in both government and private constructions.
                        He is also a member of the Constitution Club. He was born to Baswanthrao Patil and Hanmabai Patil in Sirpoor on 01st November
                        1955. On a personal note, he is married to Aruna B. Patil and has two sons. His net worth is Rs 1,28,78,51,556.
                    </p>
                    <div className="w-1/2 flex justify-end ">
                        <img className="w-[419px] h-[342px]" alt="map" src="/assets/images/zaheerabadmap.jpeg" />
                    </div>
                </div>
            </div>

            <Carousel></Carousel>

            <div className="mt-20 ">
                <div className="flex items-center  mb-4">
                    <img src="/assets/images/line.png" alt="video" className="w-20 mr-4" />
                    <p className="text-sm font-medium text-[#1D2130]  mr-4 ">VIDEO</p>
                </div>
                <div className="relative w-full h-[583px]">
                    <img
                        src="/assets/images/zahe-video.png"
                        alt="play"
                        id="cover"
                        className="absolute inset-0 w-full h-full  z-50 cursor-pointer"
                        onClick={playVideo}
                    />
                    <iframe
                        id="video"
                        className="absolute inset-0 w-full h-full object-fill rounded-2xl z-50"
                        src="https://www.youtube.com/embed/iiVwe-1vJb4"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        style={{ display: "none" }} // Correctly formatted style attribute for JSX
                    ></iframe>
                </div>
            </div>
            <div className="w-full absolute bottom-0 z-0 left-0 bg-[#FCEDC6] h-[353px]"></div>

            {/* <div>
                <CustomFooter />
            </div> */}
        </div>
    );
};

export default ZaheerabadLandingPage;
