import React, { Fragment, useContext, useEffect } from "react";
import moment from "moment";
import ArrowRight from "../../../../assets/arrow-right.png";
import ArrowRightBlue from "../../../../assets/arrow-right--blue.png";
import { Avatar } from "@mui/material";
import AuthUserContext from "contexts/session";
import { Pencil, Ellipsis } from "lucide-react";
import { Popover, PopoverContent, PopoverTrigger } from "../../../../components/ui/popover";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogOverlay, DialogContent, DialogTrigger } from "../../../../components/ui/dialog";
import Project from "views/Project";
import ProfileAnalytics from "../profile-analytics";
import { useGetCurrentUserLazyQuery } from "types/generated-types.ts";

const formatNumber = (number) => {
    return new Intl.NumberFormat("en-IN", {
        maximumFractionDigits: 2,
        style: "decimal",
    }).format(number);
};

const EditAndMapButtons = ({ isAdmin, date, handleEditProject, id }) => {
    const navigate = useNavigate();
    return (
        <Fragment>
            <div className="hidden md:!block">
                {isAdmin ? (
                    <div className="flex  items-center gap-2 ">
                        <button className="text-xs border rounded-lg p-2 hover:bg-slate-200 hover:text-black" onClick={handleEditProject}>
                            Edit Project Details
                        </button>
                        <p className="text-xs text-gray-400">{moment(date).format("MMM DD, YYYY")}</p>
                    </div>
                ) : (
                    <p className="text-xs  text-gray-400">{moment(date).format("MMM DD, YYYY")}</p>
                )}
            </div>
            <div className="flex items-start justify-end w-full md:!hidden">
                <div className="flex items-center gap-2">
                    <p
                        className="text-xs text-black/70 border px-2  py-1 rounded-lg"
                        onClick={() => {
                            navigate(`/projects/${id}`);
                        }}
                    >
                        View Project
                    </p>

                    <Popover>
                        <PopoverTrigger>
                            <Ellipsis className="w-6 h-6 text-black/90 " />
                        </PopoverTrigger>
                        <PopoverContent>
                            {isAdmin && (
                                <p onClick={handleEditProject} className="text-xs mb-2">
                                    Edit Project
                                </p>
                            )}
                            <Dialog>
                                <DialogTrigger className="text-xs">View Analytics</DialogTrigger>
                                <DialogContent className="bg-transparent border-0">
                                    <ProfileAnalytics chartIdPrefix={"mobile"} currentProject={id} />
                                </DialogContent>
                            </Dialog>
                        </PopoverContent>
                    </Popover>
                </div>

                {/* {isAdmin ? (
                    <div className="flex md:hidden items-center gap-2 ">
                        

                        <p className="text-xs text-gray-400">{moment(date).format("MMM DD, YYYY")}</p>
                    </div>
                ) : ( */}

                {/* )} */}
            </div>
        </Fragment>
    );
};

const ProjectItem = ({
    type,
    title,
    isAdmin,
    date,
    navigate,
    id,
    location,
    profilePicture,
    estimatedAmount,
    category,
    secondaryCategory,
    expressionAggregate,
    comments,
    expressions,
    isCommentedUser,
    status,
    project,
    userProfilePicture,
    projectStatusFilter,
}) => {
    const { authUser } = useContext(AuthUserContext);

    const [getUsers, { data, loading: isLoading }] = useGetCurrentUserLazyQuery();

    useEffect(() => {
        if (id) {
            getUsers({
                variables: {
                    id: authUser.id,
                },
            });
        }
    }, [id, getUsers]);

    console.log({ data });

    const verifiedExpressions = expressions
        .filter((expression) => expression.is_verified && expression.user.id === authUser.id)
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        .slice(0, 1)?.[0];

    const verifiedComments = comments.filter((comment) => comment.is_verified);

    const categories = JSON.parse(category ?? []) ?? [category];
    const types = JSON.parse(type ?? []) ?? [type];

    const handleEditProject = () => {
        navigate(`/admin/edit-project/${id}`);
    };

    return (
        <div className="group hover:bg-[#f3f3f3]/40 ">
            <div className="rounded-md flex md:flex-row flex-col items-center md:gap-4  w-full p-2">
                <div className="md:block hidden rounded-lg w-[190px] h-[112px]">
                    <img
                        src={!profilePicture ? "https://equippp-assets.s3.ap-south-1.amazonaws.com/images/no_project_image.jpeg" : profilePicture}
                        className="w-full h-full rounded-lg object-fill"
                        alt=""
                    />
                </div>
                <div className="flex md:hidden w-full mb-1">
                    <img
                        src={!profilePicture ? "https://equippp-assets.s3.ap-south-1.amazonaws.com/images/no_project_image.jpeg" : profilePicture}
                        className="w-32 h-20 rounded-md object-fill"
                        alt=""
                    />

                    <EditAndMapButtons isAdmin={isAdmin} date={date} handleEditProject={handleEditProject} id={id} />
                </div>

                <div className="w-full flex flex-col  justify-between relative">
                    <div>
                        <div className="flex w-full mb-2 items-center justify-between">
                            <p className="font-medium text-[12px] md:text-[15px] text-black md:text-base">
                                {title?.length > 55 ? title?.slice(0, 55) + "..." : title}
                            </p>
                            <div className="md:!block hidden">
                                <EditAndMapButtons isAdmin={isAdmin} date={date} handleEditProject={handleEditProject} id={id} />
                            </div>
                        </div>
                        <p className="flex items-center mb-2 text-xs md:text-[12px] md:text-sm ">
                            <img src="assets/images/location-black.png" alt="" className="w-[14px] h-[15px] mr-1" />
                            {location}
                        </p>
                        <div className="flex ">
                            <p className="text-xs md:text-[12px] text-black/79 border-r-2 border-orange-500  pr-2">
                                <span className="text-black font-medium">Project Category : </span>
                                {categories.map((type, index) => (
                                    <Fragment key={index}>
                                        {type} {index < categories.length - 1 ? ", " : ""}
                                    </Fragment>
                                ))}
                                {secondaryCategory && "/"} {secondaryCategory?.replace(/[\[\]"]+/g, "").replace(/,/g, " / ")}
                            </p>

                            <p className="text-xs md:text-[12px] text-black/79 pl-2">
                                <span className="text-black font-medium">Project Type : </span>

                                {types.map((type, index) => (
                                    <Fragment key={index}>
                                        {type} {index < types.length - 1 ? ", " : ""}
                                    </Fragment>
                                ))}
                            </p>
                        </div>
                    </div>
                    <div className="hidden md:flex items-center justify-between mt-2">
                        <p className="md:text-lg text-xs md:text-[20px] text-black font-medium gap-2 flex items-center">
                            {status === "completed" ? (
                                <Fragment>
                                    ₹{formatNumber(estimatedAmount)}/ {formatNumber(estimatedAmount)}
                                    <span className="text-xs text-black/70">- Executed</span>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    ₹{formatNumber(expressionAggregate)}/ {formatNumber(estimatedAmount)}
                                    <span className=" text-xs md:text-sm">( Received )</span>
                                    <span className="text-xs text-blue-600">- Inprogress</span>
                                </Fragment>
                            )}

                            {/* <span className="text-red-400 text-xs ">(Budget)</span> */}
                        </p>

                        <p
                            className="text-xs  text-gray-500 font-medium hover:text-blue-500 gap-1 hidden md:flex items-center"
                            onClick={() => {
                                navigate(`/projects/${id}`);
                            }}
                        >
                            View Project Details <img src={ArrowRight} alt="" className="h-2 group-hover:hidden" />
                            <img src={ArrowRightBlue} alt="" className="h-2 group-hover:block hidden" />
                        </p>
                    </div>
                </div>
            </div>
            {!isAdmin && (
                <div className="flex justify-between md:gap-8 gap-4 items-centers w-full px-2 md:py-4  py-2">
                    {verifiedComments.length > 0 ? (
                        <Fragment>
                            <div className="flex w-full ">
                                <Avatar src={data?.users_by_pk?.profile_picture} className="w-8 h-8 mr-4" />
                                <div className="flex flex-col w-full">
                                    <div className="flex justify-between ">
                                        <p className="text-xs">Your Comment</p>
                                        <p className="text-xs text-black/50">{moment(verifiedComments[0].created_at).format("MMM DD")}</p>
                                    </div>
                                    <div className="mt-2 ">
                                        <p className="text-xs text-black/70 w-full">{verifiedComments[0].comment}</p>
                                    </div>
                                    {!!verifiedComments[0].replies.length && (
                                        <div className="flex items-center mt-2">
                                            {/* <MessageSquareWarning className="w-4 h-4 mr-2 text-black/60" /> */}
                                            <p className="text-xs  text-black/60">Admin has replied to this comment</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <div className="flex w-full ">
                                <Avatar src={data?.users_by_pk?.profile_picture} className="w-8 h-8 mr-4" />
                                <div className="flex flex-col w-full">
                                    <p className="text-xs">Your Comment</p>

                                    <p className="text-xs text-black/70 w-full mt-2">No Comments Yet</p>
                                </div>
                            </div>
                        </Fragment>
                    )}

                    {verifiedExpressions ? (
                        <div className="flex w-full ">
                            <Avatar src={data?.users_by_pk?.profile_picture} className="w-8 h-8 mr-4" />
                            <div className="flex flex-col w-full">
                                <div className="flex justify-between">
                                    <p className="text-xs">Your Expression</p>
                                    <p className="text-xs text-black/50">{moment(verifiedExpressions.created_at).format("MMM DD")}</p>
                                </div>

                                <div className="mt-2">
                                    <p className="text-xs text-black/70 w-full">{verifiedExpressions.investment_amount}</p>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="flex w-full ">
                            <Avatar src={data?.users_by_pk?.profile_picture} className="w-8 h-8 mr-4" />
                            <div className="flex flex-col w-full">
                                <p className="text-xs">Your Expression</p>

                                <p className="text-xs text-black/70 w-full mt-2">No Expressions Yet</p>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default ProjectItem;
