import { Fragment } from "react";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import verified from "../../../../../assets/user-verified.png";
import "./RecentActivity.styles.scss";

const RecentActivity = ({ comments, expressions }) => {
    const monthNames = [
        "empty",
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    return (
        <Grid item xs={12} container alignItems="flex-start" className="recent-activity__container">
            <Grid item xs={12} container>
                <Typography variant="h6" className="recent-activity__title">
                    Recent Activity
                </Typography>
            </Grid>
            {comments?.length > 0 &&
                comments.map((comment, index) => {
                    return (
                        <Fragment key={index}>
                            <Divider className="divider" />
                            <Grid item xs={12} container className="recent-activity__item">
                                <Grid item xs={2} container alignItems="center" justify="space-between">
                                    <img src="https://equippp-assets.s3.ap-south-1.amazonaws.com/images/ok-sign.png" alt="" className="ok-sign" />
                                </Grid>
                                <Grid item xs={9} container direction="column" className="info-item">
                                    {/* <Typography className="activity-date">
                                        {comment?.created_at?.substr(8, 2)} {monthNames[comment?.created_at?.substr(5, 2)]}
                                    </Typography> */}
                                    <Typography className="activity-info">
                                        <span className="font-bold">{comment?.user?.user_name}</span>
                                        {comment?.user?.kyc_status === "accepted" && (
                                            <Tooltip title="Verified" arrow placement="top">
                                                <img src={verified} alt="" width={"20px"} height={"20px"} style={{ marginLeft: "2px" }} />
                                            </Tooltip>
                                        )}{" "}
                                        commented on the project <span className="font-bold">{comment?.project?.title}</span>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Fragment>
                    );
                })}
            {expressions?.length > 0 &&
                expressions.map((expression, index) => {
                    return (
                        <Fragment key={index}>
                            <Divider className="divider" />
                            <Grid item xs={12} container className="recent-activity__item">
                                <Grid item xs={2} container alignItems="center" justify="space-between">
                                    <img src="https://equippp-assets.s3.ap-south-1.amazonaws.com/images/ok-sign.png" alt="" className="ok-sign" />
                                </Grid>
                                <Grid item xs={9} container direction="column" className="info-item">
                                    {/* <Typography className="activity-date">
                                        {expression?.created_at?.substr(8, 2)} {monthNames[expression?.created_at?.substr(5, 2)]}
                                    </Typography> */}
                                    <Typography className="activity-info">
                                        {expression?.user?.user_name}
                                        {expression?.user?.kyc_status === "accepted" && (
                                            <Tooltip title="Verified" arrow placement="top">
                                                <img src={verified} alt="" width={"20px"} height={"20px"} style={{ marginLeft: "2px" }} />
                                            </Tooltip>
                                        )}{" "}
                                        participated in the project {expression?.project?.title}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Fragment>
                    );
                })}
            {comments?.length === 0 && expressions?.length === 0 && (
                <Grid item xs={12} container className="recent-activity__item">
                    <Typography className="activity-date">No Recent Activity</Typography>
                </Grid>
            )}
        </Grid>
    );
};

export default RecentActivity;
