// router
import { QueryClientProvider } from "react-query";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { UserProvider } from "contexts/user-type/user-type-context";
import { ApolloProvider } from "@apollo/client";
import ContactUs from "components/ContactUs";
import Gallery from "components/Gallery";
import MainLayout from "components/MainLayout";
import ResetPassword from "components/ResetPassword";
import SocialPartners from "components/SocialPartners";
import ConfirmEmail from "views/ConfirmEmail";
import Faqs from "views/Faqs/Faqs";
import PrivacyPolicy from "views/PrivacyPolicy/PrivacyPolicy";
import Profile from "views/Profile/Profile";
import ProjectComment from "views/Profile/ProjectComment";
import ProjectLinked from "views/Profile/ProjectLinked";
import ProjectPromote from "views/Profile/ProjectPromote";
import ProfileDetails from "views/ProfileDetails";
import CreateProject from "views/Project/CreateProject";
import Project from "views/Project/";
import ProjectPrintReport from "views/ProjectReport/ProjectPrintReport";
import PressRelease from "views/Projects/components/PressRelease";
import ResetPasswordVerification from "views/ResetPasswordVerification/ResetPasswordVerification";
import TermsofUse from "views/TermsofUse/TermsofUse";
import VerifyEmail from "views/VerifyEmail";
import ROUTES from "constants/routes";
import theme from "constants/theme";
import AdminDashboard from "admin/components/AdminDashboard/AdminDashboard";
import ChangeUserRole from "admin/components/ChangeUserRole/ChangeUserRole";
import CreateAdminUser from "admin/components/CreateAdminUser/CreateAdminUser";
import CreateUser from "admin/components/CreateUser/CreateUser";
import Dashboard from "admin/components/Dashboard/Dashboard";
import ProjectComments from "admin/components/ProjectComments/ProjectComments";
import UsersKycDashboard from "admin/components/UsersKycDashboard/UsersKycDashboard";
import ViewAllProjects from "admin/components/ViewAllProjects/ViewAllProjects";
import HomeAdmin from "./admin/HomeAdmin";
import LoginPage from "./admin/LoginPage";
import client from "./apollo";
import { withAuthUserProvider } from "./contexts/session";
import queryClient from "./queryClient";
import Feed from "./views/Feed";
import Home from "./views/Home/Home";
import Projects from "./views/Projects";
import MobileLogin from "./views/MobileLogin";
import SplashScreen from "./views/SplashScreen";
import MobileSignUp from "./views/MobileSignUp";
import ForgotPassword from "./views/ForgotPassword";
import CheckEmail from "./views/CheckEmail";
import SelectUserType from "./views/SelectUserType";
import {
    CsrFoundation,
    Individual,
    MemberOfLegislativeAssembly,
    MemberOfParliament,
    NonProfitOrganization,
    ProBono,
    PublicSectorBank,
    SubjectMatterExpert,
    Students,
    Others,
} from "./views/user-type-items";
import InviteUsers from "admin/components/invite-users/invite-users";
import InvitePage from "views/invite-page";
import Analytics from "admin/components/Analytics/Analytics";
import VerifyOtp from "views/verify-otp";
import OtpResetPassword from "views/otp-reset-password";
import MyActivity from "views/my-activity";
import "@radix-ui/themes/styles.css";
import { Theme } from "@radix-ui/themes";
import EditProject from "views/Project/edit-project";
import LandingPage from "views/landingPage/LandingPage";
import LandingPage2 from "views/landingPage/LandingPage2";
import ZaheerabadLandingPage from "views/zaheerabad-landing-page/ZaheerabadLanding";
import ZaheerabadLandingPage2 from "views/zaheerabad-landing-page/ZaheerabadLanding2";
import PemmasaniLandingPage from "views/pemmasaniLandingPage/PemmasaniLanding";
import PemmasaniLandingPage2 from "views/pemmasaniLandingPage/PemmsaniLanding2";
import SuggestProject, { District } from "views/suggest-project";
// import LandingPage2 from "views/landingPage/LandingPage2";

var hostname = window.location.hostname;
console.log("hostname", hostname);
const MainRouter = withAuthUserProvider(() => {
    return (
        <MainLayout>
            <Routes>
                {hostname === "zaheerabad.equippp.global" && <Route path={ROUTES.HOME} element={<ZaheerabadLandingPage />} />}
                {hostname === "pemmasani.equippp.global" && <Route path={ROUTES.HOME} element={<PemmasaniLandingPage />} />}
                {hostname === "manthani.equippp.global" && <Route path={ROUTES.HOME} element={<LandingPage />} />}
                {(hostname === "www.equippp.global" || hostname === "localhost") && <Route path={ROUTES.HOME} element={<Home />} />}
                <Route path={ROUTES.ABOUT_MANTHANI} element={<LandingPage2 />} />
                <Route path={ROUTES.ABOUT_ZAHEERABAD} element={<ZaheerabadLandingPage2 />} />
                <Route path={ROUTES.ABOUT_PEMMASANI} element={<PemmasaniLandingPage2 />} />
                <Route path={ROUTES.PROJECTS} element={<Projects />} />
                <Route path={ROUTES.SUGGEST_PROJECT} element={<SuggestProject />} />
                <Route path={ROUTES.PROJECT_DETAILS} element={<Project />} />
                <Route path={ROUTES.PROJECT_REPORT} element={<ProjectPrintReport />} />
                <Route path={ROUTES.PROFILE} element={<Profile />} />
                <Route path={ROUTES.CREATE_PROJECT} element={<CreateProject />} />
                <Route path={ROUTES.ADMIN_EDIT_PROJECT} element={<EditProject />} />
                <Route path={ROUTES.PROFILE_DETAILS} element={<ProfileDetails />} />
                <Route path={ROUTES.PROJECT_LINKED} element={<ProjectLinked />} />
                <Route path={ROUTES.PROJECT_COMMENTED} element={<ProjectPromote />} />
                <Route path={ROUTES.PROJECT_PROMOTED} element={<ProjectComment />} />
                <Route path={ROUTES.RESET_VERIFY} element={<ResetPasswordVerification isAdmin={false} />} />
                <Route path={ROUTES.CONFIRM_EMAIL} element={<ConfirmEmail />} />
                <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
                <Route path={ROUTES.PRESS_RELEASE} element={<PressRelease />} />
                <Route path={ROUTES.FEED} element={<Feed />} />
                <Route path={ROUTES.VERIFY_EMAIL} element={<VerifyEmail />} />
                <Route path={ROUTES.MOBILE_LOGIN} element={<MobileLogin />} />
                <Route path={ROUTES.MOBILE_SIGNUP} element={<MobileSignUp />} />
                <Route path={ROUTES.CHECK_EMAIL} element={<CheckEmail />} />
                <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
                <Route path={ROUTES.SELECT_USER_TYPE} element={<SelectUserType />} />
                <Route path={ROUTES.SPLASH_SCREEN} element={<SplashScreen />} />
                <Route path={ROUTES.CSR_FOUNDATION} element={<CsrFoundation />} />
                <Route path={ROUTES.INDIVIDUAL} element={<Individual />} />
                <Route path={ROUTES.MEMBER_OF_LEGISLATIVE_ASSEMBLY} element={<MemberOfLegislativeAssembly />} />
                <Route path={ROUTES.MEMBER_OF_PARLIAMENT} element={<MemberOfParliament />} />
                <Route path={ROUTES.NON_PROFIT_ORGANIZATION} element={<NonProfitOrganization />} />
                <Route path={ROUTES.PRO_BONO} element={<ProBono />} />
                <Route path={ROUTES.PUBLIC_SECTOR_BANK} element={<PublicSectorBank />} />
                <Route path={ROUTES.SUBJECT_MATTER_EXPERT} element={<SubjectMatterExpert />} />
                <Route path={ROUTES.STUDENTS} element={<Students />} />
                <Route path={ROUTES.OTHERS} element={<Others />} />
                <Route path={ROUTES.INVITE_PAGE} element={<InvitePage />} />
                <Route path={ROUTES.VERIFY_OTP} element={<VerifyOtp />} />
                <Route path={ROUTES.OTP_RESET_PASSWORD} element={<OtpResetPassword />} />
                <Route path={ROUTES.MY_ACTIVITY} element={<MyActivity />} />
                <Route path={ROUTES.DISTRICT} element={<District />} />
                {/* admin */}
                <Route path={ROUTES.ADMIN} element={<LoginPage />} />
                <Route path={ROUTES.ADMIN_BACKEND} element={<HomeAdmin />}>
                    <Route path={ROUTES.ADMIN_DASHBOARD} element={<Dashboard />} />
                    <Route path={ROUTES.ADMIN_ANALYTICS} element={<Analytics />} />
                    <Route path={ROUTES.ADMIN_CREATE_PROJECT} element={<CreateProject />} />
                    <Route path={ROUTES.ADMIN_EXPRESSIONS} element={<AdminDashboard />} />
                    <Route path={ROUTES.ADMIN_PROJECT_COMMENTS} element={<ProjectComments />} />
                    <Route path={ROUTES.ADMIN_ALL_PROJECTS} element={<ViewAllProjects />} />
                    <Route path={ROUTES.ADMIN_CREATE_USER} element={<CreateUser />} />
                    <Route path={ROUTES.ADMIN_CREATE_ADMIN_USER} element={<CreateAdminUser />} />
                    <Route path={ROUTES.ADMIN_CHANGE_USER_ROLE} element={<ChangeUserRole />} />
                    <Route path={ROUTES.ADMIN_USERS_KYC} element={<UsersKycDashboard />} />
                </Route>
                <Route path={ROUTES.ADMIN_INVITE_USERS} element={<InviteUsers />} />
                <Route path={ROUTES.ADMIN_PROJECT_DETAILS} element={<Project isAdmin={true} />} />

                <Route path={ROUTES.ADMIN_RESET_VERIFY} element={<ResetPasswordVerification isAdmin={true} />} />
                {/* static */}
                <Route path={ROUTES.SOCIAL_PARTNER} element={<SocialPartners />} />
                <Route path={ROUTES.CONTACT_US} element={<ContactUs />} />
                <Route path={ROUTES.MEDIA_VIDEOS} element={<Gallery galleryType="video" />} />
                <Route path={ROUTES.MEDIA_IMAGES} element={<Gallery galleryType="image" />} />
                <Route path={ROUTES.FAQS} element={<Faqs />} />
                <Route path={ROUTES.TERMS_OF_USE} element={<TermsofUse />} />
                <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy />} />
            </Routes>
        </MainLayout>
    );
});

const App = () => (
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            <Theme>
                <ApolloProvider client={client}>
                    <QueryClientProvider client={queryClient}>
                        <Router {...(process.env.REACT_APP_BASE_URI ? { basename: process.env.REACT_APP_BASE_URI } : {})}>
                            <UserProvider>
                                <div className="App h-full">
                                    <MainRouter />
                                </div>
                            </UserProvider>
                        </Router>
                    </QueryClientProvider>
                </ApolloProvider>
            </Theme>
        </ThemeProvider>
        <ToastContainer />
    </StyledEngineProvider>
);

export default App;
