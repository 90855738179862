import { useResendVerificationEmailMutation } from "types/generated-types.ts";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useUser } from "contexts/user-type/user-type-context.js";

const CheckEmail = () => {
    const navigate = useNavigate();

    const openGmail = () => {
        window.open("https://mail.google.com/mail/", "_blank");
    };

    const { state } = useUser();
    const { email } = state;

    const [resendVerifyEmail] = useResendVerificationEmailMutation({
        onCompleted: (data) => {
            if (data && data.resendVerificationEmail) {
                toast.dismiss();
                toast.success("Mail Sent Successfully");
            }
        },
    });

    const handleResendVerificationEmail = () => {
        toast.loading("Sending Email, please wait...");
        resendVerifyEmail({
            variables: {
                email: email,
            },
        });
    };

    return (
        <div className="flex h-full w-full justify-center items-center my-auto flex-col bg-[#F9F9F9] md:px-0 px-6">
            <div className="flex justify-center items-center mb-10 md:mb-4 flex-col ">
                <p className="text-[#0D0140] font-bold text-2xl md:text-3xl mb-8">Check Your Email</p>
                <p className="text-[#0D0140] text-center font-thin text-base mt-2">
                    Thanks for registering into EquiPPP™. Please check your registered email id and verify your account.
                </p>
            </div>
            <img src="assets/images/check.svg" alt="logo" className="mb-8" />
            <div className="w-full md:w-1/3">
                <div className="flex flex-col items-center w-full px-8 ">
                    <button
                        className="bg-[#130160] w-full text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="button"
                        onClick={openGmail}
                    >
                        OPEN YOUR EMAIL
                    </button>
                    <button
                        className="bg-[#D6CDFE] w-full mt-4  font-semibold py-2 px-4 rounded text-white focus:outline-none focus:shadow-outline"
                        type="button"
                        onClick={() => navigate("/login")}
                    >
                        BACK TO LOGIN
                    </button>
                </div>
            </div>

            <p className="text-center text-gray-500 text-xs mt-6">
                You have not received the email?{" "}
                <span className="underline underline-offset-2 text-[#FF9228] cursor-pointer" onClick={handleResendVerificationEmail}>
                    Resend
                </span>
            </p>
        </div>
    );
};

export default CheckEmail;
