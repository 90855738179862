import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useGetProjectQuery } from "../../../types/generated-types.ts";
import { BasicInformation, DetailedInformation, BasicInformationNew, FinancialsParticipation, MediaAndTimeline } from "./components";
import "./CreateProject.styles.scss";

const tabs = [
    {
        title: "Basic Information",
        Tab: BasicInformation,
        index: 0,
        fields: {
            left: [],
            right: [],
        },
    },
    {
        title: "Detailed Information and Questions",
        Tab: DetailedInformation,
        index: 1,
        fields: [],
    },
    {
        title: "Financials / Participation",
        Tab: FinancialsParticipation,
        index: 2,
        fields: [],
    },
    {
        title: "Media and Timelines",
        Tab: MediaAndTimeline,
        index: 3,
        fields: [],
    },
];

const CreateProject = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [projectImages, setProjectImages] = useState([]);
    const [projectDocuments, setProjectDocuments] = useState([]);
    const { projectId } = useParams();
    const projectDataFromStorage = JSON.parse(localStorage.getItem("editProjectData"));

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        control,
        trigger,
        formState: { errors },
        reset,
        getValues,
    } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
    });

    const values = getValues();
    console.log(values);
    const saveFormDataInLocalstorage = () => {
        localStorage.setItem("editProjectData", JSON.stringify(values));
        toast.success("Draft Saved !");
    };

    const {
        data: projectData,
        error,
        loading,
    } = useGetProjectQuery({
        variables: {
            id: projectId,
        },
        enabled: projectId,
    });

    useEffect(() => {
        if (projectData && projectDataFromStorage?.id !== projectId) {
            const data = projectData.projects[0];
            const timeline = [];
            const questionAnswers = [];

            for (let i = 0; i < data.timelines.length; i++) {
                const { __typename, ...timelineObject } = data.timelines[i];
                timeline.push(timelineObject);
            }
            for (let i = 0; i < data.question_answers.length; i++) {
                const { __typename, ...restData } = data.question_answers[i];
                questionAnswers.push(restData);
            }
            reset({
                ...data,
                user_name: data.user.user_name,
                location: projectData.projects[0].location_title,
                locationCoordinates: {
                    lat: projectData.projects[0].location_coordinates.coordinates[1],
                    lng: projectData.projects[0].location_coordinates.coordinates[0],
                },
                timelines: timeline,
                question_answers: questionAnswers,
            });
            setProjectImages([]);
            setProjectDocuments([]);
            data?.project_assets?.map((el) => {
                const { __typename, ...restData } = el;
                if (el.asset_type === "images") {
                    setProjectImages((prev) => [...prev, restData]);
                } else if (el.asset_type === "documents") {
                    setProjectDocuments((prev) => [...prev, restData]);
                } else {
                    setProjectImages([]);
                    setProjectDocuments([]);
                }
                return "";
            });
        } else {
            reset({ ...projectDataFromStorage });
        }

        if (error) {
            toast.error(error.message);
        }
    }, [projectData]);

    useEffect(() => {
        setValue("documents", projectDocuments);
        setValue("images", projectImages);
    }, [projectDocuments, projectImages]);

    useEffect(() => {
        if (!projectData) {
            setValue("question_answers", []);
            setValue("timelines", []);
            setValue("documents", []);
            setValue("images", []);
            setValue("timelinesId", []);
        }
    }, [projectData]);

    const handleNavigation = async (index) => {
        if (index - currentIndex < 0) {
            setCurrentIndex(index);
            return;
        }

        let filled = false;
        let fields;
        if (currentIndex === 0) {
            fields = ["user_name", "start_date", "title", "end_date", "category", "phone", "type", "email", "location"];
        } else if (currentIndex === 1) {
            fields = ["objective", "problem", "background", "requirements", "benefits", "scope"];
        } else if (currentIndex === 2) {
            fields = ["estimated_cost", "participant_type"];
        }

        await trigger(fields);
        console.log(fields.every((field) => watch(field)));
        const condition = fields.every((field) => watch(field));
        if (condition) {
            filled = true;
        }
        console.log(filled, currentIndex);
        if (filled) {
            setCurrentIndex(index);
        } else {
            toast.dismiss();
            toast.error("please fill all the required fields to continue");
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <Fragment>
            <div className="w-full h-full">
                <BasicInformationNew />
                {/* <Grid container className="inner__container">
                    <Grid item style={{ maxWidth: "100%" }}>
                        <Grid container className="tab__container">
                            <Grid className="tabs__list" container direction="row" justifyContent="center" alignItems="center">
                                {tabs.map(({ title, index }) => {
                                    return (
                                        <Fragment key={index}>
                                            <Typography
                                                className={`tabs ${index === currentIndex && "active"} `}
                                                onClick={() => handleNavigation(index)}
                                                style={{ cursor: "pointer" }}
                                            >
                                                {title}
                                            </Typography>
                                            {index !== 3 ? <Divider className="divider" /> : ""}
                                        </Fragment>
                                    );
                                })}
                            </Grid>
                            <Grid className="project-tabs">
                                {tabs.map(({ Tab, index }) => {
                                    return (
                                        <Fragment key={index}>
                                            <Tab
                                                tabs={tabs}
                                                currentIndex={currentIndex}
                                                setCurrentIndex={setCurrentIndex}
                                                handleSubmit={handleSubmit}
                                                register={register}
                                                errors={errors}
                                                setValue={setValue}
                                                watch={watch}
                                                control={control}
                                                trigger={trigger}
                                                values={values}
                                                saveDraft={saveFormDataInLocalstorage}
                                                isVisible={index === currentIndex}
                                            />
                                        </Fragment>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid> */}
            </div>
        </Fragment>
    );
};

export default CreateProject;
