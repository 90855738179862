import { LoginModalContent } from "components/CustomModal/Forms";
import { PopupModals } from "components/PopupModals/PopupModals";
import { Eye } from "lucide-react";
import { useState, useEffect, Fragment, useContext, useMemo, useRef } from "react";
import { Modal, Container, Form, Button, Spinner } from "react-bootstrap";

import { toast } from "react-toastify";

import { useForgotPasswordMutation } from "types/generated-types.ts";

import { validateEmail, validatePhoneNumber } from "utils";

import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
    const navigate = useNavigate();

    // const [forgotEmailError, setForgotEmailError] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [forgotPhoneNumberError, setForgotPhoneNumberError] = useState("");
    const [apiStatus, setApiStatus] = useState(true);
    const [forgotPassword] = useForgotPasswordMutation({
        onCompleted: (data) => {
            setApiStatus(true);
            toast.dismiss();
            toast.success("OTP has been successfully sent to your phone number");

            navigate(`/otp-reset-password?phone_number=${encodeURIComponent(phoneNumber)}`);
        },
    });

    const handleKeyDown = (e) => {
        if (
            [46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
            (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) ||
            (e.keyCode === 67 && (e.ctrlKey === true || e.metaKey === true)) ||
            (e.keyCode === 88 && (e.ctrlKey === true || e.metaKey === true)) ||
            (e.keyCode >= 35 && e.keyCode <= 40)
        ) {
            return;
        }

        if ((e.shiftKey || e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) {
            e.preventDefault();
        }
    };

    const handlePhoneNumberChange = (e) => {
        const value = e.target.value;
        // Create a regex to match only numeric characters
        const onlyNums = value.replace(/\D/g, ""); // \D matches any character that's not a digit
        setPhoneNumber(onlyNums);
    };
    const sendForgotPassword = (e) => {
        e.preventDefault();
        // setForgotEmailError("");

        console.log(phoneNumber);

        console.log({ phoneNumber });
        setForgotPhoneNumberError("");
        // const emailId = e.target.email.value;

        // console.log(emailId);
        // const emailCheck = validateEmail(emailId);
        const phoneNumberCheck = validatePhoneNumber(phoneNumber);

        console.log(phoneNumber);
        if (!phoneNumberCheck.status) {
            setForgotPhoneNumberError(phoneNumberCheck.message);
            return;
        }
        setApiStatus(false);
        forgotPassword({
            variables: {
                phone_number: phoneNumber,
            },
        });
    };

    return (
        <div className="flex h-full w-full justify-center items-center my-auto flex-col bg-[#F9F9F9] md:px-0 px-6">
            <div className="flex justify-center flex-col ">
                <p className="text-[#0D0140] font-bold text-3xl mb-8">Forgot Password?</p>
                <img src="assets/images/reset.svg" alt="logo" className="mb-8" />
            </div>
            {/* <form className="w-full md:w-1/3" onSubmit={sendForgotPassword}>
                <div className="mb-4 ">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2" for="username">
                        Email
                    </label>
                    <input
                        className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="username"
                        type="text"
                        name="email"
                        placeholder="Brandonelouis@gmail.com"
                    />
                </div>

                <p className="text-red-500 text-xs italic">{forgotEmailError}</p>

                <div className="flex flex-col items-center w-full px-8 ">
                    {apiStatus ? (
                        <button
                            className="bg-[#130160] w-full text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="button"
                        >
                            RESET PASSWORD
                        </button>
                    ) : (
                        "Sending..."
                    )}
                </div>
            </form> */}
            <Form onSubmit={sendForgotPassword} className="w-full md:w-1/3">
                <Form.Group className="mb-4">
                    {/* <Form.Control type="text" name="email" placeholder="Email" /> */}
                    <Form.Control
                        type="text"
                        name="phoneNumber"
                        placeholder="enter your mobile number"
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                        onKeyDown={handleKeyDown}
                    />
                    {/* <small className="text-danger">{forgotEmailError}</small> */}
                    {forgotPhoneNumberError && <small className="text-danger">{forgotPhoneNumberError}</small>}
                </Form.Group>
                <div className="flex flex-col items-center w-full px-8 ">
                    <button
                        className="bg-[#130160] w-full text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="submit"
                        disabled={!apiStatus}
                    >
                        {apiStatus ? <>Send</> : <Spinner animation="border" variant="light" size="sm" />}
                    </button>
                </div>
            </Form>
            <div className="flex flex-col items-center w-full md:w-1/3 px-8 ">
                <button
                    className="bg-[#D6CDFE] w-full mt-4  font-semibold py-2 px-4 rounded text-white focus:outline-none focus:shadow-outline"
                    type="button"
                    onClick={() => navigate("/login")}
                >
                    BACK TO LOGIN
                </button>
            </div>
        </div>
    );
};

export default ForgotPassword;
