import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./ProjectDetails.styles.scss";

const ProjectDetails = (props) => {
    const [projectInfo, setProjectInfo] = useState({});

    useEffect(() => {
        setProjectInfo(JSON.parse(props.details));
    }, [props.details]);

    return (
        <div className="DetailsSection">
            <div className="ObjectiveSection">
                <h4 className="ObjHeading"> Objective </h4>
                {projectInfo.project?.objective ? projectInfo.project?.objective : "Not Available"}
                <h4 className="ObjHeading"> a) Need of the Project </h4>
                <h4 className="ObjHeading"> &nbsp; Problem: </h4>
                &nbsp; &nbsp; {projectInfo.project?.need && projectInfo.project?.need.problem ? projectInfo.project?.need.problem : "Not Available"}
                <h4 className="ObjHeading"> &nbsp; Severity: </h4>
                &nbsp; &nbsp;{" "}
                {projectInfo.project?.need && projectInfo.project?.need.howSevere ? projectInfo.project?.need.howSevere : "Not Available"}
                <h4 className="ObjHeading"> b) No of people affected:: </h4>
                {projectInfo.project?.need && projectInfo.project?.need.peopleEffected ? projectInfo.project?.need.peopleEffected : "Not Available"}
            </div>
        </div>
    );
};

export default ProjectDetails;
ProjectDetails.propTypes = {
    details: PropTypes.any,
};
