import React, { Fragment, useState } from "react";
import { GoogleMap, MarkerF, useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import { googleMapInitializer } from "constants";
import { useLocation } from "react-router-dom";

/*
    You have to enable the following APIs in the Google Cloud Console:
    1. Google Maps JavaScript API
    2. Google Maps Places API
*/
const Map = ({ currentPosition, allPositions, onMarkerClick, handleLocationChange, isMobileView }) => {
    const mapStyles = {
        height: "100%",
        width: "100%",
        display: "inline-block",
        borderRadius: "10px",
    };
    const [autoComplete, setAutoComplete] = useState(null);
    const { isLoaded, loadError } = useJsApiLoader(googleMapInitializer);
    const path = useLocation();

    if (loadError) {
        return <div>Map cannot be loaded right now, sorry.</div>;
    }
    const filteredPositions =
        allPositions?.filter((position) => {
            if (position.lat && position.lng) {
                return true;
            }
            return false;
        }) || [];

    // find the center of all the positions
    const center = currentPosition || {
        lat: 21.7679,
        lng: 78.8718,
    };
    const onPlaceChanged = () => {
        if (autoComplete !== null) {
            const { formatted_address } = autoComplete.getPlace();
            handleLocationChange(formatted_address);
        } else {
            alert("Autocomplete is not loaded yet!");
        }
    };

    console.log("currentPosition", currentPosition);

    const isProjects = path.pathname === "/projects";
    const zoomLevel = isProjects ? 5 : 14;

    return (
        <Fragment>
            {isLoaded ? (
                <GoogleMap mapContainerStyle={mapStyles} zoom={zoomLevel} center={center}>
                    {!isMobileView && (
                        <Autocomplete onLoad={setAutoComplete} onPlaceChanged={onPlaceChanged}>
                            <input
                                type="text"
                                placeholder="Search for a place"
                                style={{
                                    boxSizing: `border-box`,
                                    border: `1px solid transparent`,
                                    width: `240px`,
                                    height: `32px`,
                                    padding: `0 12px`,
                                    borderRadius: `3px`,
                                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                    fontSize: `14px`,
                                    outline: `none`,
                                    textOverflow: `ellipses`,
                                    position: "absolute",
                                    left: "50%",
                                    marginLeft: "-120px",
                                }}
                            />
                        </Autocomplete>
                    )}
                    {filteredPositions.map((position, index) => {
                        console.log({ position });
                        return (
                            <MarkerF
                                key={index}
                                position={position}
                                onClick={() => {
                                    onMarkerClick(index, position);
                                }}
                            />
                        );
                    })}
                </GoogleMap>
            ) : (
                <h4>Loading...</h4>
            )}
        </Fragment>
    );
};

export default Map;
