import React, { useState, Fragment } from "react";
import { toast } from "react-toastify";
import { useRegisterMutation } from "types/generated-types.ts";
import CustomModal, {
    RegisterSuccessModalContent,
    LoginSuccessModalContent,
    ForgotPasswordModalContent,
    FeedbackModalContent,
} from "components/CustomModal/CustomModal";
import { RegisterModalContent, LoginModalContent } from "components/CustomModal/Forms";
import {
    CSRFoundationContent,
    IndividualContent,
    MLAContent,
    MPContent,
    NPOContent,
    OtherContent,
    ProBonoContent,
    PSBContent,
    SMEContent,
    StudentContent,
    UserTypeContent,
} from "components/CustomModal/Forms/RegisterModalContentOld/Components";
import MobileLogin from "views/MobileLogin";

export const PopupModals = ({ isModalOpen, setModal, isAdmin, toggle }) => {
    var specificUserTypeFields = "";

    const [{ email, password, reEnterPassword, userType, userTypeFieldsDetails }, setState] = useState({
        email: "",
        password: "",
        reEnterPassword: "",
        userType: "",
        userTypeFieldsDetails: {},
    });
    const [csrFoundationState, setCsrFoundationState] = useState({
        csrFoundationName: "",
        csrContributionCategories: [],
        csrPointOfContact: "",
        csrAddress: "",
        csrRepresentativeRole: "",
    });
    const [individualState, setIndividualState] = useState({
        individualName: "",
        individualPhone: "",
        individualAddress: "",
        individualGender: "",
        individualOccupation: "",
    });
    const [mlaState, setMlaState] = useState({
        mlaName: "",
        mlaConstituency: "",
        mlaContactDetails: "",
        mlaPartyName: "",
        mlaTenureDuration: "",
        mlaGender: "",
    });
    const [mpState, setMpState] = useState({
        mpName: "",
        mpConstituency: "",
        mpContactDetails: "",
        mpPartyName: "",
        mpTenureDuration: "",
        mpGender: "",
    });
    const [npoState, setNpoState] = useState({
        npoName: "",
        npoContributionCategories: [],
        npoPointOfContact: "",
        npoAddress: "",
        npoRepresentativeRole: "",
    });
    const [otherState, setOtherState] = useState({
        otherName: "",
        otherPhone: "",
        otherAddress: "",
    });
    const [proBonoState, setProBonoState] = useState({
        proBonoName: "",
        proBonoProfession: "",
        proBonoPointOfContact: "",
        proBonoContributionCategories: [],
        proBonoSkills: "",
        proBonoGender: "",
    });
    const [psbState, setPsbState] = useState({
        psbBankName: "",
        psbContributionCategories: [],
        psbPointOfContact: "",
        psbAddress: "",
        psbRepresentativeRole: "",
    });
    const [smeState, setSmeState] = useState({
        smeName: "",
        smeExpertise: "",
        smePreviousCompany: "",
        smeRole: "",
        smePointOfContact: "",
        smeGender: "",
    });
    const [studentState, setStudentState] = useState({
        studentName: "",
        studentCourseName: "",
        studentInstitutionName: "",
        studentInstitutionAddress: "",
        studentPersonalContact: "",
        studentGender: "",
    });

    const [register] = useRegisterMutation({
        onCompleted: (data) => {
            console.log(data);
            if (data && data.register) {
                toast.dismiss();
                toast.success("User Registered Successfully");
                setModal("register-success");
            } else {
                toast.dismiss();
                toast.error("Something went wrong, please try again.");
            }
        },
    });
    const registerUser = () => {
        if (userType === "Individual") {
            specificUserTypeFields = {
                name: individualState.individualName,
                phone: individualState.individualPhone,
                gender: individualState.individualGender,
                address: individualState.individualAddress,
                occupation: individualState.individualOccupation,
            };
        }
        if (userType === "CSR foundation") {
            specificUserTypeFields = {
                name: csrFoundationState.csrFoundationName,
                contributioCategories: csrFoundationState.csrContributionCategories,
                phone: csrFoundationState.csrPointOfContact,
                address: csrFoundationState.csrAddress,
                role: csrFoundationState.csrRepresentativeRole,
            };
        }
        if (userType === "Non-Profit Organization") {
            specificUserTypeFields = {
                name: npoState.npoName,
                contributionCategories: npoState.npoContributionCategories,
                phone: npoState.npoPointOfContact,
                address: npoState.npoAddress,
                cinNumber: npoState.npoCinNumber,
            };
        }
        if (userType === "Subject Matter Expert") {
            specificUserTypeFields = {
                name: smeState.smeName,
                expertise: smeState.smeExpertise,
                previousCompany: smeState.smePreviousCompany,
                role: smeState.smeRole,
                phone: smeState.smePointOfContact,
                gender: smeState.smeGender,
            };
        }
        if (userType === "Pro-Bono") {
            specificUserTypeFields = {
                name: proBonoState.proBonoName,
                profession: proBonoState.proBonoProfession,
                contributionCategories: proBonoState.proBonoContributionCategories,
                skills: proBonoState.proBonoSkills,
            };
        }
        if (userType === "Public Sector Bank") {
            specificUserTypeFields = {
                name: psbState.psbBankName,
                contributionCategories: psbState.psbContributionCategories,
                phone: psbState.psbPointOfContact,
                address: psbState.psbAddress,
                cinNumber: psbState.psbCinNumber,
            };
        }
        if (userType === "Member of Parliament") {
            specificUserTypeFields = {
                name: mpState.mpName,
                constituency: mpState.mpName,
                phone: mpState.mpName,
                partyName: mpState.mpName,
                tenureDuration: mpState.mpName,
                gender: mpState.mpName,
            };
        }
        if (userType === "Member of Legislative Assembly") {
            specificUserTypeFields = {
                name: mlaState.mlaName,
                constituency: mlaState.mlaConstituency,
                phone: mlaState.mlaContactDetails,
                partyName: mlaState.mlaPartyName,
                tenureDuration: mlaState.mlaTenureDuration,
                gender: mlaState.mlaGender,
            };
        }
        if (userType === "Students") {
            specificUserTypeFields = {
                name: studentState.studentName,
                courseName: studentState.studentCourseName,
                institutionName: studentState.studentInstitutionName,
                institutionAddress: studentState.studentInstitutionAddress,
                phone: studentState.studentPersonalContact,
                gender: studentState.studentGender,
            };
        }
        if (userType === "Others") {
            specificUserTypeFields = {
                name: otherState.otherName,
                phone: otherState.otherPhone,
                address: otherState.otherAddress,
            };
        }
        console.log("checking", userType, specificUserTypeFields);
        register({
            variables: {
                email,
                password,
                userType,
                userTypeFieldsDetails: JSON.stringify(specificUserTypeFields),
            },
        });
    };

    return (
        <Fragment>
            {/* login modal */}

            <CustomModal isOpen={isModalOpen === "login"} isNotStyleDefault={true} header="" setOpen={setModal}>
                <LoginModalContent setOpen={setModal} isAdmin={isAdmin} />
            </CustomModal>
            {/* register modal */}
            <CustomModal isOpen={isModalOpen === "register"} isNotStyleDefault={true} header={""} setOpen={setModal}>
                <RegisterModalContent setOpen={setModal} email={email} password={password} reEnterPassword={reEnterPassword} setState={setState} />
            </CustomModal>
            {/* register modal */}
            <CustomModal isOpen={isModalOpen === "usertype"} isNotStyleDefault={true} header={""} setOpen={setModal}>
                <UserTypeContent setOpen={setModal} userType={userType} setState={setState} />
            </CustomModal>
            {/* register modal */}
            <CustomModal isOpen={isModalOpen === "individual"} isNotStyleDefault={true} header={""} setOpen={setModal}>
                <IndividualContent
                    setOpen={setModal}
                    email={email}
                    individualState={individualState}
                    setIndividualState={setIndividualState}
                    registerUser={registerUser}
                />
            </CustomModal>
            {/* register modal */}
            <CustomModal isOpen={isModalOpen === "csr-foundation"} isNotStyleDefault={true} header={""} setOpen={setModal}>
                <CSRFoundationContent
                    email={email}
                    csrFoundationState={csrFoundationState}
                    setCsrFoundationState={setCsrFoundationState}
                    registerUser={registerUser}
                    setOpen={setModal}
                />
            </CustomModal>
            {/* register modal */}
            <CustomModal isOpen={isModalOpen === "npo"} isNotStyleDefault={true} header={""} setOpen={setModal}>
                <NPOContent email={email} npoState={npoState} setNpoState={setNpoState} registerUser={registerUser} setOpen={setModal} />
            </CustomModal>
            {/* register modal */}
            <CustomModal isOpen={isModalOpen === "pro-bono"} isNotStyleDefault={true} header={""} setOpen={setModal}>
                <ProBonoContent
                    email={email}
                    proBonoState={proBonoState}
                    setProBonoState={setProBonoState}
                    registerUser={registerUser}
                    setOpen={setModal}
                />
            </CustomModal>
            {/* register modal */}
            <CustomModal isOpen={isModalOpen === "psb"} isNotStyleDefault={true} header={""} setOpen={setModal}>
                <PSBContent email={email} psbState={psbState} setPsbState={setPsbState} registerUser={registerUser} setOpen={setModal} />
            </CustomModal>
            {/* register modal */}
            <CustomModal isOpen={isModalOpen === "mp"} isNotStyleDefault={true} header={""} setOpen={setModal}>
                <MPContent email={email} mpState={mpState} setMpState={setMpState} registerUser={registerUser} setOpen={setModal} />
            </CustomModal>
            {/* register modal */}
            <CustomModal isOpen={isModalOpen === "mla"} isNotStyleDefault={true} header={""} setOpen={setModal}>
                <MLAContent email={email} mlaState={mlaState} setMlaState={setMlaState} registerUser={registerUser} setOpen={setModal} />
            </CustomModal>
            {/* register modal */}
            <CustomModal isOpen={isModalOpen === "student"} isNotStyleDefault={true} header={""} setOpen={setModal}>
                <StudentContent
                    email={email}
                    studentState={studentState}
                    setStudentState={setStudentState}
                    registerUser={registerUser}
                    setOpen={setModal}
                />
            </CustomModal>
            {/* register modal */}
            <CustomModal isOpen={isModalOpen === "sme"} isNotStyleDefault={true} header={""} setOpen={setModal}>
                <SMEContent email={email} smeState={smeState} setSmeState={setSmeState} registerUser={registerUser} setOpen={setModal} />
            </CustomModal>
            {/* register modal */}
            <CustomModal isOpen={isModalOpen === "others"} isNotStyleDefault={true} header={""} setOpen={setModal}>
                <OtherContent email={email} otherState={otherState} setOtherState={setOtherState} registerUser={registerUser} setOpen={setModal} />
            </CustomModal>
            {/* register success modal */}
            <CustomModal isOpen={isModalOpen === "register-success"} header="&nbsp;" setOpen={setModal}>
                <RegisterSuccessModalContent setOpen={setModal} />
            </CustomModal>
            {/* login success modal */}
            <CustomModal isOpen={isModalOpen === "login-success"} header="&nbsp;SUCCESS" setOpen={setModal}>
                <LoginSuccessModalContent setOpen={setModal} />
            </CustomModal>
            {/* feedback modal */}
            <CustomModal isOpen={isModalOpen === "feedback"} header="&nbsp;GIVE YOUR FEEDBACK HERE" setOpen={setModal}>
                <FeedbackModalContent setOpen={setModal} />
            </CustomModal>
            {/* forgot password modal */}
            <CustomModal isOpen={isModalOpen === "forgot"} header="FORGOT PASSWORD?" setOpen={setModal}>
                <ForgotPasswordModalContent setOpen={setModal} toggle={(val) => toggle(val)} />
            </CustomModal>
        </Fragment>
    );
};
