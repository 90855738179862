import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";

function SME({ smeState, setSmeState, email, setOpen, registerUser }) {
    let { smeName, smeExpertise, smePreviousCompany, smeRole, smePointOfContact, smeGender } = smeState;
    const handleSMEChange = (e) => {
        setSmeState((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };
    const handleBackClick = () => {
        setOpen("usertype");
    };

    const handleForwardClick = () => {
        if (!smeName || !smeExpertise || !smePreviousCompany || !smeRole || !smePointOfContact) {
            return alert("please fill all sme details");
        }
        registerUser();
    };

    return (
        <Grid item xs={12} className="common-modal__container" container direction="column" justifyContent="flex-start" alignItems="flex-start">
            <h3>
                Sign up as <span className="span-text">SME.</span>
            </h3>
            <Grid item xs={12} className="field__grid" container justifyContent="space-between" alignItems="center" mb="15px">
                <TextField
                    variant="standard"
                    label="Name"
                    className="input__field"
                    fullWidth
                    type="text"
                    name="smeName"
                    value={smeName}
                    onChange={handleSMEChange}
                />
                <TextField variant="standard" label="Email" className="input__field" fullWidth type="text" name="smeEmail" value={email} disabled />
            </Grid>
            <Grid item xs={12} className="field__grid" container justifyContent="space-between" alignItems="center" mb="15px">
                <TextField
                    variant="standard"
                    label="Area of Expertise"
                    className="input__field"
                    fullWidth
                    type="text"
                    name="smeExpertise"
                    value={smeExpertise}
                    onChange={handleSMEChange}
                />
                <TextField
                    variant="standard"
                    label="Previous Company"
                    className="input__field"
                    fullWidth
                    type="text"
                    name="smePreviousCompany"
                    value={smePreviousCompany}
                    onChange={handleSMEChange}
                />
            </Grid>
            <Grid item xs={12} className="field__grid" container justifyContent="space-between" alignItems="center" mb="15px">
                <TextField
                    variant="standard"
                    label="Role"
                    className="input__field"
                    fullWidth
                    type="text"
                    name="smeRole"
                    value={smeRole}
                    onChange={handleSMEChange}
                />
                <TextField
                    variant="standard"
                    label="Contact"
                    className="input__field"
                    fullWidth
                    type="text"
                    onKeyPress={(event) => {
                        // ignore "-", "+" and "e"
                        if (event?.key === "-" || event?.key === "+" || event?.key === "e") {
                            event.preventDefault();
                        }
                        // if character is not number then ignore except decimal point and
                        if (event?.key !== "." && event?.key && isNaN(+event.key)) {
                            event.preventDefault();
                        }
                        if (event.target.value.length >= 10) {
                            event.preventDefault();
                        }
                    }}
                    InputProps={{
                        maxLength: 10,
                    }}
                    name="smePointOfContact"
                    value={smePointOfContact}
                    onChange={handleSMEChange}
                />
            </Grid>
            <Grid item xs={12} className="field__grid" container justifyContent="flex-start" alignItems="center" mb="15px">
                <FormControl className="radio__container">
                    <FormLabel id="radio-btns-label">Gender</FormLabel>
                    <RadioGroup row name="smeGender" value={smeGender} onChange={handleSMEChange}>
                        <FormControlLabel value="female" control={<Radio size="small" />} label="Female" />
                        <FormControlLabel value="male" control={<Radio size="small" />} label="Male" />
                        <FormControlLabel value="other" control={<Radio size="small" />} label="Other" />
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12} container direction="row" justifyContent="space-between" alignItems="center" className="button__container">
                <Button className="back__btn" type="button" onClick={handleBackClick}>
                    Back
                </Button>
                <Button className="continue__btn" type="button" onClick={handleForwardClick}>
                    Save and continue
                </Button>
            </Grid>
        </Grid>
    );
}

export default SME;
