import { useState, useEffect } from "react";
// import { useVerifyOtpMutation useResendOtpMutation } from "types/generated-types.ts";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useUser } from "contexts/user-type/user-type-context.js";
import { InputOTP, InputOTPGroup, InputOTPSeparator, InputOTPSlot } from "../../components/ui/input-otp";
import { useSearchParams } from "react-router-dom";
import { useVerifyOtpMutation, useResendOtpMutation } from "types/generated-types.ts";

const VerifyOtp = () => {
    const navigate = useNavigate();
    const [otp, setOtp] = useState("");
    const [verifyOtp, { data, loading, error }] = useVerifyOtpMutation();
    const [countdown, setCountdown] = useState(0);

    const [searchParams, setSearchParams] = useSearchParams();

    const { state, setState, registerUser } = useUser();

    const phoneNumber = state.phoneNumber || searchParams.get("phone_number");

    const [resendOtp] = useResendOtpMutation({
        onCompleted: (data) => {
            if (data && data.resendOtp) {
                toast.dismiss();
                toast.success("Otp Sent Successfully");
                setCountdown(60);
            }
        },
    });

    useEffect(() => {
        const interval = setInterval(() => {
            if (countdown > 0) {
                setCountdown(countdown - 1);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [countdown]);

    const handleOtpChange = (newOtp) => {
        setOtp(newOtp);
    };

    const handleSubmit = async () => {
        if (otp.length < 4) {
            toast.error("Please enter the 4-digit OTP.");
            return;
        }

        try {
            const { errors, data } = await verifyOtp({ variables: { otp, phone_number: phoneNumber } });

            if (errors?.length) {
                toast.error("Verification failed. Please try again.");
            } else {
                toast.success("OTP verified successfully!");
                navigate("/login");
            }
        } catch (e) {
            console.error("Verification error:", e);
            toast.error("An error occurred during verification.");
        }
    };

    const handleResendOtp = async () => {
        if (countdown === 0) {
            toast.loading("Sending OTP, please wait...");
            resendOtp({
                variables: {
                    phone_number: phoneNumber,
                },
            });
        }
    };

    return (
        <div className="flex h-full w-full justify-center items-center my-auto flex-col bg-[#F9F9F9] md:px-0 px-6">
            <div className="flex justify-center items-center mb-10 md:mb-4 flex-col ">
                <p className="text-[#0D0140] font-bold text-2xl md:text-3xl mb-4">Enter Your Otp</p>
                <p className="text-[#0D0140] text-center font-thin text-base mt-2">Thanks for registering into EquiPPP™.</p>
            </div>
            <img src="/assets/images/check.svg" alt="logo" className="mb-8" />
            <div className="w-full flex justify-center  md:w-1/3">
                <InputOTP maxLength={4} onChange={handleOtpChange}>
                    <InputOTPGroup>
                        <InputOTPSlot index={0} />
                        <InputOTPSlot index={1} />
                        <InputOTPSlot index={2} />
                        <InputOTPSlot index={3} />
                    </InputOTPGroup>
                </InputOTP>
            </div>

            <button
                className="mt-4 bg-[#FF9228] text-white font-bold py-2 px-4 rounded hover:bg-orange-500"
                onClick={handleSubmit}
                // disabled={loading}
            >
                Verify OTP
            </button>
            <p className="text-center text-gray-500 text-xs mt-6">
                You have not received the otp?{" "}
                <span
                    className={`underline underline-offset-2 text-[#FF9228] cursor-pointer ${countdown > 0 ? "text-gray-500" : ""}`}
                    onClick={handleResendOtp}
                >
                    Resend otp {countdown > 0 ? `in ${countdown}s` : ""}
                </span>
            </p>
        </div>
    );
};

export default VerifyOtp;
