import React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
// import required modules
import { Navigation } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "./DailogBox.styles.scss";

const DailogBox = (props) => {
    const { open, setOpen, pdf, imageUrl, activeSlide } = props;

    const handleClose = () => {
        setOpen(false);
    };

    const adjustHeight = (e) => {
        if (e.activeIndex === 0) {
            document.querySelector(".DocumentsDailogBox").style.height = "50vh";
        } else {
            document.querySelector(".DocumentsDailogBox").style.height = "auto";
        }
    };

    const headers = {
        "X-Access-Token": "1234567890",
        "My-Custom-Header": "my-custom-value",
        mode: "no-cors",
    };

    return (
        <Dialog className="document__dialog" onClose={handleClose} open={open} classes={{ paper: "document-dialog__paper" }}>
            <Grid container id="p2" className="documents-dailog__box">
                <Grid item xs={12} container justifyContent="flex-end" alignItems="center">
                    <CancelIcon className="document-close__icon" onClick={handleClose} />
                </Grid>
                <Swiper
                    className="mySwiper document__swiper"
                    slidesPerView={"auto"}
                    centeredSlides={true}
                    loopedSlides={20}
                    watchSlidesVisibility={true}
                    navigation={true}
                    modules={[Navigation]}
                    initialSlide={activeSlide}
                >
                    {imageUrl &&
                        imageUrl.map((item, index) => (
                            <SwiperSlide key={index}>
                                <Grid item xs={true} container direction="row" justifyContent="center" alignItems="center" className="image__slide">
                                    <img className="document__image" src={item.url} />
                                </Grid>
                            </SwiperSlide>
                        ))}
                    {pdf &&
                        pdf.map((item, index) => (
                            <SwiperSlide key={index}>
                                <Grid
                                    item
                                    xs={true}
                                    container
                                    direction="row"
                                    className="document__slide"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <DocViewer
                                        prefetchMethod="GET"
                                        requestHeaders={headers}
                                        documents={[{ uri: item.url }]}
                                        pluginRenderers={DocViewerRenderers}
                                    />
                                </Grid>
                            </SwiperSlide>
                        ))}
                </Swiper>
            </Grid>
        </Dialog>
    );
};

export default DailogBox;
