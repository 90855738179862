import { Fragment, useState } from "react";
import Form from "./Form";
import { useGetDistrictsQuery, useAddSuggestProjectMutation } from "types/generated-types.ts";
import { useNavigate } from "react-router-dom";

const states = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Delhi",
    "Jammu and Kashmir",
    "Ladakh",
    "Lakshadweep",
    "Puducherry",
];

const SuggestProject = () => {
    const [location, setLocation] = useState("");
    const [state, setState] = useState("Telangana");

    const { mutate } = useAddSuggestProjectMutation();

    const items = [
        {
            name: "Mohan Prasad",
            location: "Mathupat, Telangana",
            time: "55 mins ago",
            title: "Soil and Water Conservation",
            description:
                "Encouraging water conservation practices is essential to ensure sustainable water management. Promote awareness campaigns about the importance of water conservation.",
            link: "/",
        },
        {
            name: "Sree Lanka",
            location: "Mathupat, Telangana",
            time: "55 mins ago",
            title: "Soil and Water Conservation",
            description:
                "Encouraging water conservation practices is essential to ensure sustainable water management. Promote awareness campaigns about the importance of water conservation.",
            link: "/",
        },
        {
            name: "Venkat Swamy",
            location: "Mathupat, Telangana",
            time: "55 mins ago",
            title: "Soil and Water Conservation",
            description:
                "Encouraging water conservation practices is essential to ensure sustainable water management. Promote awareness campaigns about the importance of water conservation.",
            link: "/",
        },
    ];

    const { data: districts } = useGetDistrictsQuery({
        variables: {
            state: state,
        },
    });

    const data = [
        {
            value: districts?.districts.length,
            label: "Total District",
        },
        {
            value: districts?.districts[0]?.district,
            label: "Top District",
        },
        {
            value: districts?.districts_aggregate.aggregate?.count,
            label: "Total Sectors",
        },
        {
            value: 185,
            label: "Total Suggestions",
        },
    ];

    const footerData = [
        {
            title: "About us",
            items: [
                { name: "Talk to us", link: "/" },
                { name: "Mission", link: "/" },
                { name: "Vision", link: "/" },
                { name: "Terms & Conditions", link: "/" },
            ],
        },
        {
            title: "Users",
            items: [
                { name: "Individual", link: "/" },
                { name: "Company", link: "/" },
                { name: "Trust", link: "/" },
                { name: "Other", link: "/" },
            ],
        },
        {
            title: "Social Links",
            items: [
                { name: "LinkedIn", link: "/" },
                { name: "Facebook", link: "/" },
                { name: "Instagram", link: "/" },
                { name: "Twitter (X)", link: "/" },
            ],
        },
    ];

    const navigate = useNavigate();

    const handleChange = (e) => {
        setLocation(e.target.value);
        navigate(`/suggest-project/${state}/${e.target.value}`);
    };

    const handleStateChange = (e) => {
        setState(e.target.value);
    };

    console.log(location, "here");

    console.log({ districts });

    console.log({ state });

    return (
        <Fragment>
            <div className="px-8 py-4">
                <span
                    className="text-black/50 cursor-pointer text-sm"
                    onClick={() => {
                        navigate("/");
                    }}
                >
                    Home
                </span>
                <span className="text-black/50 cursor-pointer text-sm ml-2 mr-2">{" > "}</span>
                <span className="text-black text-sm"> Map</span>
                <span className="text-black/50 cursor-pointer text-sm ml-2 mr-2">{" > "}</span>
                <span className="text-black text-sm"> {state}</span>
                <div className="bg-gray-200 rounded-full mt-4 p-2 px-4 flex items-center justify-center gap-2 mb-4 w-fit">
                    <select
                        value={state}
                        onChange={handleStateChange}
                        placeholder="Select State"
                        defaultValue={"Telangana"}
                        className="text-sm outline-none bg-gray-200 "
                    >
                        <option value="" disabled>
                            Select State
                        </option>

                        {states?.map((state, index) => (
                            <option key={index} value={state}>
                                {state}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="mb-20  flex items-center justify-around center">
                {state === "Telangana" && <img src="/assets/images/full-map.svg" alt="map" className="w-[600px]" />}
                {state === "Andhra Pradesh" && <img src="/assets/images/andhra-map-3.png" alt="map" className="w-[500px]" />}

                <div className="bg-gray-200  rounded-full p-2 px-2.5 flex items-center justify-center gap-2">
                    <img src="/assets/images/Near Me.svg" alt="map" className="" />
                    <select
                        value={location}
                        onChange={handleChange}
                        defaultValue={"Telangana"}
                        placeholder="Select District"
                        className="text-sm outline-none bg-gray-200 w-40"
                    >
                        <option value="" disabled>
                            Select District
                        </option>

                        {districts?.districts.map((district) => (
                            <option key={district.id} value={district.district}>
                                {district.district}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="flex mb-10 justify-between text-[#170F49] gap-10 items-stretch px-20">
                {data.map((item, index) => (
                    <div className="basis-1/4 text-center bg-[#e0ecfb] rounded-md p-3" key={index}>
                        <p className="font-medium text-2xl mb-1">{item.value}</p>
                        <p className="">{item.label}</p>
                    </div>
                ))}
            </div>
            {location === "" && (
                <div className="bg-[#6858f7] flex p-5">
                    <div className="w-1/4 text-white">
                        <p className="font-semibold mb-2">Top Suggestions</p>
                        <p className="text-sm mb-3">All suggestion based on locaity of area and sector</p>
                        <div className="flex items-center justify-start gap-2">
                            <img src="/assets/images/avatar-group.svg" alt="" />
                            <p className="text-xs">250 + suggestions</p>
                        </div>
                        <div></div>
                    </div>
                    <div className="w-3/4 px-10">
                        {items.map((item, ind) => (
                            <div key={ind} className="border-white py-4 text-white border-b">
                                <div className="flex mb-2 items-center justify-start gap-2">
                                    <img src="/assets/images/suggestion-avatar.svg" alt="" className="" />
                                    <p className="font-semibold">{item.name}</p>
                                </div>
                                <p className="text-lg mb-1 font-medium">{item.title}</p>
                                <p className="text-xs">{item.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            <div className="flex justify-between items-center py-10 px-10">
                <img src="/assets/images/footer-logo.svg" alt="" className="w-1/4" />
                <div className="flex basis-2/3">
                    {footerData.map((data, ind) => (
                        <div className="basis-1/3 flex flex-col" key={ind}>
                            <p className="text-xl font-semibold mb-2">{data.title}</p>
                            {data.items?.map((item, index) => (
                                <a className="mb-2" href={item.link} key={index}>
                                    {item.name}
                                </a>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
            <p className="text-center py-3 border-t text-sm border-gray-200">@ 2024 Powered by EQUIPPP</p>
        </Fragment>
    );
};

export default SuggestProject;
