import React, { useState, Fragment, useMemo } from "react";
import { GoogleMap, MarkerF, useJsApiLoader, Autocomplete, Marker, InfoWindowF } from "@react-google-maps/api";
import PropTypes from "prop-types";
import { Switch } from "components/ui/switch";
import { googleMapInitializer } from "constants";
import { create } from "zustand";

export const useMapStore = create((set) => ({
    zoomLevel: 8,
    areMarkersVisible: true,
    actions: {
        setZoomLevel: (zoomLevel) => set({ zoomLevel }),
        showMarkers: () => set({ areMarkersVisible: true }),
        hideMarkers: () => set({ areMarkersVisible: false }),
    },
}));

/*
    You have to enable the following APIs in the Google Cloud Console:
    1. Google Maps JavaScript API
    2. Google Maps Places API
*/
const Map = ({ currentPosition, allPositions, onMarkerClick }) => {
    const mapStyles = {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: "5px",
    };
    // const zoomLevel = useMemo(() => (currentPosition ? 14 : 4.2), [currentPosition]);

    const { zoomLevel, areMarkersVisible } = useMapStore();

    const center = {
        lat: currentPosition?.coordinates[1] || allPositions?.[0]?.lat || 17.8496,
        lng: currentPosition?.coordinates[0] || allPositions?.[0]?.lng || 79.1152,
    };

    const [showInfoWindow, setShowInfoWindow] = useState(true);

    const { isLoaded, loadError } = useJsApiLoader(googleMapInitializer);

    if (loadError) {
        return <div>Map cannot be loaded right now, sorry.</div>;
    }

    const toggleInfoWindow = () => {
        setShowInfoWindow(!showInfoWindow); // Toggle InfoWindow display
    };

    const getMarkerIcon = (position) => {
        const isSelected = currentPosition && position.lat === currentPosition.coordinates[1] && position.lng === currentPosition.coordinates[0];
        return {
            url: "https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi3_hdpi.png",
            scaledSize: isSelected ? new window.google.maps.Size(30, 42) : new window.google.maps.Size(15, 21),
        };
    };

    return (
        <Fragment>
            {/* <div className="flex  mb-2 items-center">
                <Switch onClick={() => setShowMarkers(!showMarkers)} />
                <p className="ml-2 text-md font-semibold">{showMarkers ? "Hide Projects" : "Show Projects"}</p>
            </div> */}

            {isLoaded ? (
                <GoogleMap mapContainerStyle={mapStyles} zoom={zoomLevel} center={center}>
                    {allPositions.map((position, index) => {
                        if (!position.lat || !position.lng) return null;

                        return (
                            <MarkerF key={index} icon={getMarkerIcon(position)} position={position} onClick={() => onMarkerClick(index, position)} />
                        );
                    })}
                </GoogleMap>
            ) : (
                <h4>Loading...</h4>
            )}
        </Fragment>
    );
};

export default Map;
