import { React, useEffect, useState, Fragment } from "react";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useUpdateUserKycStatusMutation } from "types/generated-types.ts";
import "./PendingKyc.styles.scss";

const PendingKyc = ({ refetch, kycDetails }) => {
    const [accessToken, setAccessToken] = useState(false);
    const [tableRows, setTableRows] = useState([]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (event, newPage) => {
        console.log(newPage);
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [updateUserKycStatus] = useUpdateUserKycStatusMutation({
        onCompleted: (data) => {
            if (data.update_users.returning[0].kyc_status === "accepted") {
                toast.success("Kyc Approved Successfully");
            } else if (data.update_users.returning[0].kyc_status === "rejected") {
                toast.success("Kyc Rejected Successfully");
            } else {
                toast.success("Something Went Wrong");
            }
        },
    });
    const handleStatusChange = (userId, status) => {
        updateUserKycStatus({
            variables: {
                _set: {
                    kyc_status: status,
                },
                id: userId,
            },
        });
        refetch();
    };

    useEffect(() => {
        const token = window.localStorage.getItem("authToken");
        setAccessToken(token);
        const rows = kycDetails
            ? kycDetails.map((el) => {
                  return {
                      user_name: el.user_name,
                      email_id: el.email_id,
                      kyc_status: el.kyc_status,
                      kyc_submission_date: el.kyc_submission_date,
                      file: (
                          <Button
                              className="kyc__file-name"
                              onClick={() => {
                                  window.open(el.kyc_url, "_blank", "noopener,noreferrer");
                              }}
                          >
                              View File
                          </Button>
                      ),
                      addBtn: (
                          <Grid item xs={"auto"} container direction="row" alignItems="center" justifyContent="center" className="action__btns">
                              <button onClick={() => handleStatusChange(el.id, "accepted")}>
                                  <img
                                      src={`https://equippp-assets.s3.ap-south-1.amazonaws.com/images/success.png`}
                                      alt=""
                                      className="action__icon"
                                  />
                              </button>
                              &nbsp;&nbsp;
                              <button onClick={() => handleStatusChange(el.id, "rejected")}>
                                  <img src={`https://equippp-assets.s3.ap-south-1.amazonaws.com/images/close.png`} alt="" className="action__icon" />
                              </button>
                          </Grid>
                      ),
                  };
              })
            : [];
        setTableRows(rows);
    }, [kycDetails]);

    return (
        <Fragment>
            <Typography className="title">Users KYC</Typography>
            <br />
            {!!accessToken ? (
                <div className="exp-table">
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>User Name</TableCell>
                                    <TableCell>User Email</TableCell>
                                    <TableCell>Kyc Status</TableCell>
                                    <TableCell>Submission Date</TableCell>
                                    <TableCell>File</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableRows &&
                                    tableRows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                        <TableRow key={row.user_name}>
                                            <TableCell>{row.user_name}</TableCell>
                                            <TableCell>{row.email_id}</TableCell>
                                            <TableCell>{row.kyc_status}</TableCell>
                                            <TableCell>{row.kyc_submission_date?.substr(0, 10).split("-").reverse().join("-") || "NA"}</TableCell>
                                            <TableCell>{row.file}</TableCell>
                                            <TableCell>{row.addBtn}</TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {tableRows && tableRows.length > 0 && (
                        <TablePagination
                            component="div"
                            count={tableRows?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            rowsPerPageOptions={[5, 10]}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    )}
                </div>
            ) : (
                <Typography>Not logged in</Typography>
            )}
        </Fragment>
    );
};

export default PendingKyc;
