import { useState } from "react";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import RegisterModalFooter from "../RegisterModalFooter/RegisterModalFooter";

const UserType = ({ userType, setState, setOpen }) => {
    const [userTypeError, setUserTypeError] = useState(() => "");

    const [otherUserType, setOtherUserType] = useState("");

    const handleUserTypeChange = (e) => {
        if (e.target.name === "Others") {
            setOtherUserType(e.target.value);
            setState((prevState) => ({
                ...prevState,
                [e.target.name]: otherUserType,
            }));
        } else {
            setState((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value,
            }));
        }
    };

    const handleForwardClick = (e) => {
        e.preventDefault();
        if (!userType) {
            setUserTypeError("Please select user type");
            return;
        }
        if (userType === "Individual") {
            setOpen("individual");
        } else if (userType === "CSR foundation") {
            setOpen("csr-foundation");
        } else if (userType === "Individual") {
            setOpen("individual");
        } else if (userType === "Non-Profit Organization") {
            setOpen("npo");
        } else if (userType === "Subject Matter Expert") {
            setOpen("sme");
        } else if (userType === "Pro-Bono") {
            setOpen("pro-bono");
        } else if (userType === "Public Sector Bank") {
            setOpen("psb");
        } else if (userType === "Member of Parliament") {
            setOpen("mp");
        } else if (userType === "Member of Legislative Assembly") {
            setOpen("mla");
        } else if (userType === "Students") {
            setOpen("student");
        } else if (userType === "Others") {
            setOpen("others");
        }
    };
    return (
        <Grid item xs={12} className="common-modal__container" container direction="column" justifyContent="flex-start" alignItems="flex-start">
            <h2>Sign up as</h2>
            <p>Please select the industry below to continue</p>
            <FormControl sx={{ width: "100%" }} className="usertype__container">
                <InputLabel id="demo-simple-select-standard-label">User Type</InputLabel>
                <Select value={userType} name="userType" onChange={handleUserTypeChange} className="modal-select-input">
                    <MenuItem value={"Individual"}>Individual</MenuItem>
                    <MenuItem value={"CSR foundation"}>CSR foundation</MenuItem>
                    <MenuItem value={"Non-Profit Organization"}>Non-Profit Organization</MenuItem>
                    <MenuItem value={"Subject Matter Expert"}>Subject Matter Expert</MenuItem>
                    <MenuItem value={"Pro-Bono"}>Pro-Bono</MenuItem>
                    <MenuItem value={"Public Sector Bank"}>Public Sector Bank</MenuItem>
                    <MenuItem value={"Member of Parliament"}>Member of Parliament</MenuItem>
                    <MenuItem value={"Member of Legislative Assembly"}>Member of Legislative Assembly</MenuItem>
                    <MenuItem value={"Students"}>Student</MenuItem>
                    <MenuItem value={"Others"}>Others</MenuItem>
                </Select>
                {userType === "Others" && (
                    <p className="others-textfield__container">
                        <span> Others</span>
                        <input type="text" name="Others" value={otherUserType} placeholder="mention here" onChange={handleUserTypeChange} />
                    </p>
                )}
            </FormControl>
            {userTypeError && (
                <FormHelperText className="select-helper-text">
                    {" "}
                    <small className="text-danger">{userTypeError}</small>{" "}
                </FormHelperText>
            )}
            <Grid item container direction="row" justifyContent="flex-end" alignItems="center" className="button__container">
                <Button className="continue__btn" type="button" onClick={handleForwardClick}>
                    Save and continue
                </Button>
            </Grid>
            <RegisterModalFooter />
        </Grid>
    );
};

export default UserType;
