import { Fragment, useState, useContext, useCallback, useRef, useMemo, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Document, Page, pdfjs } from "react-pdf";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useGetUploadUrlMutation } from "types/generated-types.ts";
import { useGetCurrentUserLazyQuery, useEditProfileMutation } from "types/generated-types.ts";
import CustomModal, { MapModalContent } from "components/CustomModal/CustomModal";
import ROUTES from "constants/routes";
import KEYS from "api";
import AuthUserContext from "../../contexts/session";
import { UserTypeDetails } from "./Component/UserTypeDetails";
import "./Profile.styles.scss";

// pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.2.16.105.js";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Profile = () => {
    const [file, setFile] = useState(null);
    const [url, setUrl] = useState("");
    const documentRef = useRef(null);
    const [isMapModalOpen, setMapModal] = useState(false);
    const [openView, setOpenView] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [fileUploadType, setFileUploadType] = useState("");
    const [tabIndex, setTabIndex] = useState(0);
    const Tabs = ["Profile"];
    const { authUser } = useContext(AuthUserContext);
    const queryClient = useQueryClient();
    const username_pattern = /^\w+$/;

    const navigate = useNavigate();
    const [editProfile, { loading: editProfileLoading, error: editProfileError }] = useEditProfileMutation({
        onCompleted: (data) => {
            console.log(data);
            console.log("error", editProfileError);
            if (data && data?.update_users?.returning?.length && data?.update_users?.returning[0]?.id) {
                // TODO: Use path params
                navigate({
                    pathname: ROUTES.PROFILE_DETAILS,
                    search: `?id=${data.update_users.returning[0].id}`,
                });
            }
        },
    });

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "fit-content",
        minWidth: "400px",
        bgcolor: "background.paper",
        border: "1px solid gray",
        boxShadow: 24,
        p: 3,
    };

    useMemo(() => {
        if (editProfileError) {
            const { graphQLErrors } = editProfileError;
            if (graphQLErrors) {
                console.log(graphQLErrors);
                let error = graphQLErrors?.[0]?.message;
                toast.dismiss();
                if (error?.includes("users_user_name_key")) {
                    toast.error("Username is Not available");
                } else {
                    console.log("err", graphQLErrors);
                    toast.error(error);
                }
            }
        }
    }, [editProfileError]);

    const [
        {
            name,
            email,
            description,
            twitter_link,
            facebook_link,
            linkedin_link,
            other_link,
            user_name,
            profile_picture,
            profession,
            is_private,
            user_type,
            user_type_details,
            kyc_submission_date,
            location_coordinates,
            location_title,
            kyc_url,
            kyc_status,
            kyc_file_name,
        },
        setValues,
    ] = useState({
        project_id: "",
        email: "",
        name: "",
        description: "",
        twitter_link: "",
        facebook_link: "",
        linkedin_link: "",
        other_link: "",
        user_name: "",
        user_type: "",
        user_type_details: {},
        profession: "",
        kyc_submission_date: new Date(),
        is_private: false,
        profile_picture: "",
        location_coordinates: {
            lat: "",
            lng: "",
        },
        kyc_url: "",
        kyc_file_name: "",
        kyc_status: "",
        location_title: "",
    });

    const [getUploadURL, { loading: uploadLoading, error: uploadError }] = useGetUploadUrlMutation({
        onCompleted: async (data) => {
            console.log(data, "reched");
            if (data?.getUploadURL?.success) {
                try {
                    let { downloadUrl, uploadParams } = data.getUploadURL;
                    uploadParams = JSON.parse(uploadParams);
                    let formData = new FormData();
                    Object.keys(uploadParams.fields).forEach((key) => {
                        formData.append(key, uploadParams.fields[key]);
                    });
                    formData.append("file", file);
                    const result = await axios.post(uploadParams.url, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    });
                    if (fileUploadType === "profile_picture") {
                        setValues((prev) => ({ ...prev, profile_picture: downloadUrl }));
                        console.log(downloadUrl);
                        toast.dismiss();
                        toast.success("Profile picture uploaded successfully, please click on save to save the changes");
                    } else {
                        setValues((prev) => ({
                            ...prev,
                            kyc_url: downloadUrl,
                            kyc_status: "pending",
                            kyc_submission_date: new Date(),
                            kyc_file_name: file.name,
                        }));
                        toast.dismiss();
                        toast.success("Kyc document uploaded successfully, please click on save to save the changes");
                    }
                } catch (e) {
                    console.log(e);
                } finally {
                }
            }
        },
    });

    useEffect(() => {
        if (uploadError) {
            toast.dismiss();
            if (uploadError?.graphQLErrors?.length) {
                setFile(null);
                toast.error(uploadError.graphQLErrors[0].message);
            } else {
                toast.error("Error uploading file");
            }
        }
    }, [uploadError]);

    const handleDocumentUpload = () => {
        console.log("clicked");
        documentRef.current?.click();
    };

    const handleDocumentUploadChange = (event) => {
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
            return;
        }
        if (fileObj.size / 1024 / 1024 > 5) {
            alert("File Size must be less than 5mb");
            return;
        }
        toast.loading("Uploading file, please wait...");
        const fileExtension = fileObj.name.split(".").pop();
        const fileName = `picture.${fileExtension}`;
        setFileUploadType("kyc");
        setFile(fileObj);
        setUrl(URL.createObjectURL(fileObj));
        getUploadURL({
            variables: {
                fileName: fileName,
                fileSize: fileObj.size,
                path: `\${type}/\${userId}/${fileName}`,
                uploadType: "kyc",
            },
        });
    };

    const [getUsers, { data, loading: isLoading }] = useGetCurrentUserLazyQuery();
    useEffect(() => {
        if (authUser && authUser.id) {
            getUsers({
                variables: {
                    id: authUser.id,
                },
            });
        }
    }, [authUser, getUsers]);

    console.log("auth", authUser);
    console.log("data", data);

    const onDrop = useCallback((acceptedFiles) => {
        // Do something with the files
        const fileObj = acceptedFiles[0];
        if (!fileObj) {
            return;
        }
        if (fileObj.size / 1024 / 1024 > 5) {
            alert("File Size must be less than 5mb");
            return;
        }
        toast.loading("Uploading file, please wait...");
        const fileExtension = fileObj.name.split(".").pop();
        const fileName = `picture.${fileExtension}`;
        setFileUploadType("kyc");
        console.log("file", fileObj);
        setFile(fileObj);
        getUploadURL({
            variables: {
                fileName: fileName,
                fileSize: fileObj.size,
                path: `\${type}/\${userId}/${fileName}`,
                uploadType: "kyc",
            },
        });
    }, []);

    const { getRootProps, getInputProps, acceptedFiles, isDragActive } = useDropzone({
        accept: {
            "image/*": [".jpeg", ".png"],
            "application/pdf": [".pdf"],
        },
        maxFiles: 5,
        onDrop: onDrop,
    });

    console.log(user_type, user_type_details);

    useEffect(() => {
        if (!data) return;
        console.log("data", data);
        if (data) {
            const {
                id,
                user_name,
                name,
                email_id: email,
                description,
                twitter_link,
                facebook_link,
                linkedin_link,
                other_link,
                is_private,
                user_type,
                user_type_details,
                profile_picture,
                kyc_status,
                kyc_url,
                kyc_file_name,
                kyc_submission_date,
                profession,
                location_coordinates,
                location_title,
            } = data.users_by_pk;

            const locationExists = location_coordinates && location_coordinates.coordinates[0] && location_coordinates.coordinates[1];

            setValues({
                project_id: id || "",
                email: email || "",
                name: name || "",
                user_name: user_name || "",
                description: description || "",
                profession: profession || "",
                is_private: is_private || false,
                user_type: user_type || "",
                user_type_details: JSON.parse(user_type_details || "{}"),
                kyc_submission_date: kyc_submission_date || new Date(),
                kyc_url: kyc_url || "",
                kyc_file_name: kyc_file_name || "",
                location_coordinates: locationExists
                    ? {
                          lat: location_coordinates.coordinates[1],
                          lng: location_coordinates.coordinates[0],
                      }
                    : { lat: "", lng: "" },
                location_title: location_title || "",
                profile_picture: profile_picture || "",
                user_name: user_name || "",
                twitter_link: twitter_link || "",
                facebook_link: facebook_link || "",
                // kyc_status: kyc_status || "",
                linkedin_link: linkedin_link || "",
                other_link: other_link || "",
            });
        }
    }, [data]);

    const handleChange = (e) => {
        setValues((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };

    const handleUserTypeDetailsChange = (e) => {
        // date_of_birth: date_of_birth ? date_of_birth.split("T")[0] : "",
        if (e.target.name === "date_of_birth") {
            setValues((prevState) => ({
                ...prevState,
                ["user_type_details"]: { ...user_type_details, [e.target.name]: e.target.value.split("T")[0] },
            }));
            return;
        }
        console.log(e.target.value);
        setValues((prevState) => ({
            ...prevState,
            ["user_type_details"]: { ...user_type_details, [e.target.name]: e.target.value },
        }));
    };

    const handleFileChange = (event) => {
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
            return;
        }

        if (fileObj.size / 1024 / 1024 > 5) {
            alert("File Size must be less than 5mb");
            return;
        }

        toast.loading("Uploading file, please wait...");

        setFile(fileObj);

        // get file extension
        const fileExtension = fileObj.name.split(".").pop();

        const fileName = `picture.${fileExtension}`;
        setFileUploadType("profile_picture");
        getUploadURL({
            variables: {
                fileName: fileName,
                fileSize: fileObj.size,
                path: `\${type}/\${userId}/${fileName}`,
                uploadType: "profile_pictures",
            },
        });
    };
    const handleBackClick = () => {
        if (tabIndex === 0) {
            navigate("/");
        } else {
            setTabIndex(0);
        }
    };
    const handleSubmit = () => {
        console.log("1");
        if (tabIndex === 0) {
            console.log("2");

            if (!user_name) {
                toast.error("Please enter user_name");
                return;
            }

            console.log("3");

            if (!user_type_details.name) {
                toast.error("Name is required");
                return;
            }

            console.log("5");

            console.log("6");

            if (!location_coordinates.lat || !location_coordinates.lng) {
                toast.error("Location is required");
                return;
            }

            console.log("7");

            // setTabIndex(1);
        }

        // if (!data?.users_by_pk?.kyc_status && !kyc_url) {
        //     toast.error("KYC Details is required");
        //     return;
        // }

        console.log("8");

        editProfile({
            variables: {
                _set: {
                    description,
                    profession,
                    is_private,
                    user_type,
                    user_type_details: JSON.stringify(user_type_details),
                    kyc_submission_date,
                    email_id: email,
                    location_coordinates: {
                        type: "Point",
                        coordinates: [location_coordinates.lng, location_coordinates.lat],
                    },
                    profile_picture,
                    user_name,
                    twitter_link,
                    location_title,
                    facebook_link,
                    linkedin_link,
                    other_link,
                    // kyc_status,
                    kyc_file_name,
                    kyc_url,
                },
                id: authUser.id,
            },
        });
        queryClient.invalidateQueries([KEYS.GET_PROFILE_DETAILS]);
    };
    console.log("here", {
        description,
        profession,
        is_private,
        user_type,
        user_type_details: JSON.stringify(user_type_details),
        kyc_submission_date,
        email_id: email,
        location_coordinates: {
            type: "Point",
            coordinates: [location_coordinates.lng, location_coordinates.lat],
        },
        profile_picture,
        user_name,
        twitter_link,
        location_title,
        facebook_link,
        linkedin_link,
        other_link,
        kyc_status,
        kyc_file_name,
        kyc_url,
    });

    const handleDelete = () => {
        if (kyc_url) {
            setValues((prevState) => ({
                ...prevState,
                ["kyc_status"]: null,
                [" kyc_file_name"]: null,
                ["kyc_url"]: null,
            }));
        }

        if (file) {
            setFile(null);
        }
    };

    console.log(kyc_url, "urls", url);

    return (
        <Grid container justifyContent="space-between" alignItems="flex-start" id="profile__container">
            <Grid item xs={12} md={2} className="sidebar__container" alignItems="flex-start" direction="column">
                <Grid item>
                    <p>Profile Settings</p>
                </Grid>

                {Tabs.map((ele, index) => (
                    <Grid item onClick={() => setTabIndex(index)}>
                        <Link to={""} className={`sidebar-link ${index === tabIndex && "selected"}`}>
                            {ele} {index === tabIndex && <TrendingFlatIcon />}
                        </Link>
                    </Grid>
                ))}
            </Grid>
            <Grid xs={12} md={10} item className="main-profile__container">
                <Grid className="main-profile" container justifyContent="center" alignItems="center">
                    {isLoading ? (
                        <Grid item xs={12} container justifyContent="center" alignItems="center">
                            <CircularProgress />
                        </Grid>
                    ) : (
                        <Grid container justifyContent="flex-start" alignItems="center">
                            {/* <Grid>
                                    {" "}
                                    {authUser && !authUser.profileUpdated && (
                                        <Typography className="subtitle" style={{ color: "red" }}>
                                            Please update your profile to continue
                                        </Typography>
                                    )}
                                </Grid> */}
                            {tabIndex === 0 && (
                                <Grid container className="input-container">
                                    <UserTypeDetails
                                        name={name}
                                        email={email}
                                        description={description}
                                        twitter_link={twitter_link}
                                        facebook_link={facebook_link}
                                        linkedin_link={linkedin_link}
                                        other_link={other_link}
                                        kyc_status={kyc_status}
                                        user_name={user_name}
                                        user_type={user_type}
                                        user_type_details={user_type_details}
                                        location_title={location_title}
                                        profession={profession}
                                        profile_picture={profile_picture}
                                        isMapModalOpen={isMapModalOpen}
                                        setMapModal={setMapModal}
                                        uploadProfiePictureLoading={uploadLoading}
                                        handleChange={handleChange}
                                        handleUserTypeDetailsChange={handleUserTypeDetailsChange}
                                        handleFileChange={handleFileChange}
                                        refetchProfile={getUsers}
                                    />
                                </Grid>
                            )}
                            {tabIndex === 1 && (
                                <Grid item xs={12} container justifyContent="space-between" alignItems="center" className="kyc-container">
                                    {(user_type === "CSR foundation" ||
                                        user_type === "Non-Profit Organization" ||
                                        user_type === "Public Sector Bank") && (
                                        <Fragment>
                                            <Grid item xs={12}>
                                                <p className="sub-heading">Representative</p>
                                            </Grid>
                                            <Grid item xs={12} container justifyContent="space-between" alignItems="center" mb="25px">
                                                <Grid item xs={6} pr="2rem">
                                                    <TextField
                                                        type="text"
                                                        fullWidth
                                                        autoComplete="off"
                                                        variant="standard"
                                                        label="Employee ID"
                                                        value={user_type_details.employeeID}
                                                        onChange={handleUserTypeDetailsChange}
                                                        name="employee_id"
                                                        className="input-field"
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        type="text"
                                                        fullWidth
                                                        autoComplete="off"
                                                        variant="standard"
                                                        label="Representative Contact"
                                                        value={user_type_details.representativeContact}
                                                        onChange={handleUserTypeDetailsChange}
                                                        name="representative_contact"
                                                        className="input-field"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Fragment>
                                    )}
                                    <Grid item xs={12} container justifyContent="flex-start" alignItems="flex-start" direction="column">
                                        {kyc_status !== "accepted" && kyc_status !== "pending" && !file && (
                                            <Fragment>
                                                <p className="kyc-heading">Upload Documents</p>

                                                <p>Upload any Govt. accepted ID - Aadhaar, PAN, Driver's license, Passport, etc</p>
                                            </Fragment>
                                        )}
                                        {kyc_status === "accepted" && <p className="kyc-heading">Your KYC document is verified.</p>}
                                        {(kyc_status === "pending" || file) && <h4>Your Kyc document is Under Verification</h4>}
                                        {kyc_status !== "accepted" && kyc_status !== "pending" && !file && (
                                            <Grid
                                                container
                                                alignItems="center"
                                                justifyContent="center"
                                                direction="column"
                                                {...getRootProps({ className: "dropzone" })}
                                                className="drag-and-drop__container"
                                            >
                                                <input className="input-zone" {...getInputProps()} />
                                                <img src="assets/images/files-icon.svg" alt="" />
                                                <p>Choose .jpg, .png, .pdf only</p>
                                                <Grid container justifyContent="space-around" mt="30px">
                                                    <button className="upload-btn">
                                                        <img src="assets/images/camera-icon.svg" alt="" /> Open Camera
                                                    </button>
                                                    <button className="upload-btn">
                                                        <img src="assets/images/mydrive-icon.svg" alt="" /> My Drive
                                                    </button>
                                                </Grid>
                                            </Grid>
                                        )}
                                        <input hidden ref={documentRef} type="file" onChange={handleDocumentUploadChange} />
                                        {file && (
                                            <Grid container justifyContent="space-between" className="uploaded-items">
                                                <Grid item>
                                                    <MoreVertIcon />
                                                    <span style={{ marginRight: "6px" }}>{file.name}</span>
                                                    <Fragment>
                                                        <img src="assets/images/time-machine.svg" alt="" />
                                                        <span style={{ color: "#FFAE0A" }}>Pending</span>
                                                    </Fragment>
                                                </Grid>
                                                <Grid item>
                                                    <img
                                                        src="assets/images/vision.svg"
                                                        className="icon"
                                                        onClick={() => setOpenView(true)}
                                                        style={{ cursor: "pointer" }}
                                                        alt=""
                                                    />
                                                    <img
                                                        src="assets/images/pencil.svg"
                                                        className="icon"
                                                        onClick={handleDocumentUpload}
                                                        style={{ cursor: "pointer" }}
                                                        alt=""
                                                    />
                                                    <img
                                                        src="assets/images/trash.svg"
                                                        className="icon"
                                                        onClick={() => setOpenDelete(true)}
                                                        style={{ cursor: "pointer" }}
                                                        alt=""
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                        {kyc_status === "accepted" && (
                                            <Grid container justifyContent="space-between" className="uploaded-items">
                                                <Grid item>
                                                    <MoreVertIcon />
                                                    <span style={{ marginRight: "6px" }}>{kyc_file_name}</span>
                                                    <Fragment>
                                                        <img src={"assets/images/verified.svg"} alt="doc" />
                                                        <span style={{ color: "#4CAF50" }}>Verified</span>
                                                    </Fragment>
                                                </Grid>
                                                <Grid item>
                                                    <img
                                                        src="assets/images/vision.svg"
                                                        className="icon"
                                                        onClick={() => setOpenView(true)}
                                                        style={{ cursor: "pointer" }}
                                                        alt=""
                                                    />
                                                    {/* <img src="assets/images/pencil.svg" className="icon" onClick={handleDocumentUpload} /> */}
                                                    {/* <img src="assets/images/trash.svg" className="icon" onClick={handleDelete} /> */}
                                                </Grid>
                                            </Grid>
                                        )}
                                        {kyc_status === "pending" && !file && (
                                            <Grid container justifyContent="space-between" className="uploaded-items">
                                                <Grid item>
                                                    <MoreVertIcon />
                                                    <span style={{ marginRight: "6px" }}>{kyc_file_name}</span>
                                                    <Fragment>
                                                        <img src="assets/images/time-machine.svg" alt="" />
                                                        <span style={{ color: "#FFAE0A" }}>Pending</span>
                                                    </Fragment>
                                                </Grid>
                                                <Grid item>
                                                    <img
                                                        src="assets/images/vision.svg"
                                                        className="icon"
                                                        onClick={() => setOpenView(true)}
                                                        style={{ cursor: "pointer" }}
                                                        alt=""
                                                    />
                                                    <img
                                                        src="assets/images/pencil.svg"
                                                        className="icon"
                                                        onClick={handleDocumentUpload}
                                                        style={{ cursor: "pointer" }}
                                                        alt=""
                                                    />
                                                    <img
                                                        src="assets/images/trash.svg"
                                                        className="icon"
                                                        style={{ cursor: "pointer" }}
                                                        alt=""
                                                        onClick={() => setOpenDelete(true)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                        {/* {kyc_status === "accepted" ? (
                                                <Fragment />
                                            ) : (
                                                <Fragment>
                                                    <Button
                                                        className="btn filled"
                                                        variant="contained"
                                                        onClick={handleDocumentUpload}
                                                        disabled={kyc_status === "pending" ? true : false}
                                                    >
                                                        Upload Kyc Document
                                                    </Button>
                                                </Fragment>
                                            )} */}
                                        {(user_type === "CSR foundation" ||
                                            user_type === "Non-Profit Organization" ||
                                            user_type === "Public Sector Bank") && (
                                            <Fragment>
                                                <p className="sub-heading">Company</p>
                                                <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                                                    <Grid item xs={6} pr="2rem">
                                                        <TextField
                                                            type="text"
                                                            fullWidth
                                                            autoComplete="off"
                                                            variant="standard"
                                                            label="RoC"
                                                            value={user_type_details.roc}
                                                            onChange={handleUserTypeDetailsChange}
                                                            name="roc"
                                                            className="input-field"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            type="text"
                                                            fullWidth
                                                            autoComplete="off"
                                                            variant="standard"
                                                            label="CIN"
                                                            value={user_type_details.cinNumber}
                                                            onChange={handleUserTypeDetailsChange}
                                                            name="cinNumber"
                                                            className="input-field"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} className="request_verification_container">
                                                        <InputLabel htmlFor="request_verification" className="request_verification">
                                                            <a href="mailto:cs@equippp.com?subject='Request for account verification!'&body='Please verify my account.'">
                                                                Request A/c verification via email
                                                            </a>
                                                        </InputLabel>
                                                        <Divider />
                                                        {/* <TextField
                                                            type="text"
                                                            id="request_verification"
                                                            fullWidth
                                                            autoComplete="off"
                                                            value=""
                                                            disabled
                                                            variant="standard"
                                                            name="request_verification"
                                                            className="input-field"
                                                        /> */}
                                                    </Grid>
                                                </Grid>
                                            </Fragment>
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                            <Grid item xs={true} container justifyContent="space-between" alignItems="center" className="btn-container">
                                <Button variant="container" className="btn" onClick={handleBackClick}>
                                    {tabIndex === 0 && "Back to Home"}
                                    {tabIndex === 1 && "Back"}
                                </Button>
                                <Button variant="container" className="btn filled" onClick={handleSubmit} disabled={editProfileLoading}>
                                    {editProfileLoading ? <CircularProgress style={{ color: "white" }} size={20} /> : "Save and Continue"}
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Modal open={openView} onClose={() => setOpenView(false)}>
                <Box sx={style}>
                    <Grid container className="">
                        {/* <Grid item container justifyContent={"flex-end"}>
                            <CloseIcon onClick={()=>setOpenView(false)}/>
                        </Grid> */}
                        {kyc_url?.includes("pdf") ? (
                            <Document file={kyc_url} onContextMenu={(e) => e.preventDefault()}>
                                <Page pageNumber={1} />
                            </Document>
                        ) : (
                            <img src={kyc_url} width="100%" height={"100%"} alt="" />
                        )}
                        {/* {url && <img src={url} width="100%" height={"100%"} alt="" />} */}
                    </Grid>
                </Box>
            </Modal>
            <Modal open={openDelete} onClose={() => setOpenDelete(false)}>
                <Box sx={style}>
                    <Grid container className="">
                        <Grid item xs={12} container justifyContent="flex-start" alignItems="center">
                            <Typography className="delete-title">Are you sure you want to delete the uploaded file?</Typography>
                        </Grid>

                        <Grid item xs={12} container justifyContent="flex-end">
                            <Button variant="standard" className="btn close" onClick={handleDelete}>
                                Yes
                            </Button>
                            <Button variant="standard" className="btn save" onClick={() => setOpenDelete(false)}>
                                No
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <CustomModal isOpen={isMapModalOpen} header="&nbsp;Choose your Location" setOpen={setMapModal}>
                <MapModalContent
                    onLocationChange={(value) =>
                        handleChange({
                            target: {
                                value,
                                name: "location_coordinates",
                            },
                        })
                    }
                    onInputChange={(name, value) => {
                        setValues((prevState) => ({
                            ...prevState,
                            [name]: value,
                        }));
                    }}
                    setOpen={setMapModal}
                />
            </CustomModal>
        </Grid>
    );
};
export default Profile;
