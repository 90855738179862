import { React, useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useGetProjectQuery } from "types/generated-types.ts";
import { useDeleteExpressionMutation } from "types/generated-types.ts";
import CustomModal, { ExpressInterestModalContent } from "components/CustomModal/CustomModal";
import "./ApprovedExpressions.scss";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "fit-content",
    bgcolor: "background.paper",
    border: "1px solid gray",
    boxShadow: 24,
    p: 3,
};

const ApprovedExpressions = (props) => {
    const [accessToken, setAccessToken] = useState(false);
    const [tableRows, setTableRows] = useState([]);
    const [open, setOpen] = useState(false);
    const [expId, setExpId] = useState("");
    const [project, setProject] = useState([]);
    const [projectId, setProjectId] = useState("");
    const [isEditModalOpen, setEditModal] = useState(() => false);
    const [existingExpression, setExistingExpression] = useState({
        exp_id: "",
        investment_amount: 0,
        expression_message: "",
        investment_type: "",
    });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (event, newPage) => {
        console.log(newPage);
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const setNotification = (val, header, body) => {};

    const { data: projectData } = useGetProjectQuery({
        variables: {
            id: projectId,
        },
        skip: !projectId,
    });

    useEffect(() => {
        if (projectData) {
            setProject(projectData && projectData.projects[0]);
        }
    }, [projectData]);

    const toggleAuthModal = (value) => {
        if (value) setEditModal(value);
        else setEditModal(false);
    };

    useEffect(() => {
        const token = window.localStorage.getItem("authToken");
        setAccessToken(token);
        const rows = props.expressionDetails?.map((el) => {
            return {
                id: el.project_id,
                expression_id: el.id,
                projectName: el.project.title,
                investedType: el.investment_type,
                investedAmount: el.investment_amount,
                investmentMsg: el.expression_message,
                investedBy: el.user?.user_name || el.user?.email_id,
                date: el.created_at.substring(0, 10),
                optionsBtns: (
                    <Grid item xs={"auto"} container direction="row" alignItems="center" justifyContent="center" className="action__btns">
                        <Button
                            className="outlined-btn"
                            variant="outlined"
                            onClick={() => {
                                setExistingExpression({
                                    ...existingExpression,
                                    exp_id: el.id,
                                    investment_amount: el.investment_amount,
                                    expression_message: el.expression_message,
                                    investment_type: el.investment_type,
                                });
                                setProjectId(el.project_id);
                                setExpId(el.id);
                                toggleAuthModal("express-interest");
                            }}
                        >
                            <EditIcon className="edit-icon" />
                            Edit
                        </Button>
                        <Button
                            className="outlined-btn"
                            variant="outlined"
                            onClick={() => {
                                setExpId(el.id);
                                setOpen(true);
                            }}
                        >
                            <DeleteIcon className="edit-icon" />
                            Delete
                        </Button>
                    </Grid>
                ),
            };
        });
        setTableRows(rows);
    }, [props.expressionDetails]);

    const [deleteExpression] = useDeleteExpressionMutation({
        onCompleted: (data) => {
            toast.success("Expression Deleted Successfully");
            setTableRows(
                tableRows.filter((exp) => {
                    return exp.expression_id !== data?.delete_expressions?.returning[0]?.id;
                })
            );
            setOpen(false);
            setExpId("");
        },
    });

    const receivedExpressions = useMemo(() => {
        if (project && project?.expressions && project?.expressions?.length) {
            return project?.expressions?.reduce((acc, curr) => (curr.is_verified ? acc + curr.investment_amount : acc), 0);
        }

        return 0;
    }, [project]);

    return (
        <Grid item xs={12}>
            {!!accessToken ? (
                <Grid item>
                    <TableContainer>
                        <Table>
                            <TableHead className="table-header-font-style">
                                <TableRow>
                                    <TableCell>Project Name</TableCell>
                                    <TableCell>Investment Type</TableCell>
                                    <TableCell>Investment Amount</TableCell>
                                    <TableCell>Expression Message</TableCell>
                                    <TableCell>Invested By</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell align="center">Options</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableRows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, key) => (
                                    <TableRow key={key}>
                                        <TableCell component="td" scope="row" style={{ textAlign: "left" }}>
                                            <Link to={`/projects/${row?.id}`}>{row.projectName}</Link>
                                        </TableCell>
                                        <TableCell>{row.investedType}</TableCell>
                                        <TableCell>{row.investedAmount}</TableCell>
                                        <TableCell>{row.investmentMsg}</TableCell>
                                        <TableCell>
                                            <Link to={`/profile-details?id=${row?.id}`}>{row.investedBy}</Link>
                                        </TableCell>
                                        <TableCell style={{ whiteSpace: "none", width: "8rem" }}>{row.date}</TableCell>
                                        <TableCell>{row.optionsBtns}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {tableRows && tableRows.length > 0 && (
                        <TablePagination
                            component="div"
                            count={tableRows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            rowsPerPageOptions={[5, 10]}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    )}
                </Grid>
            ) : (
                <Typography>Not logged in</Typography>
            )}
            <Modal open={open} onClose={() => setOpen(false)}>
                <Box sx={style}>
                    <Grid container className="edit-delete-timeline__modal">
                        <Grid item xs={12} container justifyContent="flex-start" alignItems="center">
                            <Typography className="delete-title">Are you sure you want to delete this expression?</Typography>
                        </Grid>
                        <Grid item xs={12} container justifyContent="flex-end">
                            <Button
                                variant="standard"
                                className="btn close"
                                onClick={() => {
                                    deleteExpression({
                                        variables: {
                                            id: expId,
                                        },
                                    });
                                }}
                            >
                                Yes
                            </Button>
                            <Button variant="standard" className="btn save" onClick={() => setOpen(false)}>
                                No
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <CustomModal isOpen={isEditModalOpen === "express-interest"} header="Edit Expression" setOpen={setEditModal}>
                <ExpressInterestModalContent
                    setOpen={setEditModal}
                    projectId={projectId}
                    remainingFund={project.investment_amount - receivedExpressions}
                    toggle={(val, header = "Success!", body = "Expression Interest Update By Admin.") => setNotification(val, header, body)}
                    projectDetails={project}
                    isExpressionEdit={true}
                    existingExpression={existingExpression}
                    setExistingExpression={setExistingExpression}
                />
            </CustomModal>
        </Grid>
    );
};

export default ApprovedExpressions;
