import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import dayjs from "dayjs";
import "./ProjectsCalender.styles.scss";

const ProjectsCalender = () => {
    const [value, setValue] = useState(() => dayjs("2022-02-01T00:00"));

    return (
        <Grid className="calender__container">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <p className="calender__text">
                    Projects Calender &#40;<span> Click on the date to view scheduled projects </span>&#41;
                </p>
                <StaticDatePicker
                    onChange={(newValue) => setValue(newValue)}
                    value={value}
                    renderInput={(params) => <TextField {...params} />}
                    // componentsProps={{
                    //     actionBar: {
                    //         actions: ["today"],
                    //     },
                    // }}
                    className="calender__datePicker"
                />
            </LocalizationProvider>
        </Grid>
    );
};

export default ProjectsCalender;
