import { useEffect, useState, useContext, Fragment } from "react";
import { Blurhash } from "react-blurhash";
import { useNavigate, Navigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { PopupModals } from "components/PopupModals/PopupModals";
import AuthUserContext from "contexts/session";
import ROUTES from "constants/routes";
import { useGetProjectsAggregationQuery, useGetRegionAdminQuery } from "../../types/generated-types.ts";
import "./Home.styles.scss";
import MobileHome from "./MobileHome.jsx";
import { redirect } from "react-router-dom";

const projects = [
    {
        name: "POWER",
        img: "power.svg",
    },
    {
        name: "ROADS & BRIDGES",
        img: "roads.svg",
    },
    {
        name: "HEALTH CARE",
        img: "healthcare.svg",
    },
    {
        name: "EDUCATION",
        img: "education.svg",
    },
    {
        name: "JUSTICE",
        img: "justice.svg",
    },
    {
        name: "TECHNOLOGIES",
        img: "technologies.svg",
    },
    {
        name: "COMMUNITY",
        img: "community.svg",
    },
    {
        name: "HOUSING",
        img: "housing.svg",
    },
    {
        name: "AGRICULTURE",
        img: "agriculture.svg",
    },
    {
        name: "FARMING",
        img: "farming.svg",
    },
    {
        name: "WASTE MANAGEMENT",
        img: "waste.svg",
    },
    {
        name: "WATER AND SANITATION",
        img: "water.svg",
    },
];

const Home = () => {
    const { authUser } = useContext(AuthUserContext);

    const isAdmin = (authUser && authUser.role) === "admin";
    const navigate = useNavigate();

    const handleCreateProjectClick = () => {
        const accessToken = window.localStorage.getItem("authToken");
        if (!accessToken) {
            navigate("/login");
        }
        if (accessToken) {
            navigate(`${isAdmin ? ROUTES.ADMIN_CREATE_PROJECT : ROUTES.CREATE_PROJECT}`);
        }
    };
    useEffect(() => {
        document.title = `EquiPPP - Home`;
    }, []);

    const toggleAuthModal = (value) => {
        if (value) setModal(value);
        else setModal(false);
    };
    const [isModalOpen, setModal] = useState(() => false);
    const { data, error } = useGetProjectsAggregationQuery();

    // if (!authUser) {
    //     redirect("/login");
    // }

    console.log({
        item: localStorage.getItem("onboardingVisited"),
        width: window.innerWidth,
    });

    // if (!localStorage.getItem("onboardingVisited") && window.innerWidth < 768) {
    //     return <Navigate to="/onboarding" />;
    // }

    const { data: regionAdminData } = useGetRegionAdminQuery({});

    if (!localStorage.getItem("authToken") && window.innerWidth < 768) {
        return <Navigate to="/login" />;
    }

    return (
        <Fragment>
            <div className="home__container md:block hidden">
                <Grid container direction="column" className="panel-one">
                    <Grid container justifyContent="center" alignItems="center">
                        <Typography className="title">
                            SOCIAL PROJECTS EXECUTION MADE EASY THROUGH <br /> CROSS SECTOR PARTNERSHIPS
                        </Typography>
                    </Grid>
                    <Grid container justifyContent="center" alignItems="center">
                        <Typography className="description">
                            EquiPPP™ is a collaborative platform that vitalizes crowd participation in Public-Private Projects and connects
                            Corporates, <br />
                            Citizens, Domain Experts, NGOs and Philanthropists with the Government to Initiate and Participate together in socially
                            relevant projects.
                        </Typography>
                    </Grid>
                    <br />
                    <Grid container direction="row" justifyContent="center" alignItems="center" className="">
                        {authUser?.role === "admin" && (
                            <Button className="project-btn" onClick={handleCreateProjectClick}>
                                Create Project
                            </Button>
                        )}
                        &nbsp; &nbsp;
                        <Button
                            className="project-btn"
                            onClick={() => {
                                navigate(`/profile-details?id=${regionAdminData?.users[0].id}`);
                            }}
                        >
                            View Projects
                        </Button>
                        <Button
                            className="project-btn ml-4"
                            onClick={() => {
                                navigate(`/suggest-project`);
                            }}
                        >
                            Suggest Projects
                        </Button>
                    </Grid>
                </Grid>
                <Grid container className="panel-two">
                    <Grid container className="window-img">
                        <img src="https://equippp-assets.s3.ap-south-1.amazonaws.com/images/website-preview.png" alt="" />
                    </Grid>
                </Grid>
                <Grid container className="panel-top" justifyContent="center">
                    <Grid container direction="column" className="panel-three" justifyContent="center" alignItems="center">
                        <Typography className="count__title">Some count that matters</Typography>
                        <Typography className="count__sub-title">Our achievement in the journey depicted in numbers</Typography>
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                            <Grid container direction="column" className="count__section" item xs={"auto"}>
                                <Typography className="count__text" datatarget={12}>
                                    {data ? data.projects_aggregate.aggregate.count : "0"}
                                </Typography>
                                <Typography className="count__data">INITIATIVES</Typography>
                            </Grid>
                            <Grid container direction="column" className="count__section" item xs={"auto"}>
                                <Typography className="count__text" datatarget={9}>
                                    {data ? data.distinct_aggregate.aggregate.count : "0"}
                                </Typography>
                                <Typography className="count__data">PARTICIPANTS</Typography>
                            </Grid>
                            <Grid container direction="column" className="count__section" item xs={"auto"}>
                                <Typography className="count__text" datatarget={32}>
                                    1
                                </Typography>
                                <Typography className="count__data">GEOGRAPHIES COVERED</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="column" className="panel-four" justifyContent="center" alignItems="center">
                        <Grid className="inner__section" item container direction="column" xs={9} alignItems="center">
                            <Typography className="step__title">ABOUT US</Typography>
                            <Typography className="step__sub-title">
                                EquiPPP™ is a collaborative platform that vitalizes crowd participation in Public-Private Projects and connects
                                Corporates, Citizens, Domain Experts, NGOs and Philanthropists with the Government to Initiate and Participate
                                together in socially relevant projects.
                            </Typography>
                        </Grid>
                        <Grid item container direction="row" className="steps" justifyContent="space-around" alignItems="center">
                            <Grid
                                container
                                direction="column"
                                className="step__section"
                                item
                                xs={true}
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >
                                <img src="https://equippp-assets.s3.ap-south-1.amazonaws.com/images/feature_icon.svg" alt="" />
                                <Typography className="step__text">STEP: 1 - LOCATE</Typography>
                                <Typography className="step__data">
                                    EquiPPP™ has a database of projects selected across sectors and geographies started by Individuals and
                                    Organizations
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                direction="column"
                                className="step__section"
                                item
                                xs={true}
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >
                                <img src="https://equippp-assets.s3.ap-south-1.amazonaws.com/images/feature_icon.svg" alt="" />
                                <Typography className="step__text">STEP: 2 - INITIATE</Typography>
                                <Typography className="step__data">
                                    EquiPPP™ enables Individuals and Organizations to Initiate projects based on innovative solutions for
                                    socio-economic problems
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                direction="column"
                                className="step__section"
                                item
                                xs={true}
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >
                                <img src="https://equippp-assets.s3.ap-south-1.amazonaws.com/images/feature_icon.svg" alt="" />
                                <Typography className="step__text">STEP: 3 - MOTIVATE</Typography>
                                <Typography className="step__data">
                                    EquiPPP™ intends to Motivate Individuals and Organizations through its network of projects to collaborate,
                                    Initiate and/or Participate
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                direction="column"
                                className="step__section"
                                item
                                xs={true}
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >
                                <img src="https://equippp-assets.s3.ap-south-1.amazonaws.com/images/feature_icon.svg" alt="" />
                                <Typography className="step__text">STEP: 4 - PARTICIPATE</Typography>
                                <Typography className="step__data">
                                    The EquiPPP™ ecosystem through the collaborative work of Individuals and Organizations encourages participation in
                                    projects through expressions of interest on the platform
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item direction="column" className="panel-five" justifyContent="center" alignItems="center">
                        <Grid className="inner_section">
                            <Typography className="title">PROJECTS IN THE EQUIPPP ECOSYSTEM</Typography>
                            <Grid container direction="row" className="projects" justifyContent="center" alignItems="center">
                                {projects.map(({ name, img }, index) => (
                                    <Grid
                                        key={index}
                                        container
                                        direction="column"
                                        className="project__section"
                                        item
                                        xs={3}
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Grid item xs={12}>
                                            <Typography className="project__text">{name}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <img src={`https://equippp-assets.s3.ap-south-1.amazonaws.com/images/${img}`} alt="" />
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" className="panel-six" justifyContent="center" alignItems="center">
                        <img src="/assets/images/bottomBanner.svg" alt="" />
                    </Grid>
                </Grid>
                <PopupModals isModalOpen={isModalOpen} setModal={setModal} />
            </div>
            <div className="md:hidden block">
                <MobileHome
                    handleCreateProjectClick={handleCreateProjectClick}
                    toggleAuthModal={toggleAuthModal}
                    isModalOpen={isModalOpen}
                    setModal={setModal}
                    data={data}
                    error={error}
                    navigate={navigate}
                    projects={projects}
                />
            </div>
        </Fragment>
    );
};

export default Home;
