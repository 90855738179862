import { useGetProjectAnalyticsQuery, useGetProjectAnalyticsChartQuery, useGetTotalInvitesQuery } from "types/generated-types.ts";
import { AreaChart } from "@tremor/react";
import { useMemo, useEffect, Fragment } from "react";
import Chart from "chart.js/auto";
import { CircularProgress } from "@mui/material";
import { Progress } from "../../../../components/ui/progress";
import { motion as m } from "framer-motion";
const groupDataForEachDay = (data) => {
    // Extract all unique dates from the data
    const uniqueDates = [...new Set(data.map((item) => new Date(item.created_at).toDateString()))];

    // Group data for each unique date
    const groupedData = uniqueDates.map((dateString) => {
        const date = new Date(dateString);
        const dayData = data.filter((item) => {
            const itemDate = new Date(item.created_at);
            return itemDate.toDateString() === dateString;
        });
        return {
            date: new Date().getTime(),
            Amount: dayData.reduce((acc, item) => acc + item.investment_amount, 0),
        };
    });

    return groupedData;
};

const ProfileAnalytics = ({ currentProject, chartIdPrefix }) => {
    const dataFormatter = (number) => {
        if (number >= 1000000) {
            return (number / 1000000).toFixed(2) + "M";
        } else if (number >= 1000) {
            return (number / 1000).toFixed(2) + "k";
        } else {
            return Intl.NumberFormat("en").format(number);
        }
    };

    const { data, loading } = useGetProjectAnalyticsQuery({
        variables: {
            id: currentProject,
        },
        skip: !currentProject,
    });

    const { data: chartData, loading: chartDataLoading } = useGetProjectAnalyticsChartQuery({
        variables: {
            projectId: currentProject,
        },
        skip: !currentProject,
    });

    const { data: invitesData } = useGetTotalInvitesQuery({});

    const chartsData = useMemo(() => {
        if (chartData && chartData.expressions) {
            return groupDataForEachDay(chartData.expressions);
        }

        return groupDataForEachDay([]);
    }, [chartData]);

    const customTooltip = (props) => {
        const { payload, active } = props;
        if (!active || !payload) return null;
        return (
            <div className="w-56 rounded-tremor-default border border-tremor-border bg-tremor-background p-2 text-tremor-default shadow-tremor-dropdown">
                {payload.map((category, idx) => (
                    <div key={idx} className="flex flex-1 space-x-2.5">
                        <div className={`flex w-1 flex-col bg-${category.color}-500 rounded`} />
                        <div className="space-y-1">
                            <p className="text-tremor-content">{category.dataKey}</p>
                            <p className="font-medium text-tremor-content-emphasis">Rs. {dataFormatter(category.value)}</p>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    const chartId = `${chartIdPrefix}-acquisition`;

    useEffect(() => {
        if (!chartsData) return;

        const orderedData = chartsData.sort((a, b) => a.date - b.date).filter((row) => row.Amount > 0);

        // push one element to start and end of the array to make the chart look better
        if (orderedData.length) {
            orderedData.unshift({ date: orderedData[0].date - 86400000, Amount: 0 });
        }

        // const chart = new Chart(document.getElementById("acquisitions"), {
        //     type: "bar",
        //     data: {
        //         labels: data.map((row) => row.year),
        //         datasets: [
        //             {
        //                 label: "Acquisitions by year",
        //                 data: data.map((row) => row.count),
        //             },
        //         ],
        //     },
        // });

        // const labels = data.map((row) => row.year);

        const ctx = document.getElementById(chartId);

        if (!ctx) return;

        const chart = new Chart(ctx, {
            type: "line",
            options: {
                scales: {
                    y: {
                        ticks: {
                            maxTicksLimit: 10, // Limits the number of y-axis ticks to 5
                            callback: function (value, index, ticks) {
                                return dataFormatter(value);
                            },
                        },
                        // max: Math.max(...orderedData.map((row) => row.Amount)) * 1.2,
                    },
                    x: {
                        ticks: {
                            maxTicksLimit: 10,
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                let label = context.dataset.label || "";
                                if (label) {
                                    label += ": ";
                                }
                                label += dataFormatter(context.parsed.y);
                                return label;
                            },
                        },
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
            },
            data: {
                labels: orderedData.map((row, index) => (index === 0 ? "" : new Date(row.date).toLocaleDateString("en-IN"))),
                datasets: [
                    {
                        data: orderedData.map((row) => row.Amount),
                        fill: true,
                        borderColor: "#2D5BFF",
                        tension: 0,
                        pointRadius: 4,
                        backgroundColor: "rgba(45, 91, 255, 0.1)",
                    },
                ],
            },
        });

        return () => {
            console.log("Destroying.......");
            chart?.destroy();
        };
    }, [chartsData, loading, chartDataLoading]);

    const formatNumber = (number) => {
        return new Intl.NumberFormat("en-IN", {
            maximumFractionDigits: 2,
            style: "decimal",
        }).format(number);
    };

    const inviteNumbers = 834;

    // if (loading || chartDataLoading) {
    //     return <div className="flex items-center justify-center h-screen  w-full">{/* <CircularProgress /> */}</div>;
    // }
    const expressionsAmount = 232;
    var hostname = window.location.hostname;

    return (
        <m.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.1, ease: "easeInOut" }} className="">
            <div className="w-full flex-col h-full border-1 p-2 mt-2 flex rounded shadow-md bg-slate-50">
                <div className="border  flex md:hidden flex-col mb-3 rounded px-2 py-1 w-full ">
                    <Fragment>
                        <p className="text-[#9291A5] text-sm">Total Amount</p>
                        <p className="text-[#1E1B39] font-semibold text-sm mt-1">{formatNumber(data?.projects_by_pk?.estimated_cost)}</p>
                    </Fragment>
                </div>
                <div className="border flex flex-col mb-3 rounded px-2 py-1 w-full ">
                    {data?.projects_by_pk?.project_status === "completed" ? (
                        <Fragment>
                            {hostname === "zaheerabad.equippp.global" ? (
                                <Fragment>
                                    <p className="text-[#9291A5] text-sm md:text-base">Expressions Received</p>
                                    <p className="text-[#1E1B39] text-sm font-semibold md:font-bold md:text-lg">
                                        {expressionsAmount + data?.projects_by_pk?.expressions_aggregate?.aggregate?.count ?? 0}
                                    </p>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <p className="text-[#9291A5] text-sm md:text-base">Expressions Received</p>
                                    <p className="text-[#1E1B39] text-sm font-semibold md:font-bold md:text-lg">
                                        {data?.projects_by_pk?.expressions_aggregate?.aggregate?.count ?? 0}
                                    </p>
                                </Fragment>
                            )}
                        </Fragment>
                    ) : (
                        <Fragment>
                            <p className="text-[#9291A5] text-sm md:text-base">Expressions Received</p>
                            <p className="text-[#1E1B39] font-semibold md:font-bold text-sm  md:text-lg">
                                {data?.projects_by_pk?.expressions_aggregate?.aggregate?.count ?? 0}
                            </p>
                        </Fragment>
                    )}
                </div>
                <div className="border flex flex-col mb-3 rounded px-2 py-1 w-full">
                    <p className="text-[#9291A5] text-sm md:text-base">Invites sent</p>
                    <p className="text-[#1E1B39] font-semibold md:font-bold text-sm md:text-lg">
                        {invitesData?.invite_links_aggregate?.aggregate?.count + inviteNumbers ?? 0}
                    </p>
                </div>
                <div className="border flex flex-col rounded px-2 py-1 w-full mb-3">
                    <p className="text-[#9291A5] text-sm md:text-base">Comments Received</p>
                    <p className="text-[#1E1B39] font-semibold text-sm md:font-bold md:text-lg">
                        {data?.projects_by_pk?.comments_aggregate?.aggregate?.count ?? 0}
                    </p>
                </div>
                {data?.projects_by_pk?.project_status === "completed" && (
                    <div className="border flex flex-col  rounded px-2 py-1 w-full mb-4 pb-4">
                        <p className="text-sm  mb-2 text-gray-400 font-medium">Expression Progress</p>
                        <p className="text-blue-500  mb-2 font-medium text-sm">100% Received</p>
                        <Progress value={100} className="w-full bg-gray-100" />
                    </div>
                )}
                {data?.projects_by_pk?.project_status !== "completed" && <hr />}

                {data?.projects_by_pk?.project_status !== "completed" && (
                    <div className="mt-3 flex flex-grow h-[340px] w-full">
                        {/* {chartsData.length > 0 ? (
                        <canvas id="acquisition" height={340}></canvas>
                    ) : (
                        <div className="flex items-center justify-center h-full w-full">
                            <p className="text-gray-600">No Expressions Received Yet</p>
                        </div>
                    )} */}
                        <canvas id={chartId} height={340}></canvas>
                    </div>
                )}
            </div>
            {/* <AreaChart
                className="h-80 tremor-border-gray-100"
                data={chartsData}
                index="date"
                categories={["Amount"]}
                colors={["#2D5BFF"]}
                valueFormatter={dataFormatter}
                yAxisWidth={60}
                customTooltip={customTooltip}
                showAnimation={true}
                showGridLines={true}
                connectNulls={true}
                allowDecimals={false}
                showLegend={false}
                noDataText="No Expressions Received Yet"
                intervalType="equidistantPreserveStart"
            /> */}
        </m.div>
    );
};

export default ProfileAnalytics;
