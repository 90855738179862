"use client"

import * as React from "react"
import { CheckIcon, ClipboardIcon } from "@radix-ui/react-icons"
import { Button } from "./button"

export function BlockCopyCodeButton({
  name,
  code,
}) {
  const [hasCopied, setHasCopied] = React.useState(false)

  React.useEffect(() => {
    setTimeout(() => {
      setHasCopied(false)
    }, 2000)
  }, [hasCopied])

  return (

        <Button
          size="icon"
          variant="outline"
          className="h-7 w-7 [&_svg]:size-3.5"
          onClick={() => {
            navigator.clipboard.writeText(code)
            setHasCopied(true)
          }}
        >
          <span className="sr-only">Copy</span>
          {hasCopied ? <CheckIcon /> : <ClipboardIcon />}
        </Button>

  )
}