import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { RegisterModalFooter } from "..";

function Other({ otherState, setOtherState, email, setOpen, registerUser }) {
    let { otherName, otherPhone, otherAddress } = otherState;

    const handleOtherChange = (e) => {
        setOtherState((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };

    const handleBackClick = () => {
        setOpen("usertype");
    };

    const handleForwardClick = () => {
        if (!otherName || !otherPhone || !otherAddress) {
            return alert("please fill all  other  details");
        }
        // const checkEmail = validateEmail(otherEmail);

        // if (!checkEmail.status) {
        //     return alert("enter valid other email");
        // }

        var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
        if (!re.test(otherPhone)) {
            return alert("enter valid other phone number");
        }
        registerUser();
    };

    return (
        <Grid item xs={12} className="common-modal__container" container direction="column" justifyContent="flex-start" alignItems="flex-start">
            <h3>
                Sign up as <span className="span-text">Others.</span>
            </h3>
            <Grid item xs={12} className="field__grid" container justifyContent="space-between" alignItems="center" mb="15px">
                <TextField
                    variant="standard"
                    label="Name"
                    className="input__field"
                    fullWidth
                    type="text"
                    name="otherName"
                    value={otherName}
                    onChange={handleOtherChange}
                />
                <TextField variant="standard" label="Email" disabled className="input__field" fullWidth type="text" name="otherEmail" value={email} />
            </Grid>

            <Grid item xs={12} className="field__grid" container justifyContent="space-between" alignItems="center" mb="15px">
                <TextField
                    variant="standard"
                    label="Address"
                    className="input__field"
                    fullWidth
                    type="text"
                    name="otherAddress"
                    value={otherAddress}
                    onChange={handleOtherChange}
                />
                <TextField
                    variant="standard"
                    label="Contact"
                    className="input__field"
                    fullWidth
                    type="text"
                    onKeyPress={(event) => {
                        // ignore "-", "+" and "e"
                        if (event?.key === "-" || event?.key === "+" || event?.key === "e") {
                            event.preventDefault();
                        }
                        // if character is not number then ignore except decimal point and
                        if (event?.key !== "." && event?.key && isNaN(+event.key)) {
                            event.preventDefault();
                        }
                        if (event.target.value.length >= 10) {
                            event.preventDefault();
                        }
                    }}
                    InputProps={{
                        maxLength: 10,
                    }}
                    name="otherPhone"
                    value={otherPhone}
                    onChange={handleOtherChange}
                />
            </Grid>
            <Grid item xs={12} container direction="row" justifyContent="space-between" alignItems="center" className="button__container">
                <Button className="back__btn" type="button" onClick={handleBackClick}>
                    Back
                </Button>
                <Button className="continue__btn" type="button" onClick={handleForwardClick}>
                    Save and continue
                </Button>
            </Grid>
            <RegisterModalFooter />
        </Grid>
    );
}

export default Other;
