import React, { useEffect, useContext, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAcceptInviteMutation, useGetRegionAdminQuery, useCountClickedInviteLinksMutation } from "types/generated-types.ts";
import { toast } from "react-toastify";
import AuthUserContext from "contexts/session";
import { useJwt } from "react-jwt";

const InvitePage = () => {
    let { inviteId } = useParams();
    const navigate = useNavigate();

    const queryRan = useRef(false);

    const [acceptInvite, { loading }] = useAcceptInviteMutation();

    const [countClickedInviteLinks] = useCountClickedInviteLinksMutation();

    const { data: regionAdminData } = useGetRegionAdminQuery({});

    const adminAccessToken = window.localStorage.getItem("admin_access_token");
    const authToken = window.localStorage.getItem("authToken");

    const isLoggedIn = adminAccessToken || authToken;

    const { authUser, refetchProfile } = useContext(AuthUserContext);

    console.log({ inviteId });

    useEffect(() => {
        console.log("checking if the mutation is working or not");
        console.log({ inviteId });
        if (inviteId) {
            countClickedInviteLinks({
                variables: {
                    inviteId: inviteId,
                },
            });
        }
        console.log("checking if the mutation is working or not 222");

        const redirectLink = "/" + "invite-users" + "/" + inviteId;

        localStorage.setItem("redirectLink", redirectLink);

        if (!isLoggedIn) {
            navigate("/signup");
        }
    }, [isLoggedIn, navigate]);

    console.log(authUser);

    useEffect(() => {
        if (isLoggedIn && regionAdminData?.users && regionAdminData?.users.length > 0 && authUser?.is_invited_to_all_projects === true) {
            navigate(`/profile-details?id=${regionAdminData?.users[0].id}`);
        }
    }, [regionAdminData, authUser, isLoggedIn]);

    useEffect(() => {
        if (isLoggedIn && inviteId) {
            const acceptInvitation = async () => {
                if (queryRan.current) {
                    return;
                }
                queryRan.current = true;
                const { data, errors } = await acceptInvite({
                    variables: {
                        id: inviteId,
                    },
                });

                refetchProfile();

                if (data?.acceptInvite) {
                    navigate(`/profile-details?id=${regionAdminData?.users[0].id}`);
                } else if (errors?.[0]?.extensions?.exception?.message) {
                    toast.error(errors[0].extensions.exception.message);
                } else {
                    toast.error("Something went wrong. Please try again.");
                }
            };

            acceptInvitation().catch(console.error);
        }
    }, [inviteId, isLoggedIn, acceptInvite, navigate, regionAdminData?.users, refetchProfile]);

    console.log({ inviteId });

    return (
        <div className="w-full h-full flex justify-center items-center p-auto">
            {loading ? "Accepting Invitation ..." : "Redirecting..."}
            {/* <div className="w-1/2 h-fit flex px-4 py-6 items-center justify-center">
                <button className="bg-blue-500 text-white px-4 py-2 rounded mr-6" onClick={handleAcceptInvite}>
                    Accept Invitation
                </button>
                <button className="border text-black px-4 py-2 rounded" onClick={() => navigate("/")}>
                    Cancel
                </button>
            </div> */}
        </div>
    );
};

export default InvitePage;
