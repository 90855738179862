import React, { Fragment, useContext, useState } from "react";
import Linkify from "react-linkify";
import { useNavigate } from "react-router-dom";
import { Avatar, Chip, Grid, Typography, Button } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import AuthUserContext from "contexts/session";
import verified from "../../../../assets/user-verified.png";
import CommentInput from "./CommentInput";
import MentionToLinks from "./MentionToLinks";
import ReplyCommentInput from "./ReplyCommentInput";
import ProjectComment from "views/Profile/ProjectComment";
import EditComment from "./EditComment";
import { useGetCurrentUserLazyQuery } from "types/generated-types.ts";
import { MessageCircleReply } from "lucide-react";
import { Reply } from "lucide-react";
import { create } from "zustand";

// export const useOpenReply = create((set) => ({
//     openReply: false,
//     action: {
//         toggleReply: () => set((state) => ({ openReply: !state.openReply })),
//     },
// }));

const ExecutedProjectsComments = [
    "Congratulations on completing such a remarkable project! Your dedication and hard work truly shine through in every aspect.",

    "I'm thoroughly impressed by the creativity and attention to detail evident in this project. It's clear that a lot of thought and effort went into making it a success.",

    "What an incredible achievement! Your project serves as an inspiration to others, showcasing what can be accomplished with passion and determination.",

    "I'm amazed by the level of innovation displayed in this project. It's refreshing to see new ideas being brought to life in such a thoughtful and impactful way.",

    "This project is a testament to your team's expertise and collaborative spirit. Your ability to work together seamlessly has resulted in something truly exceptional.",

    "Kudos to everyone involved in bringing this project to fruition! Your commitment to excellence is evident in every aspect of its execution.",

    "I'm blown away by the results of this project. It's clear that you've set a high standard for quality and excellence.",

    "This project not only meets but exceeds expectations. It's a testament to your team's ingenuity and determination to push the boundaries.",

    "I'm thrilled to see the positive impact that this project is already having. It's a testament to the power of innovation and collaboration.",

    "Bravo! This project represents a significant milestone, showcasing the potential for positive change through creativity and dedication.",

    "I'm truly impressed by the thoughtfulness and thoroughness evident in every aspect of this project. It's clear that a lot of heart and soul went into its development.",

    "This project is a shining example of what happens when talent, dedication, and vision come together. It's been a privilege to witness such a remarkable achievement.",

    "It's evident that this project was approached with a keen understanding of both the problem and the solution. The result is a testament to your team's strategic thinking and execution.",

    "This project not only meets the brief but also surpasses it in terms of innovation and impact. It's a testament to your team's ability to think outside the box.",

    "I'm continually impressed by the level of professionalism demonstrated throughout this project. It's clear that every detail was carefully considered and executed with precision.",

    "The level of craftsmanship displayed in this project is truly exceptional. It's evident that each member of the team brought their unique skills and expertise to the table.",

    "This project is a testament to what can be achieved when passion meets purpose. It's inspiring to see how your team has translated vision into reality.",

    "I'm in awe of the transformative power of this project. It's evident that you've not only created something remarkable but also laid the groundwork for lasting change.",

    "This project serves as a reminder of the importance of perseverance and resilience in the face of challenges. Your team's ability to adapt and overcome obstacles is truly commendable.",

    "What a remarkable journey this project has been! From inception to completion, it's been a testament to your team's unwavering commitment to excellence.",
];

const CommentBar = ({ parentComment, commentsData, projectId, toggleAuthModal, refetch }) => {
    const navigate = useNavigate();
    const [{ commentIndex }, setOpenReplyState] = useState({ commentIndex: "", openReply: false });
    const [showReply, setShowReply] = useState(false);

    const authLocal = localStorage.getItem("authUser");

    const { authUser } = useContext(AuthUserContext);

    const childComments = commentsData?.comments.filter((item) => item.parent_comment_id === parentComment.id);
    console.log(parentComment?.comment, commentsData, childComments);
    const userTypeDetails = parentComment?.user?.user_type_details ? JSON.parse(parentComment.user.user_type_details) : {};
    const userName = userTypeDetails.name || "No name";

    console.log({ parentComment });
    const hasReplied = childComments.some((comment) => comment.added_by === authUser.id);

    console.log({ childComments });

    console.log({ authUser });

    return (
        <div className="comment-item mt-3 border-b pb-2 ">
            <div className="flex flex-col gap-2  w-full">
                <div
                    className={`flex w-full items-center text-sm gap-2 px-2 ${
                        parentComment.added_by !== authUser.id && authUser.role !== "admin" ? "blur-sm rounded-md px-2" : ""
                    }`}
                >
                    <Avatar src={parentComment?.user?.profile_picture || ""} alt="Avatar" className="comment-avatar rounded-full mb-1 mr-2 border" />

                    <p className="flex gap-2 w-full items-center ">
                        <span className="text-black/80  w-fit">
                            {parentComment?.added_by === authUser.id ? "You" : parentComment?.user?.user_name}
                        </span>
                        <span>: </span>

                        <span className="text-black text-start flex-grow">
                            {parentComment?.comment.indexOf("@[") > -1 && <MentionToLinks text={parentComment && parentComment.comment} />}
                            {parentComment?.comment.indexOf("http") > -1 && <Linkify>{parentComment?.comment} </Linkify>}
                            {parentComment?.comment.indexOf("http") < 0 && parentComment?.comment.indexOf("@[") < 0 && parentComment?.comment}
                        </span>
                    </p>

                    <p className="text-gray-500 font-medium text-xs text-end justify-end flex flex-grow  w-20">
                        {new Date(parentComment?.updated_at?.substring(0, 10)).toLocaleDateString("en-US", {
                            month: "short",
                            day: "2-digit",
                        }) ||
                            new Date(parentComment?.created_at?.substring(0, 10)).toLocaleDateString("en-US", {
                                month: "short",
                                day: "2-digit",
                            }) ||
                            ""}
                    </p>
                </div>
                {/* {!hasReplied && authUser?.role === "admin" && (
                    <div className="px-16 w-full cursor-pointer text-gray-600 pb-2" onClick={() => action.toggleReply()}>
                        <p className="text-xs">Reply</p>
                    </div>
                )} */}
                {(parentComment?.added_by === authUser?.id || authUser?.role === "admin") && childComments?.length > 0 && (
                    <div className="px-16 w-fit cursor-pointer text-gray-600 pb-2">
                        <p className="text-xs" onClick={() => setShowReply(!showReply)}>
                            {authUser?.role === "admin" ? "View Your Reply" : "View Admin's Reply"}
                        </p>
                    </div>
                )}
            </div>

            {/* <Button
                    variant="contained"
                    className="reply-btn bg-blue-500 mt-1"
                    disabled={authUser && authLocal ? false : true}
                    onClick={() => {
                        setOpenReplyState({ commentIndex: "parent", openReply: !openReply });
                    }}
                >
                    Reply
                </Button> */}

            <div className="w-full pb-2">
                <ReplyCommentInput
                    hasReplied={hasReplied}
                    refetch={refetch}
                    isReply={true}
                    projectId={projectId}
                    profilePicture={authUser?.profile_picture}
                    parentComment={parentComment}
                    parentUser={parentComment.user}
                />
            </div>

            {showReply && childComments.length > 0 ? (
                <Fragment>
                    {childComments.map((item, index) => (
                        <div className="flex w-full flex-col  ">
                            <div className="flex w-full px-10 py-2  justify-between items-center">
                                <div className="flex items-center w-full ">
                                    <Avatar src={item?.user?.profile_picture} alt="Avatar" className="w-8 h-8 mr-3" />
                                    <div className="flex items-center w-full ">
                                        <p className="text-sm flex gap-2 items-center text-black/80">
                                            {authUser?.role === "admin" ? <p>You</p> : <p>{item?.user?.user_name || userName}</p>}
                                            <span>: </span>
                                            <span className="text-sm text-black ">{item && item.comment}</span>
                                        </p>
                                    </div>
                                    <div className="flex  w-full md:w-1/2 items-center justify-end">
                                        <p className="text-xs mr-2">
                                            {new Date(item?.created_at?.substring(0, 10)).toLocaleDateString("en-US", {
                                                month: "short",
                                                day: "2-digit",
                                            })}
                                        </p>
                                        {hasReplied && authUser?.role === "admin" && (
                                            <EditComment comment={item.comment ?? ""} id={item.id} refetch={refetch} />
                                        )}
                                    </div>
                                </div>

                                {/* {item?.user?.role === "sme" ? (
                                    <Chip label="expert" size="small" className="chip-expert" color="secondary" />
                                ) : (
                                    <Fragment></Fragment>
                                )} */}
                            </div>

                            {/* <Button
                                variant="contained"
                                className="reply-btn"
                                disabled={authUser && authLocal ? false : true}
                                onClick={() => {
                                    setOpenReplyState({ commentIndex: index, openReply: true });
                                }}
                            >
                                <img src="/assets/images/reply.svg" alt="" />
                                Reply
                            </Button> */}
                            {/* {commentIndex === index && openReply && (
                                <Fragment>
                                    {authUser && authLocal ? (
                                        <CommentInput isReply={true} projectId={projectId} parentComment={parentComment} parentUser={item.user} />
                                    ) : (
                                        <Grid item xs={12} alignItems="center">
                                            <Button variant="contained" className="btn" onClick={() => toggleAuthModal("login")}>
                                                Login to Add Comments
                                            </Button>
                                        </Grid>
                                    )}
                                </Fragment>
                            )} */}
                        </div>
                    ))}
                </Fragment>
            ) : (
                <Fragment>
                    {/* {authUser?.role === "admin" && <Typography className="text-center h-full my-4 text-gray-500">No comments yet</Typography>} */}
                </Fragment>
            )}
        </div>
    );
};

export default CommentBar;
