import React from "react";
import { components } from "react-select";

const DropDownMenu = ({ children, ...props }) => {
    return (
        <components.Menu
            {...props}
            className={`z-1000 m-0 w-fit whitespace-nowrap rounded-none border border-t-0 border-gray-200 shadow-none transition-all duration-300
                ${props.className || ""} 
            `}
        >
            {children}
        </components.Menu>
    );
};

export default DropDownMenu;
