import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import "./PressRelease.styles.scss";

const PressRelease = () => {
    return (
        <Grid container direction="row" justifyContent="center" alignItems="center" className="press-release__container">
            <Grid item container direction="column" justifyContent="center" alignItems="center" className="header__container">
                <Typography className="header-label-1">Home >> Press Release</Typography>

                <Typography className="header-title">Press Release</Typography>

                <Typography className="header-label-2">
                    If you have business inquiries or other questions, please fill out the following form to contact us. Thank you.
                </Typography>
            </Grid>
            <Grid item container direction="column" justifyContent="flex-start" alignItems="flext-start" className="body__container">
                <Typography className="body-title">Press Release</Typography>
                <Divider />
                <Accordion elevation={0} className="accordion__container">
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography className="year-label">2017</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography className="accordion-content"> > IT firm develops platform to assist CSR projects</Typography>
                        <Typography className="accordion-content">
                            > Bodhtree Consulting’s leading CSR platform EquiPPP.com signs up with Kalamandir Foundation
                        </Typography>
                        <Typography className="accordion-content"> > Simplifying Corporate Social Responsibility through e-portal</Typography>
                        <Typography className="accordion-content"> > బోధ్ ట్రీ.. ఒప్పందం</Typography>
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>
    );
};

export default PressRelease;
