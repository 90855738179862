import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../components/ui/select";

import { useUser } from "contexts/user-type/user-type-context.js";

const CsrFoundation = () => {
    const navigate = useNavigate();
    const { state, setState, registerUser } = useUser();
    const { userType, csrFoundationState } = state;

    const all_projects = [
        "Agriculture",
        "Community",
        "Education",
        "Environment",
        "HealthCare",
        "Housing",
        "InfraStructurePower",
        "Roads and Bridges",
        "Social Justice",
        "Technology",
        "Waste Management",
        "Water & Sanitization",
        "Others",
    ];

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            csrFoundationState: {
                ...prevState.csrFoundationState,
                [name]: value,
            },
        }));
    };

    const handleSelectChange = (value) => {
        setState((prevState) => ({
            ...prevState,
            csrFoundationState: {
                ...prevState.csrFoundationState,
                csrContributionCategories: value,
            },
        }));
    };

    const handleForwardClick = () => {
        if (
            !csrFoundationState.csrFoundationName ||
            !csrFoundationState.csrContributionCategories.length === 0 ||
            !csrFoundationState.csrPointOfContact ||
            !csrFoundationState.csrAddress ||
            !csrFoundationState.csrRepresentativeRole
        ) {
            return alert("please fill all CSR foundation  details");
        }

        registerUser();
    };

    return (
        <div className="flex h-full w-full justify-center items-center my-auto flex-col bg-[#F9F9F9] md:px-0 px-6  overflow-y-auto">
            <div className="flex justify-center flex-col mb-10 md:mb-4">
                <p className="text-[#0D0140] font-bold text-2xl md:text-3xl text-center">Create Account as {userType}</p>
                <p className="text-[#0D0140] text-center font-thin text-base mt-2">Please fill the below options to continue</p>
            </div>
            <form className="w-full md:w-1/3">
                {/* <div className="mb-4 ">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2" for="username">
                        Email
                    </label>
                    <input
                        className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
                        type="email"
                        placeholder="Brandonelouis@gmail.com"
                        disabled
                    />
                </div> */}
                <div className="mb-4 ">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2" for="username">
                        Foundation Name
                    </label>
                    <input
                        className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
                        type="text"
                        placeholder="Foundation Name"
                        name="csrFoundationName"
                        value={csrFoundationState.csrFoundationName}
                        onChange={handleInputChange}
                    />
                </div>

                <div className="mb-4 ">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2" for="username">
                        Focus of Interest
                    </label>
                    <Select
                        className="modal-select-input"
                        value={csrFoundationState.csrContributionCategories || []}
                        onValueChange={handleSelectChange}
                    >
                        <SelectTrigger aria-label="Focus of Interest" className="border-0">
                            <SelectValue placeholder="Select Focus of Interest" />
                        </SelectTrigger>
                        <SelectContent>
                            {all_projects.map((project) => (
                                <SelectItem key={project} value={project}>
                                    {project}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
                <div className="mb-4 ">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2" for="username">
                        Address
                    </label>
                    <input
                        className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
                        type="text"
                        value={csrFoundationState.csrAddress}
                        onChange={handleInputChange}
                        name="csrAddress"
                        placeholder="Address"
                    />
                </div>
                <div className="mb-4 ">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2">Contact</label>
                    <input
                        className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
                        type="text"
                        name="csrPointOfContact"
                        value={csrFoundationState.csrPointOfContact}
                        onChange={handleInputChange}
                        placeholder="Contact"
                    />
                </div>
                <div className="mb-4 ">
                    <label className="block text-[#0D0140] text-sm font-bold mb-2">Representative Role</label>
                    <input
                        className="appearance-none border-0 rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline"
                        type="text"
                        placeholder="Respresentative Role"
                        name="csrRepresentativeRole"
                        value={csrFoundationState.csrRepresentativeRole}
                        onChange={handleInputChange}
                    />
                </div>

                <div className="flex flex-col items-center w-full px-8 ">
                    <button
                        className="bg-[#130160] w-full text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="button"
                        onClick={handleForwardClick}
                    >
                        CONTINUE
                    </button>
                </div>
            </form>
        </div>
    );
};

export default CsrFoundation;
