import { React, useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ShareIcon from "@mui/icons-material/Share";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useDeleteProjectMutation, useUpdateProjectStatusMutation } from "types/generated-types.ts";
import ShareModal from "components/ShareModal";
import { ProjectTile } from "views/Projects/components";
import "./ApprovedProjects.scss";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "fit-content",
    bgcolor: "background.paper",
    border: "1px solid gray",
    boxShadow: 24,
    p: 3,
};
const ApprovedProjects = (props) => {
    const [accessToken, setAccessToken] = useState(false);
    const [tableRows, setTableRows] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [project, setProject] = useState("");
    const [open, setOpen] = useState(false);
    const [shareOpen, setShareOpen] = useState(false);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const history = useNavigate();

    useEffect(() => {
        const token = window.localStorage.getItem("authToken");

        setAccessToken(token);
        const rows = props.projectDetails?.map((el) => {
            return {
                projectName: el.title,
                projectCategory: el.category,
                projectType: el.type,
                projectLocation: el.location_title,
                startDate: el.start_date.substring(0, 10),
                endDate: el.end_date.substring(0, 10),
                status: (
                    <Grid item container className="action__btns" justifyContent="center" alignItems="center" flexDirection="column">
                        <Typography style={{ fontSize: "1rem" }}>{el.is_active ? "Active" : "Inactive"}</Typography>
                        <Button
                            variant="outlined"
                            className="status_btn"
                            onClick={() => {
                                updateProjectStatus({
                                    variables: {
                                        _set: {
                                            is_active: !el.is_active,
                                        },
                                        id: el.id,
                                    },
                                });
                            }}
                        >
                            {`Change Status to ${el.is_active ? "Inactive" : "Active"}`}
                        </Button>
                    </Grid>
                ),
                editandreportBtns: (
                    <Grid item xs={"auto"} container direction="row" alignItems="center" justifyContent="center" className="action__btns">
                        <Button
                            className="outlined-btn"
                            variant="outlined"
                            onClick={() => {
                                history(`/admin/edit-project/${el.id}`);
                            }}
                        >
                            <EditIcon className="edit-icon" />
                            Edit
                        </Button>
                        <Button
                            className="outlined-btn"
                            variant="outlined"
                            onClick={() => {
                                setProject(el);
                                setOpen(true);
                            }}
                        >
                            <DeleteIcon className="edit-icon" />
                            Delete
                        </Button>
                        <Button
                            className="outlined-btn"
                            variant="outlined"
                            onClick={() => {
                                history(`/projects/project-report/${el.id}`);
                            }}
                        >
                            <VisibilityIcon className="edit-icon" />
                            View Report
                        </Button>
                        <Button
                            className="outlined-btn"
                            variant="outlined"
                            onClick={() => {
                                setProject(el);
                                setShareOpen(true);
                            }}
                        >
                            <ShareIcon className="edit-icon" />
                            Share
                        </Button>
                    </Grid>
                ),
            };
        });
        setTableRows(rows);
    }, [props.projectDetails]);

    const [updateProjectStatus] = useUpdateProjectStatusMutation({
        onCompleted: (data) => {
            if (data.update_projects.returning[0]) {
                toast.success("Status Changed Successfully");
                return;
            } else {
                toast.error("Something Went Wrong");
                return;
            }
        },
    });

    const [deleteProject] = useDeleteProjectMutation({
        onCompleted: (data) => {
            if (data) {
                toast.success("Project Deleted Successfully");
                props.refetch();
                setOpen(false);
            }
        },
        onError: (error) => {
            toast.error("Something went wrong");
        },
    });

    return (
        <Fragment>
            {!!accessToken ? (
                <Grid item>
                    <TableContainer>
                        <Table>
                            <TableHead className="table-header-font-style">
                                <TableRow>
                                    <TableCell>Project Status</TableCell>
                                    <TableCell>Project Name</TableCell>
                                    <TableCell>Project Category</TableCell>
                                    <TableCell>Project Type</TableCell>
                                    <TableCell>Project Location</TableCell>
                                    <TableCell>Start Date</TableCell>
                                    <TableCell>End Date</TableCell>
                                    <TableCell align="center">Options</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableRows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell style={{ width: "10%" }}>{row.status}</TableCell>

                                        <TableCell component="td" scope="row" align="left">
                                            {row.projectName}
                                        </TableCell>
                                        <TableCell>{row.projectCategory}</TableCell>
                                        <TableCell>{row.projectType}</TableCell>
                                        <TableCell>{row.projectLocation}</TableCell>
                                        <TableCell style={{ whiteSpace: "none", width: "8rem" }}>{row.startDate}</TableCell>
                                        <TableCell style={{ whiteSpace: "none", width: "8rem" }}>{row.endDate}</TableCell>
                                        <TableCell>{row.editandreportBtns}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <ShareModal project_id={project.id} shareOpen={shareOpen} setShareOpen={setShareOpen} props={props} />
                        <Modal open={open} onClose={() => setOpen(false)}>
                            <Box sx={style}>
                                <Grid container className="edit-delete-timeline__modal">
                                    <Grid item xs={12} container justifyContent="center">
                                        <Typography className="delete-title">Are you sure you want to delete this Project?</Typography>
                                    </Grid>
                                    <Grid item xs={12} container>
                                        <ProjectTile project={project} />
                                    </Grid>
                                    <Grid item xs={12} container justifyContent="flex-end">
                                        <Button
                                            variant="standard"
                                            className="btn close"
                                            onClick={() => {
                                                deleteProject({
                                                    variables: {
                                                        id: project?.id,
                                                    },
                                                });
                                            }}
                                        >
                                            Yes
                                        </Button>
                                        <Button variant="standard" className="btn save" onClick={() => setOpen(false)}>
                                            No
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Modal>
                    </TableContainer>
                    {tableRows && tableRows.length > 0 && (
                        <TablePagination
                            component="div"
                            count={tableRows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            rowsPerPageOptions={[5, 10]}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    )}
                </Grid>
            ) : (
                <>Not logged in</>
            )}
        </Fragment>
    );
};

export default ApprovedProjects;
