// This is expressions page BEWARE
import { React, useEffect, useState } from "react";
import { TextField } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import "bootstrap/dist/css/bootstrap.min.css";
import { useGetExpressionQuery } from "types/generated-types.ts";
import ApprovedExpressions from "../ApprovedExpressions/ApprovedExpressions";
import PendingExpressions from "../PendingExpressions/PendingExpressions";
import "./AdminDashboard.scss";

const AdminDashboard = () => {
    // This is expressions page BEWARE
    // This is expressions page BEWARE
    // This is expressions page BEWARE

    const [tab, setTab] = useState("pending");
    const [approvedExpressionList, setApprovedExpressionList] = useState(() => []);
    const [pendingExpressionList, setPendingExpressionList] = useState(() => []);
    const [searchValue, setSearchValue] = useState("");

    const { data } = useGetExpressionQuery();

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
        setSearchValue("");
    };

    useEffect(() => {
        if (data && data?.expressions?.length > 0) {
            let approvedExps = [],
                pendingExps = [];
            data?.expressions?.forEach((expression) => {
                expression.is_verified === true ? approvedExps.push(expression) : pendingExps.push(expression);
            });
            setApprovedExpressionList(approvedExps);
            setPendingExpressionList(pendingExps);
        }
    }, [data]);

    useEffect(() => {
        if (searchValue === "") {
            if (data && data.expressions) {
                let approvedExp = [],
                    pendingExp = [];
                data.expressions.forEach((expression) => {
                    expression.is_verified === true ? approvedExp.push(expression) : pendingExp.push(expression);
                });
                setApprovedExpressionList(approvedExp);
                setPendingExpressionList(pendingExp);
                return;
            }
        }
        if (tab === "pending") {
            const filteredExpressions = data?.expressions.filter(
                (item) => item.project.title.toLowerCase().includes(searchValue) && item.is_verified === false
            );
            setPendingExpressionList(filteredExpressions);
        } else {
            const filteredExpressions = data?.expressions.filter(
                (item) => item.project.title.toLowerCase().includes(searchValue) && item.is_verified === true
            );
            setApprovedExpressionList(filteredExpressions);
        }
    }, [searchValue]);

    const performedAction = (action, id) => {
        setPendingExpressionList((oldValue) => {
            return oldValue.filter((exp) => {
                return exp.id !== id;
            });
        });

        if (action === "approve") {
            setApprovedExpressionList((oldValue) => {
                return oldValue.filter((exp) => {
                    return exp.id !== id;
                });
            });
        }
    };

    return (
        <div className="admin-dashboard__container">
            <Typography className="title">Expressions</Typography>
            <br />
            <Tabs scrollButtons={false} value={tab} className="status__tabs tabs__container" onChange={handleTabChange}>
                <Tab disableRipple className={`single__tab`} label={"Approved"} value="approved" wrapped />
                <Tab disableRipple className={`single__tab`} label={"Pending"} value="pending" />
            </Tabs>
            <TextField
                sx={{ my: 2, width: "50%" }}
                placeholder="Search by project name"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
            />
            <br />
            <div className="exp-table">
                {tab === "pending" ? (
                    <PendingExpressions
                        expressionDetails={pendingExpressionList}
                        action={performedAction}
                        investment_amount={data?.investment_amount}
                    />
                ) : tab === "approved" ? (
                    <ApprovedExpressions expressionDetails={approvedExpressionList} action={performedAction} />
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

export default AdminDashboard;
