import React from "react";
import Select from "react-select";
import { DropDownControl, DropDownMenu, DropDownOption, DropDownSingleValue, DropDownValueContainer, DropDownMultiValueRemove } from "./components";

const CustomSelect = ({ placeholder, defaultValue, options, multiple, isDisabled, reactHookFormRegister }) => {
    return (
        <Select
            options={options}
            isDisabled={isDisabled || false}
            clearValue={() => {
                reactHookFormRegister?.onChange({
                    target: {
                        value: null,
                        name: reactHookFormRegister.name,
                    },
                });
            }}
            isMulti={multiple ?? false}
            isSearchable={true}
            placeholder={placeholder}
            getOptionLabel={(option) => option.label || ""}
            classNames={{
                indicatorSeparator: () => "!hidden",
                menuList: () => "!p-0 !w-full",
                groupHeading: () => "!hidden",
                menu: () => "!w-full",
            }}
            components={{
                Option: DropDownOption,
                Control: DropDownControl,
                Menu: DropDownMenu,
                SingleValue: DropDownSingleValue,
                ValueContainer: DropDownValueContainer,
                MultiValueRemove: DropDownMultiValueRemove,
            }}
            defaultValue={defaultValue}
            {...reactHookFormRegister}
            onChange={(value) => {
                reactHookFormRegister?.onChange({
                    target: {
                        value,
                        name: reactHookFormRegister.name,
                    },
                });
            }}
            menuShouldBlockScroll={false}
            menuShouldScrollIntoView={true}
            isClearable={true}
            hideSelectedOptions={false}
            backspaceRemovesValue={false}
            captureMenuScroll={false}
        />
    );
};

export default CustomSelect;
